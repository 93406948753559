import type { ClassProp } from '../../../util';
import type { SnmpProbe } from '../snmp-probe';
import type { SnmpProbeRadioModel } from '../snmp-probe-filter-item';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { Chip } from '../../../components';
import { useClipboardController } from '../../../util';
import './snmp-probe-view.scss';


type RadioModelListItemProps = {
	radioModel: SnmpProbeRadioModel;
};
const RadioModelListItem = ({ radioModel }: RadioModelListItemProps) => {
	useObservable(radioModel, 'selected');

	return (
		<li>
			<Chip
				tag="button"
				onClick={ radioModel.select }
				highlight={ radioModel.selected }
				interactive={ !radioModel.selected }
			>
				{ radioModel.name }
			</Chip>
		</li>
	);
};

type SnmpProbeDetail = { // eslint-disable-line @typescript-eslint/no-unused-vars
	label: string;
	copy: boolean;
};
const TABLE = {
	'probeType': {
		label: 'Type',
		copy: false,
	},
	'manufacturerName': {
		label: 'Manufacturer',
		copy: false,
	},
	'name': {
		label: 'Name',
		copy: true,
	},
	'oid': {
		label: 'SNMP OID',
		copy: true,
	},
	'minimum': {
		label: 'Minimum Value',
		copy: true,
	},
	'maximum': {
		label: 'Maximum Value',
		copy: true,
	},
	'dataType': {
		label: 'Data Type',
		copy: false,
	},
	'unitOfMeasurement': {
		label: 'Unit of Measurement',
		copy: true,
	},
} as const satisfies Partial<Record<keyof SnmpProbe, SnmpProbeDetail>>;
const TABLE_KEYS = Object.keys(TABLE) as readonly (keyof typeof TABLE)[];

type SnmpProbePropertyProps = {
	probe: SnmpProbe;
	property: keyof typeof TABLE;
};
const SnmpProbeProperty = ({ probe, property }: SnmpProbePropertyProps) => {
	const clipboard = useClipboardController();
	
	const { label, copy } = TABLE[property];
	const value = probe[property];
	
	const handleCopyClick = () => {
		clipboard.writeText(value.toString(), `Copied ${label} to clipboard.`);
	};

	const className = classNames('snmp-probe-view__property panel', {
		'snmp-probe-view__property--copyable': copy,
	});
	
	return (
		<div className={ className }>
			<dt className="text-bolder text-sm">{ label }</dt>
			<dd className={ `font-mono ${property}` }> { probe[property] }</dd>
			{ clipboard.enabled && copy && (
				<button
					className="btn btn--icon-only"
					onClick={ handleCopyClick }
					aria-label={ `Copy ${label} to clipboard` }
				>
					<i class="fa fa-copy"/>
				</button>
			)}
		</div>
	);
};

export type SnmpProbeViewProps = ClassProp & {
	probe: SnmpProbe
};
export const SnmpProbeView = ({ className, probe }: SnmpProbeViewProps) => (
	<div className={ classNames('snmp-probe-view panel panel--dark', className) }>
		<div>
			<h2>{ probe.name }</h2>
		</div>

		<div>
			<dl className="snmp-probe-view__property-list panel-group">
				{ TABLE_KEYS.map(propertyName => (
					<SnmpProbeProperty probe={ probe } property={ propertyName } key={ propertyName }/>
				))}
			</dl>
		</div>

		<div className="snmp-probe-view__sql">
			<button className="btn" onClick={ probe.showSqlDialog }>
				Probe creation SQL
			</button>
		</div>

		{ !!probe.radioModels.length && (
			<div>
				<h3>Suitable Radio Models</h3>
				<ul
					className="snmp-probe-view__list"
				>
					{ probe.radioModels.map(radioModel => (
						<RadioModelListItem radioModel={ radioModel } key={ radioModel.name }/>
					))}
				</ul>
			</div>
		) }
	</div>
);