import type { ChildrenProp } from '../../util';
import type { SpectrumComparisonControllerConfig } from './spectrum-comparison-controller';
import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { NullContextError, useConstant } from '../../util';
import { SpectrumComparisonController } from './spectrum-comparison-controller';


const spectrumComparisonControllerContext = createContext<SpectrumComparisonController | null>(null);
export type SpectrumComparisonControllerProviderProps = ChildrenProp & SpectrumComparisonControllerConfig;
export const SpectrumComparisonControllerProvider = ({ site, equipment, frequencyBand, data, children }: SpectrumComparisonControllerProviderProps) => {
	const controller = useConstant(() => new SpectrumComparisonController({ site, equipment, frequencyBand, data }));

	return (
		<spectrumComparisonControllerContext.Provider value={ controller }>
			{ children }
		</spectrumComparisonControllerContext.Provider>
	);
};


export const useSpectrumComparisonController = (): SpectrumComparisonController => {
	const ctx = useContext(spectrumComparisonControllerContext);
	if(!ctx) {
		throw new NullContextError('spectrumComparisonControllerContext', 'SpectrumComparisonControllerProvider');
	}

	return ctx;
};