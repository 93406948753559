import type { Site } from '@brng/domain';
import type { IssueFilter } from '@brng/domain/src/network/issues/issue-set';
import type { VNode } from 'preact';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { DataRow, DataTable, Icon } from '../../../../components';
import { useServices } from '../../../../services';
import { useSitesInfo } from '../../use-sites-info';
import { InterSiteIssueRow, OverlapIssueRow, WarningIssueRow } from './site-issues-row';


export type SiteIssueTableSquelchMode = (
	| 'hidden'
	| 'interactive'
);

export type SiteIssuesTableProps = {
	site: Site;
	linkIssues?: boolean;
	squelchMode: SiteIssueTableSquelchMode;
	disableFocus?: boolean;
};

const NUM_ISSUES = 5;

export const SiteIssuesTable = ({ site, linkIssues, squelchMode, disableFocus }: SiteIssuesTableProps): VNode => {
	const { selectSite, interSiteRange } = useSitesInfo();
	const { squelchService } = useServices();
	useObservable(squelchService, 'squelches');
	
	const [ showAll, setShowAll ] = useState(false);
	
	if(site.devices.length === 0) {
		return (
			<DataTable>
				<DataRow>
					<strong>No RF equipment found at site.</strong>
				</DataRow>
			</DataTable>
		);
	}

	const issueFilter: IssueFilter = {
		includeSquelched: squelchMode !== 'hidden',
	};

	const frequencyOverlapIssues = site.issues.getByKind('frequencyOverlap', issueFilter);
	const interSiteIssues = site.issues.getByKind('interSite', issueFilter)
		.filter(i => i.range <= interSiteRange);

	const warnings = site.issues.getByKinds([ 'missingData', 'badStatus' ]);
	
	const all = [ ...frequencyOverlapIssues, ...interSiteIssues, ...warnings ];
	const shown = showAll ? all : all.slice(0, NUM_ISSUES);

	const handleClick = linkIssues ? () => selectSite(site.id) : undefined;
	
	return (
		<>
			<DataTable>
				{shown.map(issue => {
					switch(issue.kind) {
						case 'frequencyOverlap': return (
							<OverlapIssueRow
								squelchMode={ squelchMode }
								overlap={ issue }
								onClick={ handleClick }
								disableFocus={ disableFocus }
								key={ issue.id }
							/>
						);
						case 'interSite': return (
							<InterSiteIssueRow
								squelchMode={ squelchMode }
								onClick={ handleClick }
								site={ site }
								issue={ issue }
								key={ issue.id }
							/>
						);
						case 'badStatus':
						case 'missingData': return (
							<WarningIssueRow
								warning={ issue }
								onClick={ handleClick }
								disableFocus={ disableFocus }
								key={ issue.id }
							/>
						);
					}
				})}
			</DataTable>
			{ shown.length !== all.length && (
				<button
					className="btn btn--invisible"
					onClick={ () => setShowAll(true) }
				>
					<Icon icon="eye"/>
					<span>
						Show { all.length - shown.length } more issues
					</span>
				</button>
			)}
			{ shown.length === all.length && showAll && (
				<button
					className="btn btn--invisible"
					onClick={ () => setShowAll(false) }
				>
					<Icon icon="eyeOff" className="mrg-r-sm"/>
					<span>
						Hide { all.length - NUM_ISSUES } issues
					</span>
				</button>
			) }
		</>
	);
};
