import type { NetworkItem } from '@brng/domain';
import type { IconProps } from './icon';
import type { IconName } from './icons';
import type { JSX } from 'preact/jsx-runtime';
import { Icon } from './icon';


export const getNetworkItemIcon = (item: NetworkItem): IconName => {
	switch(item?.kind) {
		case 'device':
			return 'other';
		case 'site': {
			if(item.usage === 'Customer') {
				return 'customer';
			}
			const type = item.type.toLowerCase();
			switch(type) {
				case 'building':
				case 'house':
				case 'repeater':
				case 'silo':
				case 'tower':
					return type;
				default:
					return 'other';
			}
		}
	}
};

export type NetworkItemIconProps = Omit<IconProps, 'icon'> & {
	item: NetworkItem;
};
export const NetworkItemIcon = ({ item, ...iconProps }: NetworkItemIconProps): JSX.Element => (
	<Icon icon={ getNetworkItemIcon(item) } { ...iconProps }/>
);