import type { Device } from './device';
import type { Site } from './site';


export type NetworkItem = Site | Device;
export const isNetworkItemKind = (x: unknown): x is NetworkItem['kind'] => {
	switch(x) {
		case 'site':
		case 'device':
			return true;
		default:
			return false;
	}
};

export const isDevice = (x: NetworkItem): x is Device => x.kind === 'device';
export const isSite = (x: NetworkItem): x is Site => x.kind === 'site';