import type { VNode } from 'preact';
import { ISSUE_PAGE_ISSUE_KINDS, type IssuePageIssue } from '../process-analysis';
import { analysisIssueDescriptions } from '../analysis-issue-description';
import { useAnalysisIssues } from '../analysis-provider';


const labels: Record<IssuePageIssue['kind'], string> = {
	'outOfSector': 'Outside Sector Coverage',
	'outOfRange': 'Out of Range',
	'interSite': 'Inter-Site Interference',
};

export const AnalysisIssueKindSelect = (): VNode => {
	const { analysisIssuesFilter: { kind }, updateAnalysisIssuesFilter } = useAnalysisIssues();
	
	return (
		<select
			value={ kind }
			onChange={ ev => {
				updateAnalysisIssuesFilter({
					kind: ISSUE_PAGE_ISSUE_KINDS[ev.currentTarget.selectedIndex],
				});
			} }
		>
			{ ISSUE_PAGE_ISSUE_KINDS.map(kind => (
				<option
					value={ kind }
					key={ kind }
					title={ analysisIssueDescriptions[kind] }
				>
					{labels[kind]}
				</option>
			))}
		</select>
	);
};