import type { JSX } from 'preact';
import type { ChildrenProp, ClassProp } from '../../../util';
import { useObservable } from 'ecce-preact';
import { createPortal, useEffect, useState } from 'preact/compat';
import { useMapController } from '../../map-controller-context';
import './map-controls.scss';


export type MapControlPosition = (
	| 'BOTTOM_CENTER'
	| 'BOTTOM_LEFT'
	| 'BOTTOM_RIGHT'
	| 'LEFT_BOTTOM'
	| 'LEFT_CENTER'
	| 'LEFT_TOP'
	| 'RIGHT_BOTTOM'
	| 'RIGHT_CENTER'
	| 'RIGHT_TOP'
	| 'TOP_CENTER'
	| 'TOP_LEFT'
	| 'TOP_RIGHT'
);

export type MapControlsProps = ClassProp & ChildrenProp & {
	position: MapControlPosition;
};
/**
 * Wrap some components and render them inside a google map control.
 */
export const MapControls = ({ className, children, position }: MapControlsProps): JSX.Element | null => {
	const controller = useMapController();
	useObservable(controller, 'map');
	
	const [ container, setContainer ] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if(!controller.map || !position) {
			return;
		}

		const div = document.createElement('div');
		div.className='map-controls ' + className;
		controller.map.controls[google.maps.ControlPosition[position]].push(div);
		setContainer(div);
	}, [className, controller.map, position]);
	
	if(!container) {
		return null;
	}

	return createPortal(<>{children}</>, container); // eslint-disable-line react/jsx-no-useless-fragment
};
