import type { UrlSearchParamController } from '../../util/url-search-param-controller';
import type { NetworkItem } from '@brng/domain';
import { bound, observable } from 'ecce-preact';
import { NetworkFilter } from '../network-filter';


export type DeviceTypeFilterConfig = {
	urlParams?: UrlSearchParamController;
};
export class DeviceTypeFilter extends NetworkFilter {
	static readonly URL_PARAM_KEY = 'deviceType';
	static readonly URL_PARAM_DEFAULT = 'ap,bh,sm';
	
	#accessPoints = false;
	get accessPoints(): boolean { return this.#accessPoints; }
	@observable() private set accessPoints(value: boolean) { this.#accessPoints = value; }
	
	#backhauls = false;
	get backhauls(): boolean { return this.#backhauls; }
	@observable() private set backhauls(value: boolean) { this.#backhauls = value; }
	
	#subscriberModules = false;
	get subscriberModules(): boolean { return this.#subscriberModules; }
	@observable() private set subscriberModules(value: boolean) { this.#subscriberModules = value; }
	
	constructor(config?: DeviceTypeFilterConfig) {
		super({
			urlParams: config?.urlParams,
			urlParamKey: DeviceTypeFilter.URL_PARAM_KEY,
			urlParamDefault: DeviceTypeFilter.URL_PARAM_DEFAULT,
		});
		
		const init = this.getUrlParamValue().split(',');
		for(const value of init) {
			switch(value) {
				case 'ap':
					this.#accessPoints = true;
					continue;
				case 'bh':
					this.#backhauls = true;
					continue;
				case 'sm':
					this.#subscriberModules = true;
					continue;
			}
		}
	}

	@bound()
	setAccessPoints(show: boolean): this {
		if(show !== this.accessPoints) {
			this.accessPoints = show;
			this.changed();
		}
		
		return this;
	}
	
	@bound()
	setBackhauls(show: boolean): this {
		if(show !== this.backhauls) {
			this.backhauls = show;
			this.changed();
		}
		
		return this;
	}
	
	@bound()
	setSubscriberModules(show: boolean): this {
		if(show !== this.subscriberModules) {
			this.subscriberModules = show;
			this.changed();
		}
		
		return this;
	}
	
	protected filter(item: NetworkItem): boolean {
		if(item.kind !== 'device') {
			return true;
		}
		
		switch(item.type) {
			case 'Access Point': return this.accessPoints;
			case 'Backhaul': return this.backhauls;
			case 'Subscriber Module': return this.subscriberModules;
		}
	}

	protected toUrlParam(): string {
		const values: string[] = [];
		if(this.#accessPoints) {
			values.push('ap');
		}
		if(this.#backhauls) {
			values.push('bh');
		}
		if(this.#subscriberModules) {
			values.push('sm');
		}
		
		return values.join(',');
	}
}