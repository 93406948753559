import type { Device, Network, NetworkItem } from '@brng/domain';
import type { NetworkService } from '../../services';
import { bound, observable } from 'ecce-preact';
import { SiteUsageFilter } from '../../filtering';
import { DeviceTypeFilter } from '../../filtering/device-type-filter';
import { ItemKindFilter } from '../../filtering/item-kind-filter';
import { MapController } from '../../map';
import { NetworkQuery } from '../../network-query';
import { UrlSearchParamController } from '../../util/url-search-param-controller';
import { COMPARE_DEVICE_SNMP_STATUS, type InventoryDeviceOrder } from './inventory-sorting';


export type InventoryControllerDependencies = {
	networkService: NetworkService;
};

export type InventoryDeviceTypeFilter = Record<Device['type'], boolean>;

export class InventoryController {
	static readonly DEVICE_ORDER_URL_PARAM_KEY = 'deviceOrder';
	static readonly DEVICE_ORDER_URL_PARAM_DEFAULT = 'default';
	
	readonly #network: Network;

	#focusedItem: NetworkItem | null = null;
	get focusedItem(): NetworkItem | null { return this.#focusedItem; }
	@observable() private set focusedItem(value: NetworkItem | null) { this.#focusedItem = value; }
	
	readonly map: MapController;

	readonly #urlParams: UrlSearchParamController;

	readonly itemKindFilter: ItemKindFilter;
	readonly siteUsageFilter: SiteUsageFilter;
	readonly deviceTypeFilter: DeviceTypeFilter;
	readonly query: NetworkQuery;
	
	#deviceOrder: InventoryDeviceOrder;
	get deviceOrder(): InventoryDeviceOrder { return this.#deviceOrder; }
	@observable() private set deviceOrder(value: InventoryDeviceOrder) { this.#deviceOrder = value; }
	
	constructor(network: Network) {
		this.#network = network;
		
		this.#urlParams = new UrlSearchParamController({ historyMode: 'replace' });
		
		this.itemKindFilter = new ItemKindFilter({ urlParams: this.#urlParams });
		this.siteUsageFilter = new SiteUsageFilter({ urlParams: this.#urlParams });
		this.deviceTypeFilter = new DeviceTypeFilter({ urlParams: this.#urlParams });

		this.itemKindFilter.on('change', this.#updateComparator);
		
		this.query = new NetworkQuery({
			emptySearchBehaviour: 'everything',
			filters: [
				this.itemKindFilter,
				this.siteUsageFilter,
				this.deviceTypeFilter,
			],
			urlParams: this.#urlParams,
		}).setNetwork(network);

		this.map = new MapController({
			center: { lat: 0, lng: 0 },
			zoom: 12,
		});

		window.addEventListener('hashchange', this.#handleHashChange);
		this.#handleHashChange();
		
		const deviceOrder = this.#urlParams.getString(InventoryController.DEVICE_ORDER_URL_PARAM_KEY, InventoryController.DEVICE_ORDER_URL_PARAM_DEFAULT);
		switch(deviceOrder) {
			case 'snmpStatus':
				this.#deviceOrder = 'snmpStatus';
				break;
			default:
				this.#deviceOrder = 'default';
		}
		this.#updateComparator();
	}
	
	@bound()
	setDeviceOrder(order: InventoryDeviceOrder): void {
		this.deviceOrder = order;
		this.#updateComparator();
		this.#urlParams.setString(InventoryController.DEVICE_ORDER_URL_PARAM_KEY, this.deviceOrder, InventoryController.DEVICE_ORDER_URL_PARAM_DEFAULT);
	}
	
	readonly #updateComparator = () => {
		if(this.itemKindFilter.sites) {
			this.query.setComparator(null);
			return;
		}

		this.query.setComparator(
			this.#deviceOrder === 'snmpStatus' ? COMPARE_DEVICE_SNMP_STATUS : null
		);
	};

	#handleHashChange = () => {
		const focusedItemId = window.location.hash.slice(1);
		this.focusedItem = this.#network.getItem(focusedItemId);
		if(this.focusedItem) {
			this.map.setCenter(this.focusedItem.location, { smooth: false });
		}
	};
	
	dispose() {
		window.removeEventListener('hashchange', this.#handleHashChange);
		this.siteUsageFilter.dispose();
		this.itemKindFilter.dispose();
		this.query.dispose();
		this.#urlParams.dispose();
	}
}