import type { VNode } from 'preact';
import type { IssuePageIssue } from './process-analysis';
import { useMemo } from 'preact/hooks';
import { useAnalysisIssues } from './analysis-provider';


export const analysisIssueDescriptions: Record<IssuePageIssue['kind'], string> = {
	'interSite': '',
	'outOfRange': 'Customers at an extreme range from access points which may indicate an incorrect relationship',
	'outOfSector': 'Customers are outside of the defined sector which may not be an issue depending on the horn in use',
};


export type AnalysisIssueDescriptionProps = {
	className: string;
};
export const AnalysisIssueDescription = ({ className }: AnalysisIssueDescriptionProps): VNode | null => {
	const { analysisIssuesFilter: { kind } } = useAnalysisIssues();

	const description = useMemo(() => analysisIssueDescriptions[kind], [ kind ]);

	if(!description) {
		return null;
	}

	return (
		<div className={ className }>
			{ description }
		</div>
	);
};