import type { NetworkItem } from '@brng/domain';
import type { EventCallback, EventType } from '../util';
import type { UrlSearchParamController } from '../util/url-search-param-controller';
import { EventManager } from '../util';


export type NetworkFilterChangeEvent = Readonly<{
	filter: NetworkFilter;
}>;
export type NetworkFilterEvents = {
	change: NetworkFilterChangeEvent;
};
export type NetworkFilterConfig = {
	/**
	 * If passed, the filter will update the URL search parameters when changed.
	 */
	urlParams: UrlSearchParamController | null | undefined;
	
	/**
	 * The key for the filter's URL search parameter.
	 */
	urlParamKey: string;

	/**
	 * Default value for the filter's URL search parameter.
	 *
	 * This value will be used when no UrlSearchParamController is passed to the
	 * filter, or if the url does not contain the specified `urlParamKey`.
	 *
	 * @see {@link UrlSearchParamController}
	 */
	urlParamDefault: string;
};

/**
 * Base class for interactive filters over `NetworkItem`s. Manages dispatching
 * change events, and updating URL search parameters.
 *
 * Users of implementations of this class should use {@link NetworkFilter.apply()}.
 *
 * Implementers should initialise their state using the value returned from
 * {@link NetworkFilter.getUrlParamValue()} in their constructor.
 *
 * Actual filtering logic should be implemented in {@link NetworkFilter.filter()}.
 *
 * When the filter state changes, call {@link NetworkFilter.changed()}, which
 * will notify any change event listeners, and update the URL search parameters.
 */
export abstract class NetworkFilter {
	readonly #events = new EventManager<NetworkFilterEvents>();
	
	readonly #urlParams: UrlSearchParamController | null;
	readonly #urlParamKey: string;
	readonly #urlParamDefault: string;
	
	constructor(config: NetworkFilterConfig) {
		this.#urlParams = config.urlParams ?? null;
		this.#urlParamKey = config.urlParamKey;
		this.#urlParamDefault = config.urlParamDefault;
	}
	
	/**
	 * Get the initial serialised state of the filter. Implementers of this class
	 * should use this value to determine their initial state.
	 */
	protected getUrlParamValue(): string {
		return this.#urlParams?.getString(this.#urlParamKey, this.#urlParamDefault) ?? this.#urlParamDefault;
	}
	
	/**
	 * Implement filtering logic in this method.
	 *
	 * @param item the NetworkItem being filtered.
	 */
	protected abstract filter(item: NetworkItem): boolean;
	
	/**
	 * Serialise the filter's current state.
	 */
	protected abstract toUrlParam(): string;
	
	/**
	 * Notify that the filter's state has changed.
	 *
	 * Will invoke any `change` event listeners, and update the URL search params,
	 * if appropriate.
	 */
	protected changed(): void {
		this.#events.notify('change', { filter: this });

		if(this.#urlParams) {
			this.#urlParams.setString(this.#urlParamKey, this.toUrlParam(), this.#urlParamDefault);
		}
	}

	/**
	 * Apply this filter to the specified `NetworkItem`.
	 *
	 * @example arrayOfNetworkItems.filter(networkFilter.apply)
	 */
	readonly apply = (item: NetworkItem): boolean => this.filter(item);
	
	on<E extends EventType<NetworkFilterEvents>>(event: E, listener: EventCallback<NetworkFilterEvents, E>): this {
		this.#events.on(event, listener);
		return this;
	}

	off<E extends EventType<NetworkFilterEvents>>(event: E, listener: EventCallback<NetworkFilterEvents, E>): this {
		this.#events.off(event, listener);
		return this;
	}
	
	dispose() {
		this.#events.dispose();
	}
}