import type { SnmpProbeDataset } from './snmp-probe-data';
import type { SnmpProbeFilterSelectionCallback } from './snmp-probe-filter';
import { bound, observable, ObservableArray } from 'ecce-preact';
import { notNullish } from '@brng/common';
import { SnmpProbe } from './snmp-probe';
import { SnmpProbeFilter } from './snmp-probe-filter';
import { SnmpProbeDeviceType, SnmpProbeFilterItem, SnmpProbeManufacturer, SnmpProbeRadioModel } from './snmp-probe-filter-item';




export class SnmpHelperController {
	readonly filter: SnmpProbeFilter;

	readonly #allProbes: readonly SnmpProbe[];
	readonly probes = new ObservableArray<SnmpProbe>();

	#sqlDialogTarget: SnmpProbe | null = null;
	get sqlDialogTarget(): SnmpProbe | null { return this.#sqlDialogTarget; }
	@observable() private set sqlDialogTarget(value: SnmpProbe | null) { this.#sqlDialogTarget = value; }

	constructor(data: Readonly<SnmpProbeDataset>) {
		const deviceTypes = new Map<string, SnmpProbeDeviceType>();
		for(const deviceType of data.deviceTypes) {
			deviceTypes.set(deviceType.name, new SnmpProbeDeviceType(this, deviceType));
		}

		const radioModels = new Map<string, SnmpProbeRadioModel>();
		for(const radioModel of data.radioModels) {
			radioModels.set(radioModel.name, new SnmpProbeRadioModel(this, radioModel));
		}

		this.#allProbes = data.probes
			.map(probeData => (
				new SnmpProbe(this, {
					data: probeData,
					deviceTypes: probeData.deviceTypes.map(name => deviceTypes.get(name)).filter(notNullish),
					radioModels: probeData.radioModels?.map(name => radioModels.get(name)).filter(notNullish) ?? [],
				})
			))
			.sort((a, b) => a.name.localeCompare(b.name));

		const manufacturers = data.manufacturers.map(manufacturerData => (
			new SnmpProbeManufacturer(this, manufacturerData))
		).sort(SnmpProbeFilterItem.compareName);

		this.filter = new SnmpProbeFilter(
			[ ...manufacturers, ...deviceTypes.values(), ...radioModels.values() ],
			this.#handleFilterItemSelection
		);
	}

	openSqlDialog(probe: SnmpProbe) {
		this.sqlDialogTarget = probe;
	}

	@bound()
	closeSqlDialog() {
		this.sqlDialogTarget = null;
	}

	#handleFilterItemSelection: SnmpProbeFilterSelectionCallback = item => {
		if(!item) {
			this.probes.clear();
			return;
		}

		this.probes.replace(
			this.#allProbes.filter(probe => probe.filter(item))
		);
	};
}