import type { AdminUsersLists, PatchUserRequest, PostUserRequest, RepositoryUser, User } from '@brng/common';
import type { UserCreationFormResult } from '../../../components';
import { bound, observable } from 'ecce-preact';
import { UserCreationFormController } from '../../../components';
import { Controller } from '../../../util';


export type UserAdminDialogMode = (
	| { kind: 'inviteFromRepository'; }
	| { kind: 'showInvitation', user: User & { inviteCode: string }; url: string }
	| { kind: 'inviteLocalUser', userCreationFormController: UserCreationFormController }
);

export type UserAdminData = {
	admins: User[];
	active: User[];
	pending: User[];
	inactive: User[];
	repositoryUsers: RepositoryUser[];
};

export class UsersAdminController extends Controller {
	#loading: boolean = true;
	get loading(): boolean { return this.#loading; }
	@observable() private set loading(value: boolean) { this.#loading = value; }

	#data: UserAdminData | null = null;
	get data(): UserAdminData | null { return this.#data; }
	@observable() private set data(value: UserAdminData | null) { this.#data = value; }

	#dialogMode: UserAdminDialogMode | null = null;
	get dialogMode(): UserAdminDialogMode | null { return this.#dialogMode; }
	@observable() private set dialogMode(value: UserAdminDialogMode | null) { this.#dialogMode = value; }

	protected initialise(): void {
		this.#fetchUserData();
	}

	async #fetchUserData() {
		try {
			this.loading = true;
			const response = await this.services.apiService.get<AdminUsersLists>('/api/admin/users');
			if(!response.ok) {
				console.error('Error fetching user data:', response); // eslint-disable-line no-console
				this.services.notificationService.notify({
					severity: 'error',
					message: response.errorType === 'network'
						? `Network error fetching user data: ${response.error}`
						: `Error fetching user data: ${response.status}`,
				});
				this.data = null;
				return;
			}
			
			const data: UserAdminData = {
				admins: [],
				active: [],
				pending: [],
				inactive: [],
				repositoryUsers: response.body.repositoryUsers,
			};

			const users = [ ...response.body.localUsers ].sort((a, b) => (
				a.username.localeCompare(b.username)
			));

			for(const user of users) {
				switch(user.status) {
					case 'inactive':
					case 'pending':
						data[user.status].push(user);
						continue;
					case 'active':
						if(user.role === 'admin') {
							data.admins.push(user);
						} else {
							data.active.push(user);
						}
				}
			}
			this.data = data;
		} finally {
			this.loading = false;
		}
	}

	@bound()
	showInviteFromRepositoryDialog() {
		this.dialogMode = { kind:'inviteFromRepository' };
	}

	@bound()
	showInviteLocalUserDialog() {
		this.dialogMode = {
			kind: 'inviteLocalUser',
			userCreationFormController: new UserCreationFormController({
				self: false,
				onSubmit: result => this.#inviteLocalUser(result),
				mode: 'noPassword',
				submitLabel: 'Invite User',
			}),
		};
	}

	async #inviteUser(request: PostUserRequest): Promise<void> {
		if(this.loading) {
			return;
		}

		this.loading = true;
		try {
			const response = await this.services.apiService.post<User>('/api/admin/users', request);
			if(response.ok) {
				await this.#fetchUserData();
				this.showInvitationDialog(response.body);
				return;
			}

			if(response.errorType === 'http' && response.status === 409) {
				alert('That username is already in use.\n\nPick a different username.'); // eslint-disable-line no-alert
			}

		} finally {
			this.loading = false;
		}
	}

	async inviteUserFromRepository(repositoryUserId: string, admin: boolean): Promise<void> {
		await this.#inviteUser({
			type: 'repository',
			repositoryUserId,
			admin,
		});
	}

	async #inviteLocalUser(result: UserCreationFormResult): Promise<void> {
		await this.#inviteUser({
			type: 'local',
			name: result.name,
			username: result.username,
			email: result.email,
			admin: result.admin,
		});
	}

	showInvitationDialog(user: User): void {
		if(!user.inviteCode) {
			return;
		}

		const inviteUrl = new URL(window.location.href);
		inviteUrl.pathname = '/invite/' + user.inviteCode;
		inviteUrl.hash = '';
		inviteUrl.search = '';

		this.dialogMode = {
			kind: 'showInvitation',
			user: user as User & { inviteCode: string },
			url: inviteUrl.href,
		};
	}

	async #patchUser(userId: string, request: PatchUserRequest): Promise<void> {
		if(this.loading) {
			return;
		}

		this.loading = true;
		try {
			await this.services.apiService.patch(`/api/admin/users/${userId}`, request);
			await this.#fetchUserData();
		} finally {
			this.loading = false;
		}
	}

	async revokeAdminPermissions(userId: string): Promise<void> {
		await this.#patchUser(userId, { role: 'user' });
	}

	async grantAdminPermissions(userId: string): Promise<void> {
		await this.#patchUser(userId, { role: 'admin' });
	}

	async reactivateUser(userId: string): Promise<void> {
		await this.#patchUser(userId, { status: 'active' });
	}

	async deleteUser(userId: string): Promise<void> {
		if(this.loading) {
			return;
		}

		this.loading = true;
		try {
			await this.services.apiService.delete(`/api/admin/users/${userId}`);
			await this.#fetchUserData();
		} finally {
			this.loading = false;
		}
	}

	@bound()
	closeDialog() {
		this.dialogMode = null;
	}
}