import type { RouteProps } from '../route-props';
import { useState } from 'preact/hooks';
import { SidebarTemplate } from '../../components/templates/sidebar-template';
import { useData } from '../../data';
import { SnmpHelperController } from './snmp-helper-controller';
import './snmp-helper-route.scss';
import { SNMP_PROBE_DATA } from './snmp-probe-data';
import { SnmpProbeFilterView } from './snmp-probe-filter';
import { SnmpProbeList } from './snmp-probe-list';
import { SnmpProbeSqlDialog } from './snmp-probe-sql-dialog';


export const SnmpHelperRoute = (_: RouteProps) => {
	const { data } = useData();
	const [ controller ] = useState(() => new SnmpHelperController(SNMP_PROBE_DATA));
	
	if(!data) {
		return null;
	}
	
	return (
		<>
			<SidebarTemplate
				className="snmp-probe-route"
				mainClassName="snmp-probe-route__main"
				sidebar={ <SnmpProbeFilterView filter={ controller.filter }/> }
				collapse
				showSidebarText="Show filter"
			>
				<SnmpProbeList controller={ controller }/>
			</SidebarTemplate>
			<SnmpProbeSqlDialog controller={ controller }/>
		</>
	);
};