import type { FunctionComponent } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { useData } from '../data';


export const FaqRoute: FunctionComponent = () => {
	const root = useRef<HTMLDivElement>(null);
	const snmpLink = '/snmp-helper';
	const snmpText = 'SNMP helper';

	useEffect(() => {
		const hash = window.location.hash.replace('#', '');
		const el = document.getElementById(hash);
		if(el && root.current?.contains(el)) {
			const top = el.getBoundingClientRect().top - 96; // Account for header.
			window.scrollTo({ top });
		}
	}, []);

	if(!useData().data) {
		return null;
	}

	return (
		<div ref={ root } className="content text-lg stack-md pad-v-lg pad-h-md">
			<article class="copy">
				<h2>Missing Data</h2>
				<p>Bearing is driven by data from your Powercode installation. In order to ensure accurate information
					it relies on SNMP, where possible, to get information direct from the devices in question.
				</p>
				<p>If the information in Powercode is incomplete or not present the utility of Bearing will be
					impaired.
				</p>
				<p>Our <a href={ snmpLink }>{ snmpText }</a> is a useful place to quickly find OIDs for some manufacturers.</p>
				<div className="panel-group">
					<section id="frequency-band" class="panel copy">
						<h3>Frequency band</h3>
						<p>By default Bearing uses the value specified in&nbsp;
							<code>Edit Equipment -&gt; Equipment Specifics -&gt; Frequency</code> to populate this value.
						</p>
						<p>If this value is not specified but the frequency SNMP is in place then frequency band is
							derived from the device frequency.
						</p>
					</section>

					<section id="frequency" class="panel copy">
						<h3>Frequency</h3>
						<p>Current device frequency should be obtained using SNMP configured in Powercode under&nbsp;
							<code>Equipment -&gt; Probes</code>.
						</p>
						<p>
							To correctly retrieve probe information Bearing matches against the probe name. For Frequency the
							probe name should match <code>*Center Frequency*</code>, for example:&nbsp;
							<code>Ubiquiti 5.6 AP/SM - Center Frequency</code>.
						</p>
						<p>Check our <a href={ snmpLink }>{ snmpText }</a> for a growing library of OIDs.</p>
					</section>
					
					<section id="channel-width" class="panel copy">
						<h3>Channel width</h3>
						<p>Current device channel width should be obtained using SNMP configured in Powercode under&nbsp;
							<code>Equipment -&gt; Probes</code>.
						</p>
						<p>
							To correctly retrieve probe information Bearing matches against the probe name. For Channel Width
							the probe name should match <code>*Channel Width*</code>, for example:&nbsp;
							<code>Ubiquiti 5.6 AP/SM - Channel Width</code>.
						</p>
						<p>Check our <a href={ snmpLink }>{ snmpText }</a> for a growing library of OIDs.</p>
					</section>

					<section id="azimuth-backhaul" class="panel copy">
						<h3>Azimuth (backhaul)</h3>
						<p>Azimuth for backhaul devices is calculated based on the <code>Equpiment -&gt; Parent/Child</code>&nbsp;
							relationships. These relationships should represent paired backhauls. The azimuth is then
							calculated based on the <code>Network Site -&gt; Address -&gt; Latitude/Longitude</code>.
						</p>
					</section>

					<section id="azimuth" class="panel copy">
						<h3>Azimuth</h3>
						<p>Bearing uses the value specified in&nbsp;
							<code>Edit Equipment -&gt; Equipment Specifics -&gt; Azimuth</code>.
						</p>
					</section>

					<section id="sector-size" class="panel copy">
						<h3>Sector size</h3>
						<p>Bearing uses the value specified in
						&nbsp;<code>Edit Equipment -&gt; Equipment Specifics -&gt; Sector Size</code> and
						&nbsp;<code>Edit Equipment -&gt; Equipment Specifics -&gt; Custom Sector Size</code>.
						</p>
						<p>This only applies to <code>Antenna Type: Sector</code>, omnidirectional antennas are represented as
							such.
						</p>
					</section>
				</div>
			</article>
		</div>
	);
};
