import type { JSX, VNode } from 'preact';
import type { JSXInternal } from 'preact/src/jsx';
import type { ClassProp } from '../util';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import './slider.scss';


export type SliderProps = ClassProp & {
	id: string;
	label: string;
	steps: readonly number[];
	value: number;
	onChange: (newValue: number) => void;
	formatValue?: (value: number) => string | JSX.Element;
	disabled?: boolean;
	slim?: boolean;
	immediate?: boolean;
};

const defaultFormatValue: SliderProps['formatValue'] = value => value.toLocaleString();

export const Slider = ({ id, className, label, steps, value, onChange, formatValue = defaultFormatValue, disabled, slim = false, immediate = false }: SliderProps): VNode => {
	const [ valueToShow, setValueToShow ] = useState<number>(value);
	useEffect(() => {
		if(valueToShow === value) {
			return;
		}
		setValueToShow(value);
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps

	const [ mouseDown, setMouseDown ] = useState(false);
	const handleMouseDown = useCallback<JSXInternal.GenericEventHandler<HTMLInputElement>>(() => {
		setMouseDown(true);
	}, []);

	const handleChange = useCallback<JSXInternal.GenericEventHandler<HTMLInputElement>>(ev => {
		if(!onChange) {
			return;
		}
		const newValue = steps[parseInt(ev.currentTarget.value, 10)];
		setValueToShow(newValue);
		if(immediate || !mouseDown) {
			onChange(newValue);
		}
	}, [onChange, steps, immediate, mouseDown]);
	
	const handleMouseUp = useCallback<JSXInternal.GenericEventHandler<HTMLInputElement>>(() => {
		if(valueToShow !== value) {
			onChange(valueToShow);
		}
		setMouseDown(false);
	}, [valueToShow, value, onChange]);

	const fullLabel = useMemo(() => (
		<>
			{label}: {formatValue(valueToShow)}
		</>
	), [label, valueToShow, formatValue]);
	
	const computedClassNames = classNames('slider', {
		'slider--disabled': disabled,
		'slider--slim': slim,
	}, className);

	const index = useMemo(() => {
		let index = steps.indexOf(valueToShow);
		if(index === -1) {
			if(valueToShow > steps[steps.length - 1]) {
				index = steps.length - 1;
			} else if(valueToShow < steps[0]) {
				index = 0;
			} else {
				for(let i=1; i<steps.length-2; ++i) {
					if(valueToShow > steps[i]) {
						continue;
					}
					index = i;
					break;
				}
			}
		}
		return index;
	}, [steps, valueToShow]);
	
	return (
		<fieldset className={ computedClassNames }>
			<legend><label for={ id }>{ fullLabel }</label></legend>
			<input
				id={ id }
				type="range"
				min={ 0 }
				max={ steps.length - 1 }
				step={ 1 }
				value={ index }
				disabled={ disabled }
				onMouseDown={ handleMouseDown }
				onChange={ handleChange }
				onMouseUp={ handleMouseUp }
			/>
		</fieldset>
	);
};
