import type { VNode } from 'preact';
import cn from 'classnames';
import { useObservable } from 'ecce-preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { onRouteChange } from '../../../routes';
import { useServices } from '../../../services';
import { MapUrl } from '../../../util';
import { HEADER_TOOLBAR_ID } from '../toolbar';
import { BackButton } from './back-button';
import { LicenseFlag } from './license-flag';
import { NavItem } from './nav-item';
import './page-header.scss';


const menuClosingInputEvents: (keyof WindowEventMap)[] = [
	'mousedown', 'touchstart', 'scroll',
];

const mainNavItems = [
	<NavItem label="Sites" href="/" key="Sites"/>,
	<NavItem className="bp-up-sm" label="Inventory" href="/inventory" key="Inventory"/>,
	<NavItem className="bp-up-md" label="Issues" href="/issues" key="Issues"/>,
	<NavItem label="Map" href={ MapUrl.PATH } key="Map"/>,
];

export const PageHeader = (): VNode => {
	const { authService } = useServices();
	useObservable(authService, 'admin');
	
	const menuEl = useRef<HTMLDivElement>(null);
	const hamburgerButtonEl = useRef<HTMLButtonElement>(null);
	
	const [ menuOpen, setMenuOpen ] = useState(false);
	const toggleMenuOpen = useCallback(() => {
		setMenuOpen(!menuOpen);
	}, [ menuOpen ]);

	// Close menu on input events outside of the menu.
	useEffect(() => {
		if(!menuOpen) {
			return;
		}

		const handle = (ev: Event) => {
			if(
				ev.target === hamburgerButtonEl.current
				|| menuEl.current!.contains(ev.target as Element) // eslint-disable-line @typescript-eslint/no-non-null-assertion
			) {
				return;
			}
			setMenuOpen(false);
		};

		menuClosingInputEvents.forEach(event => {
			window.addEventListener(event, handle);
		});

		return () => {
			menuClosingInputEvents.forEach(event => {
				window.removeEventListener(event, handle);
			});
		};
	}, [ menuOpen ]);
	
	// Close menu on escape key.
	useEffect(() => {
		if(!menuOpen) {
			return;
		}
		
		const handleKeydown = (ev: KeyboardEvent) => {
			if(ev.key === 'Escape') {
				setMenuOpen(false);
			}
		};

		window.addEventListener('keydown', handleKeydown);

		return () => { window.removeEventListener('keydown', handleKeydown); };
	}, [ menuOpen ]);

	// Hide menu on route.
	useEffect(() => (
		onRouteChange(() => setMenuOpen(false))
	), []);

	// Hide menu on click inside -- needed to dismiss on clicking 'logout'
	const handleMenuClickCapture = useCallback(() => {
		setMenuOpen(false);
	}, []);

	return (
		<div className="page-header-wrapper">
			<header className="page-header">
				<BackButton className="page-header__back-button pad-l-md pad-r-xs"/>
				<div role="banner" className="page-header__banner">
					<a href="/">
						<h1 className="page-header__banner mobile">BRNG</h1>
						<h1 className="page-header__banner desktop">BEARING</h1>
					</a>
					<LicenseFlag
						className="bp-up-sm"
						licenseState="BETA"
					/>
					<LicenseFlag
						className="bp-up-sm"
						licenseState="TRIAL"
					/>
				</div>

				<div id={ HEADER_TOOLBAR_ID } className="page-header__toolbar"/>

				<nav className="page-header__nav">
					{mainNavItems}
						
					<button
						ref={ hamburgerButtonEl }
						className={ cn('page-header__hamburger-button text-lg', {
							'page-header__hamburger-button--open': menuOpen,
						}) }
						onClick={ toggleMenuOpen }
						aria-haspopup="menu"
						aria-label="Open menu"
					>
						<i class="fas fa-bars"/>
						<i class="fas fa-times"/>
					</button>
				</nav>
			</header>
			<div
				ref={ menuEl }
				role="menu"
				className={ cn('page-header-menu shadow-lg', { 'page-header-menu--open': menuOpen }) }
				onClickCapture={ handleMenuClickCapture }
			>
				<NavItem className="bp-down-sm" label="Inventory" href="/inventory" key="Inventory"/>
				<NavItem label="FAQ" href="/faq"/>
				<NavItem label="SNMP Helper" href="/snmp-helper"/>
				{authService.admin && <NavItem label="Admin" href="/admin"/>}
				<NavItem label="Log out" action={ authService.logout }/>
			</div>
		</div>
	);
};