import type { Site } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { Copyable, Link } from '../../../../../components';
import { MapDevicesOverlays, MapInfrastructureSiteIcon, MapView } from '../../../../../map';
import { MapUrl, formatPoint } from '../../../../../util';
import { useInventoryController } from '../../../inventory-controller-context';
import { InventoryDetailsSection } from '../inventory-details-section';
import { InventoryDetailsSiteDevicesList } from './inventory-details-details-list';
import './inventory-details-site.scss';


export type InventoryDetailsSiteProps = {
	site: Site;
};
export const InventoryDetailsSite = ({ site }: InventoryDetailsSiteProps): JSX.Element => {
	const controller = useInventoryController();
	
	return (
		<div className="inventory-details-site">
			<InventoryDetailsSection className="inventory-details-site__overview">
				<div className="panel-group">
					<div className="panel data-panel">
						<div>
							<span className="data-label">ID</span>&nbsp;
							<span className="data-value font-mono">
								<Copyable value={ site.sourceId }>{site.displayId}</Copyable>
							</span>
						</div>
						<span className="data-value">{site.type}</span>
					</div>
					<div className="panel data-panel">
						<div className="data-label">
							Location
						</div>
						<Link
							className="data-value"
							icon="mapPin"
							label={ formatPoint(site.location) }
							href={ MapUrl.forItem(site).toRelativeUrl() }
						/>
					</div>
				</div>
			</InventoryDetailsSection>
		
			<InventoryDetailsSection title="Equipment" className="inventory-details-site__devices">
				<InventoryDetailsSiteDevicesList site={ site }/>
			</InventoryDetailsSection>

			<MapView
				className="inventory-details-site__map"
				controller={ controller.map }
			>
				<MapInfrastructureSiteIcon site={ site } highlight/>
				<MapDevicesOverlays devices={ site.devices }/>
			</MapView>
		</div>
	);
};