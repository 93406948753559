import type { JSX } from 'preact';
import type { RepositoryConfig_Powercode } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useEffect } from 'preact/hooks';
import { useServices } from '../../../services';
import { PowercodeAdmin } from './powercode-admin';
import { ManualRepositoryAdmin } from './manual-repository-admin';


export const RepositoryAdmin = (): JSX.Element | null => {
	const { repositoryService } = useServices();
	useObservable(repositoryService, 'configuration');
	useObservable(repositoryService, 'metadata');

	useEffect(() => {
		repositoryService.loadConfiguration();
	}, [repositoryService]);

	if(!repositoryService.configuration || !repositoryService.metadata) {
		return null;
	}

	switch(repositoryService.metadata.provider) {
		case 'Powercode':
			return <PowercodeAdmin configuration={ repositoryService.configuration as RepositoryConfig_Powercode }/>;
		case 'Manual':
			return <ManualRepositoryAdmin/>;
		default: return null;
	}
};