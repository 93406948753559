import type { FunctionComponent, VNode } from 'preact';
import type { BreakpointOption, ClassProp } from '../../util';
import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { useCallback, useState } from 'preact/hooks';
import { useBreakpoint } from '../../util';
import './toolbar.scss';
import { MoreMenu, MoreMenuButton } from './more-menu';


export const HEADER_TOOLBAR_ID = 'headerToolbarPortal';

const useHeaderToolbarEl = (): HTMLElement | null => {
	const [ el, setEl ] = useState<HTMLElement | null>(document.getElementById(HEADER_TOOLBAR_ID));

	const findToolbarEl = () => {
		const newEl = document.getElementById(HEADER_TOOLBAR_ID);
		if(!newEl) {
			setTimeout(findToolbarEl);
			return;
		}

		setEl(newEl);
	};
	
	if(!el) {
		findToolbarEl();
	}

	return el;
};

type ToolbarComponent = FunctionComponent<ClassProp & {
	/**
	 * Window width at which the toolbar moves into the main header.
	 * @default 'xl'
	 */
	bp?: BreakpointOption;
	moreMenu?: VNode[];
	moreMenuBreakpoint?: BreakpointOption;
}>;
/**
 * A page's toolbar.
 *
 * Note that this component renders into the page's main header, regardless of
 * where it appears in the page's structure.
 */
export const Toolbar: ToolbarComponent = ({
	className,
	children,
	bp = 'xl',
	moreMenu,
	moreMenuBreakpoint = 'sm',
}) => {
	const renderIntoHeader = useBreakpoint(bp);
	const headerToolbarEl = useHeaderToolbarEl();


	const moreMenuBreakpointActive = !useBreakpoint(moreMenuBreakpoint);
	const [ moreMenuOpen, setMoreMenuOpen ] = useState(false);
	const handleMoreMenuClick = useCallback(() => {
		setMoreMenuOpen(prevOpen => !prevOpen);
	}, []);
	const handleMenuClose = useCallback(() => {
		setMoreMenuOpen(false);
	}, []);


	const toolbar = (
		<div className="toolbar" role="toolbar">
			<div className={ cn('toolbar__content', className) }>
				{ children }
			</div>

			{ moreMenu && moreMenuBreakpointActive && (
				<>
					<MoreMenuButton
						className="toolbar__more-button"
						open={ moreMenuOpen }
						onClick={ handleMoreMenuClick }
					/>
					<div className="toolbar__more-menu">
						<MoreMenu open={ moreMenuOpen } onClose={ handleMenuClose }>
							{ moreMenu }
						</MoreMenu>
					</div>
				</>
			)}
		
		</div>
	);

	if(renderIntoHeader) {
		if(headerToolbarEl) {
			return createPortal(toolbar, headerToolbarEl);
		}
		return null;
	}

	return toolbar;
};
