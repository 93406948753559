import type { UsersAdminController } from './users-admin-controller';
import { useObservable } from 'ecce-preact';
import { Dialog, DialogHeader, UserCreationForm } from '../../../components';
import { useLatch } from '../../../util';



export const InviteLocalUserDialog = (p: { controller: UsersAdminController }) => {
	useObservable(p.controller, 'dialogMode');

	const formController = useLatch(
		p.controller.dialogMode?.kind === 'inviteLocalUser'
			? p.controller.dialogMode.userCreationFormController
			: null
	);

	return (
		<Dialog
			open={ p.controller.dialogMode?.kind === 'inviteLocalUser' }
			onClose={ p.controller.closeDialog }
			narrow
		>
			<DialogHeader
				title="Invite User"
				onClose={ p.controller.closeDialog }
			/>
			<main>
				{ formController && (
					<UserCreationForm controller={ formController }/>
				)}
			</main>
		</Dialog>
	);
};