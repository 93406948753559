import type { ChildrenProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import { useEffect, useErrorBoundary } from 'preact/hooks';
import { useErrorService } from '../../services';


export const ErrorBoundary = ({ children }: ChildrenProp): JSX.Element => {
	const errorService = useErrorService();
	const [ error ] = useErrorBoundary();
	useEffect(() => {
		if(error) {
			errorService.handleError(error);
		}
	}, [error, errorService]);

	return children as JSX.Element;
};