export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

/**
 * Types & utils for HTTP status codes used in the Bearing API.
 */
export namespace HttpStatusCode {
	export type Ok = 200;
	export const isOk = (status: number): status is HttpStatusCode.Ok => (
		status >=200 && status <= 299
	);

	export type NotModified = 304;
	
	export const isNotModified = (status: number): status is HttpStatusCode.NotModified => (
		status === 304
	);

	export type ClientError = (
		| 400
		| 404
		| 409
	);
	export const isClientError = (status: number): status is HttpStatusCode.ClientError => (
		status === 400 || status === 404 || status === 409
	);

	export type AuthError = (
		| 401
		| 403
	);
	export const isAuthError = (status: number): status is HttpStatusCode.AuthError => (
		status === 401 || status === 403
	);
}