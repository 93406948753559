import type { CsvError, DataUploadValidationError, DataUploadValidationRowError } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import { Alert, Icon } from '../../components';
import './data-validation-error-view.scss';


type CsvErrorViewProps = {
	csvError: CsvError;
};
const CsvErrorView = ({ csvError }: CsvErrorViewProps): JSX.Element => {
	switch(csvError.reason) {
		case 'invalidFormat':
			return (
				<div className="invalidFormat">
					<strong>
						The CSV contained invalid column structure.
					</strong>

					<div className="comparison">
						<strong>Expected:</strong>
						<code>{ csvError.expected.join(',') }</code>
						<strong>Received:</strong>
						<code>{ csvError.received.join(',') }</code>
					</div>
				</div>
			);
		case 'other':
			return (
				<div>
					<strong>{csvError.title}</strong>
					{ csvError.message && <div>{csvError.message}</div> }
				</div>
			);
	}
};

const getInvalidValue = (value: unknown): string | JSX.Element => {
	switch(typeof(value)) {
		case 'undefined':
		case 'string':
			if(!value) {
				return <span className="missingValue">(not provided)</span>;
			}
			return value;
		default:
			return String(value);
	}
};

type DataValidationErrorViewProps = {
	error: DataUploadValidationRowError;
};
const DataValidationRowErrorView = ({ error }: DataValidationErrorViewProps): JSX.Element => {
	return (
		<>
			<div>
				<span className="invalidColumn font-mono">{ error.column }</span>:{' '}
				<span className="invalidValue font-mono">{ getInvalidValue(error.value)}</span>
			</div>
			<ul>
				{ error.errors.map((err, index) => (
					<li key={ index }>{err}</li>
				))}
			</ul>
		</>
	);
};

export type DataUploadValidationErrorViewProps = {
	error: DataUploadValidationError | undefined | null;
	errorCsvName: string;
};
export const DataValidationErrorView = ({ error, errorCsvName }: DataUploadValidationErrorViewProps): JSX.Element | null => {
	if(!error) {
		return null;
	}

	const rowErrors = Object.entries(error.rowErrors);

	return (
		<div className="csv-validation-error-view">
			<Alert
				severity="error"
				title="The CSV was invalid"
				size="md"
			/>
			<ul>
				{ error.errors.map((csvError, index) => (
					<li key={ index }>
						<CsvErrorView csvError={ csvError }/>
					</li>
				)) }
			</ul>
			{ error.errorCsv && (
				<a
					className="btn btn--invisible"
					href={ error.errorCsv }
					download={ errorCsvName + '.csv' }
					target="_blank"
				>
					<Icon icon="download"/>
					Download errors as CSV
				</a>
			)}
			{ !!rowErrors.length && (
				<div className="invalidRows">
					<table className="table invalidRowTable">
						<thead>
							<td>Line</td>
							<td>Errors</td>
						</thead>
						<tbody>
							{ rowErrors.map(([ row, errors ]) => (
								<tr key={ row }>
									<td width="50">{parseInt(row, 10) + 2}</td>
									<td width="100%">
										<ul>
											{ errors.map(err => (
												<li key={ err }>
													<DataValidationRowErrorView error={ err }/>
												</li>
											)) }
										</ul>
									</td>
								</tr>
							)) }
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};