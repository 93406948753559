import type { Settings, SiteRaw } from '@brng/common';
import type { Device } from './device';
import type { ReadonlyIssueSet } from './issues/issue-set';
import type { NetworkIssue } from './issues/issues';
import type { NetworkItem } from './network-item';
import type { CustomerSite, InfrastructureSite } from './site';
import { Squelchable, type SquelchHandler } from './squelch';
import { IssueSet } from './issues/issue-set';
import { findIssues } from './issues/find-issues';
import { Site, isCustomerSite, isInfrastructureSite } from './site';


export type NetworkConfig = Readonly<{
	sites: readonly SiteRaw[];
	settings: Readonly<Settings>;
	squelchHandler?: SquelchHandler | undefined | null;
}>;

export class Network {
	readonly sites: readonly Site[];
	readonly infrastructureSites: readonly InfrastructureSite[];
	readonly customerSites: readonly CustomerSite[];
	readonly #sitesById: ReadonlyMap<string, Site>;

	readonly devices: readonly Device[];
	readonly infrastructureDevices: readonly Device[];
	readonly #deviceById: ReadonlyMap<string, Device>;

	readonly all: readonly NetworkItem[];
	readonly #byNetworkId: Map<string, NetworkItem>;

	readonly #issues = new IssueSet();

	constructor(config: NetworkConfig) {
		this.sites = Object.freeze(
			config.sites
				.map(s => new Site(this, s))
				.sort((a, b) => a.name.localeCompare(b.name))
		);
		this.#sitesById = new Map(this.sites.map(s => [ s.id, s ]));
		this.infrastructureSites = this.sites.filter(isInfrastructureSite);
		this.customerSites = this.sites.filter(isCustomerSite);

		this.devices = Object.freeze(
			this.sites
				.flatMap(s => s.devices)
				.sort((a, b) => a.name.localeCompare(b.name))
		);
		this.infrastructureDevices = Object.freeze(
			this.devices.filter(d => d.type === 'Access Point' || d.type === 'Backhaul')
		);
		this.#deviceById = new Map(this.devices.map(e => [ e.id, e ]));

		this.devices.forEach(e => e['initialise'](this));
		this.sites.forEach(s => s['initialise'](this));

		this.all = [
			...this.sites,
			...this.devices,
		];

		this.#byNetworkId = new Map(
			this.all.map(item => [ item.networkId, item ])
		);

		findIssues(this, config.settings);

		if(config.squelchHandler) {
			for(const issue of this.issues.getAll()) {
				if(issue instanceof Squelchable) {
					issue['initialise'](config.squelchHandler);
				}
			}
		}
	}

	getSite(id: string): Site | null {
		return this.#sitesById.get(id) ?? null;
	}

	getDevice(id: string): Device | null {
		return this.#deviceById.get(id) ?? null;
	}

	getItem(networkId: Site['networkId']): Site | null;
	getItem(networkId: Device['networkId']): Device | null;
	getItem(networkId: string): NetworkItem | null;
	getItem(networkId: string): any { // eslint-disable-line @typescript-eslint/no-explicit-any
		return this.#byNetworkId.get(networkId) ?? null;
	}

	private addIssue(issue: NetworkIssue): void {
		this.#issues.add(issue);
	}

	get issues(): ReadonlyIssueSet {
		return this.#issues;
	}
}