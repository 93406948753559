export const numMatchingChars = (a: string, b: string): number => {
	const minLength = Math.min(a.length, b.length);
	for(let i = 0; i < minLength; ++i) {
		if(a.at(i) !== b.at(i)) {
			return i;
		}
	}

	return minLength;
};
