import type { RepositoryConfig } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { PowercodeConnectionConfig } from './powercode-config-connection-form';
import { ExternalLink } from '../../components/external-link';


export type AllowUnauthorizedFormProps = {
	className?: string;
	config: RepositoryConfig;
	onSubmit: (config: PowercodeConnectionConfig) => void;
	onGoBack?: VoidFunction;
};
export const AllowUnauthorizedForm = ({ className, config, onSubmit, onGoBack }: AllowUnauthorizedFormProps) => {
	if(!('server' in config)) {
		return null;
	}

	const serverUrl = new URL(config.server);
	
	const testUrl = new URL('https://www.ssllabs.com/ssltest/analyze.html');
	testUrl.searchParams.set('hideResults', 'on');
	testUrl.searchParams.set('d', serverUrl.hostname);
	
	const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = ev => {
		ev.preventDefault();
		onSubmit({
			...config,
			allowUnauthorized: true,
		});
	};
	
	return (
		<form className={ className } onSubmit={ handleSubmit }>
			<div id="allowUnauthorizedDescription">
				<p>
					The server at <strong>{config.server}</strong> may have an incomplete
					certificate chain.
				</p>
				<p>
					You can test your domain's SSL configuration at&nbsp;
					<ExternalLink href={ testUrl.href }>
						{ testUrl.href }
					</ExternalLink>, or using OpenSSL:
					<br/>
					<pre>
						<code>
							openssl s_client -showcerts -connect {serverUrl.hostname}:{serverUrl.port || '443'}
						</code>
					</pre>
				</p>

				<hr/>

				<p>
					We recommend you fix this issue on your server. However, you can also
					allow Bearing ignore this issue and connect to the URL less securely
					by not verifying the SSL certificates.&nbsp;
					<strong>This may leave Bearing open to a man-in-the-middle attack </strong>
					when connecting to Powercode.
				</p>
			</div>
			<div className="mrg-v-md flex flex-align-center">
				<input
					id="allowUnauthorizedInput"
					type="checkbox"
					aria-describedby="allowUnauthorizedDescription"
					required
				/>
				&nbsp;
				<label htmlFor="allowUnauthorizedInput">
					Allow insecure connection to <strong>{ config.server }</strong>.
				</label>
			</div>

			<div className="flex flex-row-reverse gap-sm">
				<button className="btn btn--primary" type="submit">
					Continue
				</button>

				{ onGoBack && (
					<button
						className="btn"
						type="button"
						onClick={ onGoBack }
					>
						Go Back
					</button>
				)}
			</div>
		</form>
	);
};