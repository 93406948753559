import type { JSX } from 'preact/jsx-runtime';
import { RepositoryLink } from '../../../../components';


export const CreateCustomFieldLink = (): JSX.Element => (
	<>
		<RepositoryLink href="/index.php?page=/configure/customInfoTemplates.php">
			Create a custom field
		</RepositoryLink>
		&nbsp;
	</>
);