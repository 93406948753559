import type { VNode } from 'preact';
import type { JSXInternal } from 'preact/src/jsx';
import { useCallback, useMemo } from 'preact/hooks';
import { FrequencyBand, isBandName } from '@brng/common';
import { useAnalysisIssues } from '../analysis-provider';


export const InterSiteFreqSelect = (): VNode => {
	const { interSiteBands, analysisIssuesFilter: { interSiteBand }, updateAnalysisIssuesFilter } = useAnalysisIssues();

	const options = useMemo(() => [
		<option value="all" key="all">All bands</option>,
		...[...interSiteBands].map(band => (
			<option value={ band } key={ band }>
				{ FrequencyBand.fromFrequency(band)?.name ?? band }
			</option>
		)),
	], [ interSiteBands ]);

	const handleChange = useCallback<JSXInternal.GenericEventHandler<HTMLSelectElement>>(ev => {
		const value = isBandName(ev.currentTarget.value) ? ev.currentTarget.value : undefined;

		updateAnalysisIssuesFilter({ interSiteBand: value });
	}, [ updateAnalysisIssuesFilter ]);

	return (
		<select
			value={ interSiteBand ?? 'all' }
			onChange={ handleChange }
		>
			{ options }
		</select>
	);
};
