import type { MidasStatus } from '@brng/common';
import type { NetworkComparator } from '../../network-query';


const SNMP_STATUS_PRIORITY: Readonly<Record<MidasStatus, number>> = {
	SUCCESS: 7,
	WAITING: 6,
	PARTIAL: 5,
	NOT_IDENTIFIED: 4,
	NO_CONNECTION_DETAILS: 3,
	UNIDENTIFIED: 2,
	FAILURE: 1,
};

export type InventoryDeviceOrder = 'default' | 'snmpStatus';

export const COMPARE_DEVICE_SNMP_STATUS: NetworkComparator = (a, b) => {
	if(a.kind !== 'device' || b.kind !== 'device') {
		return 0;
	}
	
	return SNMP_STATUS_PRIORITY[a.snmpStatus] - SNMP_STATUS_PRIORITY[b.snmpStatus];
};