import type { Device } from '@brng/domain';
import type { MidasDeviceData } from '@brng/common';
import { bound, observable } from 'ecce-preact';


export class SnmpReportDialogController {
	static readonly #FINGERPRINT_PREFIX = '1.3.6.1.4.1.';
	
	readonly #device: Device;
	readonly #data: MidasDeviceData;
	
	#make = '';
	get make(): string { return this.#make; }
	@observable() private set make(value: string) { this.#make = value; }

	#model = '';
	get model(): string { return this.#model; }
	@observable() private set model(value: string) { this.#model = value; }

	#href!: string;
	get href(): string { return this.#href; }
	@observable() private set href(value: string) { this.#href = value; }
	
	constructor(device: Device, data: MidasDeviceData) {
		this.#device = device;
		this.#data = data;
		this.#updateHref();
	}
	
	@bound()
	handleMakeChange(ev: { currentTarget: { value: string } }): void {
		this.make = ev.currentTarget.value;
		this.#updateHref();
	}

	@bound()
	handleModelChange(ev: { currentTarget: { value: string } }): void {
		this.model = ev.currentTarget.value;
		this.#updateHref();
	}

	#makeFingerprint(): string {
		if(!this.#data.fingerprint) {
			return '(No fingerprint found)';
		}
		if(!this.#data.fingerprint?.length) {
			return '(Empty fingerprint)';
		}

		const ids = new Set<string>(
			this.#data.fingerprint.map(fingerprint => (
				fingerprint
					.substring(SnmpReportDialogController.#FINGERPRINT_PREFIX.length)
					.split('.')[0]
			))
		);

		return [...ids].join(',');
	}

	#updateHref() {
		const body = `
** If possible, please attach device specific MIBs to this email **

If you already have useful OIDs please include them here:


Thank you for helping us improve BEARING.

---
Manufacturer: ${this.make}
Model: ${this.model}
ID: ${this.#device.id}
Site ID: ${this.#device.site.id}
Device Type: ${this.#device.deviceType}
Enterprise Identifiers: ${this.#makeFingerprint()}
---
		`.trim();

		this.href = `mailto:support@brng.net?subject=${encodeURIComponent('SNMP Report')}&body=${encodeURIComponent(body)}`;
	}
}
