import type { NetworkIssue } from './issues';
import { Squelchable } from '..';


export type IssueFilter = Partial<{
	includeSquelched: boolean;
}>;

const DEFAULT_FILTER: Required<IssueFilter> = {
	includeSquelched: false,
};

export class IssueSet {
	readonly #byId = new Map<string, NetworkIssue>();
	readonly #byKind = new Map<NetworkIssue.Kind, NetworkIssue[]>();

	add(issue: NetworkIssue): void {
		if(this.#byId.has(issue.id)) {
			return;
		}

		this.#byId.set(issue.id, issue);
		let kinds = this.#byKind.get(issue.kind);
		if(!kinds) {
			kinds = [];
			this.#byKind.set(issue.kind, kinds);
		}
		kinds.push(issue);
	}
	
	getAll(filter?: IssueFilter): NetworkIssue[] {
		return IssueSet.#filterIssues([ ...this.#byId.values() ], filter);
	}

	getById(id: string): NetworkIssue | null {
		return this.#byId.get(id) ?? null;
	}

	getByKind<K extends NetworkIssue.Kind>(kind: K, filter?: IssueFilter): (NetworkIssue & { kind: K })[] {
		const kinds = this.#byKind.get(kind);
		const issues = kinds ? [ ...kinds ]  : [];
		return IssueSet.#filterIssues(issues, filter) as (NetworkIssue & { kind: K })[];
	}

	getByKinds<K extends NetworkIssue.Kind>(kinds: readonly K[], filter?: IssueFilter): (NetworkIssue & { kind: K })[] {
		return kinds.flatMap(kind => this.getByKind(kind, filter)) as (NetworkIssue & { kind: K })[];
	}

	getCount(filter?: IssueFilter): number {
		return this.getAll(filter).length;
	}

	getCountByKind(kind: NetworkIssue.Kind, filter?: IssueFilter): number {
		return this.getByKind(kind, filter).length;
	}

	getCountByKinds(kinds: readonly NetworkIssue.Kind[], filter?: IssueFilter): number {
		return this.getByKinds(kinds, filter).length;
	}

	static #filterIssues(issues: NetworkIssue[], partialFilter: IssueFilter | undefined): NetworkIssue[] {
		const filter = { ...DEFAULT_FILTER, ...partialFilter };
		if(filter.includeSquelched) {
			return issues;
		}

		return issues.filter(issue => {
			if(issue instanceof Squelchable && issue.squelched) {
				return false;
			}

			return true;
		});
	}
}

export type ReadonlyIssueSet = Omit<IssueSet, 'add'>;
