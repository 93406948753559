import type { Device } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { earth } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useMapController } from '../map-controller-context';
import { Polyline } from './google-maps';



export type MapDeviceArrowsProps = {
	device: Device;
};
export const MapDeviceArrows = ({ device }: MapDeviceArrowsProps): JSX.Element | null => {
	const controller = useMapController();
	useObservable(controller, 'palette');
	useObservable(controller, 'overlayRadius');

	if(device.antennaType !== 'Sector' || !Number.isFinite(device.azimuth) || device.azimuth < 0) {
		return null;
	}

	const	arrows: google.maps.PolylineOptions[] = [];

	const distance = 1.1 * (controller.overlayRadius === 'actual'
		? device.range ?? 0
		: controller.overlayRadius);
	
	if(distance === 0) {
		return null;
	}
	
	arrows.push({
		path: [
			device.location,
			earth.point(device.location, distance, device.azimuth),
		],
		icons: [{
			icon: { path: google.maps.SymbolPath.FORWARD_OPEN_ARROW },
		}],
		strokeColor: controller.palette.line,
		strokeWeight: 2,
	});

	
	if(device.type === 'Access Point' && device.sectorSize && device.radios[0]?.band) {
		const halfSector = device.sectorSize / 2;
		const strokeColor = device.radios[0].band.color(device.radios[0].frequency, .15).toRGBAHex(.5);
		for(const azimuth of [ device.azimuth - halfSector, device.azimuth + halfSector ]) {
			arrows.push({
				path: [
					device.location,
					earth.point(device.location, distance, azimuth),
				],
				strokeColor,
				strokeWeight: 1.5,
			});
		}
	}
			
	return arrows.map((options, index) => (
		<Polyline options={ options } key={ index }/>
	)) as unknown as JSX.Element;
};