import type { BandName } from '@brng/common';
import type { Device, InfrastructureSite, NetworkItem, Site, Network } from '@brng/domain';
import type { MapCoverageOverlayProps, MapCustomerSitePinProps, MapDeviceArrowsProps, MapDevicesOverlaysProps, MapInfrastructureSiteIconsProps } from '../../map';
import type { UrlSearchParamController } from '../../util/url-search-param-controller';
import type { MapRouteFocus } from './map-route-focus';
import { isCustomerSite, isInfrastructureSite } from '@brng/domain';
import { FrequencyBand, deepEqual, notNullish, unique } from '@brng/common';
import { bound, observable } from 'ecce-preact';
import { CustomerAlternativeDevicesFilter } from '../../filtering/customer-alternative-devices-filter/customer-alternative-devices-filter';
import { MapUrl } from '../../util';


export type MapOverlayMode = 'frequency' | 'coverage';
export const asOverlayMode = (x: string): MapOverlayMode => {
	switch(x) {
		case 'frequency':
		case 'coverage':
			return x;
		default:
			return 'frequency';
	}
};

export type MapDeviceTypeFilter = Readonly<{
	'Access Point': boolean;
	'Backhaul': boolean;
}>;

export type MapRouteNetworkItems = Readonly<{
	infrastructureSites: readonly MapInfrastructureSiteIconsProps[];
	customerSites: readonly MapCustomerSitePinProps[];
	devicesFrequencyOverlays: MapDevicesOverlaysProps | null;
	coverageOverlay: MapCoverageOverlayProps | null;
	deviceArrows: readonly MapDeviceArrowsProps[];
}>;
const EMPTY_ITEMS: MapRouteNetworkItems = {
	infrastructureSites: [],
	customerSites: [],
	devicesFrequencyOverlays: null,
	coverageOverlay: null,
	deviceArrows: [],
};

export type MapRouteOverlayMode = 'frequency' | 'coverage';

/**
 * Manages the display of NetworkItems on the /map route.
 */
export class MapRouteDisplay {
	static readonly #DEFAULT_OVERLAY_MODE = 'frequency';
	static readonly #DEFAULT_FREQUENCY = '900';
	
	#network: Network | null = null;
	#urlParams: UrlSearchParamController;

	#infoPopupTarget: Site | null = null;
	get infoPopupTarget(): Site | null { return this.#infoPopupTarget; }
	@observable() private set infoPopupTarget(value: Site | null) { this.#infoPopupTarget = value; }

	#frequencyBandOptions: readonly FrequencyBand[] = [];
	get frequencyBandOptions(): readonly FrequencyBand[] { return this.#frequencyBandOptions; }
	@observable() private set frequencyBandOptions(value: readonly FrequencyBand[]) { this.#frequencyBandOptions = value; }
	
	/**
	 * The frequency band the user has actively chosen.
	 *
	 * This may be different to the currently selected {@link frequencyBand} if
	 * the band they have chosen is temporarily unavailable due to the current
	 * focus state.
	 */
	#desiredFrequencyBand: FrequencyBand | null;

	#frequencyBand: FrequencyBand | null;
	get frequencyBand(): FrequencyBand | null { return this.#frequencyBand; }
	@observable() private set frequencyBand(value: FrequencyBand | null) { this.#frequencyBand = value; }
	
	#deviceTypeFilter: MapDeviceTypeFilter;
	get deviceTypeFilter(): MapDeviceTypeFilter { return this.#deviceTypeFilter; }
	@observable() private set deviceTypeFilter(value: MapDeviceTypeFilter) { this.#deviceTypeFilter = value; }
	
	#showCustomers: boolean;
	get showCustomers(): boolean { return this.#showCustomers; }
	@observable() private set showCustomers(value: boolean) { this.#showCustomers = value; }
	
	#overlayMode: MapRouteOverlayMode;
	get overlayMode(): MapRouteOverlayMode { return this.#overlayMode; }
	@observable() private set overlayMode(value: MapRouteOverlayMode) { this.#overlayMode = value; }

	#focus: MapRouteFocus | null = null;
	get focus(): MapRouteFocus | null { return this.#focus; }
	@observable() private set focus(value: MapRouteFocus | null) { this.#focus = value; }
	
	#items: MapRouteNetworkItems = EMPTY_ITEMS;
	get items(): MapRouteNetworkItems { return this.#items; }
	@observable() private set items(value: MapRouteNetworkItems) { this.#items = value; }

	readonly alternativeDevices: CustomerAlternativeDevicesFilter;
	
	constructor(urlParams: UrlSearchParamController) {
		this.#urlParams = urlParams;

		this.#desiredFrequencyBand = this.#frequencyBand = MapUrl.parseFrequency(this.#urlParams.getString(MapUrl.PARAM_FREQUENCY, MapRouteDisplay.#DEFAULT_FREQUENCY));
		this.#deviceTypeFilter = MapUrl.parseDeviceFilter(this.#urlParams.getString(MapUrl.PARAM_DEVICE_FILTER));
		this.#showCustomers = !this.#urlParams.getBoolean(MapUrl.PARAM_HIDE_CUSTOMER);
		this.#overlayMode = asOverlayMode(this.#urlParams.getString('overlayMode', 'frequency'));

		this.alternativeDevices = new CustomerAlternativeDevicesFilter({ urlParams: this.#urlParams })
			.on('change', () => this.#updateItems());
	}
	
	setNetwork(network: Network): void {
		if(network !== this.#network) {
			this.#network = network;
			
			/*
				Any references to NetworkItems we're holding in the focus state are
				no longer valid, so refresh them from the URL param state.
			*/
			this.#updateFocus(MapUrl.parseFocus(this.#urlParams.getString(MapUrl.PARAM_FOCUS), this.#network));
		}
	}
	
	openInfoPopup(target: Site): void {
		this.infoPopupTarget = target;
	}

	@bound()
	closeInfoPopup(): void {
		this.infoPopupTarget = null;
	}

	@bound()
	setFrequencyBand(band: BandName | null): void {
		this.#desiredFrequencyBand = FrequencyBand.fromFrequency(band);
		if(this.#desiredFrequencyBand !== this.frequencyBand) {
			this.#urlParams.setString(MapUrl.PARAM_FREQUENCY, MapUrl.stringifyFrequency(this.#desiredFrequencyBand), MapRouteDisplay.#DEFAULT_FREQUENCY);
			this.frequencyBand = this.#desiredFrequencyBand;
			this.#updateItems();
		}
	}

	setDeviceTypeFilter(deviceType: keyof MapDeviceTypeFilter, show: boolean): void {
		let nextFilter = {
			...this.deviceTypeFilter,
			[deviceType]: show,
		};

		if(!nextFilter['Access Point'] && !nextFilter['Backhaul']) {
			nextFilter = {
				'Access Point': !this.deviceTypeFilter['Access Point'],
				'Backhaul': !this.deviceTypeFilter['Backhaul'],
			};
		}

		if(!deepEqual(this.deviceTypeFilter, nextFilter)) {
			this.deviceTypeFilter = nextFilter;
			this.#updateItems();
			
			const param = MapUrl.stringifyDeviceFilter(this.deviceTypeFilter);
			if(param) {
				this.#urlParams.setString(MapUrl.PARAM_DEVICE_FILTER, param);
			} else {
				this.#urlParams.delete(MapUrl.PARAM_DEVICE_FILTER);
			}
		}
	}

	@bound()
	setShowCustomers(show: boolean): void {
		if(show === this.showCustomers) {
			return;
		}

		this.showCustomers = show;
		this.#updateItems();
		this.#urlParams.setBoolean(MapUrl.PARAM_HIDE_CUSTOMER, !this.showCustomers);
	}

	#updateFocus(nextFocus: Partial<Pick<MapRouteFocus, 'sites' | 'device' | 'customer'>> | null) {
		if(nextFocus?.customer) {
			this.focus = {
				kind: 'customerSite',
				sites: nextFocus.sites ?? [],
				device: nextFocus.device ?? null,
				customer: nextFocus.customer,
			};
		} else if(nextFocus?.device) {
			this.focus = {
				kind: 'device',
				sites: nextFocus.sites ?? [],
				device: nextFocus.device,
				customer: null,
			};
		} else if(nextFocus?.sites?.length) {
			this.focus = {
				kind: 'infrastructureSites',
				sites: nextFocus.sites ?? [],
				device: null,
				customer: null,
			};
		} else {
			this.focus = null;
		}

		const param = MapUrl.stringifyFocus(this.focus);
		if(param) {
			this.#urlParams.setString(MapUrl.PARAM_FOCUS, param);
		} else {
			this.#urlParams.delete(MapUrl.PARAM_FOCUS);
		}
		
		this.alternativeDevices.setCustomer(nextFocus?.customer);

		this.#updateItems();
	}

	setOverlayMode(mode: MapRouteOverlayMode): void {
		if(this.overlayMode !== mode) {
			this.overlayMode = mode;
			this.#updateItems();
			this.#urlParams.setString(MapUrl.PARAM_OVERLAY_MODE, mode, MapRouteDisplay.#DEFAULT_OVERLAY_MODE);
		}
	}

	setFocus(item: NetworkItem): void {
		switch(item.kind) {
			case 'site': {
				if(isInfrastructureSite(item)) {
					return this.#updateFocus({ sites: [item] });
				}
				if(isCustomerSite(item)) {
					return this.#updateFocus({
						sites: this.focus?.sites,
						device: this.focus?.device,
						customer: item,
					});
				}
				break;
			}
			case 'device': {
				return this.#updateFocus({
					sites: this.focus?.sites,
					device: item,
					customer: null,
				});
			}
		}
	}

	addToFocus(site: InfrastructureSite): void {
		if(this.focus?.kind !== 'infrastructureSites' || this.focus.sites.includes(site)) {
			return;
		}

		this.#updateFocus({
			sites: [...this.focus.sites, site ],
		});
	}

	/**
	 * Removes device & customer from the focus state, if they are present.
	 */
	@bound()
	refocusSites(): void {
		if(this.focus?.kind !== 'infrastructureSites' && this.focus?.sites.length) {
			this.#updateFocus({
				sites: this.focus.sites,
			});
		}
	}

	removeFromFocus(item: NetworkItem | null | undefined): void {
		if(!item) {
			return;
		}

		switch(item.kind) {
			case 'site': {
				if(isInfrastructureSite(item)) {
					return this.#updateFocus({
						sites: this.focus?.sites.filter(s => s !== item),
						device: this.focus?.device,
						customer: this.focus?.customer,
					});
				}
				if(isCustomerSite(item)) {
					if(this.focus?.customer === item) {
						this.#updateFocus({
							sites: this.focus.sites,
							device: this.focus.device,
							customer: null,
						});
					}
				}
				break;
			}
			case 'device': {
				if(this.focus?.device === item) {
					this.#updateFocus({
						sites: this.focus.sites,
						device: null,
						customer: this.focus.customer,
					});
				}
			}
		}
	}

	@bound()
	removeSitesFromFocus() {
		this.#updateFocus({
			sites: [],
			device: this.focus?.device,
			customer: this.focus?.customer,
		});
	}
	
	/**
	 * Update which NetworkItems are being rendered on the map, based on the
	 * current focus & filter state.
	 */
	#updateItems() {
		if(!this.#network) {
			this.items = EMPTY_ITEMS;
			return;
		}

		const devices = this.#getRelevantDevices();

		// Update frequency band options based on which devices are relevant.
		this.#updateFrequencyBandOptions(devices);

		this.items = {
			infrastructureSites: this.#getInfrastructureSiteItems(),
			customerSites: this.#getCustomerSiteItems(),
			...this.#getDeviceItems(devices),
			coverageOverlay: this.#getCoverageOverlay(),
		};
	}

	/**
	 * Get the infrastructure site icons which should be displayed for the
	 * current focus & filter state.
	 */
	#getInfrastructureSiteItems(): readonly MapInfrastructureSiteIconsProps[] {
		if(!this.#network) {
			// Should never happen, as we don't call this when Network is null...
			return [];
		}

		return this.#network.infrastructureSites
			.map(site => ({
				site,
				highlight: this.#shouldHighlightSite(site),
			}));
	}

	/**
	 * Check if the icon for the passed `site` should be highlighted for the
	 * current focus state.
	 */
	#shouldHighlightSite(site: InfrastructureSite): boolean {
		switch(this.focus?.kind) {
			case 'infrastructureSites':
				return this.focus.sites.includes(site);
			case 'customerSite':
				return this.focus.customer.parentDevices?.some(s => s.site === site) ?? false;
			case 'device':
				return this.focus.device.site === site;
			default:
				return false;
		}
	}

	/**
	 * Get the customer site pins which should be displayed for the current focus
	 * & filter state.
	 */
	#getCustomerSiteItems(): readonly MapCustomerSitePinProps[] {
		if(!this.focus) {
			return [];
		}

		if(this.focus.kind === 'customerSite') {
			return  [{
				customer: this.focus.customer,
				device: this.focus.customer.parentDevices?.[0] ?? null,
				lineToDevice: true,
				highlight: true,
			}];
		}

		if(!this.showCustomers) {
			return [];
		}
		
		switch(this.focus?.kind) {
			case 'infrastructureSites': {
				return this.focus.sites
					.flatMap(site => site.devices)
					.flatMap(device => {
						const highlight = !this.frequencyBand || device.radios.some(d => d.band === this.frequencyBand);
						return device.customers.map(customer => ({
							customer,
							device,
							highlight,
						}));
					});
			}
			case 'device': {
				const device = this.focus.device;
				return this.focus.device.customers
					.map(customer => ({
						customer,
						device,
						highlight: true,
					}));
			}
		}
	}

	/**
	 * Get the base set of Devices which might be rendered based on the current
	 * focus state.
	 *
	 * This is separated from {@link getDeviceItems()}, as we need to use this
	 * to determine the set of frequency bands it is possible to filter these
	 * devices by.
	 */
	#getRelevantDevices(): readonly Device[] {
		if(!this.#network) {
			return [];
		}

		switch(this.focus?.kind) {
			case 'infrastructureSites': {
				return this.focus.sites.flatMap(site => site.devices);
			}
			case 'customerSite': {
				return [
					...this.focus.customer.parentDevices ?? [],
					...this.focus.customer.alternativeDevices.filter(this.alternativeDevices.apply),
				].filter(unique);
			}
			case 'device': {
				return [ this.focus.device ];
			}
			default:
				return this.#network?.infrastructureDevices ?? [];
		}
	}

	/**
	 * Update the set of frequency bands displayed for filtering, based on those
	 * which are in use by the passed `devices`.
	 *
	 * If the user's chose frequency band filter is no longer present, it will
	 * be temporarily overridden with one which is in use.
	 */
	#updateFrequencyBandOptions(devices: readonly Device[]): void {
		if(this.focus?.kind === 'customerSite') {
			this.frequencyBandOptions = [];
			this.frequencyBand = null;
			return;
		}
		
		const bands = new Set<FrequencyBand>();
		for(const device of devices) {
			for(const radio of device.radios) {
				if(radio.band) {
					bands.add(radio.band);
				}
			}
		}

		this.frequencyBandOptions = [...bands].sort((a, b) => a.start - b.start);

		if(this.#desiredFrequencyBand && !bands.has(this.#desiredFrequencyBand)) {
			this.frequencyBand = this.frequencyBandOptions[0] ?? null;
		} else if(this.frequencyBand !== this.#desiredFrequencyBand) {
			this.frequencyBand = this.#desiredFrequencyBand;
		}
	}

	/**
	 * Get the devices overlays props for the passed `devices` based on the
	 * current filter & focus state.
	 */
	#getDeviceItems(devices: readonly Device[]): Pick<MapRouteNetworkItems, 'devicesFrequencyOverlays' | 'deviceArrows'> {
		if(this.overlayMode === 'coverage') {
			return {
				devicesFrequencyOverlays: null,
				deviceArrows: [],
			};
		}
		
		if(this.focus?.kind === 'device') {
			const backhaulSiblings = devices
				.map(d => d.sibling)
				.filter(notNullish);
			return {
				devicesFrequencyOverlays: {
					devices: [ ...devices, ...backhaulSiblings ],
				},
				deviceArrows: [],
			};
		}

		const filteredDevices = devices
			.filter(device => this.deviceTypeFilter[device.type as keyof MapDeviceTypeFilter])
			.filter(device => {
				if(!this.#frequencyBand) {
					return true;
				}

				return device.radios.some(r => r.band === this.#frequencyBand);
			});

		return {
			devicesFrequencyOverlays: {
				devices: filteredDevices,
				highlight: this.focus?.customer?.parentDevices?.map(d => d.id) ?? [],
			},
			deviceArrows: this.focus?.kind === 'infrastructureSites'
				? filteredDevices.map(device => ({ device }))
				: [],
		};
	}

	#getCoverageOverlay(): MapCoverageOverlayProps | null {
		if(!this.#network || this.overlayMode !== 'coverage') {
			return null;
		}
	
		if(this.focus?.sites.length) {
			return {
				sites: this.focus.sites,
			};
		}

		return {
			sites: this.#network.infrastructureSites,
		};
	}
}