import type { GetDataResponse } from '@brng/common';
import type { EventCallback, EventType } from '../../util';
import { bound, observable } from 'ecce-preact';
import { EventManager, HttpStatusCode } from '../../util';
import { ApiResponse, type ApiService } from '../api-service';
import { BearingError } from '../error-service';
import { LocalDataCache } from './local-data-cache';


type LegacyData = Omit<GetDataResponse.Ok, 'sites' | 'customerLocations' | 'hiddenSites'>;

/**
 * Event emitted when new data is available.
 */
export type DataEvent = Readonly<{
	/**
	 * The error data response from /api/data.
	 */
	data: GetDataResponse.Ok;

	/**
	 * True if this event is the result of a periodic background refresh, or
	 * false if this was the result of an explicit refresh.
	 */
	background: boolean;
}>;

/**
 * Event emitted when we encounter a data error.
 */
export type DataErrorEvent = Readonly<{
	/**
	 * The error response received from /api/data.
	 */
	error: GetDataResponse.Error;
}>;

type DataServiceEvents = {
	'data': DataEvent;
	'ready': DataEvent;
	'error': DataErrorEvent;
};

export type DataServiceConfig = {
	/**
	 * Millisecond interval between background refreshes, or `false` to disable
	 * background refresh.
	 */
	refreshInterval: false | number;
};
export type DataServiceDependencies = {
	apiService: ApiService;
};


/**
 * Responsible for fetching the application data from /api/data.
 *
 * Add event listeners to be notified when data or errors are received:
 * ```ts
 * dataService.on('data', ev => console.log(ev.data));
 * dataService.on('error', ev => console.log(ev.error));
 * ```
 */
export class DataService {
	readonly #api: ApiService;

	#etag: string | null = null;
	readonly #cache = new LocalDataCache();
	
	readonly #refreshInterval: false | number;
	#refreshTimeout: number | undefined = undefined;

	readonly #events = new EventManager<DataServiceEvents>();

	#loading = false;
	/**  True while the DataService is fetching data. */
	get loading(): boolean { return this.#loading; }
	@observable() private set loading(value: boolean) { this.#loading = value; }
	
	#ready: boolean = false;
	get ready(): boolean { return this.#ready; }
	@observable() private set ready(value: boolean) { this.#ready = value; }

	#legacyData: LegacyData | undefined = undefined;
	/**
	 * @deprecated Legacy raw data response, exposed for compatibility. Use
	 *  `dataService.on('data', ...)` to be notified about new data.
	 */
	// TODO(#527): Remove this once unused.
	get legacyData(): LegacyData | undefined { return this.#legacyData; }
	@observable() private set legacyData(value: LegacyData | undefined) { this.#legacyData = value; }

	#legacyDataError: GetDataResponse.Error | undefined = undefined;
	/**
	 * @deprecated Legacy raw error response, exposed for compatibility. Use
	 *  `dataService.on('error', ...)` to be notified about data errors.
	 */
	// TODO(#527): Remove this once unused.
	get legacyDataError(): GetDataResponse.Error | undefined { return this.#legacyDataError; }
	@observable() private set legacyDataError(value: GetDataResponse.Error | undefined) { this.#legacyDataError = value; }
	
	constructor(config: DataServiceConfig, deps: DataServiceDependencies) {
		this.#api = deps.apiService;

		this.#refreshInterval = config.refreshInterval;
	}

	/**
	 * Perform the initial data fetch.
	 */
	async initialise(): Promise<void> {
		const cached = await this.#cache.get();
		if(cached) {
			this.#receiveData(cached.data, cached.etag, false);
			this.backgroundRefresh(); // Not awaited.
			return;
		}

		await this.refresh();
	}

	/**
	 * Re-fetch the data.
	 */
	@bound()
	async refresh(): Promise<void> {
		if(this.loading) {
			return;
		}

		this.loading = true;
		try {
			await this.#fetchData(false);
		} finally {
			this.loading = false;
		}
	}

	@bound()
	async backgroundRefresh(): Promise<void> {
		try {
			await this.#fetchData(true);
		} catch(err) {
			// No need to interrupt the user here...
		}
	}

	/**
	 * Perform the data fetch.
	 *
	 * @param background Determines if this fetch is a periodic background
	 * refresh. If true, error event listeners will not be notified, and
	 * DataEvent.background will be true.
	 */
	async #fetchData(background: boolean): Promise<void> {
		clearTimeout(this.#refreshTimeout);
		this.#refreshTimeout = undefined;

		const response = await this.#api.get<GetDataResponse>('/api/data', {
			headers: {
				'if-none-match': this.#etag ?? '',
			},
		});
	
		if(!response.ok) {
			if(response.errorType === 'http' && HttpStatusCode.isNotModified(response.status)) {
				this.#cache.resetTimestamp();
				return;
			}

			throw new Error(`Failed to fetch data: ${ApiResponse.toStatusString(response)}`);
		}
			
		if(response.body.ok) {
			const etag = response.headers.get('etag');
			this.#receiveData(response.body, etag, background);
			await this.#cache.set(response.body, etag);
			return;
		}
	
		// Don't interrupt the user for errors from a background refresh.
		if(background) {
			return;
		}

		if(response.body.reason === 'repository-error') {
			throw new BearingError({ kind: 'repository', response: response.body });
		}
	
		this.#events.notify('error', { error: response.body });
		this.legacyData = undefined;
		this.legacyDataError = response.body as GetDataResponse.Error;
	}

	#receiveData(data: GetDataResponse.Ok, etag: string | undefined | null, background: boolean) {
		this.#etag = etag	?? null;
		this.#events.notify('data', {
			data,
			background,
		});
		this.legacyData = data;
		this.legacyDataError = undefined;
		
		if(this.#refreshInterval) {
			this.#refreshTimeout = setTimeout(this.backgroundRefresh, this.#refreshInterval) as unknown as number;
		}

		this.#events.notify('ready', {
			data,
			background,
		});
		this.ready = true;
	}

	clearCache(): void {
		this.#cache.clear();
	}
	
	on<E extends EventType<DataServiceEvents>>(event: E, listener: EventCallback<DataServiceEvents, E>): this {
		this.#events.on(event, listener);
		return this;
	}

	off<E extends EventType<DataServiceEvents>>(event: E, listener: EventCallback<DataServiceEvents, E>): this {
		this.#events.off(event, listener);
		return this;
	}

	/**
	 * Clears the timeout used for periodic background refresh.
	 *
	 * For use in unit tests, not required in the actual application.
	 */
	terminate() {
		clearTimeout(this.#refreshTimeout);
	}
}