import type { RenderableDevice } from '../../../services/overlay-rendering-service';
import { useObservable } from 'ecce-preact';
import { MapControls, MapDevicesOverlays, MapRadiusSlider, MapView } from '../../../map';
import { useSpectrumComparisonController } from '../spectrum-comparison-controller-context';


const SpectrumDeviceOverlay = () => {
	const controller = useSpectrumComparisonController();
	useObservable(controller.slider);
	
	const d: RenderableDevice = {
		...controller.equipment,
		id: controller.equipment.id + '-compare',
		radios: [{
			id: 'fake-radio',
			frequency: controller.slider.frequency,
			channelWidth: controller.slider.channelWidth,
			band: controller.frequencyBand,
			frequencyBand: controller.frequencyBand.band,
		}],
	} as unknown as RenderableDevice;

	return (
		<MapDevicesOverlays
			devices={ [ d ] }
			highlight={ [ d.id] }
		/>
	);
};

export const SpectrumMapView = () => {
	const controller = useSpectrumComparisonController();
	useObservable(controller, 'interfering');

	return (
		<MapView controller={ controller.map }>
			<MapDevicesOverlays
				devices={ controller.interfering.map(i => i.equipment) }
			/>
			<SpectrumDeviceOverlay/>

			<MapControls position="LEFT_TOP">
				<MapRadiusSlider controller={ controller.map } overMap/>
			</MapControls>
		</MapView>
	);
};