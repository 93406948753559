import type { LicenseState } from '@brng/common';
import type { VNode } from 'preact';
import type { ClassProp } from '../../../util';
import { h } from 'preact';
import classNames from 'classnames';
import { useData } from '../../../data';
import './license-flag.scss';


export type LicenseFlagProps = ClassProp & {
	licenseState: LicenseState;
	onClick?: () => void;
};
export const LicenseFlag = ({ className, licenseState, onClick }: LicenseFlagProps): VNode | null => {
	const { data } = useData();
	
	if(data?.license.state !== licenseState) {
		return null;
	}

	return h(onClick ? 'button' : 'span', {
		className: classNames('license-flag', className),
		onClick,
		children: licenseState,
	});
};
