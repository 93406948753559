import type { ClassProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { isNullish } from '@brng/common';
import './definition-list.scss';


export type Definition = {
	label: string;
	value: string | number | JSX.Element | null | undefined;
	monospace?: boolean;
	condition?: boolean;
};

export type DefinitionListProps = ClassProp & {
	definitions: readonly Definition[];
	itemClassName?: string;
	monospace?: boolean
};
export const DefinitionList = ({ className, itemClassName, definitions, monospace }: DefinitionListProps): JSX.Element | null => {
	if(!definitions.length) {
		return null;
	}

	const definitionElements = definitions.map(def => {
		if (isNullish(def.value) || def.condition === false) {
			return null;
		}

		const ddClassName = classNames({
			'mono': def.monospace ?? monospace,
		});

		return (
			<div
				className={ classNames('definition-list__item panel', itemClassName) }
				key={ def.label }
			>
				<td>
					{def.label}
				</td>
				<dd className={ ddClassName }>
					{def.value}
				</dd>
			</div>
		);
	});
	if(definitionElements.every(isNullish)) {
		return null;
	}
	
	return (
		<dl className={ classNames('definition-list panel-group', className) }>
			{ definitionElements }
		</dl>
	);
};