import type { ErrorInfo, Services } from '../../../services';
import { bound, observable } from 'ecce-preact';


export type SubmissionStatus = 'idle' | 'automaticallySubmitted' | 'submitting' | 'complete' | 'failed';

export type ErrorDialogConfig = {
	errorInfo: ErrorInfo;
	services: Services;
};
export class ErrorDialogController {
	readonly #error: ErrorInfo;
	readonly #services: Services;

	readonly dialogTitle: string;

	readonly showLogoutButton: boolean;
	readonly showAutoSubmitCheckbox: boolean;

	#canSubmit: boolean;
	get canSubmit(): boolean { return this.#canSubmit; }
	@observable() private set canSubmit(value: boolean) { this.#canSubmit = value; }

	#submissionStatus: SubmissionStatus;
	get submissionStatus(): SubmissionStatus { return this.#submissionStatus; }
	@observable() private set submissionStatus(value: SubmissionStatus) { this.#submissionStatus = value; }

	#autoSubmitInFuture: boolean = false;
	get autoSubmitInFuture(): boolean { return this.#autoSubmitInFuture; }
	@observable() private set autoSubmitInFuture(value: boolean) { this.#autoSubmitInFuture = value; }

	#submissionFailure: unknown = null;

	constructor(config: ErrorDialogConfig) {
		this.#services = config.services;
		this.#error = config.errorInfo;

		this.dialogTitle = this.#makeDialogTitle();

		this.showLogoutButton = !!this.#services.authService.activeUser;
		this.#canSubmit = this.#isInitiallySubmittable();
		this.#submissionStatus = this.#services.errorService.didAutoSubmit
			? 'automaticallySubmitted'
			: 'idle';

		this.showAutoSubmitCheckbox = this.#services.authService.admin
			&& !this.#services.settingsService.settings.automaticErrorReports;
	}

	@bound()
	logout(): void {
		this.#services.authService.logout();
	}

	@bound()
	refresh(): void {
		window.location.reload();
	}

	@bound()
	async submitReport(): Promise<void> {
		if(this.submissionStatus !== 'idle') {
			return;
		}

		this.submissionStatus = 'submitting';
		const result = await this.#services.errorService.submitErrorReport(this.#error);

		if(this.autoSubmitInFuture) {
			try {
				await this.#services.settingsService.update('automaticErrorReports', true);
			} catch(err) {
				console.error('Failed to enable automatic error reports:', err); // eslint-disable-line no-console
			}
		}

		if(!result.ok) {
			this.submissionStatus = 'failed';
			this.#submissionFailure = result.reason;
			this.canSubmit = false;
			return;
		}

		this.submissionStatus = 'complete';
		this.canSubmit = false;
	}

	@bound()
	setAutoSubmitInFuture(nextAutoSubmit: boolean): void {
		this.autoSubmitInFuture = nextAutoSubmit;
	}

	#isInitiallySubmittable() {
		return !this.#services.errorService.didAutoSubmit
			&& this.#services.errorService.isSubmittableError(this.#error);
	}

	#makeErrorDetails(): Record<string, unknown> {
		const details: Record<string, unknown> = { ...this.#error };
		if('error' in this.#error) {
			details.error = {
				message: String(this.#error.error),
				stack: this.#error.error.stack,
			};
		}

		return details;
	}

	#makeDialogTitle(): string {
		switch(this.#error.kind) {
			case 'repository':
				switch(this.#error.response.status) {
					case 'authentication-error':
						return `Cannot authenticate with ${this.#error.response.provider}`;
					case 'network-error':
						return `Cannot connect to ${this.#error.response.provider}`;
					case 'permission-error':
						return `Invalid permissions for ${this.#error.response.provider}`;
					case 'upstream-error':
						return `${this.#error.response.provider} error`;
					case 'incomplete-certificate-chain':
						return `Unable to securely connect to ${this.#error.response.provider}`;
					default:
						return `Error connecting to ${this.#error.response.provider}`;
				}
			case 'api':
				return (this.#error.status ? `${this.#error.status}: ` : '') + this.#error.name;
			case 'unknown':
				return 'Something went wrong';
		}
	}

	get contactSupportHref(): string {
		const bodySegments = [ '', '', '---', 'Error details:' ];

		bodySegments.push(JSON.stringify(this.#makeErrorDetails(), null, 2));

		if('error' in this.#error) {
			bodySegments.push('');
			bodySegments.push('Stack trace: ' + this.#error.error.message + '\n' + this.#error.error.stack);
		}

		if(this.submissionStatus === 'failed') {
			bodySegments.push('');
			bodySegments.push(`Error report submission failed: ${this.#submissionFailure}`);
		}

		const body = bodySegments.join('\n').replace(/\n/g, '%0D%0A');
		return `mailto:support@brng.net?subject=Bearing error: ${this.#error.kind}&body=${body}`;
	}
}