import type { SettingsService } from '../settings-service';
import type { DataService } from '../data-service';
import { asError, type ErrorReportRequest } from '@brng/common';
import { observable } from 'ecce-preact';
import { AuthError } from '../auth-service';
import { ErrorInfo } from './bearing-error';


export namespace SubmitErrorReportResult {
	export type Ok = {
		ok: true;
	};
	export type Failure = {
		ok: false;
		reason: string;
	};
}
export type SubmitErrorReportResult = (
	| SubmitErrorReportResult.Ok
	| SubmitErrorReportResult.Failure
);

export type ErrorServiceConfig = {
	dataService: DataService;
	settingsService: SettingsService;
};

export class ErrorService {
	#error: ErrorInfo | null = null;
	get error(): ErrorInfo | null { return this.#error; }
	@observable() private set error(value: ErrorInfo | null) { this.#error = value; }
	
	#didAutoSubmit = false;

	readonly #dataService: DataService;
	readonly #settingsService: SettingsService;

	constructor(config: ErrorServiceConfig) {
		if(typeof window !== 'undefined') {
			window.addEventListener('error', ev => this.handleError(ev.error));
			window.addEventListener('unhandledrejection', ev => this.handleError(ev.reason));
		}

		this.#dataService = config.dataService;
		this.#settingsService = config.settingsService;
	}

	async handleError(thrown: unknown) {
		if(thrown instanceof AuthError) {
			this.#dataService.clearCache();
			switch(thrown.status) {
				case 401:
					/*
						Throw away the world & reload the page. The user will be shown the
						login form.
					*/
					setTimeout(() => {
						window.location.reload();
					});
					return;
				case 403:
					/*
						Route back to the home page. In the normal application flow, this
						could only happen if the user's admin privileges had been revoked
						since they last logged in.
					*/
					setTimeout(() => {
						window.location.href = '/';
					});
					return;
			}
		}

		const error = ErrorInfo.fromError(thrown);

		if(this.isSubmittableError(error)) {
			if(this.#settingsService.automaticErrorReports) {
				const result = await this.submitErrorReport(error);
				this.#didAutoSubmit = result.ok;
			}
		} else {
			console.error(thrown); // eslint-disable-line no-console
		}

		this.error = error;
	}

	isSubmittableError(error: ErrorInfo): boolean {
		switch(error.kind) {
			case 'repository':
				return error.response.status === 'unknown-error';
			default:
				return true;
		}
	}

	async submitErrorReport(error: ErrorInfo): Promise<SubmitErrorReportResult> {
		try {
			const response = await fetch('/api/error-report', {
				method: 'POST',
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify({
					type: error.kind,
					message: 'Client error',
					error: 'error' in error
						? { name: error.error.name, message: error.error.message, stack: error.error.stack }
						: undefined,
					details: {
						path: window.location.pathname,
					},
				} satisfies ErrorReportRequest),
			});

			if(!response.ok) {
				throw new Error(`Responded ${response.status} ${await response.text().catch(() => '')}`);
			}

			return { ok: true };
		} catch(err) {
			const error = asError(err);
			return {
				ok: false,
				reason: `${error.name}: ${error.message}`,
			};
		}
	}

	get didAutoSubmit() {
		return this.#didAutoSubmit;
	}
}