import { Color } from './color';


export type Range = Readonly<[ low: number, high: number ]>;

const BAND_NAMES = [ '900', '2400', '3650', '5000', '5900', '6000', '10000', '11000', '24000', '60000' ] as const;
export type BandName = typeof BAND_NAMES[number];
export const isBandName = (x: unknown): x is BandName => BAND_NAMES.includes(x as BandName);

export class FrequencyBand {
	readonly radialGradientStops: string;
	
	private constructor(readonly band: BandName, readonly name: string, readonly start: number, readonly end: number, readonly exclusions: Readonly<Range[]>, readonly colors: Readonly<Color[]>, readonly channelSpacing: number, readonly channelWidths: Readonly<number[]>) {
		// Generate radial gradient stops
		const result:string[] = [];
		for(let i = 0; i < this.colors.length; i++) {
			result.push(`<stop offset="${(i * 100 / (this.colors.length - 1)).toFixed(2).replace('.00', '')}%" style="stop-color:${this.colors[i].toRGB()};stop-opacity:0.7;" />`);
		}
		this.radialGradientStops = result.join('');
	}

	normalizeFrequency(frequency: number ,offset=0): number {
		if(frequency < this.start) {
			return offset ? offset / 2 : 0;
		}
		if(frequency >= this.end) {
			return 1;
		}
		let normalized = frequency - this.start;

		this.exclusions.filter(range => frequency>=range[1])
			.forEach(range => {
				normalized -= range[1]-range[0];
			});

		const max = this.end - this.start - this.exclusions.reduce((total,range) => total + range[1] - range[0],0);
		return (normalized/max)*(1-offset) + offset;
	}
		
	#colorNormalize(frequency: number,offset=0):number {
		return this.normalizeFrequency(frequency,offset) * (this.colors.length-1);
	}
	
	color(frequency: number | null | undefined,offset=0): Color {
		if(!frequency) {
			frequency = 0;
		}

		const normalized = this.#colorNormalize(frequency,offset);

		const index = Math.floor(normalized);
		const startColor = this.colors[index];
		if(index === this.colors.length-1) {
			return startColor;
		}
		return startColor.blend(this.colors[index+1], normalized - index);
	}

	linearGradient(start: number, end: number): string {
		const offset = 0.15;
		const colorsAndLinearStop: [Color,string][] = [];
		colorsAndLinearStop.push([this.color(start,offset),'0']);

		const normalizedStart = this.#colorNormalize(start,offset);
		const normalizedEnd = this.#colorNormalize(end,offset);
		const highIndex = Math.ceil(normalizedEnd);

		for(let index = Math.ceil(normalizedStart);index<highIndex;index++) {
			colorsAndLinearStop.push([this.colors[index],(100*(index-normalizedStart)/(normalizedEnd-normalizedStart)).toFixed(2)]);
		}

		colorsAndLinearStop.push([this.color(end,offset),'100']);

		return `linear-gradient(to right,${colorsAndLinearStop.map(([color,linearStop]) => `${color.toRGBA(0.7)} ${linearStop}%`).join(',')})`;
	}
	
	isExcluded(frequency: number, width = 0): boolean {
		const frequencyStart = frequency - width;
		const frequencyEnd = frequency + width;

		for(const [ excludeStart, excludeEnd ] of this.exclusions) {
			if(frequencyStart <= excludeEnd && frequencyEnd >= excludeStart) {
				return true;
			}
		}

		return false;
	}
	
	static fromBand(bandName: BandName): FrequencyBand {
		return FrequencyBand.bands[bandName];
	}

	static fromFrequency(frequency: BandName | string | number | null | undefined): FrequencyBand | null {
		if(!frequency) {
			return null;
		}
		if(typeof frequency === 'string' && isBandName(frequency)) {
			return this.fromBand(frequency);
		}
		if(Number.isNaN(Number(frequency))) {
			return null;
		}
		
		return FrequencyBand.bandNames
			.map(this.fromBand)
			.find(value => Number(frequency) >= value.start && Number(frequency) <= value.end) ?? null;
	}
	
	static readonly bands: Readonly<Record<BandName, FrequencyBand>> = {
		'900': new FrequencyBand('900', '900 MHz', 902, 928,
			[],
			[
				Color.lime,
				Color.cyan,
				Color.blue,
			], 0.5, [1, 2, 5, 6, 7, 8, 10, 15, 20]),
		'2400': new FrequencyBand('2400','2.4 GHz',2400,2500,
			[],
			[
				Color.blue,
				Color.magenta,
				Color.red,
			],2.5,[5,10,15,20,30,40]),
		'3650': new FrequencyBand('3650','3.65 GHz',3550,3700,
			[],
			[
				Color.red,
				Color.yellow,
				Color.lime,
			],2.5,[5,10,15,20,30,40]),
		'5000': new FrequencyBand('5000','5 GHz',4940,5850,
			[
				[4990, 5150],
				[5350, 5465],
			],
			[
				Color.red,
				Color.yellow,
				Color.lime,
				Color.cyan,
				Color.blue,
				Color.magenta,
			],2.5,[5,10,15,20,30,40,80,160]),
		'5900': new FrequencyBand('5900','5.9 GHz',5850, 5925,
			[],
			[
				Color.red,
				Color.yellow,
				Color.lime,
			],2.5,[5,10,15,20,30,40]),
		'6000': new FrequencyBand('6000','6 GHz',5925,6875,
			[
				[6425, 6525],
			],
			[
				Color.cyan,
				Color.blue,
				Color.magenta,
				Color.red,
				Color.yellow,
				Color.lime,
			],5,[20,40,80,160,320]),
		'10000': new FrequencyBand('10000','10 GHz',10000,10500,
			[],
			[
				Color.magenta,
				Color.red,
				Color.yellow,
				Color.lime,
				Color.cyan,
				Color.blue,
			],5,[5,10,15,20,30,40,80,160,320]),
		'11000': new FrequencyBand('11000','11 GHz',10700,11700,
			[],
			[
				Color.magenta,
				Color.red,
				Color.yellow,
				Color.lime,
				Color.cyan,
				Color.blue,
			],5,[5,10,15,20,30,40,80,160,320]),
		'24000': new FrequencyBand('24000','24 GHz',24050,24250,
			[],
			[
				Color.cyan,
				Color.blue,
				Color.magenta,
				Color.red,
				Color.yellow,
				Color.lime,
			],50,[50,56,100,112]),
		'60000': new FrequencyBand('60000','60 GHz',57240,70200,
			[],
			[
				Color.yellow,
				Color.lime,
				Color.cyan,
				Color.blue,
				Color.magenta,
				Color.red,
			],1080,[2160,4320,6480,8640]),
	};
	
	static readonly bandNames: Readonly<BandName[]> = BAND_NAMES.map(bandName => bandName);
}
