import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';


export const SiteInput = Type.Object({
	id: Type.String({ description: 'Unique identifier for the site' }),
	link: Type.String({ format: 'uri', description: 'URL to the site\'s page on UISP/Splunk/Sonar etc.' }),
	name: Type.String({ description: 'Site name or Customer name' }),
	company: Type.Optional(Type.String({ description: 'Company name for customer site' })),
	type: Type.Union([
		Type.Literal('Tower'),
		Type.Literal('Silo'),
		Type.Literal('Building'),
		Type.Literal('House'),
		Type.Literal('Repeater'),
		Type.Literal('Pole'),
		Type.Literal('Other'),
	]),
	usage: Type.Union([
		Type.Literal('Infrastructure', { description: 'The site is part of your network infrastructure.' }),
		Type.Literal('Customer', { description: 'The site is a customer premises' }),
	], { description: 'Describes how the site is used in your network. If in doubt or dual role choose \'Infrastructure\'' }),
	latitude: Type.Number(),
	longitude: Type.Number(),
});
export type SiteInput = Static<typeof SiteInput>;

export const DeviceInput = Type.Object({
	id: Type.String({ description: 'Unique identifier for the device' }),
	link: Type.String({ format: 'uri', description: 'URL to the device\'s page on UISP/Splunk/Sonar etc.' }),
	siteId: Type.String({ description: 'Site ID of this device' }),
	parentId: Type.Optional(Type.String({ description: 'Access Point ID if this is a Subscriber Module' })),
	siblingId: Type.Optional(Type.String({ description: 'Related Backhaul ID if this is a Backhaul' })),
	name: Type.String({ description: 'Device name' }),
	deviceType: Type.String({ description: 'e.g. \'Cambium AP PMP450\'' }),
	ipAddress: Type.Union([
		Type.String({ format: 'ipv4' }),
		Type.String({ format: 'ipv6' }),
	]),
	type: Type.Union([
		Type.Literal('Access Point'),
		Type.Literal('Backhaul'),
		Type.Literal('Subscriber Module'),
	]),
	url: Type.String({ format: 'uri', description: 'URL for direct device access' }),
	antennaType: Type.Union([
		Type.Literal('Sector'),
		Type.Literal('Omni'),
	]),
	azimuth: Type.Optional(Type.Number({ description: 'Azimuth in degrees. Optional as it is calculated for BHs and SMs but, you should include it whenever possible for APs' })),
	sectorSize: Type.Optional(Type.Number({ description: 'Sector size in degrees. Optional but, you should include it whenever possible for APs' })),
	height: Type.Optional(Type.Number()),
	downtilt: Type.Optional(Type.Number()),
	range: Type.Optional(Type.Number({ description: 'Effective range in meters. Optional as it is calculated for BHs and SMs but, you should include it whenever possible for APs' })),

	snmpVersion: Type.Optional(Type.Union([
		Type.Literal('1'),
		Type.Literal('2c'),
	])),
	snmpCommunity: Type.Optional(Type.String()),

	radio1Frequency: Type.Optional(Type.Number({ description: 'Primary radio\'s frequency in MHz' })),
	radio1ChannelWidth: Type.Optional(Type.Number({ description: 'Primary radio\'s channel with in MHz' })),
	radio2Frequency: Type.Optional(Type.Number({ description: 'Secondary radio\'s frequency in MHz' })),
	radio2ChannelWidth: Type.Optional(Type.Number({ description: 'Secondary radio\'s channel with in MHz' })),
	radio3Frequency: Type.Optional(Type.Number({ description: 'Tertiary radio\'s frequency in MHz' })),
	radio3ChannelWidth: Type.Optional(Type.Number({ description: 'Tertiary radio\'s channel with in MHz' })),
});
export type DeviceInput = Static<typeof DeviceInput>;