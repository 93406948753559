import type { VNode } from 'preact';
import type { ClassProp } from '../../../util';
import type { AdminTabId } from '../admin-tabs';
import classNames from 'classnames';
import { getAdminTabIcon, getAdminTabLabel } from '../admin-tabs';
import { useAdminTabs } from '../admin-tabs-context';
import './admin-tab-controls.scss';
import { Icon } from '../../../components';


type AdminTabLinkProps = { tabId: AdminTabId };
const AdminTabLink = ({ tabId }: AdminTabLinkProps) => {
	const { activeAdminTabId } = useAdminTabs();
	const active = activeAdminTabId === tabId;

	return (
		<a
			className={ classNames('admin-tab-controls__link', { active }) }
			href={ `#${tabId}` }
			key={ tabId }
		>
			<Icon icon={ getAdminTabIcon(tabId) }/>
			{ getAdminTabLabel(tabId) }
		</a>
	);
};


export type AdminTabControlsProps = ClassProp;
export const AdminTabControls = ({ className }: AdminTabControlsProps): VNode => {
	const { adminTabSections } = useAdminTabs();
	
	return (
		<nav className={ classNames('admin-tab-controls', className) }>
			{ adminTabSections.map(({ key, title, icon = null, tabs }) => (
				<section className="admin-tab-controls__section" key={ key }>
					{title && (
						<header className="admin-tab-controls__section-header">
							{ icon && <Icon icon={ icon }/> }<h2>{ title }</h2>
						</header>
					)}
					{ tabs.map(tabId => <AdminTabLink tabId={ tabId } key={ tabId }/>) }
				</section>
			)) }
		</nav>
	);
};