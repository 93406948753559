import type { JSX } from 'preact/jsx-runtime';
import { ObservableArray, bound, observable } from 'ecce-preact';
import Fuse from 'fuse.js';
import { SnmpProbeFilterItem } from '../snmp-probe-filter-item';


export type SnmpProbeFilterSelectionCallback = (item: SnmpProbeFilterItem | null) => void;

export class SnmpProbeFilter {
	readonly #allItems: readonly SnmpProbeFilterItem[];
	readonly #fuse: Fuse<SnmpProbeFilterItem>;

	readonly items: ObservableArray<SnmpProbeFilterItem>;
	readonly #onSelection: SnmpProbeFilterSelectionCallback;
	
	#query = '';
	get query(): string { return this.#query; }
	@observable() private set query(value: string) { this.#query = value; }

	#selectedItem: SnmpProbeFilterItem | null = null;
	get selectedItem(): SnmpProbeFilterItem | null { return this.#selectedItem; }
	@observable() private set selectedItem(value: SnmpProbeFilterItem | null) { this.#selectedItem = value; }
	
	
	constructor(items: readonly SnmpProbeFilterItem[], onSelection: SnmpProbeFilterSelectionCallback) {
		this.#allItems = [ ...items ]
			.sort(SnmpProbeFilterItem.compareName)
			.sort(SnmpProbeFilterItem.compareKind);
		this.#fuse = new Fuse(this.#allItems, {
			keys: [ 'name', 'manufacturerName' ],
			includeMatches: true,
			threshold: .3,
		});
		this.items = new ObservableArray(this.#allItems);
		this.#onSelection = onSelection;
	}

	selectItem(item: SnmpProbeFilterItem | null): void {
		if(this.selectedItem) {
			this.selectedItem['selected'] = false;
			this.selectedItem = null;
		}

		if(item) {
			item['selected'] = true;
			this.selectedItem = item;
		}

		this.#onSelection(item);
	}
	
	@bound()
	updateQuery(ev: JSX.TargetedEvent<HTMLInputElement>) {
		this.query = ev.currentTarget.value;

		if(!this.query) {
			this.items.replace(this.#allItems);
			return;
		}

		const results = this.#fuse.search(this.query);
		this.items.replace(results.map(i => i.item).sort(SnmpProbeFilterItem.compareKind));
	}
}