import type { ClassProp } from '../../../../../util';
import type { JSX } from 'preact/jsx-runtime';
import type { Device } from '@brng/domain';
import { isNullish } from '@brng/common';
import classNames from 'classnames';
import { MapUrl, formatDegrees, formatDistance, formatPoint } from '../../../../../util';
import { Copyable, DeviceStatus, Link, getNetworkItemIcon } from '../../../../../components';


export type InventoryDetailsDeviceOverviewProps = ClassProp & {
	device: Device;
};

export const InventoryDetailsDeviceOverview = ({ className, device }: InventoryDetailsDeviceOverviewProps): JSX.Element => (
	<div className={ classNames(className, 'panel-group') }>
		<div className="panel data-panel">
			<div>
				<span className="data-label">ID</span>&nbsp;
				<span className="data-value font-mono"><Copyable value={ device.displayId }/></span>
			</div>
			<Link
				className="data-value"
				label={ device.site.name }
				icon={ getNetworkItemIcon(device.site) }
				href={ '#' + device.site.networkId }
			/>
		</div>
		<div className="panel data-panel">
			<div>
				<span className="data-label">{device.type}</span>&nbsp;&nbsp;
				<span className="data-value">{device.deviceType}</span>
			</div>
			{ device.antennaType === 'Sector' && (
				<div className="data-value">
					{ !isNullish(device.azimuth) && (
						<>
							<span className="font-mono">{formatDegrees(device.azimuth)}</span>&nbsp;
						</>
					)}
					{ !isNullish(device.sectorSize)  && (
						<>
							{ !isNullish(device.azimuth)  && '('}
							<span className="font-mono">{formatDegrees(device.sectorSize)}</span> sector
							{ !isNullish(device.azimuth)  && ')'}
						</>
					)}
				</div>
			)}
			{ isNullish(device.azimuth) && (
				<div className="data-value text-sm">
					Missing azimuth data.
				</div>
			)}
			{ isNullish(device.sectorSize) && (
				<div className="data-value text-sm">
					Missing sector size data.
				</div>
			)}
			<div className="data-value">
				{ isNullish(device.range)
					? <span className="text-sm">Missing wireless range data.</span>
					: <span className="font-mono">{formatDistance(device.range)}</span>
				}
			</div>
		</div>
		<div className="panel data-panel">
			<div className="data-label">IP Address</div>
			<div className="data-value font-mono">
				<Copyable value={ device.ipAddress }/>
			</div>
			<Link className="data-value" href={ device.url }/>
		</div>
		<div className="panel data-panel">
			<div className="data-label">Location</div>
			<Link
				label={ formatPoint(device.location) }
				icon="mapPin"
				href={ MapUrl.forItem(device).toRelativeUrl() }
			/>
		</div>
		<div className="panel data-panel">
			<div className="data-label">Status</div>
			<div className="data-value"> <DeviceStatus status={ device.status }/></div>
		</div>
	</div>
);