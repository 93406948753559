import { useEffect } from 'preact/hooks';
import { useData } from '../../data';
import { useNotificationService } from '../../services/services-context';


const STORAGE_KEY = 'hasShownUpdateNotification';

export const UpdateNotification = (): null => {
	const { data } = useData();
	const { notify } = useNotificationService();

	useEffect(() => {
		if(!data) {
			return;
		}

		if(!data.updateVersion) {
			return;
		}
		
		const hasShownNotification = sessionStorage.getItem(STORAGE_KEY) === 'true';
		if(hasShownNotification) {
			return;
		}

		const url = new URL('https://brng.net/documentation/updating-bearing');
		url.searchParams.set('from', data.version);
		url.searchParams.set('to', data.updateVersion);

		notify({
			severity: 'info',
			message: (
				<>
					Version <strong>{data.updateVersion}</strong> is available!&nbsp;
					<a href={ url.href } target="_blank">Update now</a>.
				</>
			),
			persistent: true,
		});

		sessionStorage.setItem(STORAGE_KEY, 'true');
	}, [data, notify]);
	
	return null;
};