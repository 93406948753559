import type { VNode } from 'preact';
import type { MenuButtonProps, MenuItem } from '../../../components';
import { useMemo } from 'preact/hooks';
import { MenuButton } from '../../../components';
import { useData } from '../../../data';
import { makeAllDataReport, makeMissingDataReport } from '../../../reports';
import { downloadDataUrl } from '../../../util';
import { useSitesInfo } from '../use-sites-info';



const reportMenuItem = (name: string, data: () => string): MenuItem => ({
	label: `${name}.csv`,
	onClick: () => {
		downloadDataUrl(`${name}-${new Date().toISOString()}.csv`, data());
	},
});

export const ReportDownloadButton = (): VNode | null => {
	const { data } = useData();
	const { sites } = useSitesInfo();

	const items = useMemo<MenuButtonProps['items'] | null>(() => {
		if(!data) {
			return null;
		}

		return [
			reportMenuItem('all-data', () => makeAllDataReport(data)),
			reportMenuItem('missing-data', () => makeMissingDataReport(sites)),
		];
		
	}, [data, sites]);

	if(!items) {
		return null;
	}
	
	return (
		<MenuButton
			items={ items }
			label={ (
				<>
					<i class="fas fa-download"/>
					<span>Reports</span>
				</>
			) }
		/>
	);
};