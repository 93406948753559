import type { GetDataResponse } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { DataErrorEvent, DataEvent } from '../../services';
import { observable, useObservable } from 'ecce-preact';
import { Dialog, DialogHeader } from '../../components';
import { Controller, useController } from '../../util';
import { offRouteChange, onRouteChange } from '../../routes';


class NoManualDataDialogController extends Controller {
	#open: boolean = false;
	get open(): boolean { return this.#open; }
	@observable() private set open(value: boolean) { this.#open = value; }

	#admin: boolean = false;
	get admin(): boolean { return this.#admin; }
	@observable() private set admin(value: boolean) { this.#admin = value; }

	#data: GetDataResponse.Ok | null = null;


	protected initialise(): void {
		this.services.dataService.on('ready', this.#handleData);
		this.services.dataService.on('error', this.#handleData);
		onRouteChange(this.#updateState);
	}

	#handleData = (ev: DataEvent | DataErrorEvent): void => {
		if('error' in ev) {
			this.#data = null;
		} else {
			this.#data = ev.data;
		}

		this.#updateState();
	};

	#updateState = (): void => {
		if(!this.#data || window.location.pathname.startsWith('/admin')) {
			this.open = false;
			return;
		}

		this.open = this.#data.providerMetadata.provider === 'Manual' && !this.#data.sites.length;
		this.admin = this.#data.me.role === 'admin';
	};

	protected dispose(): void {
		this.services.dataService.off('ready', this.#handleData);
		this.services.dataService.off('error', this.#handleData);
		offRouteChange(this.#updateState);
	}
}

export const NoManualDataDialog = (): JSX.Element | null => {
	const controller = useController(NoManualDataDialogController);
	useObservable(controller);

	return (
		<Dialog
			className="content content--narrow"
			open={ controller.open }
		>
			<DialogHeader icon="database" title="Upload your Network Data"/>
			<div className="pad-sm">
				<p>
					No data has been uploaded into BEARING.
				</p>
				{ controller.admin && (
					<p>
						Visit <a href="/admin#csv-upload">/admin#csv-upload</a> to upload
						your network data.
					</p>
				)}
				{ !controller.admin && (
					<p>
						Ask your local BEARING administrator to upload your network data.
					</p>
				)}
			</div>
		</Dialog>
	);
};