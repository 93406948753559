import { useObservable } from 'ecce-preact';
import { useInventoryController } from '../../inventory-controller-context';



export const InventoryNoResultsMessage = () => {
	const inventory = useInventoryController();
	useObservable(inventory.query, 'results');
	useObservable(inventory.query, 'search');

	if(!inventory.query.results || inventory.query.results.length) {
		return null;
	}

	return (
		<div className="text-center">
			<p>No results found for <strong>"{inventory.query.search}"</strong></p>
		</div>
	);
};