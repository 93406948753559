import type { ClassProp } from '../../../util';
import type { SnmpProbeFilterItem, SnmpProbeFilterItemKind } from '../snmp-probe-filter-item';
import type { SnmpProbeFilter } from './snmp-probe-filter';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useCallback } from 'preact/hooks';
import { useSidebarTemplate } from '../../../components';
import './snmp-probe-filter-list.scss';


const KIND_LABEL: Record<SnmpProbeFilterItemKind, string> = {
	manufacturer: 'Manufacturer',
	deviceType: 'Device Type',
	radioModel: 'Radio Model',
};


type SnmpProbeFilterListItemProps = {
	item: SnmpProbeFilterItem;
};
const SnmpProbeFilterListItem = ({ item }: SnmpProbeFilterListItemProps) => {
	const sidebar = useSidebarTemplate();
	useObservable(item, 'selected');

	const handleClick = useCallback(() => {
		item.select();
		sidebar?.closeDrawer();
	}, [item, sidebar]);
	
	return (
		<li className="snmp-probe-filter-list-item">
			<button
				className="panel"
				onClick={ handleClick }
				aria-selected={ item.selected }
			>
				<div className="text-lg color-text-hl">
					{ item.name }
				</div>
				<div className="text-sm">
					{ KIND_LABEL[item.kind] }
					{ item.manufacturerName && (
						<span>&nbsp;({item.manufacturerName})</span>
					) }
				</div>
			</button>
		</li>
	);
};

const NoResults = ({ filter }: SnmpProbeFilterListProps) => {
	useObservable(filter, 'query');

	return (
		<div className="text-center mrg-t-lg">
			No devices match <strong>"{filter.query}"</strong>.
		</div>
	);
};

export type SnmpProbeFilterListProps = ClassProp & {
	filter: SnmpProbeFilter;
};
export const SnmpProbeFilterList = ({ className, filter }: SnmpProbeFilterListProps) => {
	useObservable(filter.items);
	useObservable(filter, 'query');

	if(!filter.items.length) {
		return <NoResults filter={ filter }/>;
	}
	
	return (
		<ol className={ classNames('snmp-probe-filter-list', className) }>
			{ filter.items.map(item => (
				<SnmpProbeFilterListItem
					item={ item }
					key={ item.id }
				/>
			))}
		</ol>
	);
};