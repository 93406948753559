import type { FunctionalComponent } from 'preact';
import type { AdminTabId, AdminTabSection } from './admin-tabs';
import { createContext } from 'preact';
import { useContext, useEffect, useMemo, useState } from 'preact/hooks';
import { useRepositoryProviderName } from '../../services';
import { getAdminTabSections, isAdminTabId } from './admin-tabs';



const getTabIdFromUrlHash = (allSections: AdminTabSection[]): AdminTabId => {
	const hash = window.location.hash.replace('#', '');
	if(isAdminTabId(hash)) {
		return hash;
	}

	return allSections[0].tabs[0];
};

export type AdminTabsContext = {
	adminTabSections: AdminTabSection[];
	activeAdminTabId: AdminTabId;
};
const adminTabContext = createContext<AdminTabsContext | null>(null);

export const AdminTabsProvider: FunctionalComponent = ({ children }) => {
	const provider = useRepositoryProviderName();
	
	const adminTabSections = useMemo(() => getAdminTabSections(provider), [provider]);
	const [ activeAdminTabId, setActiveAdminTabId ] = useState(getTabIdFromUrlHash(adminTabSections));

	// Update active tab on hashchange
	useEffect(() => {
		const handleHashChange = () => {
			setActiveAdminTabId(getTabIdFromUrlHash(adminTabSections));
		};

		handleHashChange();

		window.addEventListener('hashchange', handleHashChange);
		return () => { window.removeEventListener('hashchange', handleHashChange); };
	}, [adminTabSections]);

	const value = useMemo<AdminTabsContext>(() => ({
		adminTabSections, activeAdminTabId,
	}), [activeAdminTabId, adminTabSections]);

	return (
		<adminTabContext.Provider value={ value }>
			{ children }
		</adminTabContext.Provider>
	);
};

export const useAdminTabs = (): AdminTabsContext => {
	const ctx = useContext(adminTabContext);
	if(!ctx) {
		throw new Error('adminTabContext was null; missing <AdminTabsProvider>?');
	}

	return ctx;
};
