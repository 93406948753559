import type { VNode } from 'preact';
import { MAX_GPS_SYNC_AZIMUTH, MIN_GPS_SYNC_AZIMUTH, range } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { Checkbox, Slider } from '../../../components';
import { useSettingsService } from '../../../services';
import { formatDegrees } from '../../../util';
import { SettingsSection } from './settings-section';


const AZIMUTH_STEPS = range(MIN_GPS_SYNC_AZIMUTH, MAX_GPS_SYNC_AZIMUTH, 20);

export const GpsSyncSettings = (): VNode | null => {
	const settingsService = useSettingsService();
	useObservable(settingsService.settings, 'gpsSyncEnabled');
	useObservable(settingsService.settings, 'gpsSyncAzimuth');

	return (
		<SettingsSection title="GPS Sync">
			<div id="gpsSyncDescription" className="form__description">
				Enabling <code>GPS Sync everywhere</code> everywhere is an easy way to have Bearing ignore frequency
				overlaps for GPS Synced radios. A 170° minimum angle between antenna azimuths implies radios should
				be back to back.
			</div>
			<Checkbox
				label="Use GPS Sync everywhere"
				id="gpsSyncEnabledCheckbox"
				checked={ settingsService.settings.gpsSyncEnabled }
				onChange={ checked => settingsService.update('gpsSyncEnabled', checked) }
				aria-describedby="gpsSyncDescription"
			/>
			<Slider
				id="gpsSyncSlider"
				label="Minimum angle between antenna azimuths"
				steps={ AZIMUTH_STEPS }
				value={ settingsService.settings.gpsSyncAzimuth }
				onChange={ value => settingsService.update('gpsSyncAzimuth', value) }
				disabled={ !settingsService.settings.gpsSyncAzimuth }
				formatValue={ formatDegrees }
				aria-describedby="gpsSyncDescription"
			/>
		</SettingsSection>
	);
};