import type { FrequencyBand } from '@brng/common';
import type { Device, Radio } from '@brng/domain';
import { bound, notify, observable, observe, unobserve } from 'ecce-preact';
import { SpectrumGraph } from '../spectrum-graph';


export type SpectrumSliderDef = {
	frequencyBand: FrequencyBand;
	equipment: Device;
};

type SnappedValue = {
	value: number;
	index: number;
};

// TODO(#527): Handle this better...
type MutableRadio = Omit<Radio, 'frequency' | 'channelWidth'> & {
	frequency: number;
	channelWidth: number;
};

export class SpectrumSliderController {
	readonly frequencyBand: FrequencyBand;
	readonly equipment: Device;
	readonly #radio: MutableRadio;

	readonly graph: SpectrumGraph;

	#frequencySnapPoints: readonly number[];
	readonly channelWidthSnapPoints: readonly number[];

	readonly #initialFrequency: number;
	#targetFrequency: number;
	readonly #initialChannelWidth: number;
	#targetChannelWidth: number;

	@observable() get frequency(): number { return this.#radio.frequency; }
	@observable() get channelWidth(): number { return this.#radio.channelWidth; }
	
	#hasChanged = false;
	get hasChanged(): boolean { return this.#hasChanged; }
	@observable() private set hasChanged(value: boolean) { this.#hasChanged = value; }
	
	constructor(def: SpectrumSliderDef) {
		this.equipment = this.#cloneEquipment(def.equipment);
		this.#radio = this.equipment.radios[0];
		this.#initialFrequency = this.#targetFrequency = this.#radio.frequency;
		this.#initialChannelWidth = this.#targetChannelWidth = this.#radio.channelWidth;
		this.frequencyBand = def.frequencyBand;
		
		this.#frequencySnapPoints = this.#makeFrequencySnapPoints();
		this.channelWidthSnapPoints = this.frequencyBand.channelWidths;

		this.graph = new SpectrumGraph({
			frequencyBand: this.frequencyBand,
			equipment: [ this.equipment ],
			disableFocus: true,
		});
	}

	// TODO(#527): Handle this better...
	#cloneEquipment(equipment: Device): Device {
		return {
			...equipment,
			radios: [ { ...equipment.radios[0] } ],
		} as unknown as Device;
	}

	#makeFrequencySnapPoints(): readonly number[] {
		const snaps = new Set<number>();

		const addSnap = (snap: number) => {
			if(this.frequencyBand.isExcluded(snap, this.halfChannelWidth)) {
				return;
			}

			snaps.add(Math.floor(snap));
		};

		const bandWidth = this.frequencyBand.end - this.frequencyBand.start;
		const numSnaps = Math.floor(bandWidth / this.frequencyBand.channelSpacing);
		const firstSnap = this.frequencyBand.start + this.halfChannelWidth;

		for(let i=0; i<numSnaps; ++i) {
			const snap = Math.floor(firstSnap + this.frequencyBand.channelSpacing * i);

			if(snap >= this.frequencyBand.end - this.halfChannelWidth) {
				break;
			}
			
			addSnap(snap);
		}

		// Add nearest snapping points around exclusions.
		for(const [ low, high ] of this.frequencyBand.exclusions) {
			addSnap((low - 1) - this.halfChannelWidth);
			addSnap(high + this.halfChannelWidth + 1);
		}

		// Add a final snapping point at the end of the frequency band.
		addSnap(this.frequencyBand.end - this.halfChannelWidth);

		return [...snaps].sort((a, b) => a - b);
	}

	#snapValue(value: number, snapPoints: readonly number[]): SnappedValue {
		if(value <= snapPoints[0]) {
			return {
				value: snapPoints[0],
				index: 0,
			};
		}

		const lastIndex = snapPoints.length - 1;
		if(value >= snapPoints[lastIndex]) {
			return {
				value: snapPoints[lastIndex],
				index: lastIndex,
			};
		}

		const nearestSnap = snapPoints.reduce((previous: SnappedValue, current, index) => {
			const currentDiff = Math.abs(current - value);
			const previousDiff = Math.abs(previous.value - value);
			if(currentDiff < previousDiff) {
				return {
					value: current,
					index,
				};
			}
			
			return previous;
		}, { value: snapPoints[0], index: 0 });

		return nearestSnap;
	}

	#applyTargetValues() {
		let changed = false;

		// Update channelWidth first, as this may change the frequency value.
		const nextChannelWidth = this.#targetChannelWidth;
		if(nextChannelWidth !== this.#radio.channelWidth) {
			changed = true;
			this.#radio.channelWidth = this.#targetChannelWidth;
			notify(this, 'channelWidth');

			// Regenerate frequency snap points, as these depend on channel width.
			this.#frequencySnapPoints = this.#makeFrequencySnapPoints();
		}
		
		const nextFrequency = this.#snapValue(this.#targetFrequency, this.#frequencySnapPoints).value;
		if(nextFrequency !== this.#radio.frequency) {
			changed = true;
			this.#radio.frequency = nextFrequency;
			notify(this, 'frequency');
		}
		
		if(changed) {
			this.graph.setEquipment([ this.equipment ]);

			this.hasChanged = (
				this.#targetChannelWidth !== this.#initialChannelWidth
				|| this.#targetFrequency !== this.#initialFrequency
			);
		}
	}

	@bound()
	reset() {
		this.#targetFrequency = this.#initialFrequency;
		this.#targetChannelWidth = this.#initialChannelWidth;
		this.#applyTargetValues();
	}
	
	/**
	 * Set the frequency of the slider.
	 * @returns true if the value was value, or false if it fell into an excluded range.
	 */
	setFrequency(nextFrequency: number): void {
		this.#targetFrequency = nextFrequency;
		this.#applyTargetValues();
	}

	addFrequencyStep(delta: number): void {
		const currentSnapIndex = this.#snapValue(this.#targetFrequency, this.#frequencySnapPoints).index;
		const nextSnapIndex = currentSnapIndex + delta;

		const nextValue = this.#frequencySnapPoints[Math.max(0, Math.min(this.#frequencySnapPoints.length - 1, nextSnapIndex))];
	
		this.setFrequency(nextValue);
	}

	@bound()
	setChannelWidth(nextChannelWidth: number): void {
		this.#targetChannelWidth = nextChannelWidth;
		this.#applyTargetValues();
	}

	get halfChannelWidth(): number {
		return this.#radio.channelWidth / 2;
	}

	get minFrequency(): number {
		return this.frequencyBand.start + this.halfChannelWidth;
	}

	get maxFrequency(): number {
		return this.frequencyBand.end - this.halfChannelWidth;
	}

	observeChange(callback: VoidFunction): void {
		observe(this, callback);
	}

	unobserveChange(callback: VoidFunction): void {
		unobserve(this, callback);
	}
}