import type { JSX } from 'preact/jsx-runtime';
import type { MapRouteDisplay } from './map-route-display';
import { useObservable } from 'ecce-preact';
import { MapCoverageOverlay, MapCustomerSitePin, MapDeviceArrows, MapDevicesOverlays, MapInfrastructureSiteIcon } from '../../map';


export type MapRouteItemsViewProps = {
	display: MapRouteDisplay;
};
/**
 * Renders the /map route network items, as determined by {@link MapRouteDisplay}.
 */
export const MapRouteItemsView = ({ display }: MapRouteItemsViewProps): JSX.Element | null => {
	useObservable(display, 'items');
	
	const { infrastructureSites, customerSites, devicesFrequencyOverlays, deviceArrows, coverageOverlay } = display.items;
	
	return (
		<>
			{ infrastructureSites.map(props => (
				<MapInfrastructureSiteIcon { ...props } key={ props.site.id }/>
			))}

			{ customerSites.map(props => (
				<MapCustomerSitePin { ...props } key={ props.customer.id }/>
			))}
			
			{ devicesFrequencyOverlays && (
				<MapDevicesOverlays { ...devicesFrequencyOverlays }/>
			)}

			{ deviceArrows.map(props => (
				<MapDeviceArrows { ...props } key={ props.device.id }/>
			))}

			{ coverageOverlay && (
				<MapCoverageOverlay { ...coverageOverlay }/>
			)}
		</>
	);
};