import type { FunctionComponent } from 'preact';
import { bound, observable, useObservable } from 'ecce-preact';
import { Alert, Form, TextField } from '..';
import { AuthError } from '../../services';
import { Controller, useController } from '../../util';


type LoginFormStatus = 'ready' | 'loading' | 'invalid' | 'inactive';

const USERNAME_INPUT_ID = 'usernameInput';
const PASSWORD_INPUT_ID = 'passwordInput';

class LoginFormController extends Controller {
	@observable()
	accessor username: string = '';

	@observable()
	accessor password: string = '';

	#status: LoginFormStatus = 'ready';
	get status(): LoginFormStatus { return this.#status; }
	@observable() private set status(value: LoginFormStatus) { this.#status = value; }

	protected initialise(): void {
		// Workaround for FireFox not firing input events when auto-filling username
		// and password fields :(
		this.username = (document.getElementById(USERNAME_INPUT_ID) as HTMLInputElement)?.value ?? '';
		this.password = (document.getElementById(PASSWORD_INPUT_ID) as HTMLInputElement)?.value ?? '';
	}

	@bound()
	async submit() {
		try {
			await this.services.authService.login({
				username: this.username,
				password: this.password,
			});
		} catch(err) {
			if(err instanceof AuthError) {
				switch(err.status) {
					case 401:
						this.status = 'invalid';
						return;
					case 403:
						this.status = 'inactive';
						return;
				}
			}
			throw err;
		}
	}
}


export const LoginForm: FunctionComponent = () => {
	const controller = useController(LoginFormController);
	useObservable(controller);


	return (
		<div className="content content--veryNarrow mrg-auto">
			{controller.status === 'invalid' && (
				<Alert className="mrg-b-lg" severity="error" title="Invalid Credentials">
					Username or password was incorrect.
				</Alert>
			)}
			{controller.status === 'inactive' && (
				<Alert className="mrg-b-lg" severity="error" title="Account Deactivated">
					Your account has been deactivated. Please contact your Bearing administrator.
				</Alert>
			)}
			<Form
				className="form"
				onSubmit={ controller.submit }
			>
				<TextField
					id={ USERNAME_INPUT_ID }
					label="Username"
					type="text"
					autocomplete="username"
					autocorrect="off"
					autocapitalize="off"
					required
					value={ controller.username }
					onValueChange={ value => controller.username = value }
				/>
				<TextField
					id={ PASSWORD_INPUT_ID }
					label="Password"
					type="password"
					autocomplete="current-password"
					required
					value={ controller.password }
					onValueChange={ value => controller.password = value }
				/>
				<button
					className="btn mrg-l-auto"
					type="submit"
				>
					Log In
				</button>
			</Form>
		</div>
	);
};