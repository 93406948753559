import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';


export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 1000;

export const RepositoryUser = Type.Object({
	id: Type.String(),
	username: Type.String(),
	name: Type.String(),
	email: Type.String(),
	imageUrl: Type.String(),
});
export type RepositoryUser = Static<typeof RepositoryUser>;

export const UserRole = Type.Union([
	Type.Literal('admin'),
	Type.Literal('user'),
], { title: 'UserRole' });
export type UserRole = Static<typeof UserRole>;

export const UserStatus = Type.Union([
	Type.Literal('inactive'),
	Type.Literal('pending'),
	Type.Literal('active'),
]);
export type UserStatus = Static<typeof UserStatus>;

export const User = Type.Object({
	id: Type.String(),
	repositoryId: Type.Union([Type.String(), Type.Null()]),
	name: Type.String(),
	username: Type.String(),
	email: Type.String({ format: 'email' }),
	status: UserStatus,
	role: UserRole,
	inviteCode: Type.Optional(Type.String()),
	imageUrl: Type.Union([ Type.String(), Type.Null() ]),
}, { title: 'User' });
export type User = Static<typeof User> & {
	password?: never; // Forces us to sanitize user objects on the back-end.
};

export const PostFirstUserRequest = Type.Object({
	name: Type.String(),
	username: Type.String(),
	email: Type.String({ format: 'email' }),
	password: Type.String(),
}, { title: 'PostFirstUserRequest' });
export type PostFirstUserRequest = Static<typeof PostFirstUserRequest>;

export const PostFirstUserResult_Ok = Type.Object({
	ok: Type.Literal(true),
});
export const PostFirstUserResult_Failure = Type.Object({
	ok: Type.Literal(false),
	reason: Type.Union([
		Type.Literal('not-needed'),
		Type.Literal('no-such-user'),
		Type.Literal('password-length'),
	]),
});
export const PostFirstUserResult = Type.Intersect([
	PostFirstUserResult_Ok,
	PostFirstUserResult_Failure,
]);
export type PostFirstUserResult = Static<typeof PostFirstUserResult_Ok> | Static<typeof PostFirstUserResult_Failure>;

export const PostUserRequest_Local = Type.Object({
	type: Type.Literal('local'),
	username: Type.String(),
	email: Type.String({ format: 'email' }),
	name: Type.String(),
	admin: Type.Boolean(),
}, { title: 'PostUserRequest_Local' });
export type PostUserRequest_Local = Static<typeof PostUserRequest_Local>;
export const PostUserRequest_Repository = Type.Object({
	type: Type.Literal('repository'),
	repositoryUserId: Type.String(),
	admin: Type.Boolean(),
}, { title: 'PostUserRequest_Repository' });
export type PostUserRequest_Repository = Static<typeof PostUserRequest_Repository>;

export const PostUserRequest = Type.Union([
	PostUserRequest_Local,
	PostUserRequest_Repository,
], { title: 'PostUserRequest' });
export type PostUserRequest = Static<typeof PostUserRequest>;

export const PostUserResponse_Ok = Type.Object({
	ok: Type.Literal(true),
	user: User,
}, { title: 'PostUserResponse_Ok' });

export const PatchUserRequest = Type.Partial(Type.Object({
	role: UserRole,
	status: UserStatus,
}));
export type PatchUserRequest = Static<typeof PatchUserRequest>;