export const wordWrap = (value: string, width: number): string[] => {
	if(value.length<=width) {
		return [ value ];
	}
	const result: string[] = [];
	let remaining = value;
	let splitAt = -1;
	while((splitAt = splitIndex(remaining, width)) > 0) {
		result.push(remaining.substring(0,splitAt).trim());
		remaining = remaining.substring(splitAt).trim();
	}
	result.push(remaining);
	return result;
};


const splitIndex = (value: string, width: number): number => {
	const splitOn = /\W|_/g;
	if(value.length <= width) {
		return -1;
	}
	let lastIndexOf = -1;
	let nextStop = 0;
	let result;
	while ((result = splitOn.exec(value)) !== null) {
		if(result.index > width) {
			break;
		}
		if(lastIndexOf < width && lastIndexOf < value.length) {
			// Greedily include break char (potentially '-' etc)
			lastIndexOf = result.index + 1;
		}
		else {
			lastIndexOf = result.index;
		}
		splitOn.lastIndex = ++nextStop;
	}
	if((lastIndexOf < 0 && value.length > width) || (value.length - lastIndexOf) > width) {
		return width;
	}
	return lastIndexOf;
};