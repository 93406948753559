import type { GetDataResponse } from '@brng/common';
import type { JSX } from 'preact';
import type { PowercodeConnectionConfig } from '../../../config';
import { useMemo } from 'preact/hooks';
import { Alert, Page } from '../..';
import { PowercodeConnectionConfigForm } from '../../../config';
import { useData } from '../../../data';
import { useServices } from '../../../services';
import { Mailto } from '../../mailto';
import { ErrorDetailDisplay, renderDetails } from '../error-details';
import { LoginForm } from '../login-form';
import { PowercodeRepositoryError } from './powercode-repository-error';


export type RepositoryErrorProps = {
	error: GetDataResponse.RepositoryError
};
export const RepositoryError = ({ error }: RepositoryErrorProps): JSX.Element => {
	const { authService, apiService } = useServices();
	const { refreshData } = useData();
	const { provider, status, me } = error;
	const isLoggedIn = !!me;
	const isAdmin = me?.role === 'admin';

	const detailString = useMemo(() => (
		renderDetails(error.details || 'No details available.')
	), [ error ]);
	
	const title = useMemo(() => {
		if(isAdmin) {
			switch(status) {
				case 'authentication-error':
					return `Cannot authenticate with ${provider}`;
				case 'network-error':
					return `Cannot connect to ${provider}`;
				case 'permission-error':
					return `Invalid permissions for ${provider}`;
				case 'upstream-error':
					return `${provider} error`;
			}
		}
		return 'Something is wrong';
	}, [isAdmin, provider, status]);

	const explanation = useMemo(() => {
		if(!isAdmin) {
			return null;
		}

		if(error.status === 'unknown-error') {
			return (
				<>
					<p>
						Bearing has encountered an unexpected error.
						This is probably a bug.
					</p>
					<p>
						<big>
							Please reach out to us at
							&nbsp;
							<Mailto
								to="support@brng.net"
								subject="Unknown Error"
								body={ `Details:\n${detailString}}` }
							/>,
							including the details below, and a description of what happened.
						</big>
					</p>
				</>
			);
		}

		switch(provider) {
			case 'Powercode':
				return <PowercodeRepositoryError error={ error }/>;
			default:
				return null;
		}
		
	}, [detailString, error, isAdmin, provider]);
	
	const handleSubmit = (newConfig: PowercodeConnectionConfig) => {
		apiService.post('/api/admin/repository', newConfig)
			.then(() => refreshData());
	};
	
	return (
		<Page>
			{ isLoggedIn ? (
				<div className="content content--narrow stack-md mrg-t-lg">
					<Alert severity="error" title={ title }>
						{ !isAdmin && (
							<p>
								If this problem persists, please contact your local Bearing
								administrator to investigate.
							</p>
						) }
					</Alert>

					{ isAdmin && (
						<div className="stack-md copy">
							{ explanation }
							{ error.details ? (
								<ErrorDetailDisplay details={ error.details }/>
							) : <hr/> }

							{ error.status !== 'unknown-error' && (
								<PowercodeConnectionConfigForm onSubmit={ handleSubmit }/>
							)}
						</div>
					)}

					{ error.status === 'unknown-error' && (
						<p>
							In the mean time, you can try:
							<ul className="mrg-t-xs">
								<li>Refreshing the page</li>
								<li><a href="#" onClick={ authService.logout }>Logging out</a>, and logging back in again</li>
								{ isAdmin && <li>Restarting the Bearing server</li> }
							</ul>
						</p>
					)}
					
				</div>
			) : <LoginForm/>}
		</Page>
	);
};