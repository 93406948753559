import type { FunctionComponent } from 'preact';
import type { ClassProp } from '../util';
import './spinner.scss';


type SpinnerComponent = FunctionComponent<ClassProp>;
export const Spinner: SpinnerComponent = ({ className }) => (
	<div className={ className }>
		<svg className="spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
			<circle cx="50" cy="50" r="45"/>
		</svg>
	</div>
);