/**
 * Test if `el` is currently visible in `container`'s scroll viewport.
 * Assumes that `el` is shorter than `container`.
 */
export const isInViewport = (container: HTMLElement, el: HTMLElement): boolean => {
	const containerBounds = container.getBoundingClientRect();
	const elBounds = el.getBoundingClientRect();
	
	return elBounds.top >= containerBounds.top && elBounds.bottom <= containerBounds.bottom;
};


export const downloadDataUrl = (fileName: string, dataUrl: string): void => {
	// https://stackoverflow.com/a/15832662
	const a = document.createElement('a');
	a.download = fileName;
	a.href = dataUrl;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};