import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import { isNullish } from '@brng/common';
import classNames from 'classnames';
import './object-table.scss';


type DataValue = string | number | JSX.Element | null | undefined;
type DataItem = DataValue | {
	value: DataValue;
	monospace?: boolean;
};

export type ObjectTableProps = ClassProp & {
	data: Record<string, DataValue | DataItem>;
};
export const ObjectTable = ({ className, data }: ObjectTableProps): JSX.Element => {
	return (
		<table className={ classNames('object-table', className) }>
			{
				[...Object.keys(data)]
					.map(key => {
						const item = data[key];
						let value: DataValue | null | undefined = null;
						let monospace = false;

						if(item && typeof(item) === 'object' && 'value' in item) {
							value = item.value;
							monospace = item.monospace ?? false;
						} else {
							value = item;
						}
						
						if(isNullish(value)) {
							return null;
						}

						return (
							<tr key={ key }>
								<th scope="row">
									{key}
								</th>
								<td className={ classNames({ 'font-mono': monospace }) }>
									{ value }
								</td>
							</tr>
						);
					})
			}
		</table>
	);
};