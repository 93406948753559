type MailtoHrefArgs = {
	to: string;
	subject?: string;
	body?: string;
};
export const makeMailtoHref = (args: MailtoHrefArgs): string => {
	const segments = [
		'mailto:', process.env['DEPLOY_ENV'] === 'prod' ? args.to : (args.to.split('@')[0] + '@example.com'),
	];

	if(args.subject || args.body) {
		segments.push('?');

		if(args.subject) {
			segments.push('subject=', args.subject);
			if(args.body) {
				segments.push('&');
			}
		}

		if(args.body) {
			segments.push('body=', args.body.trim().replace(/\n/g, '%0D%0A'));
		}

	}

	return segments.join('');
};