import type { MissableData, MissingDataIssue, Site } from '@brng/domain';
import { makeCsvDataUrl } from './csv';


const COLUMNS = [
	'Site Name',
	'Equipment Name',
	'Type',
	'Device Type',
	'Frequency Band',
	'Frequency',
	'Channel Width',
	'Azimuth',
	'Azimuth Backhaul',
	'Sector Size',
	'Antenna',
	'Wireless Range',
	'CRM URL',
	'Radio URL',
] as const;
type Column = typeof COLUMNS[number];

const getColumnValue = (col: Column, warning: readonly MissableData[]): string => {
	switch(col) {
		case 'Frequency Band':
			return warning.includes('frequency-band') ? 'Frequency' : '';
		case 'Frequency':
			return warning.includes('frequency') ? "SNMP 'Center' Frequency" : '';
		case 'Channel Width':
			return warning.includes('channel-width') ? "SNMP 'Channel Width'" : '';
		case 'Azimuth':
			return warning.includes('azimuth') ? 'Azimuth' : '';
		case 'Azimuth Backhaul':
			return warning.includes('azimuth-backhaul') ? 'Missing Parent/Child' : '';
		case 'Sector Size':
			return warning.includes('sector-size') ? 'Sector Size' : '';
		case 'Antenna':
			return warning.includes('not-omni') ? 'Should be \'Omni\'' : '';
	}
	return '';
};

const makeRow = (site: Site, warning: MissingDataIssue) => ({
	'Site Name': site.name,
	'Equipment Name': warning.device.name,
	'Type': warning.device.type,
	'Device Type': warning.device.deviceType,
	'Frequency Band': getColumnValue('Frequency Band', warning.missing),
	'Frequency': getColumnValue('Frequency', warning.missing),
	'Channel Width': getColumnValue('Channel Width', warning.missing),
	'Azimuth': getColumnValue('Azimuth', warning.missing),
	'Azimuth Backhaul': getColumnValue('Azimuth Backhaul', warning.missing),
	'Sector Size': getColumnValue('Sector Size', warning.missing),
	'Antenna': getColumnValue('Antenna', warning.missing),
	'Wireless Range': getColumnValue('Wireless Range', warning.missing),
	'CRM URL': warning.device.link ?? 'No URL provided',
	'Radio URL': warning.device.url,
});

export const makeMissingDataReport = (sites: readonly Site[]): string => {
	return makeCsvDataUrl(
		COLUMNS,
		sites.flatMap(site => (
			site.issues.getByKind('missingData')
				.map(warning => makeRow(site, warning))
		))
	);
};