import type { Device } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { Link, getNetworkItemIcon } from '../../../../../components';
import { formatFrequency } from '../../../../../util';
import { InventoryDetailsSection } from '../inventory-details-section';
import { DeviceSnmpDetails } from './device-snmp-details';
import { InventoryDetailsDeviceOverview } from './inventory-details-device-overview';
import './inventory-details-device.scss';


export type InventoryDetailsDeviceProps = {
	device: Device;
};
export const InventoryDetailsDevice = ({ device }: InventoryDetailsDeviceProps): JSX.Element => {
	return (
		<div className="inventory-details-device">
			<InventoryDetailsSection className="inventory-details-device__overview">
				<InventoryDetailsDeviceOverview device={ device }/>
			</InventoryDetailsSection>
			
			<InventoryDetailsSection
				className="inventory-details-device__radios"
				title="Radios"
			>
				{ device.radios.length && (
					<ul className="panel-group">
						{ device.radios.map((r, index) => (
							<li className="panel panel--dark" key={ index }>
								<h4 className="mrg-0">{r.band?.name}</h4>
								<code>{ formatFrequency(r.frequency) } ({ formatFrequency(r.channelWidth)})</code>
							</li>
						)) }
					</ul>
				)}

				{ !device.radios.length && (
					<span>No radios found at {device.name}.</span>
				)}
			</InventoryDetailsSection>


			{ device.type === 'Access Point' && (
				<InventoryDetailsSection title="Subscriber Modules" className="inventory-details-device__subscriberModules">
					{ !!device.children?.length && (
						<ul className="panel-group">
							{ device.children.map(child => (
								<li className="panel data-panel" key={ child.id }>
									<Link
										label={ child.name }
										icon={ getNetworkItemIcon(child) }
										href={ '#' + child.networkId }
									/>
									<div className="text-sm flex-align-center">
										for&nbsp;
										<Link
											label={ child.site.name }
											icon='customer'
											href={ '#' + child.site.networkId }
										/>
									</div>
								</li>
							))}
						</ul>
					)}
					{ !device.children?.length && (
						<span>No subscriber modules assigned to {device.name}.</span>
					)}
				</InventoryDetailsSection>
			)}
			{ device.type === 'Backhaul' && (
				<InventoryDetailsSection title="Backhaul Sibling" className="inventory-details-device__subscriberModules">
					{ device.sibling && (
						<ul className="panel-group">
							<li className="panel data-panel" key={ device.sibling.id }>
								<Link
									label={ device.sibling.name }
									icon={ getNetworkItemIcon(device.sibling) }
									href={ '#' + device.sibling.networkId }
								/>
								<div className="text-sm flex-align-center">
									for&nbsp;
									<Link
										label={ device.sibling.site.name }
										icon={ getNetworkItemIcon(device.sibling.site) }
										href={ '#' + device.sibling.site.networkId }
									/>
								</div>
							</li>
						</ul>
					)}
				</InventoryDetailsSection>
			)}
			{ device.type === 'Subscriber Module' && (
				<InventoryDetailsSection title="Access Point" className="inventory-details-device__subscriberModules">
					{ device.parent && (
						<ul className="panel-group">
							<li className="panel data-panel" key={ device.parent.id }>
								<Link
									label={ device.parent.name }
									icon={ getNetworkItemIcon(device.parent) }
									href={ '#' + device.parent.networkId }
								/>
								<div className="text-sm flex-align-center">
									for&nbsp;
									<Link
										label={ device.parent.site.name }
										icon={ getNetworkItemIcon(device.parent.site) }
										href={ '#' + device.parent.site.networkId }
									/>
								</div>
							</li>
						</ul>
					)}
				</InventoryDetailsSection>
			)}

			<InventoryDetailsSection title="SNMP" className="inventory-details-device__snmp">
				<DeviceSnmpDetails device={ device }/>
			</InventoryDetailsSection>
		</div>
	);
};