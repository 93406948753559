import type { ChildrenProp, HtmlTag, TagProp } from '../util';
import { h } from 'preact';
import './chip.scss';
import classNames from 'classnames';


export type ChipProps<Tag extends HtmlTag = 'div'> = TagProp<Tag> & ChildrenProp & {
	highlight?: boolean;
	interactive?: boolean;
};

export const Chip = <Tag extends HtmlTag = 'div'>({ tag, className, highlight, interactive, children, ...props }: ChipProps<Tag>) => {
	return h(tag ?? 'div', {
		...props as any, // eslint-disable-line @typescript-eslint/no-explicit-any
		className: classNames('chip', {
			'chip--highlight': highlight,
			'chip--interactive': interactive,
		}, className),
	}, children);
};