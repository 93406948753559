import type { EventCallback, EventType } from '../../util';
import type { ApiService } from '../api-service';
import type { AuthService } from '../auth-service';
import type { DataService } from '../data-service';
import type { SquelchHandler } from '@brng/domain';
import { type Squelch, type SquelchData } from '@brng/common';
import { bound, notify, observable } from 'ecce-preact';
import { Squelchable } from '@brng/domain';
import { EventManager } from '../../util';


export type SquelchServiceEvents = {
	change: {
		squelches: Squelches;
		squelchService: SquelchService;
	}
};

export type SquelchServiceDependencies = {
	apiService: ApiService
	dataService: DataService;
	authService: AuthService;
};

export type Squelches = Record<string, Record<string, Readonly<Squelch>>>;

export class SquelchService implements SquelchHandler {
	readonly #apiService: ApiService;
	readonly #dataService: DataService;
	readonly #authService: AuthService;

	#squelches: Squelches = {};
	get squelches(): Squelches { return this.#squelches; }
	@observable() private set squelches(value: Squelches) { this.#squelches = value; }

	readonly #events = new EventManager<SquelchServiceEvents>();

	constructor(deps: SquelchServiceDependencies) {
		this.#apiService = deps.apiService;
		this.#dataService = deps.dataService;
		this.#authService = deps.authService;

		this.#dataService.on('data', ev => {
			this.squelches = this.#makeSquelches(ev.data.squelches);
		});
	}


	getSquelch(target: Squelchable): Squelch | null;
	/** @deprecated use `issue.squelch`. */
	getSquelch(kind: string, issueId: string): Squelch | null;
	getSquelch(targetOrKind: Squelchable | string, issueId?: string): Squelch | null {
		if(targetOrKind instanceof Squelchable) {
			return this.squelches[targetOrKind.kind]?.[targetOrKind.id];
		}
		const squelch = this.squelches[targetOrKind]?.[issueId as string] ?? null;
		return squelch;
	}

	setSquelched(target: Squelchable, squelched: boolean): Squelch | null {
		if(squelched) {
			return this.addSquelch(target.kind, target.id);
		}

		this.removeSquelch(target.kind, target.id);
		return null;
	}


	/**
	 * @deprecated use `issue.squelched`.
	 */
	@bound()
	isSquelched(kind: string, issueId: string): boolean {
		return !!this.getSquelch(kind, issueId);
	}

	/**
	 * @deprecated use `issue.setSquelched()`.
	 */
	addSquelch(kind: string, issueId: string): Squelch {
		const squelch: Squelch = {
			issueId,
			blame: this.#authService.activeUser?.name ?? '',
		};
		this.squelches[kind] ??= {};
		this.squelches[kind][issueId] = squelch;

		this.#changed();

		this.#apiService.put(`/api/squelches/${kind}/${issueId}`)
			.catch(() => {/**/});

		return squelch;
	}

	/**
	 * @deprecated use `issue.setSquelched()`.
	 */
	removeSquelch(kind: string, issueId: string): void {
		if(!this.squelches[kind]?.[issueId]) {
			return;
		}

		delete this.squelches[kind][issueId];
		this.#changed();

		this.#apiService.delete<SquelchData>(`/api/squelches/${kind}/${issueId}`)
			.catch(() => {/**/});
	}

	/**
	 * @deprecated use `issue.toggleSquelch()`.
	 */
	toggleSquelch(kind: string, issueId: string): void {
		if(this.isSquelched(kind, issueId)) {
			return this.removeSquelch(kind, issueId);
		}
		this.addSquelch(kind, issueId);
	}

	async cleanDanglingSquelches(kind: string, issueIds: readonly string[]): Promise<void> {
		await this.#apiService.post(`/api/squelches/clean/${kind}`, { issueIds });
	}

	#changed() {
		notify(this, 'squelches');
		this.#events.notify('change', { squelches: this.squelches, squelchService: this });
	}

	#makeSquelches(data: SquelchData): Squelches {
		const squelches: Squelches = {};
		for(const kind in data) {
			squelches[kind] = {};
			for(const squelch of data[kind]) {
				squelches[kind][squelch.issueId] = squelch;
			}
		}

		return squelches;
	}

	on<T extends EventType<SquelchServiceEvents>>(event: T, listener: EventCallback<SquelchServiceEvents, T>): this {
		this.#events.on(event, listener);
		return this;
	}

	off<T extends EventType<SquelchServiceEvents>>(event: T, listener: EventCallback<SquelchServiceEvents, T>): this {
		this.#events.off(event, listener);
		return this;
	}
}