import type { JSX } from 'preact';
import classNames from 'classnames';
import { useMemo } from 'preact/hooks';
import { getNetworkItemIcon } from '../../../components';
import { useSitesInfo } from '../use-sites-info';
import { NextSteps } from './next-steps';
import { SiteIssuesTable } from './site-issues-table/site-issues-table';
import { SitesPageSection } from './sites-page-section';


const classForLabel = (label: string): string | undefined => {
	switch(label) {
		case 'Frequency issues':
		case 'Inter-site interference issues':
			return 'panel--danger';
		case 'Missing data':
			return 'panel--warning';
	}
};

export const SitesSummary = (): JSX.Element | null => {
	const { selectedSite, selectSite, overview, sites } = useSitesInfo();

	const summarySites = useMemo(() => (
		sites
			.filter(site => (
				site.devices.length === 0
				|| (site.issues.getCountByKinds(['frequencyOverlap', 'interSite', 'badStatus', 'missingData']) > 0)
			))
	), [ sites ]);
	
	if(selectedSite) {
		return null;
	}

	return (
		<div className="sites-info-grid">
			<div className="firstItem">
				<div className="stack-md">
					<NextSteps/>
					<SitesPageSection title="Network Overview" icon="network">
						<div className="panel-group flex-column">
							{
								Object.entries(overview)
									.map(([ label, value ]) => (
										<div
											className={ classNames('flex-justify-between gap-sm panel', classForLabel(label)) }
											key={ label }
										>
											<strong>{ label }</strong>
											<span>{ value }</span>
										</div>
									))
							}
						</div>
					</SitesPageSection>

				</div>

			</div>
			{summarySites.map(site => (
				<SitesPageSection
					title={ site.name }
					icon={ getNetworkItemIcon(site) }
					link={ {
						href: 'javascript:void(0)',
						onClick: (ev) => {
							ev.preventDefault();
							selectSite(site.id);
						},
					} }
					key={ site.id }
				>
					<SiteIssuesTable
						site={ site }
						linkIssues
						squelchMode="hidden"
						disableFocus
					/>
				</SitesPageSection>
			))}
		</div>
	);
};
