import { useRef } from 'preact/hooks';


type ConstantBox<T> = { value: T };
export const useConstant = <T>(factory: () => T): T => {
	const box = useRef<ConstantBox<T>>();

	if(!box.current) {
		box.current = { value: factory() };
	}

	return box.current.value;
};