import classNames from 'classnames';
import { Modal } from '../molecules';
import { Spinner } from '../spinner';
import './modal-loading-indicator.scss';


type ModalLoadingIndicatorProps = {
	show: boolean;
	message?: string | undefined;
};

/**
 * Full-screen modal loading indicator.
 */
export const ModalLoadingIndicator = ({ show, message }: ModalLoadingIndicatorProps) => {
	const className = classNames('modal-loading-indicator', {
		'modal-loading-indicator--show': show,
	});
	
	return (
		<Modal open={ show } noDismiss>
			<div className={ className }>
				<Spinner/>
				<div class="text-bolder text-over-image">
					{message || <>&nbsp;</>}
				</div>
			</div>
		</Modal>
	);
};