import type { JSX } from 'preact';
import type { ErrorInfo } from '../../../services/error-service/bearing-error';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { Alert, Dialog, Icon, ModalLoadingIndicator } from '../../../components';
import { useErrorService, useServices } from '../../../services';
import { ErrorDialogAutoSubmitCheckbox } from './error-dialog-auto-submit-checkbox';
import { ErrorDialogContent } from './error-dialog-content';
import { ErrorDialogController } from './error-dialog-controller';
import './error-dialog.scss';


export type ErrorDialogProps = {
	errorInfo: ErrorInfo;
};
export const ErrorDialog = ({ errorInfo }: ErrorDialogProps): JSX.Element => {
	const services = useServices();
	const controller = useMemo(() => new ErrorDialogController({
		services,
		errorInfo,
	}), [errorInfo, services]);

	useObservable(controller, 'submissionStatus');
	useObservable(controller, 'canSubmit');

	return (
		<>
			<Dialog className="error-dialog" open noDismiss>
				<header>
					<Alert severity="error" title={ controller.dialogTitle }/>
				</header>

				{ controller.submissionStatus === 'complete' && (
					<Alert severity="success" size="md" title="Thank you, your report has been submitted."/>
				)}

				{ controller.submissionStatus === 'failed' && (
					<Alert severity="error" size="md" title="Error report could not be submitted.">
						Please contact support.
					</Alert>
				)}

				{ controller.submissionStatus === 'automaticallySubmitted' && (
					<Alert severity="info" size="md" title="An error report has been submitted."/>
				)}

				<ErrorDialogContent
					className="error-dialog__content"
					errorInfo={ errorInfo }
				/>

				<ErrorDialogAutoSubmitCheckbox controller={ controller }/>

				<footer className="error-dialog__footer">
					{ controller.canSubmit && (
						<button className="btn btn--primary" onClick={ controller.submitReport }>
							<Icon icon="send"/>
							<span>Submit Error Report</span>
						</button>
					)}

					<button className="btn" onClick={ controller.refresh }>
						Refresh
					</button>

					{ controller.showLogoutButton && (
						<button className="btn" onClick={ controller.logout }>
							Logout
						</button>
					)}

					<a className="btn" href={ controller.contactSupportHref }>
						<Icon icon="email"/>
						<span>Contact Support</span>
					</a>
				</footer>
			</Dialog>
			<ModalLoadingIndicator show={ controller.submissionStatus === 'submitting' }/>
		</>
	);
};

/**
 * Shows an ErrorDialog when the ErrorService catches an error.
 */
export const ErrorServiceDialog = (): JSX.Element | null => {
	const errorService = useErrorService();
	useObservable(errorService, 'error');
	
	const errorInfo = errorService.error;
	if(!errorInfo) {
		return null;
	}

	return (
		<ErrorDialog errorInfo={ errorInfo }/>
	);
};