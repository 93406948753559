import type { BandName } from '@brng/common';
import type { Device } from '@brng/domain';
import type { VNode } from 'preact';
import { FrequencyBand } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { Alert, Link, NetworkItemIcon, getRepositoryIconName } from '../../../../components';
import { useRepositoryProviderName, useServices } from '../../../../services';
import { MapUrl } from '../../../../util';
import { useSitesInfo } from '../../use-sites-info';
import { SiteIssuesTable } from '../site-issues-table/site-issues-table';
import { SitesPageSection } from '../sites-page-section';
import './site-details.scss';
import { SiteEquipment } from './site-equipment';
import { SiteImage } from './site-image';
import { SiteSpectrum } from './site-spectrum';
import { SiteDetailsProvider, useSiteDetails } from './use-site-details';


const SiteDetailIssues = () => {
	const { site } = useSiteDetails();

	const { authService } = useServices();
	useObservable(authService, 'admin');
	const { admin } = authService;

	return (
		<SitesPageSection title="Site Issues" icon="error">
			{site.issues.getCountByKind('frequencyOverlap') > 2 && (
				<Alert severity="info" title="GPS Sync">
					<p>This site has several frequency overlaps!</p>
					{ admin ? (
						<p>If you are using <a href="/admin#settings">GPS Sync</a> you can enable it in the Admin settings.</p>
					) : (
						<p>Consider asking your Bearing administrator to enable GPS Sync.</p>
					)}
				</Alert>
			)}
			<SiteIssuesTable site={ site } squelchMode="interactive"/>
		</SitesPageSection>
	);
};

const SiteDetailEquipment = () => {
	const { equipmentByFrequencyBand, site } = useSiteDetails();

	return (
		<>
			{
				(Object.entries(equipmentByFrequencyBand) as [BandName, Device[]][]).map(([ freq, equipment ]) => (
					<section
						className="stack-sm"
						key={ freq }
					>
						<h2 className="mrg-0">{ FrequencyBand.fromBand(freq).name + ' Equipment' }</h2>
						<SiteImage frequencyBand={ freq }/>
						<SiteSpectrum
							frequencyBand={ freq }
							equipment={ equipment }
							site={ site }
							overlaps={ site.issues.getByKind('frequencyOverlap') }
						/>
						<SiteEquipment
							frequencyBand={ freq }
							equipment={ equipment }
						/>
					</section>
				))
			}
		</>
	);
};

export const SiteDetails = (): VNode | null => {
	const provider = useRepositoryProviderName();
	const { selectedSite } = useSitesInfo();

	if(!selectedSite) {
		return null;
	}

	if(!selectedSite.devices.length) {
		return (
			<div className="flex-justify-center pad-t-lg">
				<p className="text-xl text-center">
					No RF equipment found at <span className="text-bolder">{selectedSite.name}</span>.
				</p>
			</div>
		);
	}
	
	return (
		<SiteDetailsProvider>
			<div className="sites-info-grid">
				<header className='fullWidth site-details-header'>
					<h2><NetworkItemIcon item={ selectedSite }/>{selectedSite.name}</h2>
					<nav>
						<Link
							label="Inventory"
							icon="inventory"
							href={ `/inventory#${selectedSite.networkId}` }
						/>
						<Link
							label="Map"
							icon="mapPin"
							href={ MapUrl.forItem(selectedSite).toRelativeUrl() }
						/>
						{ selectedSite.link && (
							<Link
								label={ provider }
								icon={ getRepositoryIconName(provider) }
								href={ selectedSite.link }
							/>
						)}
					</nav>
				</header>
				<SiteDetailIssues/>
				<SiteDetailEquipment/>
			</div>
		</SiteDetailsProvider>
	);
};