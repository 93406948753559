/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { isUrl } from './format';

/*
	So, this is gnarly.

	The only place that Google Maps puts any information about auth errors it
	encounters is into the console. As we make users provide their own API key,
	we want to be able to show them details about these errors in the UI. So, we
	have to monkey patch `console.error`, and listen for messages which match the
	format of Google's error messages, and store them so they can be retrieved in
	the UI.
*/

export namespace ConsoleError {
	export type GoogleMap = {
		kind: 'google-map';
		errorName?: string;
		errorUrl?: string;
		errorMessage: string;
	};
}
export type ConsoleError = ConsoleError.GoogleMap;

const parseGoogleMapError = (errorMessage: string): ConsoleError.GoogleMap => {
	const lines = errorMessage.split('\n');
	const errorName = lines[0]?.split(':')[1]?.trim();
	const errorUrl = lines.find(isUrl);
	return { kind: 'google-map', errorName, errorUrl, errorMessage };
};

const errorHistory: ConsoleError[] = [];
export const initConsoleErrorHistory = (): void => {
	const consoleError = console.error;

	console.error = (msg: any, ...args: any[]) => {
		console.log('err', msg, ...args);
		if(typeof(msg) === 'string' && msg.startsWith('Google Maps JavaScript API error')) {
			errorHistory.push(parseGoogleMapError(msg));
		}
		
		consoleError(msg, ...args);
	};
};

export const getConsoleErrorHistory = (): ConsoleError[] => [ ...errorHistory ];
export const getGoogleMapsConsoleError = (): ConsoleError.GoogleMap | null => (
	errorHistory.find(err => err.kind === 'google-map') ?? null
);