import { observable } from 'ecce-preact';
import { Controller } from '../../util';


export class AdminRouteController extends Controller {
	#loading: boolean = true;
	get loading(): boolean { return this.#loading; }
	@observable() private set loading(value: boolean) { this.#loading = value; }

	protected initialise(): void {
		this.loading = !this.services.dataService.ready;
		this.services.dataService.on('ready', this.handleDataReady);
	}

	readonly handleDataReady = () => {
		this.services.authService.assertAdmin();
		if(this.loading) {
			this.loading = false;
		}
	};

	dispose() {
		this.services.dataService.off('ready', this.handleDataReady);
	}
}