import type { JSX } from 'preact';
import type { EquipmentFilter } from './equipment-filter';
import { useObservable } from 'ecce-preact';
import { FilterButton } from './filter-button';


export type EquipmentFilterControlProps = {
	equipmentFilter: EquipmentFilter;
};
export const EquipmentFilterControl = ({ equipmentFilter }: EquipmentFilterControlProps): JSX.Element => {
	useObservable(equipmentFilter);

	return (
		<div className="button-group">
			<FilterButton
				checked={ equipmentFilter.showAccessPoints }
				onChange={ equipmentFilter.setShowAccessPoints }
				label="Access Points"
				shortLabel="AP"
			/>
			<FilterButton
				checked={ equipmentFilter.showBackhauls }
				onChange={ equipmentFilter.setShowBackhauls }
				label="Backhauls"
				shortLabel="BH"
			/>
			<FilterButton
				checked={ equipmentFilter.showSubscriberModules }
				onChange={ equipmentFilter.setShowSubscriberModules }
				label="Subscriber Modules"
				shortLabel="SM"
			/>
		</div>
	);
};
