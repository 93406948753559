import type { Device } from '@brng/domain';
import { bound, observable, observe, unobserve } from 'ecce-preact';


export type EquipmentFilterState = Readonly<{
	showAccessPoints: boolean;
	showBackhauls: boolean;
	showSubscriberModules: boolean;
}>;

export class EquipmentFilter {
	#state: EquipmentFilterState;
	get state(): EquipmentFilterState { return this.#state; }
	@observable() private set state(value: EquipmentFilterState) { this.#state = value; }
	
	constructor() {
		this.#state = {
			showAccessPoints: true,
			showBackhauls: true,
			showSubscriberModules: true,
		};
	}
	
	@bound()
	isEquipmentShown(eq: Device): boolean {
		switch(eq.type) {
			case 'Access Point': return this.state.showAccessPoints;
			case 'Backhaul': return this.state.showBackhauls;
			case 'Subscriber Module': return this.state.showSubscriberModules;
			default: return false;
		}
	}

	#updateState(key: keyof EquipmentFilterState, show: boolean) {
		this.state = {
			...this.state,
			[key]: show,
		};
	}

	filter(eq: Iterable<Device>): Device[] {
		return [ ...eq ].filter(this.isEquipmentShown);
	}

	/**
	 * True if any of the passed equipment is shown.
	 */
	some(equipment: Iterable<Device>): boolean {
		for(const eq of equipment) {
			if(this.isEquipmentShown(eq)) {
				return true;
			}
		}

		return false;
	}

	@bound()
	setShowAccessPoints(show: boolean): void {
		this.#updateState('showAccessPoints', show);
	}

	get showAccessPoints() {
		return this.state.showAccessPoints;
	}

	@bound()
	setShowBackhauls(show: boolean): void {
		this.#updateState('showBackhauls', show);
	}

	get showBackhauls() {
		return this.state.showBackhauls;
	}

	@bound()
	setShowSubscriberModules(show: boolean): void {
		this.#updateState('showSubscriberModules', show);
	}
	
	get showSubscriberModules(): boolean {
		return this.state.showSubscriberModules;
	}
	
	onChange(listener: VoidFunction) {
		observe(this, 'state', listener);
	}

	offChange(listener: VoidFunction) {
		unobserve(this, 'state', listener);
	}
}