import type { JSX } from 'preact/jsx-runtime';
import { useNotificationService } from '../../../services/services-context';
import { ClipboardController, MapUrl, type ClassProp } from '../../../util';
import { MapButton } from './map-button';


export type MapShareButton = ClassProp & {
	getUrlParams: () => string;
};
export const MapShareButton = ({ className, getUrlParams }: MapShareButton): JSX.Element => {
	const notificationService = useNotificationService();

	const handleClick = () => {
		const url = new URL(window.location.href);
		url.pathname = MapUrl.PATH;
		url.search = getUrlParams();

		new ClipboardController(notificationService)
			.writeText(url.href, {
				severity: 'success',
				message: 'Copied URL to clipboard',
			});
	};

	return (
		<MapButton
			className={ className }
			icon="share"
			onClick={ handleClick }
		>
			Share this View
		</MapButton>
	);
};