import type { CustomerSite } from '@brng/domain';
import { useObservable } from 'ecce-preact';
import { useEffect, useState } from 'preact/hooks';
import { CustomerAlternativeDeviceFilterView, CustomerAlternativeDevicesFilter } from '../../../filtering';
import { MapController, MapControls, MapCustomerSitePin, MapDevicesOverlays, MapInfrastructureSiteIcon, MapRadiusSlider, MapView } from '../../../map';
import { MapControlSurface } from '../../../map/components/controls/map-control-surface';
import { useAnalysisIssues } from '../analysis-provider';


export const AnalysisIssueMapView = () => {
	const { focusedIssue, focusedEquipment, analysisIssuesFilter } = useAnalysisIssues();
	
	const [ mapController ] = useState(() => new MapController({
		center: focusedIssue ? focusedIssue.location : undefined,
		overlayRadius: 'actual',
		zoom: 12,
	}));
	useEffect(() => () => mapController.dispose(), [mapController]);

	useEffect(() => {
		if(focusedIssue) {
			mapController.setCenter(focusedIssue.location);
		} else if(focusedEquipment) {
			mapController.setCenter(focusedEquipment.location);
		}
	}, [focusedEquipment, focusedIssue, mapController]);
	
	const [ altDeviceFilter ] = useState(() => new CustomerAlternativeDevicesFilter());
	useObservable(altDeviceFilter);
	useEffect(() => {
		if(focusedIssue?.kind === 'outOfRange' || focusedIssue?.kind === 'outOfSector') {
			altDeviceFilter.setCustomer(focusedIssue.customer as CustomerSite);
		} else {
			altDeviceFilter.setCustomer(null);
		}
	}, [altDeviceFilter, focusedIssue]);
	
	return (
		<MapView controller={ mapController }>
			{ focusedIssue?.kind === 'interSite' && (
				<>
					{ focusedIssue.devices.map(device => (
						<MapInfrastructureSiteIcon site={ device.site } key={ device.id } highlight/>
					))}

					<MapDevicesOverlays
						devices={ focusedIssue.devices }
						conflict={ focusedIssue.devices.map(e => e.id) }
					/>
				</>
			)}
			{ (focusedIssue?.kind === 'outOfRange' || focusedIssue?.kind === 'outOfSector') && (
				<>
					<MapInfrastructureSiteIcon
						site={ focusedIssue.device.site }
						highlight
					/>
					<MapCustomerSitePin
						customer={ focusedIssue.customer }
						device={ focusedIssue.device }
						lineToDevice
						highlight
					/>

					<MapDevicesOverlays
						devices={ [
							focusedIssue.device,
							...(focusedIssue.customer.alternativeDevices.filter(altDeviceFilter.apply)),
						] }
						highlight={ [ focusedIssue.device.id ] }
					/>
				</>
			)}

			{ focusedEquipment && (
				<>
					<MapDevicesOverlays
						devices={ [ focusedEquipment ] }
					/>
					{ analysisIssuesFilter.kind !== 'interSite' && (
						focusedEquipment.issues.getByKind(analysisIssuesFilter.kind)
							.map(issue => (
								<MapCustomerSitePin
									customer={ issue.customer }
									device={ focusedEquipment }
									lineToDevice
									highlight
									key={ issue.id }
								/>
							))
					) }
				</>
			)}

			<MapControls className="stack-sm flex-align-start" position={ 'LEFT_TOP' }>
				<MapRadiusSlider controller={ mapController } overMap/>
				{ (focusedIssue?.kind === 'outOfRange' || focusedIssue?.kind === 'outOfSector') && (
					<MapControlSurface className="flex-column flex-align-center customer-alternative-devices-filter-view-container">
						<CustomerAlternativeDeviceFilterView filter={ altDeviceFilter }/>
					</MapControlSurface>
				)}
			</MapControls>

		</MapView>
	);
};
