import type { Device } from '@brng/domain';
import type { ClassProp } from '../../../../../util';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { Alert, DefinitionList, Icon, Spinner } from '../../../../../components';
import { useAdmin } from '../../../../../services';
import { getSnmpStatusDisplayInfo, getSnmpVersionString } from '../../../../../util';
import { useDeviceSnmpDetailsController } from './device-snmp-details-controller';
import { DeviceSnmpValues } from './device-snmp-values';
import { SnmpReportDialog } from './snmp-report-dialog';
import './device-snmp-details.scss';


export type DeviceSnmpDetailsProps = ClassProp & {
	device: Device;
};
export const DeviceSnmpDetails = ({ className, device }: DeviceSnmpDetailsProps) => {
	const controller = useDeviceSnmpDetailsController(device);
	useObservable(controller, 'snmpState');
	
	const admin = useAdmin();

	const [ reportDialogOpen, setReportDialogOpen ] = useState(false);
	
	if(controller.snmpState.kind === 'loading') {
		return (
			<div className="flex-column flex-center gap-md pad-sm">
				<Spinner/>
				<span>
					{ controller.snmpState.refresh
						? 'Refreshing SNMP details...'
						: 'Loading SNMP details...'
					}
				</span>
			</div>
		);
	}

	if(controller.snmpState.kind === 'disabled') {
		return (
			<Alert severity="warning" title="SNMP Disabled" size="md">
				<div className="stack-sm">
					<div>Bearing is relying on Powercode probes for SNMP.</div>
					{admin
						? <div><a href="/admin#settings">Enable built in SNMP</a> to provide automatic identification and extend Powercode probes.</div>
						: <div>Ask your Bearing administrator about enabling built in SNMP.</div>
					}
				</div>
			</Alert>
		);
	}
	
	if(controller.snmpState.kind === 'error') {
		return <p>ERROR</p>;
	}
	
	const deviceData = controller.snmpState.data;

	return (
		<div className={ classNames('device-snmp-details', className) }>
			<Alert
				{ ...getSnmpStatusDisplayInfo(controller.snmpState.data.status) }
				size="md"
			>
				{ deviceData?.message }
				{ controller.snmpState.data.status === 'UNIDENTIFIED' && (
					<div className="mrg-t-sm stack-sm">
						<p>
							Help us add this device to BEARING.
						</p>

						<button className="btn btn--icon btn--primary width-100" onClick={ () => setReportDialogOpen(true) }>
							<Icon icon="email"/>
							Send device details
						</button>

						<SnmpReportDialog
							device={ device }
							data={ controller.snmpState.data }
							open={ reportDialogOpen }
							onClose={ () => setReportDialogOpen(false) }
						/>
					</div>
				)}
			</Alert>
			
			<button
				className="btn btn--icon"
				onClick={ controller.refreshData }
			>
				<Icon icon="reload"/>
				Refresh
			</button>

			{ deviceData && (
				<>
					<section>
						<DefinitionList
							definitions={ [
								{
									label: 'Manufacturer',
									value: deviceData.manufacturer,
								}, {
									label: 'MIB',
									value: deviceData.mibName,
								}, {
									label: 'Version',
									value: getSnmpVersionString(deviceData.connectionDetails?.version),
									monospace: true,
								},
							] }
						/>
					</section>
					<DeviceSnmpValues
						className="device-snmp-details__values"
						values={ deviceData.values }
					/>
				</>
			)}
		</div>
	);
};