import type netSnmp from 'net-snmp';


type DescriptorTypeMap = {
	systemName: string;
	centerFrequency: number;
	channelWidth: number;
	transmitPower: number;
	noiseFloor: number;
	macAddresses: string[];
	remoteMacAddresses: string[];
	latitude: number;
	longitude: number;
	altitude: number;
};

export const DESCRIPTOR_KEYS: DescriptorKey[] = [ 'systemName', 'centerFrequency', 'channelWidth', 'transmitPower', 'noiseFloor', 'macAddresses', 'remoteMacAddresses', 'latitude', 'longitude', 'altitude' ];
export type DescriptorKey = keyof DescriptorTypeMap;
export type DescriptorType<K extends DescriptorKey> = DescriptorTypeMap[K];

export type MidasStatus = (
	| 'SUCCESS'
	| 'WAITING'
	| 'PARTIAL'
	| 'FAILURE'
	| 'UNIDENTIFIED'
	| 'NOT_IDENTIFIED'
	| 'NO_CONNECTION_DETAILS'
);

export const Version1 = 0;
export const Version2c = 1;
export const Version3 = 3;
declare type Version = netSnmp.Version;

export type SessionOptions =  netSnmp.SessionOptions;

export type SnmpConnectionDetails = SnmpConnectionDetails1or2c | SnmpConnectionDetails3;

export type SnmpConnectionDetailsBase = {
	target: string;
	version: Version;
	options?: SessionOptions;
};

export type SnmpConnectionDetails1or2c = SnmpConnectionDetailsBase & {
	version: typeof Version1 | typeof Version2c;
	community: string;
};

export type SnmpConnectionDetails3 = SnmpConnectionDetailsBase & {
	version: typeof Version3;
	user: SnmpUser;
};

export type SnmpUser = netSnmp.User;

export type MidasUpdate<T> = {
	oid: string | null;
	value: T | null;
	raw: unknown;
	message: string | null;
	lastSuccess: number | null;
	lastFailure: number | null;
};


export type MidasDeviceValues = {
	[ K in DescriptorKey ]: MidasUpdate<DescriptorType<K>>;
};

export type MidasDeviceData = {
	id: string;
	connectionDetails?: SnmpConnectionDetails;
	mibName: string | null;
	manufacturer: string | null;
	values: MidasDeviceValues;
	fingerprint: string[] | null;
	message: string | null;
	remoteIds: string[];
	lastEnriched: number;
	lastIdentified: number | null;
	lastUpdate: number | null;
	status: MidasStatus;
};