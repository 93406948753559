import type { VNode } from 'preact';
import type { PowercodeConnectionConfig } from '../../../config';
import type { ErrorInfo } from '../../../services/error-service/bearing-error';
import { shallowDiffObject } from '@brng/common';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { Spinner } from '../../../components';
import { PowercodeConnectionConfigForm } from '../../../config';
import { AllowUnauthorizedForm } from '../../../config/powercode/allow-unauthorized-form';
import { useServices } from '../../../services';


export type PowercodeRepositoryErrorContentProps = {
	errorInfo: ErrorInfo.Repository;
};

const PowercodeErrorMessage = ({ errorInfo }: PowercodeRepositoryErrorContentProps)  => {
	switch(errorInfo.response.status) {
		case 'authentication-error': return (
			<>
				<p>
					Bearing failed to authenticate with Powercode using the configured
					credentials.
				</p>
				<p>
					This might happen if the associated User was deleted or the password
					was changed on the Powercode Portal.
				</p>
			</>
		);
			
		case 'network-error': return (
			<>
				<p>
					Bearing failed to connect to Powercode on the configured Portal URL.
				</p>
				<p>
					This might happen if the URL has changed, there is no route between
					Bearing and the Powercode Portal or the Powercode Portal is down for
					maintenance. Bearing will recover as soon as the network route is
					restored.
				</p>
			</>
		);
			
		case 'upstream-error': return (
			<>
				<p>
					Bearing is having trouble retrieving data from Powercode.
				</p>
				<p>
					This can be caused by a variety of issues such as user interference or
					an upgrade to Powercode Portal. Please see the error details below for
					specific information.
				</p>
			</>
		);
			
		case 'permission-error': return (
			<>
				<p>
					Bearing does not have sufficient permissions on Powercode Portal.
				</p>
				<p>
					Please verify that the Bearing user has these permissions:
				</p>
				<ul>
					<li><code>Reports &rArr; Custom &rArr; Custom Reports = Edit</code></li>
					<li><code>Reports &rArr; Dashboard &rArr; Reports Dashboard = View</code></li>
				</ul>
			</>
		);
		case 'unknown-error': return (
			<div>An unexpected error occurred while connecting to Powercode.</div>
		);
		default:
			return null;
	}
};

export const PowercodeRepositoryErrorContent = ({ errorInfo }: PowercodeRepositoryErrorContentProps): VNode => {
	const { apiService } = useServices();
	const isAdmin = errorInfo.response.me?.role === 'admin';

	const [ config, setConfig ] = useState<PowercodeConnectionConfig | null>(null);
	useEffect(() => {
		apiService.get<PowercodeConnectionConfig>('/api/admin/repository.json')
			.then(response => {
				if(response.ok) {
					setConfig(response.body);
				}
			})
			.catch(() => {/**/});
	}, [apiService]);

	const [ loading, setLoading ] = useState(false);
	const handleSubmit = useCallback<PowercodeConnectionConfigForm['onSubmit']>(newConfig => {
		const patch = shallowDiffObject(config ?? {}, newConfig);
		if(!patch) {
			return;
		}
		
		setLoading(true);
		apiService.patch('/api/admin/repository', patch)
			.then(() => { window.location.reload(); });
	}, [apiService, config]);


	if(errorInfo.response.status === 'incomplete-certificate-chain') {
		if(!config || loading) {
			return (
				<div className="copy flex-column">
					<Spinner className="mrg-auto"/>
				</div>
			);
		}
		
		return (
			<AllowUnauthorizedForm
				config={ config }
				onSubmit={ handleSubmit }
			/>
		);
	}
	
	const details = errorInfo.response.details ?? 'No details available; please check the Bearing logs.';
	
	return (
		<div className="copy flex-column">
			<PowercodeErrorMessage errorInfo={ errorInfo }/>
			{ !isAdmin && (
				<p>
					If this problem persists, please contact your local Bearing
					administrator to investigate.
				</p>
			)}

			<div className="error-dialog__details">
				<strong className="mrg-b-sm">Details</strong>:&nbsp;
				<pre>
					{
						typeof details === 'string'
							? details
							: JSON.stringify(details, null, 2).split('\n')
								.map(line => <>{line}<br/></>)
					}
				</pre>
			</div>

			{ errorInfo.response.status !== 'unknown-error' && isAdmin && config && !loading && (
				<>
					<hr/>
					<p>Please ensure that the connection details are correct:</p>
					<PowercodeConnectionConfigForm
						initialConfig={ config }
						onSubmit={ handleSubmit }
					/>
				</>
			)}
			{
				loading && (
					<>
						<br/>
						<Spinner className="mrg-auto"/>
					</>
				)
			}
		</div>
	);
};