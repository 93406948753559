import type { MapController, OverlayRadius } from '../../map-controller';
import type { ClassProp } from '../../../util';
import { useObservable } from 'ecce-preact';
import { formatDistance } from '../../../util';
import { Slider } from '../../../components';
import { MapSlider } from './map-slider';


const OVERLAY_RADIUS_OPTIONS: OverlayRadius[] = [ 'actual', 250, 500, 1000, 2000, 3000, 5000, 8000, 12000 ];

const formatValue = (v: number): string => {
	const r = OVERLAY_RADIUS_OPTIONS[v];
	if(r === 'actual') {
		return 'Actual';
	}
	return formatDistance(r);
};

export type MapRadiusSliderProps = ClassProp & {
	controller: MapController;
	overMap?: boolean;
};
/**
 * Slider to control the radii of device overlays rendered on a map.
 *
 * Comes in two flavours, determined by the `overMap` prop:
 *
 * - `overMap={true}`: Rendered in a Material Design-ish style for use as a map
 *   control.
 * - `overMap={false}`: Rendered as a regular Bearing `<Slider/>`
 */
export const MapRadiusSlider = ({ className, controller, overMap }: MapRadiusSliderProps) => {
	useObservable(controller, 'overlayRadius');

	const value = OVERLAY_RADIUS_OPTIONS.indexOf(controller.overlayRadius);
	const handleChange = (v: number) => controller.setOverlayRadius(OVERLAY_RADIUS_OPTIONS[v]);

	if(overMap) {
		return (
			<MapSlider
				id="radiusSlider"
				className={ className }
				label="Radius"
				min={ 0 }
				max={ OVERLAY_RADIUS_OPTIONS.length-1 }
				value={ value }
				onChange={ handleChange }
				formatValue={ formatValue }
			/>
		);
	}

	return (
		<Slider
			id="radiusSlider"
			className={ className }
			label="Radius"
			steps={ OVERLAY_RADIUS_OPTIONS.map((o, i) => i) }
			value={ value }
			onChange={ handleChange }
			formatValue={ formatValue }
		/>
	);
};