import type { ClassProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import type { IconName } from '../atoms/icon/icons';
import classNames from 'classnames';
import { Icon } from '../atoms';
import './device-status.scss';


export type DeviceStatusProps = ClassProp & {
	status: string;
};
export const DeviceStatus = ({ className, status }: DeviceStatusProps): JSX.Element => {
	let icon: IconName | undefined;
	switch(status) {
		case 'Good':
			icon = 'success';
			break;
		case 'Warning':
			icon = 'warning';
			break;
		case 'Down':
			icon = 'error';
			break;
	}
	
	return (
		<span className={ classNames(className, 'deviceStatus', 'deviceStatus--' + status) }>
			{ icon && <Icon className="mrg-r-xs" icon={ icon } size="sm"/> }
			<span>{status}</span>
		</span>
	);
};