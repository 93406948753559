import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { IconName } from '../atoms/icon/icons';
import type { IconSize } from '../atoms';
import classNames from 'classnames';
import { Icon } from '../atoms';
import './link.scss';


export type LinkProps = ClassProp & {
	href: string;
	label?: string;
	icon?: IconName;
	iconSize?: IconSize;
};
export const Link = ({ className, href, label, icon, iconSize }: LinkProps): JSX.Element => {
	const external = href.startsWith('http');
	return (
		<a
			className={ classNames(className, 'link') }
			href={ href }
			target={ external ? '_blank' : undefined }
			rel={ external ? 'noopener noreferrer' : undefined }
		>
			{ icon && <Icon className="link__startIcon" icon={ icon } size={ iconSize ?? 'sm' }/>}
			<span>{ label ?? href }</span>
			{ external && <Icon className="link__endIcon" icon="externalLink" size="sm"/> }
		</a>
	);
};