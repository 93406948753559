import type { Settings } from '@brng/common';
import type { Network } from '..';
import { findOverlaps } from './frequency-overlaps';
import { findMissingDataAndBadStatus } from './missing-data';
import { findInterSiteInterferenceIssues } from './inter-site';
import { findOutOfRangeIssues } from './out-of-range';
import { findOutOfSectorIssues } from './out-of-sector';


export const findIssues = (network: Network, settings: Settings): void => {
	for(const site of network.infrastructureSites) {
		findOverlaps(site, settings);

		for(const device of site.devices) {
			findMissingDataAndBadStatus(device);
			findOutOfRangeIssues(device);
			findOutOfSectorIssues(device);
		}
	}

	findInterSiteInterferenceIssues(network);
};
