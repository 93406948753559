import type { JSX } from 'preact/jsx-runtime';
import type { ErrorDialogController } from './error-dialog-controller';
import { useObservable } from 'ecce-preact';
import { Checkbox } from '../../atoms';


export type ErrorDialogAutoSubmitCheckboxProps = {
	controller: ErrorDialogController;
};
export const ErrorDialogAutoSubmitCheckbox = ({ controller }: ErrorDialogAutoSubmitCheckboxProps): JSX.Element | null => {
	useObservable(controller, 'autoSubmitInFuture');
	useObservable(controller, 'submissionStatus');

	if(!controller.showAutoSubmitCheckbox || controller.submissionStatus !== 'idle') {
		return null;
	}

	return (
		<div className="pad-h-sm pad-b-md">
			<Checkbox
				id="autoSubmitCheckbox"
				label="Automatically submit error reports in future"
				checked={ controller.autoSubmitInFuture }
				onChange={ controller.setAutoSubmitInFuture }
			/>
		</div>
	);
};