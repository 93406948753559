import type { VNode } from 'preact';
import type { SliderProps } from '../../../components';
import { useCallback } from 'preact/hooks';
import { Slider } from '../../../components';
import { useAnalysisIssues } from '../analysis-provider';


const steps: SliderProps['steps'] = [ 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 ];
const formatValue: SliderProps['formatValue'] = value => `${value}%`;

export const OutOfSectorToleranceSlider = (): VNode => {
	const { analysisIssuesFilter: { outOfSectorTolerance }, updateAnalysisIssuesFilter } = useAnalysisIssues();

	const handleChange = useCallback<SliderProps['onChange']>(value => {
		updateAnalysisIssuesFilter({ outOfSectorTolerance: value });
	}, [ updateAnalysisIssuesFilter ]);
	
	return (
		<Slider
			id="outOfSectorSlider"
			label='Leniency'
			steps={ steps }
			formatValue={ formatValue }
			slim
			value={ outOfSectorTolerance }
			onChange={ handleChange }
		/>
	);
};