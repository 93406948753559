import type { FunctionComponent } from 'preact';
import './settings-section.scss';


type SettingsSectionProps = {
	title: string;
};
export const SettingsSection: FunctionComponent<SettingsSectionProps> = ({ title, children }) => (
	<section className="settings-section form panel">
		<div className="stack-sm">
			<h3>{ title }</h3>
			{ children }
		</div>
	</section>
);
