import type { ClassProp } from '../../../util';
import { useObservable } from 'ecce-preact';
import { useRef } from 'preact/hooks';
import classNames from 'classnames';
import { SpectrumSliderView, SpectrumChannelWidthSlider } from '../../../components/spectrum';
import { SpectrumGraphPopup, SpectrumGraphView } from '../../../components/spectrum/spectrum-graph';
import { useSpectrumComparisonController } from '../spectrum-comparison-controller-context';
import './equipment-spectrums.scss';


const CurrentFrequency = () => {
	const { slider } = useSpectrumComparisonController();
	useObservable(slider, 'frequency');

	return <code>{slider.frequency}MHz</code>;
};

const InterferingGraphs = () => {
	const controller = useSpectrumComparisonController();
	useObservable(controller, 'interfering');

	return (
		<>
			{ controller.interfering.map(({ graph }) => (
				<div key={ graph.id }>
					<strong>{ graph.name }</strong>
					<SpectrumGraphView graph={ graph }/>
				</div>
			))}
		</>
	);
};

const ResetButton = ({ className }: ClassProp) => {
	const controller = useSpectrumComparisonController();
	useObservable(controller.slider, 'hasChanged');

	return (
		<button
			className={ classNames('btn', className) }
			onClick={ controller.slider.reset }
			disabled={ !controller.slider.hasChanged }
		>
			<i className="fa fa-undo"/>
			<span>Reset</span>
		</button>
	);
};

export const EquipmentSpectrums = () => {
	const controller = useSpectrumComparisonController();
	const nearbySpectrumRoot = useRef<HTMLDivElement>(null);

	return (
		<div className="panel-group">
			<div className="panel">
				<header className="flex-justify-between flex-align-center text-bolder mrg-b-sm">
					<span>{ controller.equipment.name }</span>
				</header>
			
				<div className="spectrum-comparison-controls">
					<SpectrumChannelWidthSlider className="spectrum-comparison-controls__width" controller={ controller.slider }/>
					<ResetButton className="spectrum-comparison-controls__reset"/>

					<div className="spectrum-comparison-controls__slider">
						<label
							className="spectrum-comparison-controls__frequency"
							for="spectrumSlider"
						>
							Center Frequency: <CurrentFrequency/>
						</label>
						<SpectrumSliderView
							id="spectrumSlider"
							controller={ controller.slider }
						/>
					</div>
				</div>
			</div>

			<div className="panel">
				<strong>
					{ controller.site.name }
				</strong>
				<SpectrumGraphView graph={ controller.siteSpectrum }/>
			</div>

			<div className="panel">
				<SpectrumGraphView
					ref={ nearbySpectrumRoot }
					graph={ controller.nearbySpectrum }
					hasPopup
				/>
				<SpectrumGraphPopup
					graph={ controller.nearbySpectrum }
					anchor={ nearbySpectrumRoot }
				/>
				<InterferingGraphs/>
			</div>
		</div>
	);
};