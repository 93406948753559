import type { Static, TSchema } from '@sinclair/typebox';
import type { ErrorDetails } from './repository';
import { Type } from '@sinclair/typebox';


export type ServerErrorResponse = {
	details?: ErrorDetails;
	stack?: string;
};

export type ErrorReportRequest = {
	type: string;
	message: string;
	details?: Record<string, unknown> | undefined;
	error?: {
		name: string;
		message: string;
		stack?: string;
	}
};

export const GenericErrorResponse = Type.Object({
	ok: Type.Literal(false),
	error: Type.String(),
	message: Type.Optional(Type.String()),
});
export type GenericErrorResponse = Static<typeof GenericErrorResponse>;
export const ErrorResponse = (specificErrorResponse?: TSchema | undefined | null): TSchema => {
	if(!specificErrorResponse) {
		return GenericErrorResponse;
	}

	return Type.Union([ GenericErrorResponse, specificErrorResponse ]);
};