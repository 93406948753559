import type { CompanyMetadata } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { SetupRepositoryController } from '../setup-repository-controller';
import { Alert } from '../../../../components';
import { usePatchReducer } from '../../../../util';


const FORM_DESCRIPTION_ID = 'setupRepositoryDescription';

const EMPTY_STATE: CompanyMetadata = {
	companyName: '',
	address: '',
	city: '',
	zip: '',
	state: '',
	country: '',
	phone: '',
};

const LABELS: CompanyMetadata = {
	companyName: 'Company Name',
	address: 'Address ',
	city: 'City',
	state: 'State',
	zip: 'Zip / Postal code',
	country: 'Country',
	phone: 'Phone Number',
};

export type SetupManualRepositoryViewProps = {
	controller: SetupRepositoryController;
};
export const SetupManualRepositoryView = ({ controller }: SetupManualRepositoryViewProps) => {
	const [ value, patch ] = usePatchReducer<CompanyMetadata>(EMPTY_STATE);

	const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = ev => {
		ev.preventDefault();
		controller.submitConfig({
			provider: 'Manual',
			companyMetadata: value,
		});
	};

	return (
		<>
			<Alert
				severity="info"
				title="Configure your company information"
				icon="file"
			>
				<div id={ FORM_DESCRIPTION_ID } className="copy">
					<p>
						Please enter your company's information.
						This will be used to validate your BEARING license.
					</p>
					<p>
						If your have setup up BEARING using this license key before,
						you should enter the same information here again.
					</p>
				</div>
			</Alert>
			<form onSubmit={ handleSubmit } className="flex-column gap-md content content--narrow">
				{
					Object.entries(LABELS).map(([ key, label ]) => {
						if(key === 'country') {
							return (
								<label className="flex-column gap-sm" key={ key }>
									<span>{label}*</span>
									<select
										value={ value[key as keyof CompanyMetadata] }
										onChange={ ev => patch({ [key]: ev.currentTarget.value }) }
										required
									>
										<option value="US">United States</option>
										<option value="CA">Canada</option>
									</select>
								</label>
							);
						}

						return (
							<label className="flex-column gap-sm" key={ key }>
								<span>{label}*</span>
								<input
									value={ value[key as keyof CompanyMetadata] }
									onChange={ ev => patch({ [key]: ev.currentTarget.value }) }
									required
								/>
							</label>
						);
					})
				}
				<button className="btn mrg-l-auto" type="submit">
					Submit
				</button>

			</form>
		</>
	);
};