import type { ChildrenProp, ClassProp } from '../../../util';
import type { IconName } from '../../../components/atoms/icon/icons';
import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { Icon } from '../../../components';
import './map-button.scss';


export type MapButtonProps = ClassProp & ChildrenProp & {
	icon?: IconName;
	onClick?: VoidFunction;
};
export const MapButton = ({ className, icon, onClick, children }: MapButtonProps): JSX.Element => {
	return (
		<button
			className={ classNames(className, 'map-button') }
			onClick={ onClick }
		>
			{ icon && <Icon icon={ icon }/>}
			<span>
				{ children }
			</span>
		</button>
	);
};
