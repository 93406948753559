import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';


export const MIN_GPS_SYNC_AZIMUTH = 20;
export const MAX_GPS_SYNC_AZIMUTH = 170;

export const Settings = Type.Object({
	snmpEnabled: Type.Boolean(),
	automaticErrorReports: Type.Boolean(),
	gpsSyncEnabled: Type.Boolean(),
	gpsSyncAzimuth: Type.Number(),
	googleMapsKey: Type.Union([ Type.String(), Type.Null() ]),
});
export type Settings = Static<typeof Settings>;