import type { JSX } from 'preact/jsx-runtime';
import type { SelectOption } from '../../../components';
import type { MapRouteController } from '../map-route-controller';
import type { BandName } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { Select } from '../../../components';
import { MapSelect } from '../../../map';


const ALL_FREQUENCIES = 'ALL';
export type MapRouteFrequencySelectProps = {
	controller: MapRouteController;
	overMap: boolean;
};
export const MapRouteFrequencySelect = ({ controller, overMap }: MapRouteFrequencySelectProps): JSX.Element => {
	useObservable(controller.display, 'frequencyBandOptions');
	useObservable(controller.display, 'frequencyBand');
	
	const options = useMemo<readonly SelectOption<'ALL' | BandName>[]>(() => [
		{
			value: ALL_FREQUENCIES,
			label: 'All Bands',
		},
		...controller.display.frequencyBandOptions
			.map(opt => ({
				value: opt.band,
				label: opt.name,
			})),
	], [controller.display.frequencyBandOptions]);
	
	const value = controller.display.frequencyBand?.band ?? ALL_FREQUENCIES;
	const handleChange = (band: 'ALL' | BandName) => controller.display.setFrequencyBand(band === ALL_FREQUENCIES ? null : band);
	
	if(!overMap) {
		return (
			<Select
				options={ options }
				value={ value }
				onChange={ handleChange }
			/>
		);
	}
	
	return (
		<MapSelect
			options={ options }
			value={ value }
			onChange={ handleChange }
			disabled={ options.length <= 1 }
		/>
	);
};