import type { SnmpHelperController } from '../snmp-helper-controller';
import type { ChildrenProp } from '../../../util';
import { useObservable } from 'ecce-preact';
import { SnmpProbeView } from '../snmp-probe-view';
import './snmp-probe-list.scss';


export type SnmpProbeListProps = ChildrenProp & {
	controller: SnmpHelperController;
};
export const SnmpProbeList = ({ controller, children }: SnmpProbeListProps) => {
	useObservable(controller.probes);

	if(!controller.probes.length) {
		return (
			<>
				{ children }
				<div className="content mrg-t-lg text-lg pad-h-lg">
					<div className="copy">
						<h2>SNMP Probe Helper</h2>
						<p>
							This is a library of SNMP probe definitions from some of our clients.
						</p>

						<ul>
							<li>Filter by Manufacturer, Radio Model or Device Type</li>
							<li>Create new SNMP probes in Powercode manually or using SQL</li>
						</ul>

						<p>
							We have added example SQL <code>INSERT</code> queries for each probe
							definition. Any changes made to your Powercode database are your
							responsibility and all queries should be checked before execution.
						</p>
						<p>
							The SQL queries create the probes in Powercode which can then be
							tested and assigned to different device types.
						</p>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			{ children }
			<ul className="snmp-probe-list">
				{ controller.probes.map(p => (
					<li
						className="snmp-probe-list__item"
						key={ p.oid }
					>
						<SnmpProbeView probe={ p }/>
					</li>
				))}
			</ul>
		</>
	);
};