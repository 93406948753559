import type { ClassProp } from '../../../util';
import type { SnmpProbeFilter } from './snmp-probe-filter';
import classNames from 'classnames';
import { Viewport } from '../../../components';
import { SnmpProbeFilterInput } from './snmp-probe-filter-input';
import { SnmpProbeFilterList } from './snmp-probe-filter-list';
import './snmp-probe-filter-view.scss';


export type SnmpProbeFilterViewProps = ClassProp & {
	filter: SnmpProbeFilter;
};

export const SnmpProbeFilterView = ({ className, filter }: SnmpProbeFilterViewProps) => {
	return (
		<div className={ classNames('snmp-probe-filter-view', className) }>
			<SnmpProbeFilterInput
				className="snmp-probe-filter-view__query"
				filter={ filter }
			/>
			<Viewport shadow>
				<SnmpProbeFilterList
					className="snmp-probe-filter-view__list"
					filter={ filter }
				/>
			</Viewport>
		</div>
	);
};