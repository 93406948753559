import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { forwardRef } from 'preact/compat';
import { Label, TextInput, type TextInputProps } from '../atoms';


export type TextFieldProps = Omit<TextInputProps, 'aria-describedby'> & {
	id: string;
	label: string;
	description?: string | JSX.Element;
};
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({ id, className, label, description, ...textInputProps }, ref): JSX.Element => {
	const descriptionId = description ? id + 'Description' : undefined;

	return (
		<div className={ classNames(className, 'stack-xs') }>
			<Label
				htmlFor={ id }
				label={ label }
				required={ textInputProps.required }
			/>
			{ description && (
				<div id={ descriptionId } className="text-sm mrg-b-xs">
					{ description }
				</div>
			)}
			<TextInput
				{ ...textInputProps }
				id={ id }
				aria-describedby={ descriptionId }
				ref={ ref }
			/>
		</div>
	);
});