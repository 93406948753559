import type { JSX } from 'preact/jsx-runtime';
import type { BreakpointOption, ChildrenProp, ClassProp } from '../../util';
import classNames from 'classnames';
import { createContext } from 'preact';
import { useCallback, useContext, useMemo, useState } from 'preact/hooks';
import { useBreakpoint } from '../../util';
import { Drawer } from '../organisms';
import './sidebar-template.scss';


export type SidebarTemplateContext = {
	collapsed: boolean;
	openDrawer: VoidFunction;
	closeDrawer: VoidFunction;
};

const sidebarTemplateContext = createContext<SidebarTemplateContext | null>(null);

export type SidebarTemplateProps = ClassProp & ChildrenProp & {
	sidebarClassName?: string;
	sidebar: JSX.Element;
	collapse?: boolean;
	collapseBreakpoint?: BreakpointOption;
	showSidebarText?: string;
	sidebarInitiallyOpen?: boolean;
	mainClassName?: string;
};
export const SidebarTemplate = ({ className, sidebarClassName, mainClassName, sidebar, collapse, collapseBreakpoint, showSidebarText, sidebarInitiallyOpen, children }: SidebarTemplateProps) => {
	const [ drawerOpen, setDrawerOpen ] = useState(sidebarInitiallyOpen ?? false);
	const large = useBreakpoint(collapseBreakpoint ?? 'sm');
	
	const rootClassName = classNames('sidebar-template', className, {
		'sidebar-template--large': !collapse || large,
	});

	const openDrawer = useCallback(() => {
		setDrawerOpen(true);
	}, []);

	const closeDrawer = useCallback(() => {
		setDrawerOpen(false);
	}, []);
	
	const context = useMemo<SidebarTemplateContext | null>(() => {
		if(!collapse) {
			return null;
		}
		
		return {
			collapsed: !large,
			openDrawer,
			closeDrawer,
		};
	}, [closeDrawer, collapse, large, openDrawer]);
	
	return (
		<sidebarTemplateContext.Provider value={ context }>
			<div className={ rootClassName }>
				{ (!collapse || large) && (
					<div className={ classNames('sidebar-template__side', sidebarClassName) }>
						{sidebar}
					</div>
				)}

				{ collapse && !large && showSidebarText && (
					<div className="sidebar-template__show-sidebar">
						<button className="btn" onClick={ openDrawer }>
							{ showSidebarText }
						</button>
					</div>
				) }

				<div className={ classNames('sidebar-template__main', mainClassName) }>
					{children}
				</div>
			</div>
			{ (collapse && !large) && (
				<Drawer
					className={ classNames('sidebar-template__drawer', sidebarClassName) }
					open={ collapse && !large && drawerOpen }
					onClose={ () => setDrawerOpen(false) }
				>
					{ sidebar }
				</Drawer>
			)}
		</sidebarTemplateContext.Provider>
	);
};

export const useSidebarTemplate = (): SidebarTemplateContext | null => (
	useContext(sidebarTemplateContext)
);