import type { GetDataResponse, User } from '@brng/common';
import type { Device, Site } from '@brng/domain';
import type { JSX } from 'preact';
import type { ChildrenProp } from './util';
import { useObservable } from 'ecce-preact';
import { createContext } from 'preact';
import { useContext, useEffect, useMemo, useState } from 'preact/hooks';
import { useServices } from './services';
import { BearingError } from './services/error-service/bearing-error';
import { NullContextError, useCachedGetter } from './util';


/**
 * Legacy data structure.
 *
 * Any code using this should eventually migrate to using `Network`, or an
 * appropriate service.
 */
// TODO(#527): deprecate this.
export type LegacyData = Omit<GetDataResponse.Ok, 'sites' | 'customerLocations' | 'settings' | 'me'> & {
	sites: Site[];
	customerLocations: Site[];
};

// TODO(#527): deprecate this & all its members.
type DataContext = {
	isDataLoading: boolean;
	data: LegacyData | undefined;
	dataError: GetDataResponse.Error | undefined;
	refreshData: () => void;

	allEquipment: Device[];

	getCustomer: (id: string) => Site;
	getEquipment: (id: string) => Device;
	getSite: (id: string) => Site;
	getCustomerEquipment: (id: Site['id']) => Device[],
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const dataContext = createContext<DataContext>(null!);
/**
 * Legacy data-access layer.
 *
 * Acts as a facade to the `DataService` & `NetworkService` to provide temporary
 * compatibility between the two versions of the system while we refactor the
 * codebase.
 */
// TODO(#527): deprecate this.
export const DataProvider = ({ children }: ChildrenProp): JSX.Element => {
	const { dataService, networkService } = useServices();
	useObservable(dataService, 'loading');
	useObservable(dataService, 'legacyData');
	useObservable(dataService, 'legacyDataError');
	useObservable(networkService, 'network');

	/*
		If we receive a repository error and we are logged in, throw it up to the
		error handler.
	*/
	if(dataService.legacyDataError?.reason === 'repository-error' && dataService.legacyData?.me) {
		throw new BearingError({
			kind: 'repository',
			response: dataService.legacyDataError,
		});
	}
	
	const [ data, setData ] = useState<LegacyData | undefined>();
	useEffect(() => {
		if(!dataService.legacyData || !networkService.network) {
			setData(undefined);
			return;
		}

		setData({
			...dataService.legacyData,
			/*
				TODO(#564): these should be marked as `readonly`, but will likely
				cause a cascade of type errors...
			*/
			sites: [ ...networkService.network.sites.filter(s => s.usage === 'Infrastructure') ],
			hiddenSites: [ ...networkService.hiddenSites ],
			customerLocations: [ ...networkService.network.sites.filter(s => s.usage === 'Customer') ],
		});
	}, [dataService.legacyData, networkService.hiddenSites, networkService.network]);
	
	const getCustomerEquipment = useCachedGetter<Device[]>(customerId => {
		return data?.customerLocations.find(c => c.id === customerId)
			?.parentDevices as Device[] ?? [];
	}, [ data ]);
	
	const value = useMemo<DataContext>(() => ({
		isDataLoading: dataService.loading,
		data,
		dataError: dataService.legacyDataError,
		refreshData: dataService.refresh,

		allEquipment: networkService.network
			? [ ...networkService.network.devices ]
			: [],

		getCustomer(id) {
			const customer = networkService.network?.getSite(id);
			if(customer?.usage !== 'Customer') {
				throw new Error('No customer with ID: ' + id);
			}
			return customer;
		},
		getEquipment(id) {
			const eq = networkService.network?.getDevice(id);
			if(!eq) {
				throw new Error('No equipment with ID: ' + id);
			}
			return eq;
		},
		getSite(id) {
			const site = networkService.network?.getSite(id);
			if(!site) {
				throw new Error('No site with ID: ' + id);
			}
			return site;
		},
		getCustomerEquipment,
	}), [data, dataService.legacyDataError, dataService.loading, dataService.refresh, getCustomerEquipment, networkService.network]);
	
	return (
		<dataContext.Provider value={ value }>
			{ children }
		</dataContext.Provider>
	);
};

export const useData = (): DataContext => {
	const ctx = useContext(dataContext);
	if(!ctx) {
		throw new NullContextError('dataContext', 'DataProvider');
	}

	return ctx;
};

export const isAdmin = (user?: User): boolean => {
	return !!user && user.status === 'active' && user.role === 'admin';
};

export const findSite = (sites: Site[], siteId: string): Site | null => (
	sites.find(({ id }) => id === siteId) || null
);