import type { Squelch } from '@brng/common';
import type { NetworkIssue } from '@brng/domain';
import type { VNode } from 'preact';
import type { ChildrenProp } from '../util';
import classNames from 'classnames';
import { DataRow } from './data-table';
import './issue-row.scss';
import { SquelchButton } from './squelch-button';


export type IssueRowSquelchMode = 'hidden' | 'interactive';

export type IssueRowProps = ChildrenProp & {
	innerClassName?: string;
	kind: string;
	issue: NetworkIssue;
	title: string | VNode;
	squelchMode?: IssueRowSquelchMode;
	squelch?: Squelch | null;
	selected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};
export const IssueRow = ({ innerClassName, issue, title, squelch, squelchMode = 'interactive', selected, onClick, onMouseEnter, onMouseLeave, children }: IssueRowProps): VNode | null => {
	const className = classNames(
		'issue-row', {
			'issue-row--squelched': !!squelch,
		}
	);

	if(squelchMode === 'hidden' && squelch) {
		return null;
	}

	return (
		<DataRow
			className={ className }
			status={ !squelch ? 'danger' : undefined }
			noFocus
			selected={ selected }
			onClick={ onClick }
			onMouseEnter={ onMouseEnter }
			onMouseLeave={ onMouseLeave }
		>
			<div className="issue-row__content">
				<div className="stack-sm">
					{ typeof(title) === 'string' ? <strong>{title}</strong> : title }
					<div className={ innerClassName }>
						{ children }
					</div>
				</div>

			</div>

			{ squelchMode === 'interactive' && (
				<SquelchButton className="issue-row__actions mrg-b-auto" issue={ issue }/>
			)}
		</DataRow>
	);
};