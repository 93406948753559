declare global {
	interface Math {
		toRadians: (degrees: number) => number;
		toDegrees: (radians: number) => number;
		clamp: (value: number, min: number, max: number) => number;
		blend: (start: number, end: number, ratio: number) => number;
	}
}

Math.toRadians = (degrees: number): number => degrees * Math.PI / 180;
Math.toDegrees = (radians: number): number => (radians * 180 / Math.PI + 360) % 360;

/**
 * Restrict the given value between a minimum and maximum.
 * @param value the value to restrict
 * @param min the minimum value returned
 * @param max the maximum value returned
 */
Math.clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);


// Workaround to allow TypeScript to see the type declarations above.
export {};