import type { IconName } from '../../../components/atoms/icon/icons';
import type { ClassProp } from '../../../util';
import type { JSX } from 'preact/compat';
import classNames from 'classnames';
import { forwardRef } from 'preact/compat';
import { Icon } from '../../../components';
import './map-text-field.scss';


export type MapTextFieldProps = ClassProp & {
	id: string;
	icon?: IconName;
	value?: string;
	onChange?: (value: string) => void;
	onFocus?: VoidFunction;
	onBlur?: JSX.FocusEventHandler<HTMLInputElement> | undefined;
	onKeyDown?: JSX.KeyboardEventHandler<HTMLInputElement>;
	onClick?: VoidFunction;
};
export const MapTextField = forwardRef<HTMLInputElement, MapTextFieldProps>(({ id, className, icon, value, onChange, onFocus, onBlur, onKeyDown, onClick }, ref): JSX.Element => {
	const handleClickCapture = () => {
		document.getElementById(id)?.focus();
	};
	
	return (
		<div
			className={ classNames(className, 'map-text-field') }
			onClickCapture={ handleClickCapture }
		>
			{ icon && <Icon icon={ icon } size="md"/> }
			<input
				id={ id }
				value={ value }
				onChange={ ev => onChange?.(ev.currentTarget.value) }
				ref={ ref }
				onFocus={ onFocus }
				onBlur={ onBlur }
				onKeyDown={ onKeyDown }
				onClick={ onClick }
			/>
		</div>
	);
});