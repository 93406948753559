export type Squelch = {
	issueId: string;
	blame: string;
};
export type SquelchData = Record<string, Squelch[]>;
export namespace SquelchData {
	export const add = (data: SquelchData, kind: string, newSquelch: Squelch): SquelchData => {
		const newSquelches = { ...data };

		if(!newSquelches[kind]) {
			newSquelches[kind] = [];
		} else {
			newSquelches[kind] = newSquelches[kind].filter(squelch => squelch.issueId !== newSquelch.issueId);
		}
	
		newSquelches[kind].push(newSquelch);

		return newSquelches;
	};

	export const remove = (data: SquelchData, kind: string, issueIdOrSquelch: string | Squelch): SquelchData => {
		const issueId = typeof(issueIdOrSquelch) === 'string' ? issueIdOrSquelch : issueIdOrSquelch.issueId;
		
		const newSquelches = { ...data };

		if(!newSquelches[kind]) {
			return newSquelches;
		}
	
		newSquelches[kind] = newSquelches[kind].filter(squelch => issueId !== squelch.issueId);
	
		if(!newSquelches[kind].length) {
			delete newSquelches[kind];
		}

		return newSquelches;
	};
}