import type { Device } from '@brng/domain';
import type { JSX, VNode } from 'preact';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useMemo, useState } from 'preact/hooks';
import { DataRow, DataTable, RepositoryLink } from '../../../../components';
import { useData } from '../../../../data';
import { useServices } from '../../../../services';
import { useSitesInfo } from '../../use-sites-info';
import { useSortedEquipment } from '../../use-sorted-equipment';
import './site-equipment.scss';


const EquipmentRow = ({ equipment }: { equipment: Device }): JSX.Element => {
	const { getEquipment } = useData();
	const { focusedEquipmentSet } = useSitesInfo();
	useObservable(focusedEquipmentSet);

	useObservable(useServices().squelchService, 'squelches');

	const status = equipment.issues.getCountByKind('frequencyOverlap')
		? 'danger'
		: 'info';

	const frequency = useMemo<string>(() => {
		let freq = `${equipment.radios[0]?.frequency || ''}`;
		if(freq) {
			const channelWidth = `${equipment.radios[0].channelWidth || ''}`;
			if(channelWidth) {
				freq += `(${channelWidth})`;
			}

			return freq;
		}
		return '';
	}, [equipment]);

	const azimuth = useMemo<string>(() => {
		if(equipment.antennaType === 'Sector') {
			if(Number.isFinite(equipment.azimuth)) {
				let az = `${Math.round(equipment.azimuth)}°`;
				if(equipment.type !== 'Backhaul') {
					az += `(${equipment.sectorSize})`;
				}
				return az;
			}
			return '';
		}
		return 'Omni';
	}, [equipment]);


	const [ sibling, siblingFrequency ] = useMemo<[ Device | null,  string | undefined ]>(() => {
		if(!equipment.sibling) {
			return [ null, undefined ];
		}

		const siblingFrequency = getEquipment(equipment.sibling.id).radios[0]?.frequency;
		const equipFrequency = equipment.radios[0]?.frequency;

		return [
			equipment.sibling,
			siblingFrequency !== equipFrequency ? siblingFrequency?.toString() : undefined,
		];
	}, [equipment, getEquipment]);

	const [ isHovered, setIsHovered ] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
		focusedEquipmentSet.add(equipment);
	};
	
	const handleMouseLeave = () => {
		setIsHovered(false);
		focusedEquipmentSet.delete(equipment);
	};

	const className = classNames(
		'equipment-row', {
			highlight: isHovered || focusedEquipmentSet.has(equipment),
		}
	);

	const longName = equipment.name.length >= 18;

	return (
		<DataRow
			className={ className }
			status={ status }
			onMouseEnter={ handleMouseEnter }
			onMouseLeave={ handleMouseLeave }
		>
			<strong
				className={ classNames('equipment-row__name', { 'text-sm': false }) }
				title={ longName ? equipment.name : undefined }
			>
				{equipment.name}
			</strong>
			<code className="equipment-row__azimuth">
				{ azimuth }
			</code>

			<code className="equipment-row__frequency">
				{ frequency }
			</code>

			{sibling && (
				<>
					<div className="equipment-row__sibling-name text-sm">
						<a href={ `/?siteId=${sibling.siteId}` }>
							{ sibling.siteName }
						</a>
					</div>
					{ siblingFrequency && (
						<div className="equipment-row__sibling-data text-sm">
							<code>{siblingFrequency}</code>
						</div>
					)}
				</>
			)}


			{isHovered && (
				<div className="equipment-row__popup">
					{ equipment.radios[0]?.band && (
						<a href={ `/spectrum?siteId=${equipment.siteId}&equipmentId=${equipment.id}&freq=${equipment.radios[0].band.band}` }>
							Compare Spectrum
						</a>
					)}
					
					<a href={ `http://${equipment.ipAddress}` } target="_blank">
						{equipment.ipAddress}&nbsp;
						<i className="fa fa-external-link-alt"/>
					</a>

					<RepositoryLink href={ equipment.link }/>
				</div>
			)}
		</DataRow>
	);
};


type SiteEquipmentProps = {
	frequencyBand: string;
	equipment: Device[];
};
export const SiteEquipment = ({ equipment }: SiteEquipmentProps): VNode => {
	const { equipmentFilter } = useSitesInfo();
	useObservable(equipmentFilter);
	
	const { equipmentComparator } = useSortedEquipment();
	const filterState = equipmentFilter.state;
	const equipmentToRender = useMemo(() => {
		filterState;
		return equipmentFilter.filter(equipment).sort(equipmentComparator);
	}, [filterState, equipmentFilter, equipment, equipmentComparator]);
	
	return (
		<DataTable>
			{equipmentToRender.map(eq => <EquipmentRow equipment={ eq } key={ eq.id }/>)}
		</DataTable>
	);
};