import type { Device, Site } from '@brng/domain';
import type { FunctionComponent } from 'preact';
import { notNullish } from '@brng/common';
import { ObservableSet, observableRef, useObservable } from 'ecce-preact';
import { createContext } from 'preact';
import { route } from 'preact-router';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { useData } from '../../data';
import { EquipmentFilter } from '../../domain';
import { useIsSquelched, useNetwork, useServices } from '../../services';
import { useConstant } from '../../util';
import { onRouteChange } from '../route-handler';


type Overview = {
	'Network Sites': number;
	'RF Devices': number;
	'Backhauls': number;
	'Access points': number;
	'Frequency issues': number;
	'Inter-site interference issues': number;
	'Missing data': number;
};

export type SiteInfoContext = {
	sites: Site[];
	selectedSite: Site | null;
	selectSite: (newSiteId: string | null) => void;
	overview: Overview;
	deviceTypes: string[];
	selectedDeviceType: string | null;
	selectDeviceType: (type: string | null) => void;
	focusedEquipmentSet: ObservableSet<Device>;
	equipmentFilter: EquipmentFilter;
	interSiteRange: number;
};

const siteInfoContext = createContext<SiteInfoContext | null>(null);

// TODO(#527) This should be a controller class.
export const SitesInfoProvider: FunctionComponent = ({ children }) => {
	const { data } = useData();
	const network = useNetwork();
	const [ selectedSite, setSelectedSite ] = useState<Site | null>(null);
	const { squelchService } = useServices();
	useIsSquelched();

	const deviceTypes = useMemo<string[]>(() => {
		const issues = network?.issues.getByKinds(['badStatus', 'missingData']) ?? [];
		const types = issues?.map(issue => issue.device.deviceType);
		return [ ...new Set(types) ];
	}, [network?.issues]);

	const [ selectedDeviceType, setSelectedDeviceType ] = useState<SiteInfoContext['selectedDeviceType']>(null);

	// TODO(#829): how to handle this??
	const interSiteRange = 4000;

	useObservable(squelchService, 'squelches');
	const squelchRef = observableRef(squelchService, 'squelches');
	const { overview, sites } = useMemo<Pick<SiteInfoContext, 'overview' | 'sites'>>(() => {
		// Data is dependent on squelches... hack while this is not yet a controller...
		squelchRef;

		const overview: SiteInfoContext['overview'] = {
			'Network Sites': 0,
			'RF Devices': 0,
			'Backhauls': 0,
			'Access points': 0,
			'Frequency issues': network?.issues.getCountByKind('frequencyOverlap') ?? 0,
			'Inter-site interference issues': network?.issues.getByKind('interSite').filter(i => i.range <= interSiteRange).length ?? 0,
			'Missing data': network?.issues.getByKind('missingData').reduce((total, missing) => total + missing.numMissingFields, 0) ?? 0,
		};
		
		if(!data || !network) {
			return { sites: [], overview };
		}
		overview['Network Sites'] = network.infrastructureSites.length;
		overview['RF Devices'] = network.infrastructureDevices.length;
		for(const device of network.infrastructureDevices) {
			switch(device.type) {
				case 'Access Point':
					++overview['Access points'];
					break;
				case 'Backhaul':
					++overview['Backhauls'];
					break;
			}
		}

		const sites: SiteInfoContext['sites'] = data.sites
			.sort((a, b) => {
				const aOverlaps = a.issues.getCountByKind('frequencyOverlap');
				const aInterSite = a.issues.getByKind('interSite').filter(issue => issue.range <= interSiteRange).length;
				const bOverlaps = b.issues.getCountByKind('frequencyOverlap');
				const bInterSite = b.issues.getByKind('interSite').filter(issue => issue.range <= interSiteRange).length;

				// Weight frequency overlaps higher than inter-site.
				const aIssues = (aOverlaps * 10) + aInterSite;
				const bIssues = (bOverlaps * 10) + bInterSite;

				if(aIssues !== bIssues) {
					return bIssues - aIssues;
				}

				const aWarnings = a.issues.getCountByKinds([ 'badStatus', 'missingData' ]);
				const bWarnings = b.issues.getCountByKinds([ 'badStatus', 'missingData' ]);
				if(aWarnings !== bWarnings) {
					return bWarnings - aWarnings;
				}
				return a.name.localeCompare(b.name);
			});

		return { sites, overview };
	}, [squelchRef, network, data]);

	
	const selectSite = useCallback<SiteInfoContext['selectSite']>(newSiteId => {
		if(!newSiteId) {
			setSelectedSite(null);
			return;
		}

		const newSite = network?.getSite(newSiteId) ?? null;
		setSelectedSite(newSite);
	}, [network]);

	const focusedEquipmentSet = useConstant(() => new ObservableSet<Device>());
	useObservable(focusedEquipmentSet);

	const sitesFiltered = useMemo<SiteInfoContext['sites']>(() => {
		// Only filter on the summary page, or when we have a deviceType selected.
		if(selectedSite || !selectedDeviceType) {
			return sites;
		}
		
		return sites
			.map(site => {
				const allWarnings = site.issues.getByKinds(['missingData', 'badStatus']);
				if(!allWarnings.find(issue => issue.device.deviceType === selectedDeviceType)) {
					return null;
				}
				
				const frequencyOverlaps = site.issues.getByKind('frequencyOverlap')
					.filter(overlap => overlap.devices.some(eq => {
						return eq.deviceType === selectedDeviceType;
					}));

				const warnings = allWarnings
					.filter(warning => warning.device.deviceType === selectedDeviceType);

				const count = frequencyOverlaps.length + warnings.length;

				if(count === 0) {
					return null;
				}

				return site;
			})
			.filter(notNullish);
	}, [selectedDeviceType, selectedSite, sites]);

	const equipmentFilter = useConstant(() => new EquipmentFilter());
	
	const value = useMemo<SiteInfoContext>(() => {
		const info: SiteInfoContext = {
			sites: sitesFiltered,
			overview,
			deviceTypes,
			selectedDeviceType,
			selectDeviceType: setSelectedDeviceType,
			selectedSite,
			selectSite,
			focusedEquipmentSet,
			equipmentFilter,
			interSiteRange,
		};

		return info;
	}, [sitesFiltered, overview, deviceTypes, selectedDeviceType, selectedSite, selectSite, focusedEquipmentSet, equipmentFilter]);

	useEffect(() => {
		const handleUrl = () => {
			const siteId = new URLSearchParams(window.location.search).get('siteId');
			selectSite(siteId);
		};

		handleUrl();
		return onRouteChange(handleUrl);
	}, [selectSite]);
	
	const routeDebounce = useRef<number>();
	useEffect(() => {
		clearTimeout(routeDebounce.current);
		routeDebounce.current = setTimeout(() => {
			const selectedSiteId = selectedSite?.id ?? null;
			const urlSiteId = new URLSearchParams(window.location.search).get('siteId');
			if(urlSiteId === selectedSiteId) {
				return;
			}
			
			route(selectedSite ? `/?siteId=${selectedSite.id}` : '/');
		}, 100) as unknown as number;

	}, [selectedSite]);

	return <siteInfoContext.Provider value={ value } children={ children }/>;
};


export const useSitesInfo = (): SiteInfoContext => {
	const ctx = useContext(siteInfoContext);
	if(!ctx) {
		throw new Error('siteInfoContext was null. Missing <SiteInfoProvider>?');
	}

	return ctx;
};