import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { ModalStateProps } from '../molecules';
import classNames from 'classnames';
import { Modal } from '../molecules';
import './drawer.scss';


export type DrawerProps = ClassProp & ModalStateProps & {
	children: JSX.Element;
	position?: 'left' | 'right'
};
export const Drawer = ({ className, position = 'left', children, ...modalState }: DrawerProps) => {
	return (
		<Modal { ...modalState }>
			<div className={ classNames('drawer', 'drawer--' + position, className) }>
				{ children }
			</div>
		</Modal>
	);
};