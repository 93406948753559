import type { VNode } from 'preact';
import { useCallback } from 'preact/hooks';
import './filter-button.scss';
import { Icon } from '../components';


type FilterButtonProps = {
	label: string;
	shortLabel: string | VNode;
	checked: boolean;
	onChange: (newChecked: boolean) => void;
	disabled?: boolean;
};
export const FilterButton = ({ label, shortLabel, checked, onChange, disabled }: FilterButtonProps): VNode => {
	const handleClick = useCallback(() => {
		onChange(!checked);
	}, [checked, onChange]);
	
	return (
		<button
			className="btn filter-button"
			onClick={ handleClick }
			aria-selected={ checked }
			disabled={ disabled }
		>
			<Icon icon={ checked ? 'eye' : 'eyeOff' }/>
			<span className="filter-button__label--sm">{shortLabel}</span>
			<span className="filter-button__label--lg">{label}</span>
		</button>
	);
};
