export const unique = (value: unknown, index: number, array: unknown[]): boolean => (
	array.indexOf(value) === index
);

export const range = (first: number, last: number, step = 1): number[] => {
	if(first > last) {
		// Could instead generate a reverse range if we ever need one...
		throw new RangeError('First must be less than last.');
	}
	
	const range: number[] = [ first ];

	let value = first;
	while(value < last) {
		value = Math.min(last, value + step);
		range.push(value);
	}
	
	return range;
};

export const asArray = <T> (maybeArray: T | T[]): T[] => Array.isArray(maybeArray) ? maybeArray : [ maybeArray ];

export const uniqueArray = <T> (array: T[]): T[] => [ ...new Set(array) ];

export const mapPaged = <T, R> (input: Iterable<T>, pageSize: number, pageCount: number, mapFn: (element: T) => R): R[] => {
	const limit = pageSize * pageCount;
	const result: R[] = [];
	let index = 0;
	for(const item of input) {
		if(index++ >= limit) {
			break;
		}
		result.push(mapFn(item));
	}
	return result;
};