import type { GetDataResponse } from '@brng/common';
import type { JSX } from 'preact';
import { Page } from '../../components';
import { useServices } from '../../services';
import { SetupAdmin } from './setup-admin';
import { SetupRepositoryView } from './setup-repository';


export type SetupWizardProps = {
	response: GetDataResponse.NoConfig | GetDataResponse.NoAdmin;
};
export const SetupWizard = ({ response }: SetupWizardProps): JSX.Element => {
	const { dataService } = useServices();
	
	return (
		<Page>
			<div className="content content mrg-t-lg">
				{ response.reason === 'no-config' && (
					<SetupRepositoryView response={ response }/>
				)}
				{ response.reason === 'no-admin' && <SetupAdmin onComplete={ dataService.refresh }/> }
			</div>
		</Page>
	);
};