if(process.env.NODE_ENV === 'development') {
	require('preact/debug');
}

import { useObservable } from 'ecce-preact';
import localforage from 'localforage';
import { render } from 'preact';
import { Router } from 'preact-router';
import { ErrorBoundary, ErrorDialog, ErrorServiceDialog, LoadingDataIndicatorProvider, NotificationArea } from './components';
import { LoginForm } from './components/organisms/login-form';
import { Page } from './components/page';
import { UpdateNotification } from './components/page/update-notification';
import { SetupWizard } from './config';
import { NoManualDataDialog } from './config/manual/no-manual-data-dialog';
import { DataProvider, useData } from './data';
import { LicenseFailed } from './licensing';
import { LicenseAlert } from './licensing/license-alert';
import { AdminRoute, FaqRoute, IssueRoute, MapRoute, SiteRoute, VerifyMapsKeyRoute, handleRouteChange } from './routes';
import { InventoryRoute } from './routes/inventory';
import { InviteRoute } from './routes/invite-route';
import { NotFoundRoute } from './routes/not-found-route';
import { SnmpHelperRoute } from './routes/snmp-helper/snmp-helper-route';
import { SpectrumRoute } from './routes/spectrum';
import { ErrorInfo, ServiceProvider, Services, useServices } from './services';
import { HttpClientFetch } from './services/api-service/http-client';
import './styles/_.scss';
import { MapUrl, initConsoleErrorHistory } from './util';
import { ClipboardControllerProvider } from './util/clipboard-controller';


initConsoleErrorHistory();

localforage.config({
	name: 'bearing',
	driver: [ localforage.INDEXEDDB, localforage.LOCALSTORAGE ],
	storeName: 'bearing-data',
});


window.navigator?.serviceWorker?.register?.(
	new URL('service-worker.ts', import.meta.url),
	{ type: 'module' }
).catch(err => console.error('Failed to register service worker:', err)); // eslint-disable-line no-console

const Main = () => {
	const { isDataLoading, data, dataError } = useData();
	const { authService } = useServices();
	useObservable(authService, 'loginRequired');


	if(data?.license.state === 'FAILED') {
		return <LicenseFailed/>;
	}

	if(dataError?.reason === 'no-config' || dataError?.reason === 'no-admin') {
		return <SetupWizard response={ dataError }/>;
	}

	return (
		<Page>
			<LicenseAlert/>
			<Router onChange={ handleRouteChange }>
				<SiteRoute path="/"/>
				{/* Alias the homepage to work around inconsistent PWA start_url behavior between browsers. */}
				<SiteRoute path="/public"/>
				<IssueRoute path="/issues"/>
				<MapRoute path={ MapUrl.PATH }/>
				<SpectrumRoute path="/spectrum"/>
				<InventoryRoute path="/inventory"/>
				<SnmpHelperRoute path="/snmp-helper"/>
				
				<FaqRoute path="/faq"/>
					
				<AdminRoute path="/admin" auth="admin"/>
				<InviteRoute path="/invite/:inviteCode?" auth="open"/>
				<NotFoundRoute default auth="open"/>

				<VerifyMapsKeyRoute path="/__/verify-maps-key"/>

			</Router>

			<UpdateNotification/>
			<NotificationArea/>
			<NoManualDataDialog/>
			
			{/* Show the login form if required by the current route. */}
			{ !isDataLoading && authService.loginRequired && <LoginForm/> }
		</Page>
	);
};

type RootProps = {
	services: Services;
};
const Root = ({ services }: RootProps) => (
	<ServiceProvider services={ services }>
		<ErrorBoundary>
			<ClipboardControllerProvider>
				<DataProvider>
					<LoadingDataIndicatorProvider>
						<Main/>
					</LoadingDataIndicatorProvider>
				</DataProvider>
			</ClipboardControllerProvider>
		</ErrorBoundary>
		<ErrorServiceDialog/>
	</ServiceProvider>
);

window.addEventListener('load', () => {
	const rootEl = document.getElementById('root')!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
	
	try {
		const services = new Services({
			dataService: {
				refreshInterval: 15 * 60 * 1000, // 15 minutes.
			},
		}, {
			apiService: {
				httpClient: new HttpClientFetch(),
			},
		});
		services.initialise();
		
		
		render(<Root services={ services }/>, rootEl);
	} catch(err) {
		render(<ErrorDialog errorInfo={ ErrorInfo.fromError(err) }/>, rootEl);
	}
});