import type { JSX } from 'preact/jsx-runtime';
import type { IconName } from '../../../../components/atoms/icon/icons';
import type { MapRouteController } from '../../map-route-controller';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { Icon, getNetworkItemIcon } from '../../../../components';
import { type ClassProp } from '../../../../util';
import './map-route-focus-tools.scss';


type FocusedItemProps = {
	label: string;
	icon: IconName;
	highlight: boolean;
	onFocusClick?: VoidFunction | undefined;
	onUnfocusClick: VoidFunction;
};
const FocusedItem = ({ label, icon, highlight, onFocusClick, onUnfocusClick }: FocusedItemProps): JSX.Element => {
	return (
		<div className={ classNames('map-route-focus-item', { 'map-route-focus-item--hl': highlight }) }>
			<button
				className="map-route-focus-item__main"
				onClick={ onFocusClick }
				disabled={ !onFocusClick }
				title={ label }
			>
				<Icon icon={ icon } size="sm"/>
				<span className="map-route-focus-item__label">
					{label}
				</span>
			</button>
			<button
				className="map-route-focus-item__unfocus"
				onClick={ onUnfocusClick }
				title="Unfocus"
			>
				<Icon icon="x" size="sm"/>
				<span className="visually-hidden">Unfocus</span>
			</button>
		</div>
	);
};

const MAX_SITES_SHOWN = 3;
const getSiteItems = (controller: MapRouteController): JSX.Element | null => {
	if(!controller.display.focus?.sites.length) {
		return null;
	}

	const highligh = controller.display.focus.kind === 'infrastructureSites';

	if(controller.display.focus.sites.length > MAX_SITES_SHOWN) {
		return (
			<FocusedItem
				icon="tower"
				label="Multiple sites..."
				highlight={ highligh }
				onUnfocusClick={ controller.display.removeSitesFromFocus }
			/>
		);
	}

	const canRefocusSites = (
		controller.display.focus && controller.display.focus.kind !== 'infrastructureSites'
	);

	return (
		<>
			{ controller.display.focus.sites.map(site => {
				const onFocusClick = () => {
					if(canRefocusSites) {
						controller.display.refocusSites();
					}
					controller.map?.setCenter(site.location);
				};

				return (
					<FocusedItem
						icon={ getNetworkItemIcon(site) }
						label={ site.name }
						highlight={ highligh }
						onFocusClick={ onFocusClick }
						onUnfocusClick={ () => controller.display.removeFromFocus(site) }
						key={ site.id }
					/>
				);
			})}
		</>
	);
};

export type MapRouteFocusToolsProps = ClassProp & {
	controller: MapRouteController;
	large: boolean;
};
export const MapRouteFocusTools = ({ className, controller, large }: MapRouteFocusToolsProps): JSX.Element | null => {
	useObservable(controller.display, 'focus');
	const focus = controller.display.focus;
	
	return (
		<div className={ classNames(className, 'map-route-focus-tools') }>
			{!focus && (
				<span className="map-route-focus-tools__allSites text-bolder">
					All sites
				</span>
			)}

			{ focus && !large && (
				<>
					{ focus.kind === 'customerSite' && (
						<FocusedItem
							icon={ getNetworkItemIcon(focus.customer) }
							label={ focus.customer.name }
							onUnfocusClick={ () => controller.display.removeFromFocus(focus.customer) }
							highlight
						/>
					)}
					{ focus.kind === 'device' && (
						<FocusedItem
							icon={ getNetworkItemIcon(focus.device) }
							label={ focus.device.name }
							onUnfocusClick={ () => controller.display.removeFromFocus(focus.device) }
							highlight
						/>
					)}
					{ focus.kind === 'infrastructureSites' && focus.sites.length > 1 && (
						<FocusedItem
							icon="tower"
							label="Multiple sites..."
							onUnfocusClick={ controller.display.removeSitesFromFocus }
							highlight
						/>
					)}
					{ focus.kind === 'infrastructureSites' && focus.sites.length === 1 && (
						<FocusedItem
							icon={ getNetworkItemIcon(focus.sites[0]) }
							label={ focus.sites[0].name }
							onUnfocusClick={ controller.display.removeSitesFromFocus }
							highlight
						/>
					)}
				</>
			)}
			
			{ large && (
				<>
					{ getSiteItems(controller) }

					{ focus?.device && (
						<FocusedItem
							icon={ getNetworkItemIcon(focus.device) }
							label={ focus.device.name }
							highlight={ focus.kind === 'device' }
							onFocusClick={ controller.display.focus?.customer
								?	() => controller.display.removeFromFocus(controller.display.focus?.customer)
								: undefined
							}
							onUnfocusClick={ () => controller.display.removeFromFocus(focus.device) }
						/>
					)}

					{ focus?.customer && (
						<FocusedItem
							icon={ getNetworkItemIcon(focus.customer) }
							label={ focus.customer.name }
							highlight={ focus.kind === 'customerSite' }
							onUnfocusClick={ () => controller.display.removeFromFocus(focus.customer) }
						/>
					)}
				</>
			)}

		</div>
	);
};