import type { RouteProps } from '../route-props';
import type { BandName } from '@brng/common';
import { route } from 'preact-router';
import { FrequencyBand, isBandName } from '@brng/common';
import { SidebarTemplate, useSidebarTemplate } from '../../components';
import { useData } from '../../data';
import { EquipmentSpectrums } from './components/equipment-spectrums';
import { SpectrumMapView } from './components/spectrum-map-view';
import { SpectrumComparisonControllerProvider } from './spectrum-comparison-controller-context';
import './spectrum-route.scss';


type SpectrumComparisonInfo = {
	siteId: string;
	equipmentId: string;
	band: BandName;
};

const fromUrlParams = (): SpectrumComparisonInfo | null => {
	const params = new URLSearchParams(window.location.search);

	const siteId = params.get('siteId');
	const equipmentId = params.get('equipmentId');
	const freq = params.get('freq');
	const band = isBandName(freq) ? freq : null;

	if(!siteId || !equipmentId || !band) {
		return null;
	}

	return { siteId, equipmentId, band };
};

const SpectrumRouteSidebar = () => {
	const sidebar = useSidebarTemplate();
	
	return (
		<>
			{ sidebar?.collapsed && (
				<button className="btn width-100 mrg-b-md" onClick={ sidebar.closeDrawer }>
					View map
				</button>
			)}
			<EquipmentSpectrums/>
		</>
	);
};

export const SpectrumRoute = (_: RouteProps) => {
	const { data, getSite, getEquipment } = useData();
	if(!data) {
		return null;
	}

	const comparisonInfo = fromUrlParams();
	if(!comparisonInfo) {
		route('/', true);
		return null;
	}

	const { siteId, equipmentId, band } = comparisonInfo;
	
	return (
		<SpectrumComparisonControllerProvider
			data={ data }
			site={ getSite(siteId) }
			equipment={ getEquipment(equipmentId) }
			frequencyBand={ FrequencyBand.fromBand(band) }
		>

			<SidebarTemplate
				className="spectrum-route"
				sidebarClassName="spectrum-route__sidebar"
				sidebar={ <SpectrumRouteSidebar/> }
				showSidebarText="Compare spectrums"
				sidebarInitiallyOpen
				collapse
			>
				<SpectrumMapView/>
			</SidebarTemplate>
		</SpectrumComparisonControllerProvider>
	);
};