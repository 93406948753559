import type { GetDataResponse, LicenseState } from '@brng/common';
import localforage from 'localforage';


export type CachedData = Readonly<{
	version: number;
	etag: string | null;
	data: GetDataResponse.Ok;
	timestamp: number;
}>;

export class LocalDataCache {
	static readonly #CACHE_LICENSE_STATES: ReadonlySet<LicenseState> = new Set([
		'BETA', 'TRIAL', 'VALID',
	]);

	/**
	 * Version of our cached data type. Increment this to invalidate
	 * existing local data caches.
	 */
	static readonly #VERSION = 1;
	static readonly #KEY = 'bearing-data-cache';

	/**
	 * Milliseconds before revalidating.
	 */
	static readonly #TTL = 1000 * 60 * 60 * 24 * 4; // 4 Days.

	async get(): Promise<CachedData | null> {
		try {
			const data = await localforage.getItem<CachedData>(LocalDataCache.#KEY);
			if(!data) {
				return null;
			}

			if(!this.#isValid(data)) {
				await this.clear();
				return null;
			}

			return data;
		} catch {
			return null;
		}
	}

	#isValid(data: CachedData): boolean {
		if(data.version !== LocalDataCache.#VERSION) {
			return false;
		}

		if(Date.now() - data.timestamp > LocalDataCache.#TTL) {
			return false;
		}

		return LocalDataCache.#CACHE_LICENSE_STATES.has(data.data.license.state);
	}

	async set(data: GetDataResponse.Ok, etag: string | undefined | null): Promise<void> {
		if(!LocalDataCache.#CACHE_LICENSE_STATES.has(data.license.state)) {
			return;
		}

		try {
			await localforage.setItem(LocalDataCache.#KEY, {
				version: LocalDataCache.#VERSION,
				etag: etag ?? null,
				data,
				timestamp: Date.now(),
			} satisfies CachedData);
		} catch { /**/ }
	}

	/**
	 * Reset the cached data's timestamp to now.
	 */
	async resetTimestamp(): Promise<void> {
		const cache = await this.get();
		if(cache) {
			await this.set(cache.data, cache.etag);
		}
	}

	async clear(): Promise<void> {
		try {
			await localforage.removeItem(LocalDataCache.#KEY);
		} catch { /**/ }
	}
}