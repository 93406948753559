import type { GoogleMapProps, GroundOverlayProps, InfoWindowProps, MarkerProps, PolylineProps } from '@react-google-maps/api';
import type { FunctionComponent } from 'preact';
import { GroundOverlay as GGroundOverlay, InfoWindow as GInfoWindow, GoogleMap as GMap, Marker as GMarker, Polyline as GPolyline } from '@react-google-maps/api';


/*
	As we are using the Preact compatibility layer, we need to re-export components
	from `@react-google-maps/api` asserted as Preact types.
*/
export const GoogleMap = GMap as unknown as FunctionComponent<GoogleMapProps>;
export const Marker = GMarker as unknown as FunctionComponent<MarkerProps>;
export const GroundOverlay = GGroundOverlay as unknown as FunctionComponent<GroundOverlayProps>;
export const Polyline = GPolyline as unknown as FunctionComponent<PolylineProps>;
export const InfoWindow = GInfoWindow as unknown as FunctionComponent<InfoWindowProps>;
