import type { JSX } from 'preact/jsx-runtime';
import type { Breakpoint } from '../../../../util';
import { mapPaged } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useEffect, useState } from 'preact/hooks';
import { Intersector } from '../../../../components';
import { useCurrentBreakpoint } from '../../../../util';
import { useInventoryController } from '../../inventory-controller-context';
import { InventoryResultView } from './inventory-result-view';
import './inventory-result-list-view.scss';


const BREAKPOINT_PAGE_SIZES: Record<Breakpoint, number> = {
	'sm': 15,
	'md': 20,
	'lg': 45,
	'xl': 120,
};

export const InventoryResultListView = (): JSX.Element | null => {
	const inventory = useInventoryController();
	useObservable(inventory.query, 'results');

	const pageSize = BREAKPOINT_PAGE_SIZES[useCurrentBreakpoint()];
	const [ pageCount, setPageCount ] = useState(1);
	
	useEffect(() => {
		setPageCount(1);
	}, [inventory.query.results]);
	
	if(!inventory.query.results?.length) {
		return null;
	}
	
	return (
		<>
			<ul className="inventory-item-list-view">
				{ mapPaged(inventory.query.results, pageSize, pageCount, result => (
					<InventoryResultView result={ result } key={ result.item.networkId }/>
				)) }
			</ul>
			<Intersector onIntersectionBegin={ () => setPageCount(pageCount + 1) }/>
		</>
	);
};