import type { NetworkItem } from '@brng/domain';
import type { NetworkComparator } from './network-query';


export type MatchedField = Readonly<{
	name: string;
	value: string;
}>;
export class NetworkQueryResult<T extends NetworkItem = NetworkItem> {
	readonly item: T;
	readonly score: number;
	readonly matchedFields: readonly MatchedField[];

	constructor(item: T, score: number, matchedFields: readonly MatchedField[]) {
		this.item = item;
		this.score = score;
		this.matchedFields = matchedFields;
	}
}

type MatchedFieldBuilder = MatchedField & { score: number };
class NetworkQueryResultBuilder {
	readonly item: NetworkItem;
	readonly #matchedFields: MatchedFieldBuilder[] = [];
	score = 0;

	constructor(item: NetworkItem) {
		this.item = item;
	}

	matchField(field: string, value: string, score: number) {
		this.#matchedFields.push({ name: field, value, score });
		this.score += score;
	}

	make(): NetworkQueryResult<NetworkItem> {
		const matchedFields = this.#matchedFields
			.sort((a, b) => b.score - a.score);
		return new NetworkQueryResult(this.item, this.score, matchedFields);
	}
}

export class NetworkQueryResultsBuilder {
	#results = new Map<NetworkItem, NetworkQueryResultBuilder>();
	#comparator: NetworkComparator | null = null;

	item(item: NetworkItem, score = 0): NetworkQueryResultBuilder {
		let builder = this.#results.get(item);
		if(!builder) {
			builder = new NetworkQueryResultBuilder(item);
			this.#results.set(item, builder);
		}
		builder.score += score;
		return builder;
	}
	
	filter(predicate: (item: NetworkItem) => boolean): void {
		for(const item of this.#results.keys()) {
			if(!predicate(item)) {
				this.#results.delete(item);
			}
		}
	}
	
	sort(comparator: NetworkComparator | null): void {
		this.#comparator = comparator;
	}
	
	make(): NetworkQueryResult[] {
		const results = [...this.#results.values()]
			.map(b => b.make())
			.sort((a, b) => a.item.name.localeCompare(b.item.name))
			.sort((a, b) => b.score - a.score);

		if(this.#comparator) {
			results.sort((a, b) => this.#comparator!(a.item, b.item)); // eslint-disable-line @typescript-eslint/no-non-null-assertion
		}
			
		return results;
	}
}