import { useEffect, useState } from 'preact/hooks';


export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';
const BREAKPOINTS: Record<Breakpoint, number> = {
	sm: 600,
	md: 900,
	lg: 1200,
	xl: 1800,
};

export const useMediaQuery = (query: `(${string})`): boolean => {
	const [ mediaQuery ] = useState(() => window.matchMedia(query));
	const [ matches, setMatches ] = useState(mediaQuery.matches);

	useEffect(() => {
		const handleChange = () => {
			setMatches(mediaQuery.matches);
		};

		mediaQuery.addEventListener('change', handleChange);
		return () => {
			mediaQuery.removeEventListener('change', handleChange);
		};
	}, [ mediaQuery ]);

	return matches;
};

export type BreakpointOption = number | Breakpoint;

const getBpWidth = (bp: BreakpointOption): number => typeof(bp) === 'number' ? bp : BREAKPOINTS[bp];

export const useBreakpoint = (bp: BreakpointOption): boolean => (
	useMediaQuery(`(min-width: ${getBpWidth(bp)}px)`)
);

const getCurrentBreakpoint = (): Breakpoint => {
	const width = window.innerWidth;
	if(width < BREAKPOINTS['sm']) {
		return 'sm';
	}
	if(width < BREAKPOINTS['md']) {
		return 'md';
	}
	if(width < BREAKPOINTS['lg']) {
		return 'lg';
	}

	return 'xl';
};

export const useCurrentBreakpoint = (): Breakpoint => {
	const [ breakpoint, setBreakpoint ] = useState(getCurrentBreakpoint());

	useEffect(() => {
		const handleResize = () => {
			setBreakpoint(getCurrentBreakpoint());
		};

		window.addEventListener('resize', handleResize, { passive: true });
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return breakpoint;
};

/**
 * Check if the user-agent has expressed a preference for reduced motion.
 */
export const prefersReducedMotion = (): boolean => (
	matchMedia('(prefers-reduced-motion: reduce)').matches
);