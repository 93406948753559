import type { JSX } from 'preact';
import type { ChildrenProp } from '../../util';
import { createContext } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useNetwork } from '../../services';
import { InventoryController } from './inventory-controller';


const inventoryControllerContext = createContext<InventoryController | null>(null);

export const InventoryControllerProvider = ({ children }: ChildrenProp): JSX.Element | null => {
	const network = useNetwork();
	
	const [ controller, setController ] = useState<InventoryController | null>(() => (
		network ? new InventoryController(network) : null
	));

	useEffect(() => {
		setController(previous => {
			if(network && !previous) {
				return new InventoryController(network);
			}
			return previous;
		});
	}, [network]);

	useEffect(() => {
		if(controller) {
			return () => controller.dispose();
		}
	}, [controller]);

	if(!controller) {
		return null;
	}

	return (
		<inventoryControllerContext.Provider value={ controller }>
			{ children }
		</inventoryControllerContext.Provider>
	);
};

export const useInventoryController = (): InventoryController => {
	const ctx = useContext(inventoryControllerContext);
	if(!ctx) {
		throw new Error('InventoryController was not ready.');
	}

	return ctx;
};