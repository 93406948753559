import type { MaybeRepositoryStatus } from '@brng/common';
import type { FunctionComponent } from 'preact';
import { createContext } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'preact/hooks';
import { useData } from '../data';
import { ModalLoadingIndicator } from './organisms';


type BeginLoadingFn = () => () => void;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const loadingIndicatorContext = createContext<BeginLoadingFn>(null!);

export const LoadingDataIndicatorProvider: FunctionComponent = ({ children }) => {
	const { isDataLoading } = useData();
	const [ numLoading, dispatch ] = useReducer<number, 'begin' | 'end'>((prevState, action) => {
		switch(action) {
			case 'begin':
				return prevState + 1;
			case 'end':
				return prevState - 1;
		}
	}, 0);

	const showLoadingIndicator = isDataLoading || numLoading > 0;

	const value = useCallback<BeginLoadingFn>(() => {
		dispatch('begin');
		return () => { dispatch('end'); };
	}, [ dispatch ]);


	const [ status, setStatus ]	= useState<MaybeRepositoryStatus | undefined>(undefined);
	useEffect(() => {
		setStatus('loading');

		const events = new EventSource('/api/status', { withCredentials: true });
		events.onmessage = ev => {
			setStatus(ev.data);
		};
		events.onerror = () => {
			setStatus(undefined);
		};

		return () => { events.close(); };
	}, [ showLoadingIndicator ]);

	const statusText = useMemo(() => {
		switch(status) {
			case 'no-repository':
				return 'Configuring connection to Powercode';
			case 'closed':
				return 'Reconnecting to Powercode';
			case 'verifying':
				return 'Verifying connection to Powercode';
			case 'authenticating':
				return 'Authenticating with Powercode';
			case 'loading':
			case 'ready':
				return 'Loading data from Powercode';
			default:
				return undefined;
		}
	}, [ status ]);

	return (
		<loadingIndicatorContext.Provider value={ value }>
			<ModalLoadingIndicator show={ showLoadingIndicator } message={ statusText }/>
			{children}
		</loadingIndicatorContext.Provider>
	);
};

export const useLoadingIndicator = (): BeginLoadingFn => {
	const ctx = useContext(loadingIndicatorContext);
	if(!ctx) {
		throw new Error('loadingIndicatorContext was null. Missing <LoadingIndicatorProvider>?');
	}
	return ctx;
};