import type { ChildrenProp, ClassProp } from '../util';
import classNames from 'classnames';
import './viewport.scss';
import { forwardRef } from 'preact/compat';


export type ViewportProps = ClassProp & ChildrenProp & {
	innerClassName?: string;
	shadow?: boolean;
};

export const Viewport = forwardRef<HTMLDivElement, ViewportProps>(({ className, innerClassName, shadow, children }, ref) => (
	<div className={ classNames('viewport', { 'viewport--shadow': shadow }, className) }>
		<div ref={ ref } className={ classNames('viewport__content', innerClassName) }>
			{ children }
		</div>
	</div>
));