


export class Color {
	readonly red: number;
	readonly green: number;
	readonly blue: number;
	readonly opacity: number;

	/**
	 * @param red 0-255
	 * @param green 0-255
	 * @param blue 0-255
	 * @param opacity 0-1
	 */
	constructor(red: number, green: number, blue: number, opacity = 1) {
		this.red = Math.round(Math.clamp(red,0,255));
		this.green = Math.round(Math.clamp(green,0,255));
		this.blue = Math.round(Math.clamp(blue,0,255));
		this.opacity = Math.clamp(opacity,0,1);
	}

	/**
	 * @param target Color to blend toward
	 * @param ratio 0-1
	 */
	blend(target: Color, ratio: number): Color {
		return new Color(Color.#blendNum(this.red,target.red,ratio),
			Color.#blendNum(this.green,target.green,ratio),
			Color.#blendNum(this.blue,target.blue,ratio),
			Color.#blendNum(this.opacity,target.opacity,ratio));
	}

	/**
	 * Uses perceived luminance (BT.601) to blend color closer to gray
	 * @param ratio 0-1 where 1 results in gray according to BT.601
	 */
	desaturate(ratio: number): Color {
		const gray = this.red * 0.299 + this.green * 0.587 + this.blue * 0.114;
		return new Color(Color.#blendNum(this.red,gray,ratio),
			Color.#blendNum(this.green,gray,ratio),
			Color.#blendNum(this.blue,gray,ratio),
			this.opacity);
	}

	/**
	 * Reduces all color values by the given ratio.
	 * @param ratio 0-1 where 1 results in black
	 */
	darken(ratio: number): Color {
		const multiplier = 1-ratio;
		return new Color(this.red * multiplier,
			this.green * multiplier,
			this.blue * multiplier,
			this.opacity);
	}
	
	toRGB(): string {
		return `rgb(${this.red},${this.green},${this.blue})`;
	}
	
	toRGBA(opacityOverride : number | null | undefined): string {
		return `rgba(${this.red},${this.green},${this.blue},${opacityOverride ?? this.opacity})`;
	}
	
	toRGBHex(): string {
		return `#${Color.#radix16Pair(this.red)}${Color.#radix16Pair(this.green)}${Color.#radix16Pair(this.blue)}`;
	}
	
	toRGBAHex(opacityOverride : number | null | undefined): string {
		return `#${Color.#radix16Pair(this.red)}${Color.#radix16Pair(this.green)}${Color.#radix16Pair(this.blue)}${Color.#radix16Pair(Math.round(Math.clamp(255 * (opacityOverride ?? this.opacity), 0, 255)))}`;
	}
	
	static get red(): Color {
		return this.#colors['red'];
	}
	
	static get yellow(): Color {
		return this.#colors['yellow'];
	}
	
	static get lime(): Color {
		return this.#colors['lime'];
	}
	
	static get cyan(): Color {
		return this.#colors['cyan'];
	}
	
	static get blue(): Color {
		return this.#colors['blue'];
	}

	static get magenta(): Color {
		return this.#colors['magenta'];
	}

	static get black(): Color {
		return this.#colors['black'];
	}
	
	static readonly #colors: Readonly<Record<string, Color>> = {
		'red': new Color(255, 0, 0),
		'yellow': new Color(255, 255, 0),
		'lime': new Color(0, 255, 0),
		'cyan': new Color(0, 255, 255),
		'blue': new Color(0, 0, 255),
		'magenta': new Color(255, 0, 255),
		'black': new Color(0, 0, 0),
	};

	/**
	 * Blend one number with another with the ratio as the balance between the two.
	 * @param start start number
	 * @param end end number
	 * @param ratio 0-1 where 0 would return the start number, 1 would return the end number and a decimal results in
	 * the position on a linear progression between the two.
	 */
	static #blendNum(start: number, end: number, ratio: number): number {
		return start * (1 - ratio) + end * ratio;
	}

	static #radix16Pair(base10Int: number): string {
		const hex = base10Int.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}
}