import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp, IdProp } from '../../util';


export type LabelProps = IdProp & ClassProp & ChildrenProp & {
	label: string;
	htmlFor?: string;
	required?: boolean;
};
export const Label = (p: LabelProps): JSX.Element => {
	return (
		<label
			id={ p.id }
			className={ p.className }
		>
			<span className="text-lg text-bolder">
				{ p.label }
				{ p.required && <><strong aria-hidden>*</strong><span className="visually-hidden"> (Required)</span></>}
			</span>
			{ p.children }
		</label>
	);
};