import { useRef } from 'preact/hooks';

/**
 * Returns the most recent non-nullish value that was passed to the hook.
 */
export const usePersistent = <T> (value: T | null | undefined): T | null | undefined => {
	const ref = useRef<T | null | undefined>(value);
	if(value) {
		ref.current = value;
	}

	return ref.current;
};