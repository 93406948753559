export type DataUploadRequest = {
	type: 'csv';
	sites: string;
	devices: string;
};

export type DataUploadValidationRowError = {
	column: string;
	value: unknown;
	errors: string[];
};

export namespace CsvError {
	export type InvalidFormat = {
		reason: 'invalidFormat';
		expected: string[];
		received: string[];
	};
	export type Other = {
		reason: 'other';
		title: string;
		message?: string;
	};
}
export type CsvError = (
	| CsvError.InvalidFormat
	| CsvError.Other
);

export type DataUploadValidationError = {
	ok: false;
	errors: CsvError[];
	rowErrors: Record<number, DataUploadValidationRowError[]>;
	errorCsv: string | null;
};

export namespace DataUploadResult {
	export type Ok = {
		ok: true;
	};
	export type InvalidData = {
		ok: false;
		reason: 'invalidData';
		sitesError: DataUploadValidationError | null;
		devicesError: DataUploadValidationError | null;
	};
	export type Error = (
		| DataUploadResult.InvalidData
	);
}
export type DataUploadResult = DataUploadResult.Ok | DataUploadResult.Error;