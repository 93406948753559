import type { FrequencyBand } from '@brng/common';
import type { Device, Site } from '@brng/domain';
import type { ReadonlyObservableArray } from 'ecce-preact';
import type { LegacyData } from '../../data';
import { ObservableArray, ObservableSet, observable } from 'ecce-preact';
import { MapController } from '../../map';
import { SpectrumSliderController } from '../../components/spectrum';
import { SpectrumGraph } from '../../components/spectrum/spectrum-graph';
import { getInterferingEquipment, getNearbyEquipment } from './related-equipment';


export type EquipmentAndSpectrum = Readonly<{
	equipment: Device;
	graph: SpectrumGraph;
}>;

export type SpectrumComparisonControllerConfig = {
	data: LegacyData;
	site: Site;
	equipment: Device;
	frequencyBand: FrequencyBand;
};
export class SpectrumComparisonController {
	readonly #data: LegacyData;
	readonly site: Site;
	readonly equipment: Device;
	readonly frequencyBand: FrequencyBand;

	readonly focusedEquipment = new ObservableSet<Device>();

	readonly slider: SpectrumSliderController;
	readonly siteSpectrum: SpectrumGraph;
	
	readonly nearbyEquipment: Readonly<Device[]>;
	readonly nearbySpectrum: SpectrumGraph;
	#interfering: readonly EquipmentAndSpectrum[] = [];
	get interfering(): readonly EquipmentAndSpectrum[] { return this.#interfering; }
	@observable() private set interfering(value: readonly EquipmentAndSpectrum[]) { this.#interfering = value; }

	#interferingSpectrums = new ObservableArray<SpectrumGraph>();
	get interferingSpectrums(): ReadonlyObservableArray<SpectrumGraph> { return this.#interferingSpectrums; }
	
	readonly map: MapController;
	
	#mapBounds: google.maps.LatLngBoundsLiteral | null = null;
	get mapBounds(): google.maps.LatLngBoundsLiteral | null { return this.#mapBounds; }
	@observable() private set mapBounds(value: google.maps.LatLngBoundsLiteral | null) { this.#mapBounds = value; }
	
	constructor(config: SpectrumComparisonControllerConfig) {
		this.#data = config.data;
		this.site = config.site;
		this.equipment = config.equipment;
		this.frequencyBand = config.frequencyBand;
		this.map = new MapController();
		
		this.slider = new SpectrumSliderController({
			frequencyBand: config.frequencyBand,
			equipment: this.equipment,
		});
		
		const otherEquipment = this.site.devices
			.filter(eq => (
				eq !== this.equipment
				&& eq.radios[0]?.band === this.frequencyBand
			));

		this.siteSpectrum = new SpectrumGraph({
			equipment: otherEquipment,
			frequencyBand: this.frequencyBand,
		}, {
			focusedEquipment: this.focusedEquipment,
		});

		const { equipment, bounds } = getNearbyEquipment(this.equipment, this.#data);
		this.nearbyEquipment = equipment;
		this.nearbySpectrum = new SpectrumGraph({
			equipment: this.nearbyEquipment,
			frequencyBand: this.frequencyBand,
		}, {
			focusedEquipment: this.focusedEquipment,
		});
		
		this.#updateInterferingSpectrums();

		this.slider.observeChange(() => this.#updateInterferingSpectrums());
		this.map.setBounds(bounds);
	}

	#updateInterferingSpectrums() {
		const equipment = getInterferingEquipment(
			this.equipment,
			this.slider.frequency,
			this.slider.channelWidth,
			this.nearbyEquipment
		);

		this.interfering = equipment
			.sort((a, b) => {
				const freqA = a.radios[0].frequency - a.radios[0].channelWidth / 2;
				const freqB = b.radios[0].frequency - b.radios[0].channelWidth / 2;

				return freqA - freqB;
			})
			.map(equipment => ({
				equipment,
				graph: new SpectrumGraph({
					name: equipment.name,
					equipment: [ equipment ],
					frequencyBand: this.frequencyBand,
				}, {
					focusedEquipment: this.focusedEquipment,
				}),
			}));
	}
}