import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { useAdmin, useServices, useSettingsService } from '../../../../services';
import { useSitesInfo } from '../../use-sites-info';
import { SitesPageSection } from '../sites-page-section';
import './next-steps.scss';


type NextStepInfo = {
	title: string;
	content: JSX.Element;
};

export const NextSteps = (): JSX.Element | null => {
	const { settings } = useSettingsService();
	const { repositoryService } = useServices();
	const { sites } = useSitesInfo();
	const admin = useAdmin();
	
	useObservable(repositoryService, 'metadata');
	const { metadata } = repositoryService;

	const nextSteps = useMemo(() => {
		const steps: NextStepInfo[] = [];

		// SNMP helper
		if(sites.some(site => (
			site.issues.getByKind('missingData').some(w => w.missing.includes('frequency') || w.missing.includes('channel-width'))
		))) {
			if(settings.snmpEnabled) {
				steps.push({
					title: 'SNMP',
					content: (
						<>
							<p>Bearing uses SNMP to gather device data.</p>
							<p>Click on radios below to see specific issues.</p>
							{ admin
								? <p>Bearing's SNMP can be disabled <a href="/admin#settings">here</a>.</p>
								: <p>Ask your local Bearing administrator if you think this should be disabled.</p>
							}
						</>
					),
				});
			}
			else {
				steps.push({
					title: 'Device probes',
					content: (
						<>
							<p>To get accurate data Bearing depends on SNMP probes configured in Powercode.</p>
							<p>Try our <a href="/snmp-helper">SNMP Helper</a> to track down OIDs.</p>
							{ admin
								? <p>Alternatively, enable Bearing's built in SNMP <a href="/admin#settings">here</a>.</p>
								: <p>Alternatively, ask your local Bearing administrator about our built in SNMP.</p>
							}
						</>
					),
				});
			}
		}
		
		// Google Maps key
		if(!settings.googleMapsKey) {
			steps.push({
				title: 'Configure Google Maps',
				content: (
					<>
						<p>
							Bearing requires a Google Maps API key in order to show you maps.
						</p>
						{ admin
							? <p>Head over to the <a href="/admin#google-maps">Admin page</a> to set this up.</p>
							: <p>Ask your local Bearing administrator set this up.</p>
						}
					</>
				),
			});
		}
		
		// Wireless range custom field
		if(metadata?.provider === 'Powercode' && !metadata?.wirelessRange.configured) {
			steps.push({
				title: 'Configure wireless range',
				content: (
					<>
						<p>
							Bearing uses custom fields set on your equipment in Powercode
							to determine an Access Point's range.
						</p>
						{admin
							? <p>Head over to the <a href="/admin#powercode-custom-fields">Admin page</a> to set this up.</p>
							: <p>Ask your local Bearing administrator set this up.</p>
						}
					</>
				),
			});
		}

		return steps;
	}, [sites, settings.googleMapsKey, settings.snmpEnabled, metadata, admin]);
	
	if(!metadata || !nextSteps?.length) {
		return null;
	}
	
	return (
		<SitesPageSection title="Next Steps" icon="rocket" className="panel--info">
			<div className="stack-xs">
				{nextSteps.map(nextStep => (
					<div className="next-step panel copy copy--sm" key={ nextStep.title }>
						<h3>{nextStep.title}</h3>
						{ nextStep.content }
					</div>
				))}
			</div>
		</SitesPageSection>
	);
};