import type { Point } from '@brng/common';
import { notNullish } from '@brng/common';


const roundToDecimalPlace = (value: number, places: number): string => {
	const scalar = Math.max(places * 100, 1);
	const rounded = (Math.round(value * scalar) / scalar).toString();
	let result = rounded;
	const decimalIndex = rounded.indexOf('.');
	if(decimalIndex >= 0) {
		result = rounded.slice(0, decimalIndex + 1 + places);
	}

	return result;
};

/**
 * Produce a human-readable label for the passed `meters` in km.
 */
export const formatDistance = <T extends number | null | undefined>(metres: T, decimalPlaces = 3):T extends number ? string : null => {
	return ((metres !== null && metres !== undefined) ? roundToDecimalPlace(metres / 1000, decimalPlaces) + 'km' : null) as (T extends number ? string : null);
};

export const formatDegrees = <T extends number | null | undefined>(degrees: T, decimalPlaces = 0):T extends number ? string : null => (
	((degrees !== null && degrees !== undefined) ? roundToDecimalPlace(degrees, decimalPlaces) + '°' : null) as (T extends number ? string : null)
);

export const formatFrequency = (mhz: number | null | undefined): string | null => (
	notNullish(mhz) ? mhz + 'MHz' : null
);

export const formatPoint = (point: Point): string => point.lat + ', ' + point.lng;

export const isUrl = (x: string): boolean => {
	try {
		new URL(x);
		return true;
	} catch {
		return false;
	}
};


export const formatDate = (date: string | number | Date): string => (
	(date instanceof Date ? date : new Date(date)).toLocaleTimeString()
);

export const formatDateTime = (date: string | number | Date): string => {
	const d = date instanceof Date ? date : new Date(date);

	return d.toLocaleTimeString() + ' ' + d.toLocaleDateString();
};

export const formatMacAddress = (rawMacAddress: string): string => {
	if(rawMacAddress.length !== 12) {
		// Invalid mac address
		return 'Invalid MAC address: ' + rawMacAddress;
	}
	
	const chars = [ ...rawMacAddress.toUpperCase() ];
	const out: string[] = [];
	while(chars.length) {
		out.push(chars.shift() as string + chars.shift());
	}

	return out.join(':');
};