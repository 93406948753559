import type { IconName } from './icons';
import type { ClassProp } from '../../../util';
import type { JSX } from 'preact/jsx-runtime';
import { cloneElement } from 'preact';
import classNames from 'classnames';
import { ICONS } from './icons';
import './icon.scss';


export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type IconProps = ClassProp & {
	icon: IconName;
	size?: IconSize;
};
export const Icon = ({ className, icon, size = 'md' }: IconProps): JSX.Element => (
	cloneElement(ICONS[icon], {
		className: classNames('icon', 'icon--' + size, 'icon--' + icon, className),
	})
);