import type { Point } from '@brng/common';
import type { LegacyData } from '../../data';
import { DeviceGeometry, type Device, type Radio, type Site } from '@brng/domain';


type EquipmentLocation = {
	equipment: Device;
	point: Point;
};

export type NearbyEquipment = Readonly<{
	equipment: readonly Device[];
	bounds: Readonly<google.maps.LatLngBoundsLiteral>;
}>;

function _getBounds(targetLocation: EquipmentLocation, sites: Site[]): google.maps.LatLngBoundsLiteral {
	const bounds = {
		east: targetLocation.point.lng,
		west: targetLocation.point.lng,
		north: targetLocation.point.lat,
		south: targetLocation.point.lat,
	};
	sites.forEach(site => {
		if(site.latitude>bounds.north) {
			bounds.north = site.latitude;
		}
		if(site.latitude<bounds.south) {
			bounds.south = site.latitude;
		}
		if(site.longitude>bounds.east) {
			bounds.east = site.longitude;
		}
		if(site.longitude<bounds.west) {
			bounds.west = site.longitude;
		}
	});
	
	return bounds;
}

export const getNearbyEquipment = (target: Device, data: LegacyData): NearbyEquipment => {
	const targetSite = target.site;
	const targetLocation = {
		equipment: target,
		point: targetSite.location,
	};
	const targetGeometry = new DeviceGeometry(target);
	
	const equipment = data.sites
		.flatMap(site => site.devices
			.filter(eq => targetGeometry.filterCouldInterfere(new DeviceGeometry(eq))));

	return {
		equipment,
		bounds: _getBounds(targetLocation, data.sites.filter(site => equipment.find(eq => eq.site.id===site.id))),
	};
};

function _frequencyOverlaps(frequency: number, channelWidth: number, radio: Radio) {
	const channelWidthAllowance = (channelWidth + radio.channelWidth) / 2;
	return _frequencyDifference(frequency, radio) < channelWidthAllowance;
}

function _frequencyDifference(frequency: number, radio: Radio) {
	return Math.abs(frequency - radio.frequency);
}

export const getInterferingEquipment = (target: Device,frequency: number, channelWidth: number, equipment: readonly Device[]): Device[] => {
	return equipment.filter(eq => _frequencyOverlaps(frequency, channelWidth, eq.radios[0])).sort((a, b) => {
		// Which center frequency is closer
		const frequencyDifference = _frequencyDifference(frequency, a.radios[0]) - _frequencyDifference(frequency, b.radios[0]);
		if(frequencyDifference !== 0) {
			return frequencyDifference;
		}
		// Which channel width is wider
		const channelWidthDifference = b.radios[0].channelWidth - a.radios[0].channelWidth;
		if(channelWidthDifference !== 0) {
			return channelWidthDifference;
		}
		return a.id.localeCompare(b.id);
	});
};