import type { Site } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../../../util';
import classNames from 'classnames';
import { Link, getNetworkItemIcon } from '../../../../../components';


export type InventoryDetailsSiteDevicesProps = ClassProp & {
	site: Site;
};
export const InventoryDetailsSiteDevicesList = ({ className, site }: InventoryDetailsSiteDevicesProps): JSX.Element => {
	if(!site.devices.length) {
		return <>No RF Equipment found at {site.name}.</>;
	}
	
	return (
		<ul className={ classNames(className, 'panel-group') }>
			{site.devices.map(device => (
				<li className="inventory-details-site__deviceItem panel" key={ device.id }>
					<Link
						className="inventory-details-site__deviceItemName"
						label={ device.name }
						icon={ getNetworkItemIcon(device) }
						href={ '#' + device.networkId }
					/>
					{!!device.children?.length && (
						<ul className="panel-group">
							{device.children.map(child => (
								<li className="panel panel--dark data-panel" key={ child.id }>
									<Link
										label={ child.site.name }
										icon='customer'
										href={ '#' + child.site.networkId }
									/>
									<div className="text-sm flex-align-center">
										via&nbsp;
										<Link
											label={ child.name }
											icon={ getNetworkItemIcon(child) }
											href={ '#' + child.networkId }
										/>
									</div>
								</li>
							))}
						</ul>
					)}
					{!device.children?.length && device.type === 'Access Point' && (
						<span className="text-sm">No customers assigned to {device.name}.</span>
					)}
					{device.sibling && (
						<div className="panel panel--dark data-panel">
							<Link
								label={ device.sibling.name }
								icon={ getNetworkItemIcon(device.sibling) }
								href={ '#' + device.networkId }
							/>
							<div className="text-sm flex-align-center">
								on&nbsp;
								<Link
									label={ device.sibling.site.name }
									icon={ getNetworkItemIcon(device.sibling.site) }
									href={ '#' + device.sibling.site.networkId }
								/>
							</div>
						</div>
					)}
				</li>
			))}
		</ul>
	);
};