import type { FunctionComponent } from 'preact';
import type { ClassProp } from '../../../util';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { onRouteChange } from '../../../routes';


export const BackButton: FunctionComponent<ClassProp> = ({ className }) => {
	const [ show, setShow ] = useState(false);
	
	// Only show if we have anywhere to navigate to.
	useEffect(() => (
		onRouteChange(({ historySize }) => {
			setShow(historySize > 1);
		})
	), []);
	
	const handleClick = useCallback(() => {
		window.history.back();
	}, []);
	
	if(!show) {
		return null;
	}
	
	return (
		<button
			className={ `btn btn--icon-only ${className}` }
			onClick={ handleClick }
		>
			<i className="fas fa-arrow-left"/>
			<span className="visually-hidden">Go back</span>
		</button>
	);
};
