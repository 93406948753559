import type { VNode } from 'preact';
import type { RouterOnChangeArgs } from 'preact-router';
import type { RouteAuthMode, RouteProps } from './route-props';
import { route } from 'preact-router';


type RouteEvent = {
	url: string;
	auth: RouteAuthMode;
	historySize: number;
};
const makeRouteEvent = (ev: RouterOnChangeArgs): RouteEvent => ({
	url: ev.url,
	auth: (ev.current as VNode<RouteProps>).props.auth || 'user',
	historySize: history.length,
});

type RouteListener = (ev: RouteEvent) => void;
const routeListeners = new Set<RouteListener>();

let currentRoute: RouterOnChangeArgs;
const history: string[] = [];
let isBack = false;

if(process.env.NODE_ENV !== 'testing') {
	window.addEventListener('popstate', () => {
		isBack = true;
	});
}
/**
 * Listen for route changes.
 * @returns function to call to stop listening.
 */
export const onRouteChange = (listener: RouteListener): () => void => {
	routeListeners.add(listener);
	if(currentRoute) {
		listener(makeRouteEvent(currentRoute));
	}
	return () => { offRouteChange(listener); };
};

export const offRouteChange = (listener: RouteListener): void => {
	routeListeners.delete(listener);
};

/**
 * Callback for our main `<Router>`'s `onChange` event.
 */
export const handleRouteChange = (ev: RouterOnChangeArgs): void => {
	// Will be caught by the `default` `<NotFoundRoute>`.
	if(!ev.current) {
		return;
	}

	// Redirect from old `.html` path names
	if(ev.url.includes('.html')) {
		route(ev.url.replace('.html', ''), true);
		return;
	}

	// Update history
	if(isBack) {
		history.pop();
		isBack = false;
	} else if(ev.url !== ev.previous) {
		history.push(ev.url);
	}

	// Notify listeners
	const routeEvent = makeRouteEvent(ev);
	routeListeners.forEach(l => l(routeEvent));
	currentRoute = ev;
};