import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp } from '../../../../util';
import classNames from 'classnames';
import './inventory-details-section.scss';


export type InventoryDetailsSectionProps = ClassProp & ChildrenProp & {
	innerClassName?: string;
	title?: string;
};
export const InventoryDetailsSection = ({ className, innerClassName, title, children }: InventoryDetailsSectionProps): JSX.Element => {
	return (
		<section className={ classNames('inventory-details-section', className) }>
			{ title && (
				<h3 className="inventory-details-section__title">
					{title}
				</h3>
			)}
			<div className={ classNames('inventory-details-section__content', innerClassName) }>
				{children}
			</div>
		</section>
	);
};