/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */

import type { MapPalette, MapTheme } from '../../map-controller';
import type { Site } from '@brng/domain';
import buildingDark from 'data-url:./building-dark.png';
import buildingFocus from 'data-url:./building-focus.png';
import buildingLight from 'data-url:./building-light.png';
import houseDark from 'data-url:./house-dark.png';
import houseFocus from 'data-url:./house-focus.png';
import houseLight from 'data-url:./house-light.png';
import otherDark from 'data-url:./other-dark.png';
import otherFocus from 'data-url:./other-focus.png';
import otherLight from 'data-url:./other-light.png';
import repeaterDark from 'data-url:./repeater-dark.png';
import repeaterFocus from 'data-url:./repeater-focus.png';
import repeaterLight from 'data-url:./repeater-light.png';
import siloDark from 'data-url:./silo-dark.png';
import siloFocus from 'data-url:./silo-focus.png';
import siloLight from 'data-url:./silo-light.png';
import towerDark from 'data-url:./tower-dark.png';
import towerFocus from 'data-url:./tower-focus.png';
import towerLight from 'data-url:./tower-light.png';


const siteIcons: Readonly<Record<MapTheme | 'focus', Readonly<Record<string, string>>>> = {
	light: {
		building: buildingLight,
		house: houseLight,
		other: otherLight,
		repeater: repeaterLight,
		silo: siloLight,
		tower: towerLight,
	},
	dark: {
		building: buildingDark,
		house: houseDark,
		other: otherDark,
		repeater: repeaterDark,
		silo: siloDark,
		tower: towerDark,
	},
	focus: {
		building: buildingFocus,
		house: houseFocus,
		other: otherFocus,
		repeater: repeaterFocus,
		silo: siloFocus,
		tower: towerFocus,
	},
};

export const getMapSiteIcon = (theme: MapTheme, site: Site, focused: boolean): string => {
	return siteIcons[focused ? 'focus' : theme][site.type.toLowerCase()] ?? siteIcons[theme]['other'];
};