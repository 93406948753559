import type { JSX } from 'preact/compat';
import type { SpectrumGraph } from './spectrum-graph';
import type { SpectrumGraphSegment } from './spectrum-graph-segment';
import type { RefObject } from 'preact';
import { useObservable } from 'ecce-preact';
import { createPortal } from 'preact/compat';
import { useState } from 'preact/hooks';
import { usePopper } from 'react-popper';


const spectrumGraphPopupContent = (segment: SpectrumGraphSegment): JSX.Element[] | JSX.Element => {
	switch(segment.kind) {
		case 'available':
			return <span className="spectrum-graph-popup__info">Available spectrum</span>;
		case 'excluded':
			return <span className="spectrum-graph-popup__info">Outside allowed spectrum</span>;
		case 'used':
			return [...segment.equipment].map(equipment => {
				const radio = equipment.radios[0];
				const start = radio.frequency - radio.channelWidth / 2;
				const end = start + radio.channelWidth;
				
				return (
					<div className="spectrum-graph-popup__info" key={ equipment.id }>
						<span>{ equipment.name }</span>
						<code>
							{ start } - { end } ({ radio.channelWidth })
						</code>
					</div>
				);
			});
	}
};

export type SpectrumGraphPopupProps = {
	anchor: RefObject<HTMLElement | null>;
	graph: SpectrumGraph;
};
export const SpectrumGraphPopup = ({ anchor, graph }: SpectrumGraphPopupProps) => {
	useObservable(graph, 'focusedSegment');
	
	const [ root, setRoot ] = useState<HTMLElement | null>(null);
	const { styles, attributes } = usePopper(anchor.current, root);
	
	
	if(!anchor.current || !graph.focusedSegment) {
		return null;
	}

	const popupStyle = {
		...styles.popper,
		width: anchor.current?.clientWidth,
	};

	const popup = (
		<div
			className="spectrum-graph-popup shadow-lg"
			ref={ setRoot }
			style={ popupStyle }
			{ ...attributes }
		>
			<code className="spectrum-graph-popup__freq">
				{ graph.focusedSegment.startFrequency } - { graph.focusedSegment.endFrequency } ({ graph.focusedSegment.width })
			</code>
			{ spectrumGraphPopupContent(graph.focusedSegment) }
		</div>
	);
	
	return createPortal(popup, document.body);
};