import type { HttpMethod } from '../../util/http';


export type HttpRequest = {
	method: HttpMethod;
	path: string;
	body: Readonly<Record<string, unknown> | null>;
};

export type HttpResponse = {
	status: number;
	statusText: string;
	body: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	headers: Headers;
};

/**
 * Responsible for making HTTP request for the ApiService.
 */
export type HttpClient = {
	request(request: Readonly<HttpRequest>, headers: Readonly<Record<string, string>>): Promise<HttpResponse>
};

export class HttpClientFetch implements HttpClient {
	async #getBody(response: Response): Promise<HttpRequest['body']> {
		try {
			return await response.json();
		} catch(err) {
			return null;
		}
	}
	
	async request(request: Readonly<HttpRequest>, headers: Readonly<Record<string, string>>): Promise<HttpResponse> {
		const response = await fetch(request.path, {
			method: request.method,
			headers,
			body: request.body && JSON.stringify(request.body),
		});

		return {
			status: response.status,
			statusText: response.statusText,
			body: await this.#getBody(response),
			headers: response.headers,
		};
	}
}