import type { ExtraCustomFieldContentProps } from '../custom-fields';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { asRepositoryRangeUnits, REPOSITORY_RANGE_UNITS } from '@brng/common';
import './wireless-range-units-radio.scss';


const LABELS = {
	km: 'Kilometres',
	miles: 'Miles',
};

export const WirelessRangeUnitsControl = ({ model }: ExtraCustomFieldContentProps): JSX.Element => {
	useObservable(model, 'wirelessRangeUnits');
	
	const handleChange: JSX.GenericEventHandler<HTMLInputElement> = ev => {
		model.wirelessRangeUnits = asRepositoryRangeUnits(ev.currentTarget.value);
	};
		
	return (
		<div className="wireless-range-units-radio">
			<label id="wirelessRangeUnitsLabel">Wireless Range Units:</label>
			<div className="wireless-range-units-radio__inputs">
				{ REPOSITORY_RANGE_UNITS.map(unit => (
					<div key={ unit }>
						<input
							id={ 'wirelessRangeUnits' + unit }
							type="radio"
							name="wirelessRangeUnits"
							value={ unit }
							checked={ unit === model.wirelessRangeUnits }
							onChange={ handleChange }
							aria-describedby="wirelessRangeUnitsLabel"
						/>
						<label for={ 'wirelessRangeUnits' + unit }>
							{ LABELS[unit] }
						</label>
					</div>
				)) }
			</div>
		</div>
	);
};