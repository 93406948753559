import type { FunctionComponent } from 'preact';
import { useData } from '../../data';
import './footer.scss';


export const Footer: FunctionComponent = () => {
	const { data } = useData();

	if(!data) {
		return null;
	}
	
	return (
		<footer className="main-footer flex-center">
			&copy; <a href="https://brng.net" target="_blank" title="BRNG">brng.net</a>
			&nbsp;-&nbsp;
			<span>v{ data.version }</span>
			&nbsp;-&nbsp;
			<a href="https://brng.net/documentation/getting-started" target="_blank">Documentation</a>
			&nbsp;-&nbsp;
			<a href="https://brng.net/about/end-user-licence-agreement" target="_blank" title="End User Licence Agreement">EULA</a>
		</footer>
	);
};