import type { Network } from '@brng/domain';
import type { ChildrenProp } from '../../util';
import type { IssuePageFilter, IssuePageIssue, ProcessedIssuePageIssues } from './process-analysis';
import type { AnalysisFocusHook } from './use-analysis-focus';
import { createContext } from 'preact';
import { useCallback, useContext, useMemo, useState } from 'preact/hooks';
import { useProcessedAnalysis } from './process-analysis';
import { useAnalysisFocus } from './use-analysis-focus';


export type AnalysisIssuesContext = AnalysisFocusHook & {
	issues: ProcessedIssuePageIssues;
	analysisIssuesFilter: IssuePageFilter;
	updateAnalysisIssuesFilter: (patch: Partial<IssuePageFilter>) => void;
	interSiteBands: string[];
};

const analysisIssuesContext = createContext<AnalysisIssuesContext | null>(null);

const isAnalysisIssueKind = (x: string): x is IssuePageIssue['kind'] => (
	x === 'outOfSector' || x === 'interSite' || x === 'outOfRange'
);

const getInitialIssueKind = (): IssuePageIssue['kind'] => {
	const hash = window.location.hash.slice(1);
	if(hash.startsWith('eq-')) {
		const [ kind ] = hash.slice(3).split('-');
		if(isAnalysisIssueKind(kind)) {
			return kind;
		}
		return 'outOfRange';
	}
	
	const [ kind ] = hash.split('-');
	return isAnalysisIssueKind(kind) ? kind : 'outOfRange';
};

export const AnalysisIssuesProvider = (p: ChildrenProp & { network: Network }) => {
	const [ analysisIssuesFilter, setAnalysisIssuesFilter ] = useState<IssuePageFilter>(() => ({
		kind: getInitialIssueKind(),
		outOfSectorTolerance: 10,
		outOfRangeTolerance: 8,
		interSiteRange: 4,
		interSiteBand: undefined,
	}));
	
	const updateAnalysisIssuesFilter = useCallback<AnalysisIssuesContext['updateAnalysisIssuesFilter']>(patch => {
		setAnalysisIssuesFilter(prevFilter => ({
			...prevFilter,
			...patch,
		}));
	}, []);

	const interSiteBands = useMemo<AnalysisIssuesContext['interSiteBands']>(() => {
		const bands = Array.from(new Set(
			p.network.issues.getByKind('interSite').map(issue => issue.frequencyBand.band)
		)).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

		return bands;
	}, [p.network.issues]);

	const issues = useProcessedAnalysis(p.network, analysisIssuesFilter);
	const focus = useAnalysisFocus(issues, analysisIssuesFilter);

	const value = useMemo<AnalysisIssuesContext>(() => ({
		issues,
		...focus,
		analysisIssuesFilter,
		updateAnalysisIssuesFilter,
		interSiteBands,
	}), [issues, focus, analysisIssuesFilter, updateAnalysisIssuesFilter, interSiteBands]);

	return <analysisIssuesContext.Provider value={ value } children={ p.children }/>;
};

export const useAnalysisIssues = (): AnalysisIssuesContext => {
	const ctx = useContext(analysisIssuesContext);
	if(!ctx) {
		throw new Error('analysisIssuesContext was null. Missing <AnalysisIssuesProvider>?');
	}

	return ctx;
};
