import type { Point } from '@brng/common';
import type { Device } from '../network';
import { earth } from '@brng/common';


const DEFAULT_RANGE = 8000;

const PERIMETER_POINT_SPACING = 500; // Max spacing between perimeter points in meters

const DEFAULT_PERCENTAGE_BUFFER = 40;

export class DeviceGeometry {
	readonly range: number;
	
	constructor(public readonly device: Device) {
		this.range = this.device.range ?? DEFAULT_RANGE;
	}
	
	percentageOutsideSector(target: Point): number {
		if(!this.device.partial) {
			return 100;
		}
		
		return this.outsideByRangeBearing(earth.distance(this.device.location, target), this.device.antennaType === 'Omni' ? 0 : earth.azimuth(this.device.location, target));
	}
	
	outsideByRangeBearing(rangeToPoint: number, bearing: number): number {
		if(!this.device.partial) {
			return 100;
		}
		
		const degrees = this.#degreesOutside(bearing);
		
		if(degrees <= 0 && rangeToPoint <= this.range) {
			return 0;
		}
		
		if(rangeToPoint < 20) {
			return 100;
		}
		
		let result = 0;
		
		if(rangeToPoint > this.range) {
			result = Math.round(100 * (rangeToPoint - this.range)/this.range);
		}
		
		const angleExcess = Math.round(100 * (rangeToPoint * Math.sin(Math.toRadians(degrees)))/this.range);
		
		if(angleExcess > result) {
			result = angleExcess;
		}
		
		if(this.device.sectorSize > 180 || degrees <= 90) {
			return result;
		}
		
		const minRangeExcess = 10 + Math.round(100 * (rangeToPoint/this.range));
		if(minRangeExcess > result) {
			result = minRangeExcess;
		}
		
		return result;
	}
	
	filterCouldInterfere(other: DeviceGeometry, percentageBuffer = DEFAULT_PERCENTAGE_BUFFER): boolean {
		if(this.device.id === other.device.id || this.device.isRelated(other.device)) {
			return false;
		}
		
		if(this.device.valid && other.device.valid && this.device.radios[0]?.band !== other.device.radios[0]?.band) {
			return false;
		}
		
		if(this.device.site.id === other.device.site.id) {
			return true;
		}
		
		const maxRange = Math.max(this.range, other.range);
		const rangeBetween = earth.distance(this.device.location, other.device.location);
		
		// Range check
		if(this.range + other.range + maxRange * (percentageBuffer/100) < rangeBetween) {
			return false;
		}
		
		// Check if both Omnis
		if(this.device.antennaType === 'Omni' && other.device.antennaType === 'Omni') {
			return true;
		}
		
		// Check using center points for quick wins
		if(this.percentageOutsideSector(other.device.location) <= percentageBuffer || other.percentageOutsideSector(this.device.location) <= percentageBuffer) {
			return true;
		}
		
		// Check using perimeter points
		if(this.device.antennaType === 'Omni' && other.perimeterPoints().find(point => this.percentageOutsideSector(point) <= percentageBuffer)) {
			return true;
		}
		if(other.device.antennaType === 'Omni' && this.perimeterPoints().find(point => other.percentageOutsideSector(point) <= percentageBuffer)) {
			return true;
		}
		return !!(other.perimeterPoints().find(point => this.percentageOutsideSector(point) <= percentageBuffer) ||
			this.perimeterPoints().find(point => other.percentageOutsideSector(point) <= percentageBuffer));
	}

	#degreesOutside(targetBearing: number): number {
		if(this.device.antennaType === 'Omni') {
			return 0;
		}
		let normalAzimuth = targetBearing - this.device.azimuth;
		if(normalAzimuth > 180) {
			normalAzimuth = normalAzimuth - 360;
		} else if (normalAzimuth < -180) {
			normalAzimuth = normalAzimuth + 360;
		}
		if(normalAzimuth < 0) {
			normalAzimuth = normalAzimuth * -1;
		}

		return Math.max(0, Math.round(normalAzimuth - this.device.sectorSize/2));
	}
	
	perimeterPoints(): Point[] {
		const perimeterSegments = (distance: number) => Math.ceil(distance / PERIMETER_POINT_SPACING);
		
		const points = [];
		points.push(this.device.location);
		
		if(!this.device.valid) {
			return points;
		}
		
		if(this.device.antennaType === 'Omni') {
			const alphaInc = 360 / perimeterSegments(2 * Math.PI * this.range);
			for(let alpha = 0; alpha<360;alpha += alphaInc) {
				points.push(earth.point(this.device.location,this.range,alpha));
			}
			return points;
		}
		
		// Add corners first to improve speed when checking
		points.push(earth.point(this.device.location,this.range,this.device.minAzimuth));
		points.push(earth.point(this.device.location,this.range,this.device.maxAzimuth));
		
		const distanceSegments = perimeterSegments(this.range);
		const distanceInc = this.range / distanceSegments;
		for(let segment = 1; segment<distanceSegments; segment++) {
			const distance = segment * distanceInc;
			points.push(earth.point(this.device.location,distance,this.device.minAzimuth));
			points.push(earth.point(this.device.location,distance,this.device.maxAzimuth));
		}
		
		const alphaSegments = perimeterSegments(2 * Math.PI * this.range * this.device.sectorSize / 360);
		const alphaInc = this.device.sectorSize / alphaSegments;
		for(let segment = 1; segment<alphaSegments; segment++) {
			points.push(earth.point(this.device.location,this.range,this.device.minAzimuth + segment * alphaInc));
		}
		
		return points;
	}
}