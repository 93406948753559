import type { MissableData, Device } from '..';
import { BadStatusIssue, MissingDataIssue } from '..';


export const findMissingDataAndBadStatus = (device: Device): void => {
	if(device.status !== 'Good') {
		device['addIssue'](new BadStatusIssue({ device, status: device.status }));
		return;
	}

	const missing = new Set<MissableData>();
	device.radios.forEach(radio => {
		if(!radio.band) {
			missing.add('frequency-band');
		}
		if(!radio.frequency) {
			missing.add('frequency');
		}
		if(!radio.channelWidth) {
			missing.add('channel-width');
		}
	});
	
	if(device.type === 'Backhaul') {
		if(!device.azimuth) {
			missing.add('azimuth-backhaul');
		}
	} else if(device.antennaType === 'Sector') {
		if(!Number.isFinite(device.azimuth)) {
			missing.add('azimuth');
		}
		if(!device.sectorSize) {
			missing.add('sector-size');
		}
		if(device.azimuth === 0 && device.sectorSize === 360) {
			missing.add('not-omni');
		}
	}
	
	if(missing.size) {
		device['addIssue'](new MissingDataIssue({ device, missing: [...missing] }));
	}
};
