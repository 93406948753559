import type { ClassProp } from '../../../../util';
import type { MapRouteController } from '../../map-route-controller';
import classNames from 'classnames';
import { useEffect, useState } from 'preact/hooks';
import { usePopper } from 'react-popper';
import { Icon } from '../../../../components';
import { Expander } from '../../../../components/expander';
import { MapRadiusSlider } from '../../../../map';
import { MapRouteSearch } from '../map-route-search';
import { CustomerAlternativeDeviceFilterView } from '../../../../filtering';
import { MapRouteFilterTools } from './map-route-filter-tools';
import './map-route-tool-menu.scss';
import { MapRouteOverlayModeSelect } from './overlay-mode-select';


export type MapRouteToolMenuProps = ClassProp & {
	controller: MapRouteController;
};
export const MapRouteToolMenu = ({ className, controller }: MapRouteToolMenuProps) => {
	const [ anchor, setAnchor ] = useState<HTMLButtonElement | null>(null);
	const [ menu, setMenu ] = useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(anchor, menu, {
		placement: 'bottom-end',
	});

	const [ open, setOpen ] = useState(false);
	
	useEffect(() => {
		if(!open) {
			return;
		}

		const handleMouseDown = (ev: MouseEvent) => {
			if(ev.target === anchor || !menu || !(ev.target instanceof Node)) {
				return;
			}

			if(!menu.contains(ev.target)) {
				setOpen(false);
			}
		};

		window.addEventListener('mousedown', handleMouseDown);
		return () => {
			window.removeEventListener('mousedown', handleMouseDown);
		};
		
	}, [anchor, menu, open]);
	
	return (
		<>
			<button
				className={ classNames(className, 'btn btn--icon-only btn--invisible mrg-l-sm') }
				onClick={ () => setOpen(!open) }
				ref={ setAnchor }
			>
				<Icon icon={ open ? 'x' : 'more' }/>
				<span className="visually-hidden">More tools</span>
			</button>
			<div
				className="map-route-tool-menu"
				ref={ setMenu }
				style={ { ...styles.popper, zIndex: 9999 } }
				{ ...attributes.popper }
			>
				<Expander open={ open }>
					<div className="map-route-tool-menu__content">
						<MapRouteSearch
							className="map-route-tool-menu__search"
							controller={ controller }
							onSelectItem={ () => setOpen(false) }
						/>
						<MapRouteFilterTools
							controller={ controller }
							disableMode="disable"
							forceLongLabel
						/>
						<MapRouteOverlayModeSelect
							controller={ controller }
							onChange={ () => setOpen(false) }
						/>
						{controller.map && (
							<MapRadiusSlider
								className="map-route-tool-menu__radius-slider"
								controller={ controller.map }
							/>
						)}
						{ controller.display.focus?.kind === 'customerSite' && (
							<CustomerAlternativeDeviceFilterView filter={ controller.display.alternativeDevices }/>
						)}
					</div>
				</Expander>
			</div>
		</>
	);
};