import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp, Severity } from '../util';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { ExternalLink } from '../components/external-link';
import { useData } from '../data';
import { onRouteChange } from '../routes';
import './license-alert.scss';


type LicenseAlertContentProps = ClassProp & ChildrenProp & {
	icon: string;
	title: string;
	severity: Severity;
	reopenMs: number;
};
const LicenseAlertContent = ({ className, icon, title, severity, reopenMs, children }: LicenseAlertContentProps) => {
	const [ open, setOpen ] = useState(true);

	const closedTime = useRef<number | undefined>();
	const handleClose = useCallback(() => {
		setOpen(false);
		closedTime.current = Date.now();
	}, []);

	// Re-open the alert on route change, after a delay.
	useEffect(() => (
		onRouteChange(() => {
			setOpen(prevOpen => {
				if(prevOpen) {
					return prevOpen;
				}

				const timeSinceClosed = Date.now() - (closedTime.current ?? Date.now());
				return timeSinceClosed > reopenMs;
			});
		})
	), [reopenMs]);

	if(!open) {
		return null;
	}
	
	return (
		<div className={ classNames('license-alert', 'license-alert--' + severity, className) }>
			<h2 className="license-alert__head">
				<i className={ `fas ${icon}` }/>
				<span>
					{ title }
				</span>
			</h2>
			<div className="license-alert__body">
				{ children }
			</div>
			<button
				className="btn btn--icon-only"
				onClick={ handleClose }
			>
				<i className="fa fa-times"/>
			</button>
		</div>
	);
};

export const LicenseAlert = (): JSX.Element | null => {
	const { data } = useData();
	
	if(!data) {
		return null;
	}

	if(data.license.state === 'TRIAL') {
		return (
			<LicenseAlertContent
				icon="fa-rocket"
				title="Free Trial"
				severity="info"
				reopenMs={ 1000 * 60 * 30 } // 30 minutes
			>
				<p>Thank you for trying out Bearing! The free trial is limited to <strong>5</strong> Network Sites.</p>
				<p>
					Visit <ExternalLink href="https://brng.net/billing">brng.net</ExternalLink> to
					activate billing and unlock the full product.
				</p>
			</LicenseAlertContent>
		);
	}

	switch(data.license.billing) {
		case 'failed':
			return (
				<LicenseAlertContent
					icon="fa-exclamation-circle"
					title="Payment Failed"
					severity="error"
					reopenMs={ 1000 * 60 * 60 } // 1 hour
				>
					<p>
						We were unable to process your latest payment.
						Visit <ExternalLink href="https://brng.net/billing">brng.net</ExternalLink> to update
						your payment details, or retry the payment.
					</p>
					<p>
						If no action is taken, you will lose access to the full bearing features
						on <strong>{ new Date(data.license.expires as string).toLocaleDateString() }</strong>, at
						which point you will revert to our free trial licence.
					</p>
				</LicenseAlertContent>
			);
		case 'cancelled':
			return (
				<LicenseAlertContent
					severity="warning"
					icon="fa-exclamation-triangle"
					title="Subscription Cancelled"
					reopenMs={ 1000 * 60 * 60 } // 1 hour
				>
					<p>
						You have cancelled your subscription.
						You can visit <ExternalLink href="https://brng.net/billing">brng.net</ExternalLink> to re-activate
						at any time.
					</p>
					<p>
						You will lose access to the full bearing features
						on <strong>{ new Date(data.license.expires as string).toLocaleDateString() }</strong>, at
						which point you will revert to our free trial licence.
					</p>
				</LicenseAlertContent>
			);
	}

	return null;
};