import type { FrequencyBand } from '@brng/common';
import type { Device, FrequencyOverlapIssue } from '@brng/domain';
import type { ObservableSet } from 'ecce-preact';
import type { EquipmentFilter } from '../../../domain';
import type { SquelchService } from '../../../services';
import type { EquipmentRadio } from './spectrum-graph-segment';
import { observable } from 'ecce-preact';
import { SpectrumGraphSegment } from './spectrum-graph-segment';


export type SpectrumGraphDef = {
	equipment: readonly Device[];
	overlaps?: FrequencyOverlapIssue[];
	name?: string;
	disableFocus?: boolean;
	frequencyBand: FrequencyBand;
};
export type SpectrumGraphDependencies = {
	equipmentFilter?: EquipmentFilter;
	focusedEquipment?: ObservableSet<Device>;
	squelchService?: SquelchService;
};

export class SpectrumGraph {
	readonly id: string;
	readonly name: string;
	readonly frequencyBand: FrequencyBand;
	
	readonly #focusedEquipment: ObservableSet<Device> | null;
	readonly #equipmentFilter: EquipmentFilter | null;
	readonly #overlaps: FrequencyOverlapIssue[];
	
	readonly #disableFocus: boolean;
	#focusedSegment: SpectrumGraphSegment | null = null;
	get focusedSegment(): SpectrumGraphSegment | null { return this.#focusedSegment; }
	@observable() private set focusedSegment(value: SpectrumGraphSegment | null) { this.#focusedSegment = value; }
	
	#segments: readonly SpectrumGraphSegment[];
	get segments(): readonly SpectrumGraphSegment[] { return this.#segments; }
	@observable() private set segments(value: readonly SpectrumGraphSegment[]) { this.#segments = value; }

	readonly #squelchService: SquelchService | null;

	constructor(def: SpectrumGraphDef, dependencies?: SpectrumGraphDependencies) {
		this.id = def.frequencyBand.band + def.equipment.map(eq => eq.id).join();
		this.name = def.name ?? '';
		this.frequencyBand = def.frequencyBand;
		this.#overlaps = def.overlaps ?? [];
		this.#disableFocus = def.disableFocus ?? false;
		this.#focusedEquipment = dependencies?.focusedEquipment ?? null;
		this.#equipmentFilter = dependencies?.equipmentFilter ?? null;

		this.#segments = Object.freeze(this.#makeSegments(def.equipment));

		this.#focusedEquipment?.observe(() => this.#updateHighlights());
		this.#updateHighlights();

		this.#equipmentFilter?.onChange(() => this.#updateFilters());
		this.#updateFilters();

		this.#squelchService = dependencies?.squelchService ?? null;
		if(this.#squelchService) {
			this.#squelchService.on('change', this.#updateSquelches);
			this.#updateSquelches();
		}
	}

	dispose() {
		this.#squelchService?.off('change', this.#updateSquelches);
	}

	#makeSegments(equipment: Readonly<Device[]>): SpectrumGraphSegment[] {
		const equipmentRadios: EquipmentRadio[] = equipment
			.filter(eq => eq.radios[0]?.band === this.frequencyBand)
			.flatMap(equipment => (
				equipment.radios
					.filter(radio => (
						radio.band === this.frequencyBand && !!radio.frequency && !!radio.channelWidth
					))
					.map((radio): EquipmentRadio => ({
						equipment,
						radio,
						low: radio.frequency - (radio.channelWidth / 2),
						high: radio.frequency + (radio.channelWidth / 2),
					}))
			));

		// `breakFrequencies` are the points where we intend to split the spectrum
		// This can get a little crowded. We may be able to refactor this after changing overlaps
		const breakFrequencies = [ this.frequencyBand.start, this.frequencyBand.end ];
		this.frequencyBand.exclusions.forEach(range => {
			if(!breakFrequencies.includes(range[0])) {
				breakFrequencies.push(range[0]);
			}
			if(!breakFrequencies.includes(range[1])) {
				breakFrequencies.push(range[1]);
			}
		});
		equipmentRadios.forEach(eqR => {
			if(!breakFrequencies.includes(eqR.low)) {
				breakFrequencies.push(eqR.low);
			}
			if(!breakFrequencies.includes(eqR.high)) {
				breakFrequencies.push(eqR.high);
			}
		});

		// Thin down overlaps as there is a lot of looping here...
		const activeOverlaps = this.#overlaps.filter(overlap => (
			overlap.range[0] < this.frequencyBand.end && this.frequencyBand.start < overlap.range[1]
		));
	
		breakFrequencies.sort();
		let last = breakFrequencies[0];
		return breakFrequencies.slice(1)
			.map((end): SpectrumGraphSegment => {
				const start = last;
				last = end;

				if(this.frequencyBand.exclusions.find(([ low,high ]) => start===low && end===high)) {
					return new SpectrumGraphSegment({
						kind: 'excluded',
						start, end,
					}, this);
				}

				const segmentEquipmentRadios = equipmentRadios.filter(eqR => eqR.low < end && start < eqR.high);
				if(segmentEquipmentRadios.length === 0) {
					return new SpectrumGraphSegment({
						kind: 'available',
						start, end,
					}, this);
				}
			
				return new SpectrumGraphSegment({
					kind: 'used',
					start, end,
					equipmentRadios: segmentEquipmentRadios,
					overlaps: activeOverlaps.filter(overlap => overlap.range[0] < end && start < overlap.range[1]),
				}, this);
			});
	}

	setEquipment(nextEquipment: readonly Device[]) {
		this.segments = Object.freeze(this.#makeSegments(nextEquipment));
	}

	getSegment(id: string | null | undefined): SpectrumGraphSegment | null {
		if(!id) {
			return null;
		}

		return this.#segments.find(seg => seg.id === id) ?? null;
	}

	focusSegment(segment: SpectrumGraphSegment) {
		if(this.#disableFocus) {
			return;
		}
		
		this.focusedSegment = segment;
		if(segment.hasAnyEquipment()) {
			this.#focusedEquipment?.addAll(segment.equipment);
		} else {
			this.#updateHighlights();
		}
	}

	blurSegment(segment: SpectrumGraphSegment) {
		if(this.#disableFocus) {
			return;
		}
		
		if(segment === this.focusedSegment) {
			this.focusedSegment = null;
			if(segment.hasAnyEquipment()) {
				this.#focusedEquipment?.deleteAll(segment.equipment);
			} else {
				this.#updateHighlights();
			}
		}
	}

	#updateSquelches = () => {
		if(!this.#squelchService) {
			return;
		}

		for(const segment of this.segments) {
			if(!segment.hasAnyOverlaps()) {
				segment['overlap'] = false;
				continue;
			}
			
			segment['overlap'] = segment.overlaps.some(overlap => !overlap.squelched);
		}
	};

	#updateHighlights() {
		for(const segment of this.segments) {
			segment['highlight'] = this.#shouldHighlightSegment(segment);
		}
	}

	#shouldHighlightSegment(segment: SpectrumGraphSegment): boolean {
		if(segment === this.#focusedSegment) {
			return true;
		}

		if(segment.overlap && this.#focusedSegment?.kind === 'used') {
			return segment.hasEquipment(this.#focusedSegment.equipment);
		}

		if(this.#focusedEquipment) {
			return segment.hasEquipment(this.#focusedEquipment);
		}

		return false;
	}

	#updateFilters() {
		if(!this.#equipmentFilter) {
			return;
		}

		for(const segment of this.segments) {
			if(!segment.hasAnyEquipment()) {
				continue;
			}

			segment['filtered'] = !this.#equipmentFilter.some(segment.equipment);
			if(segment.filtered) {
				this.blurSegment(segment);
			}
		}
	}
}