import type { GetDataResponse, Settings } from '@brng/common';
import type { SquelchHandler } from '@brng/domain';
import type { EventCallback, EventType } from '../util';
import type { DataEvent, DataService } from './data-service';
import { Network } from '@brng/domain';
import { observable } from 'ecce-preact';
import { EventManager } from '../util';


export type NetworkEvent = Readonly<{
	network: Network;
}>;
type NetworkServiceEvents = {
	network: NetworkEvent;
};

export type NetworkServiceDependencies = {
	dataService: DataService;
	squelchHandler: SquelchHandler;
};

/**
 * Responsible for the application's {@link Network} instance.
 */
export class NetworkService {
	#network: Network | null = null;
	/**
	 * The application's {@link Network} instance.
	 *
	 * Will be `null` if:
	 *  - The user is not logged in.
	 *  - We are currently loading the data for the first time.
	 *  - The application is in an error state.
	 */
	get network(): Network | null { return this.#network; }
	@observable() private set network(value: Network | null) { this.#network = value; }
	
	#hiddenSites: readonly string[] = [];
	/**
	 * List of site names which are not included in the Network due to being in
	 * the limited trial mode.
	 */
	get hiddenSites(): readonly string[] { return this.#hiddenSites; }
	@observable() private set hiddenSites(value: readonly string[]) { this.#hiddenSites = value; }
	
	readonly #events = new EventManager<NetworkServiceEvents>();
	#data: GetDataResponse.Ok | null = null;

	readonly #squelchHandler: SquelchHandler;
	
	constructor(deps: NetworkServiceDependencies) {
		deps.dataService
			.on('data', this.#handleData)
			.on('error', this.#handleDataError);

		this.#squelchHandler = deps.squelchHandler;
	}

	#handleData = (ev: DataEvent) => {
		this.#data = ev.data;
		this.#makeNetwork(ev.data, ev.data.settings);
	};

	#handleDataError = () => {
		this.network = null;
		this.hiddenSites = [];
	};

	#makeNetwork(data: GetDataResponse.Ok, settings: Readonly<Settings>): void {
		const network = new Network({
			sites: data.sites,
			settings,
			squelchHandler: this.#squelchHandler,
		});

		this.hiddenSites = Object.freeze(
			[ ...data.hiddenSites ].sort((a, b) => a.localeCompare(b))
		);

		this.#events.notify('network', { network });
		this.network = network;
	}

	updateSettings(settings: Readonly<Settings>): void {
		if(!this.#data) {
			return;
		}

		this.#makeNetwork(this.#data, settings);
	}

	on<E extends EventType<NetworkServiceEvents>>(event: E, listener: EventCallback<NetworkServiceEvents, E>): this {
		this.#events.on(event, listener);
		return this;
	}

	off<E extends EventType<NetworkServiceEvents>>(event: E, listener: EventCallback<NetworkServiceEvents, E>): this {
		this.#events.off(event, listener);
		return this;
	}

	dispose() {
		this.#events.dispose();
	}
}