import type { Device } from '../device';
import type { Radio, RadioWithBand } from '../radio';
import type { Site } from '../site';
import { notNullish, type Settings } from '@brng/common';
import { FrequencyOverlapIssue } from './issues';


type EquipmentAndRadio = {
	equipment: Device,
	radio: Radio,
};

function hasFrequency(radio: Radio):radio is RadioWithBand {
	if(radio.band && Number.isFinite(radio.frequency)) {
		return radio.band.start < radio.frequency && radio.frequency < radio.band.end;
	}
	return false;
}

function frequencies(radio: Radio): { low: number, high: number } {
	if(!radio.channelWidth) {
		return { low:radio.frequency, high:radio.frequency };
	}
	return { low:radio.frequency-Math.round(radio.channelWidth/2), high:radio.frequency+Math.round(radio.channelWidth/2) };
}

function frequencyOverlap(eR1: EquipmentAndRadio, eR2: EquipmentAndRadio, settings: Settings): FrequencyOverlapIssue | null {
	if(eR1.equipment.id === eR2.equipment.id || eR1.equipment.isRelated(eR2.equipment)) {
		return null;
	}
	// Potential GPS timing controlled
	if(settings.gpsSyncEnabled &&
		eR1.equipment.type === 'Access Point' && eR2.equipment.type === 'Access Point' &&
		eR1.equipment.antennaType === 'Sector' && eR2.equipment.antennaType === 'Sector' &&
		Number.isFinite(eR1.equipment.azimuth) && Number.isFinite(eR2.equipment.azimuth)) {
		const separation = Math.max(eR1.equipment.azimuth, eR2.equipment.azimuth) - Math.min(eR1.equipment.azimuth, eR2.equipment.azimuth);
		if((separation <= 180 && separation >= settings.gpsSyncAzimuth) ||
			(separation > 180 && separation <= (360 - settings.gpsSyncAzimuth))) {
			return null;
		}
	}
	const frequencies1 = frequencies(eR1.radio);
	const frequencies2 = frequencies(eR2.radio);
	if(frequencies1.low<frequencies2.high && frequencies1.high>frequencies2.low) {
		return new FrequencyOverlapIssue({
			device1: eR1.equipment,
			device2: eR2.equipment,
			overlap: Math.min(frequencies1.high, frequencies2.high) - Math.max(frequencies1.low, frequencies2.low),
			range: [Math.max(frequencies1.low, frequencies2.low), Math.min(frequencies1.high, frequencies2.high)],
		});
	}
	return null;
}

export function findOverlaps(site: Site, settings: Settings): FrequencyOverlapIssue[] {
	const frequencyOverlaps: FrequencyOverlapIssue[] = [];
	
	// Splitting by frequency band reduces unnecessary frequency band checks when looping equipmentAndRadios
	const byFrequencyBand: Record<string,EquipmentAndRadio[]> = {};
	[ ...site.devices ]
		.sort((a, b) => Number(b.id)-Number(a.id))
		.flatMap(equipment => [ ...equipment.radios ]
			.filter(hasFrequency)
			.map(radio => ({ equipment, radio: radio as RadioWithBand })))
		.forEach(equipmentRadio => {
			(byFrequencyBand[equipmentRadio.radio.band.band] ??= []).push(equipmentRadio);
		});

	Object.values(byFrequencyBand).forEach(equipmentAndRadios => {
		while(equipmentAndRadios.length) {
			const eR1 = equipmentAndRadios.pop() as EquipmentAndRadio;
			equipmentAndRadios
				.map(eR2 => frequencyOverlap(eR1, eR2, settings))
				.filter(notNullish)
				.forEach(overlap => {
					frequencyOverlaps.push(overlap);
				});
		}
	});
	
	return frequencyOverlaps;
}
