import type { KeysOfValue } from '@brng/common';
import type { Device, Network, NetworkItem } from '@brng/domain';
import type { NetworkFilter } from '../filtering';
import type { UrlSearchParamController } from '../util/url-search-param-controller';
import type { NetworkQueryResult } from './network-query-results';
import { isDevice } from '@brng/domain';
import { bound, observable } from 'ecce-preact';
import Fuse from 'fuse.js';
import { numMatchingChars } from '../util';
import { NetworkQueryResultsBuilder } from './network-query-results';


export type NetworkQueryConfig = {
	/**
	 * Determines how an empty search string is handled.
	 *
	 *  - `"everything"`: results contains all items.
	 *  - `"null"`: results is null.
	 *
	 * @default "null"
	 */
	emptySearchBehaviour?: 'everything' | 'null';

	/**
	 * Filters which will be applied.
	 */
	filters?: Iterable<NetworkFilter>;
	
	/**
	 * Maximum number of results to include.
	 *
	 * @default POSITIVE_INFINITY
	 */
	limit?: number;

	/**
	 * Maximum number of CustomerSites to include. This is applied before `limit`,
	 * and can be used to avoid flooding the results with customers.
	 *
	 * @default POSITIVE_INFINITY
	 */
	customerLimit?: number;

	/**
	 * Filter applied to {@link Network.all} to determine the base set of
	 * {@link NetworkItem}s to query.
	 *
	 * Applied once when {@link setNetwork()} is called.
	 */
	preFilter?: (item: NetworkItem) => boolean;
	
	urlParams?: UrlSearchParamController;
};

export type NetworkComparator = (a: NetworkItem, b: NetworkItem) => number;

type FieldStartsWithSearchOptions<T extends NetworkItem> = {
	results: NetworkQueryResultsBuilder;
	/**
	 * Search query to match the start of the field by.
	 *
	 * @default The NetworkQuery's search.
	 */
	search?: string;
	/**
	 * Items to search over.
	 */
	items: readonly T[];
	/**
	 * Field to search for
	 */
	field: KeysOfValue<T, string>;
	/**
	 * Label for the field, displayed in the UI.
	 */
	label: string;
	/**
	 * Highest possible score granted when `search === item[field]`.
	 *
	 * @default the number of items which had any match.
	 */
	maxScore?: number;
};

/**
 * Provides interactive search & filtering behaviour for {@link NetworkItem}s.
 */
export class NetworkQuery {
	static readonly URL_PARAM_SEARCH_KEY = 'q';
	static readonly URL_PARAM_SEARCH_DEFAULT = '';
	
	static readonly ID_SEARCH_PREFIX = '#';
	static readonly #VALID_FUZZY_SEARCH_PATTERN = /[a-z0-9]/;
	
	/** Max score for a perfect name match. */
	static readonly #NAME_SCORE = 15;
	
	/** Max score for a non-prefixed perfect ID match. */
	static readonly #SOURCE_ID_SCORE = 0;

	/** Max score for a perfect IP Address match. */
	static readonly #DEVICE_IP_ADDRESS_SCORE = 5;

	/** Max bonus applied for a perfect IP Address octet match. */
	static readonly #DEVICE_IP_OCTET_BONUS = 15;

	/** Max score for a perfect device type match. */
	static readonly #DEVICE_TYPE_SCORE = 20;
	
	/** Bonus applied to perfect `startsWith` matches. */
	static readonly #PERFECT_MATCH_BONUS = 1000;
	
		
	#search: string;
	get search(): string { return this.#search; }
	@observable() private set search(value: string) { this.#search = value; }
	
	#comparator: NetworkComparator | null = null;
	get comparator(): NetworkComparator | null { return this.#comparator; }
	@observable() private set comparator(value: NetworkComparator | null) { this.#comparator = value; }
	
	#results: readonly NetworkQueryResult[] | null = null;
	get results(): readonly NetworkQueryResult[] | null { return this.#results; }
	@observable() private set results(value: readonly NetworkQueryResult[] | null) { this.#results = value; }
	
	#items: readonly NetworkItem[] = [];
	#fuse: Fuse<NetworkItem>;

	readonly config: Readonly<NetworkQueryConfig>;
	readonly filters: readonly NetworkFilter[];
	
	readonly #urlParams: UrlSearchParamController | null;

	constructor(config: Readonly<NetworkQueryConfig> = {}) {
		this.config = config;
		this.#fuse = new Fuse(this.#items, {
			includeScore: true,
			includeMatches: true,
			threshold: .2,
			useExtendedSearch: true,
			keys: [ 'name', 'ipAddress' ],
		});

		const filters: NetworkFilter[] = [];
		if(config.filters) {
			for(const filter of config.filters) {
				filters.push(filter);
				filter.on('change', this.#handleFilterChange);
			}
		}
		this.filters = filters;
		this.#urlParams = config.urlParams ?? null;
		this.#search = this.#urlParams?.getString(NetworkQuery.URL_PARAM_SEARCH_KEY, NetworkQuery.URL_PARAM_SEARCH_DEFAULT) ?? NetworkQuery.URL_PARAM_SEARCH_DEFAULT;
	}

	readonly #handleFilterChange = () => {
		this.#apply();
	};
	
	setNetwork(network: Network): this {
		if(this.config.preFilter) {
			this.#items = network.all.filter(this.config.preFilter);
		} else {
			this.#items = network.all;
		}
		this.#fuse.setCollection(this.#items);
		
		this.#apply();

		return this;
	}

	@bound()
	setSearch(search: string): void {
		if(this.search !== search) {
			this.search = search;
			this.#apply();
			this.#urlParams?.setString(NetworkQuery.URL_PARAM_SEARCH_KEY, this.search, NetworkQuery.URL_PARAM_SEARCH_DEFAULT);
		}
	}
	
	@bound()
	setComparator(comparator: NetworkComparator | null): NetworkQuery {
		if(this.comparator !== comparator) {
			this.comparator = comparator;
			this.#apply();
		}

		return this;
	}

	#apply() {
		const results = new NetworkQueryResultsBuilder();

		let searching = false;
		if(this.search) {
			if(this.search.startsWith(NetworkQuery.ID_SEARCH_PREFIX)) {
				this.#idSearch(this.search.slice(1), results);
			} else {
				this.#applyFuzzySearch(results);
				searching = true;
			}
		} else {
			switch(this.config.emptySearchBehaviour) {
				case 'everything':
					for(const item of this.#items) {
						results.item(item);
					}
					break;
				case 'null':
				case undefined:
					this.results = null;
					return;
			}
		}

		for(const filter of this.filters) {
			results.filter(filter.apply);
		}
		
		if(searching) {
			this.#idSearch(this.search, results);
			this.#deviceTypeSearch(results);
		}
		
		results.sort(this.comparator);

		this.results = this.#applyLimits(results.make());
	}

	#applyFuzzySearch(results: NetworkQueryResultsBuilder): void {
		if(!this.search.match(NetworkQuery.#VALID_FUZZY_SEARCH_PATTERN)) {
			return;
		}

		for(const result of this.#fuseSearch(this.search)) {
			for(const match of result.matches!) { // eslint-disable-line @typescript-eslint/no-non-null-assertion
				switch(match.key) {
					case 'name':
						results.item(result.item, NetworkQuery.#NAME_SCORE *  (1 - (result.score as number)));
						break;
					case 'ipAddress':
						this.#matchIpAddress(results, result.item as Device, NetworkQuery.#DEVICE_IP_ADDRESS_SCORE * (1 - (result.score as number))); // eslint-disable-line @typescript-eslint/no-non-null-assertion
						results.item(result.item);
						break;
				}
			}
		}
		

	}
	
	#matchIpAddress(results: NetworkQueryResultsBuilder, device: Device, score: number): void {
		/*
			If the search term is a perfect match for an octet, then boost the score
			weighted toward occurrences at the end of the IP address.
		*/
		if(this.search.length <=4) {
			const inputOctet = this.search.replace('.', '');
			const octets = device.ipAddress.split('.');
			
			const matchIndex = octets.indexOf(inputOctet);
			if(matchIndex >= 0) {
				score += (NetworkQuery.#DEVICE_IP_OCTET_BONUS / 4) * (matchIndex + 1);
			}
		}
		results.item(device).matchField('IP Address', device.ipAddress, score);
	}

	#fuseSearch(rawQuery: string): Fuse.FuseResult<NetworkItem>[] {
		const tokens = rawQuery.split(/\s!\^\$'"=/g).filter(token => token.length > 0);
		if(tokens.length === 0) {
			return [];
		}
		const query = tokens.length === 1 ? tokens[0] : ('\'' + tokens.join(' \''));
		return this.#fuse.search(query);
	}

	#idSearch(search: string, results: NetworkQueryResultsBuilder): void {
		this.#fieldStartsWithSearch({
			results,
			search,
			items: this.#items,
			field: 'sourceId',
			label: 'ID',
			maxScore: NetworkQuery.#SOURCE_ID_SCORE,
		});
	}
	
	#deviceTypeSearch(results: NetworkQueryResultsBuilder): void {
		this.#fieldStartsWithSearch({
			results,
			items: this.#items.filter(isDevice),
			field: 'deviceType',
			label: 'Type',
			maxScore: NetworkQuery.#DEVICE_TYPE_SCORE,
		});
	}

	#fieldStartsWithSearch<T extends NetworkItem>(options: FieldStartsWithSearchOptions<T>): void {
		/*
			The type of options.field ensures that item[options.field] will always be
			a string, but TypeScript cannot infer that when accessing the field, so we
			have assert `as string` on all usages.
		*/

		const search = (options.search ?? this.search).toLowerCase();
		(options.items)
			.filter(item => (item[options.field] as string).toLowerCase().startsWith(search))
			.sort((a, b) => {
				const fieldA = (a[options.field] as string).toLowerCase();
				const fieldB = (b[options.field] as string).toLowerCase();

				const diff = numMatchingChars(fieldA, search) - numMatchingChars(fieldB, search);
				return diff || fieldA.localeCompare(fieldB);
			})
			.forEach((item, index, array) => {
				let score: number;
				if((item[options.field] as string).toLowerCase() === search) {
					score = NetworkQuery.#PERFECT_MATCH_BONUS;
				} else {
					score = (options.maxScore ?? array.length) - index;
				}
				
				options.results.item(item).matchField(
					options.label, item[options.field] as string, score
				);
			});
	}

	#applyLimits(results: NetworkQueryResult[]): NetworkQueryResult[] {
		if(this.config.customerLimit) {
			let remaining = this.config.customerLimit;
			results = results.filter(r => {
				if(r.item.kind !== 'site' || r.item.usage !== 'Customer') {
					return true;
				}
				return remaining-- > 0;
			});
		}
		
		if(!this.config.limit) {
			return results;
		}

		return results.slice(0, this.config.limit);
	}
	
	dispose() {
		for(const filter of this.filters) {
			filter.off('change', this.#handleFilterChange);
		}
	}
}
