import type { UrlSearchParamController } from '../../util/url-search-param-controller';
import { type NetworkItem } from '@brng/domain';
import { bound, observable } from 'ecce-preact';
import { NetworkFilter } from '../network-filter';


export type SiteUsageFilterConfig = {
	urlParams?: UrlSearchParamController | null | undefined;
};
export class SiteUsageFilter extends NetworkFilter {
	static readonly URL_SEARCH_PARAM = 'siteUsage';
	static readonly URL_SEARCH_DEFAULT = 'Infrastructure';
	
	#customer: boolean;
	get customer(): boolean { return this.#customer; }
	@observable() private set customer(value: boolean) { this.#customer = value; }
	
	#infrastructure: boolean;
	get infrastructure(): boolean { return this.#infrastructure; }
	@observable() private set infrastructure(value: boolean) { this.#infrastructure = value; }
	
	constructor(config?: SiteUsageFilterConfig) {
		super({
			urlParams: config?.urlParams,
			urlParamKey: SiteUsageFilter.URL_SEARCH_PARAM,
			urlParamDefault: SiteUsageFilter.URL_SEARCH_DEFAULT,
		});
		
		if(this.getUrlParamValue() === 'Customer') {
			this.#customer = true;
			this.#infrastructure = false;
		} else {
			this.#infrastructure = true;
			this.#customer = false;
		}
	}

	protected toUrlParam(): string {
		return this.#customer ? 'Customer' : 'Infrastructure';
	}
	
	@bound()
	setInfrastructure(show: boolean): this {
		if(this.infrastructure === show) {
			return this;
		}
		
		this.infrastructure = show;
		this.customer = !show;
		
		this.changed();

		return this;
	}

	@bound()
	setCustomer(show: boolean): this {
		if(this.customer === show) {
			return this;
		}
		
		this.customer = show;
		this.infrastructure = !show;
		
		this.changed();
		
		return this;
	}
	
	protected filter(item: NetworkItem): boolean {
		if(item.kind !== 'site') {
			return true;
		}
		
		switch(item.usage) {
			case 'Customer': return this.customer;
			case 'Infrastructure': return this.infrastructure;
		}
	}
}