import type { FunctionComponent } from 'preact';
import { useMemo } from 'preact/hooks';


type MailtoComponent = FunctionComponent<{
	to: string;
	subject: string;
	body?: string;
}>;
export const Mailto: MailtoComponent = ({
	children,
	to,
	subject,
	body,
}) => {
	const href = useMemo(() => {
		const params = (Object.entries({ subject, body })
			.filter(([ , value]) => !!value) as [ string, string ][])
			.map(([ key, value ]) => [ key, encodeURIComponent(value)])
			.map(([ key, value ]) => `${key}=${value}`)
			.join('&');

		return `mailto:${to}?${params}`;
	}, [ to, subject, body ]);

	return <a href={ href }>{ children || to }</a>;
};