export type EventMap = Record<string, boolean | number | string | Readonly<Record<string, unknown>>>;
export type EventType<T extends EventMap> = keyof T;
export type EventValue<T extends EventMap, E extends EventType<T>> = T[E];
export type EventCallback<T extends EventMap, E extends EventType<T>> = (ev: EventValue<T, E>) => void;


/**
 * Manage a collection of event listeners.
 *
 * Available event types & their values are determined by the `EventMap` generic
 * parameter.
 *
 * @example
 * ```ts
 *   type ExampleEvents = {
 *     foo: number;
 *     bar: { baz: string };
 *   }
 *
 *   const events = new EventManager<ExampleEvents>();
 *
 *   events.on('foo' ev => { console.log('notified:', ev); });
 *   events.on('bar' ev => { console.log('notified:', ev.baz); })
 *
 *   events.notify('foo', 5);                // prints `notified: 5`
 *   events.notify('bar', { baz: 'hello' }); // prints `notified: hello`
 * ```
 */
export class EventManager<T extends EventMap> {
	readonly #listeners = new Map<unknown, Set<(ev: any) => void>>(); // eslint-disable-line @typescript-eslint/no-explicit-any

	on<E extends EventType<T>>(event: E, listener: EventCallback<T, E>): void {
		let eventListeners = this.#listeners.get(event);
		if(!eventListeners) {
			eventListeners = new Set();
			this.#listeners.set(event, eventListeners);
		}

		eventListeners.add(listener);

		const x =5;
		x.toPrecision(2);
	
	}

	
	off<E extends EventType<T>>(event: E, listener: EventCallback<T, E>): void {
		this.#listeners.get(event)?.delete(listener);
	}

	notify<E extends EventType<T>>(event: E, value: EventValue<T, E>): void {
		const eventListeners = this.#listeners.get(event);
		if(!eventListeners) {
			return;
		}

		for(const listener of eventListeners) {
			listener(value);
		}
	}

	dispose() {
		this.#listeners.clear();
	}
}