import type { PostRepositoryResponse, RepositoryConfig_Powercode } from '@brng/common';
import type { JSX, VNode } from 'preact';
import type { ClassProp } from '../../util';
import cn from 'classnames';
import { useCallback, useMemo } from 'preact/hooks';
import { Alert, PasswordInput } from '../../components';
import { usePatchReducer } from '../../util';
import { AllowUnauthorizedForm } from './allow-unauthorized-form';


export type PowercodeConnectionConfig = Pick<RepositoryConfig_Powercode, 'provider' | 'username' | 'password' | 'server' | 'allowUnauthorized'>;

const EMPTY_STATE: Readonly<PowercodeConnectionConfig> = {
	provider: 'Powercode',
	server: '',
	username: '',
	password: '',
};

export type PowercodeConnectionConfigForm = ClassProp & {
	initialConfig?: PowercodeConnectionConfig;
	onSubmit: (config: PowercodeConnectionConfig) => void;
	response?: PostRepositoryResponse | null;
	describedBy?: string;
	formClassName?: string;
};
export const PowercodeConnectionConfigForm = ({ className, formClassName, initialConfig, onSubmit, response, describedBy }: PowercodeConnectionConfigForm): VNode => {
	const [ config, patchConfig ] = usePatchReducer<PowercodeConnectionConfig>(initialConfig ?? EMPTY_STATE);

	// Assume we are in the setup wizard if no initialState was passed.
	const initialSetup = !initialConfig;
	
	const handleSubmit = useCallback((ev: Event) => {
		ev.preventDefault();
		onSubmit(config);
	}, [config, onSubmit]);

	const handleInputChange = useCallback<JSX.GenericEventHandler<HTMLInputElement>>(ev => {
		const { name, value } = ev.currentTarget;
		switch(name) {
			case 'server':
			case 'username':
			case 'password':
				patchConfig({ [name]: value });
				break;
			default:
				throw new Error(`Bad <input> name: ${name}`);
		}
	}, [patchConfig]);
	
	const canSubmit = useMemo(() => {
		if(!initialConfig) {
			return true;
		}

		return (
			initialConfig.server !== config.server
			|| initialConfig.username !== config.username
			|| config.password !== ''
		);
		
	}, [config.password, config.server, config.username, initialConfig]);
	
	if(!response?.ok && response?.reason === 'incomplete-certificate-chain') {
		return (
			<Alert severity="error" title="Unable to securely connect to Powercode">
				<AllowUnauthorizedForm
					className="content"
					config={ config }
					onSubmit={ onSubmit }
					onGoBack={ () => window.location.reload() }
				/>
			</Alert>

		);
	}
	
	return (
		<form
			className={ className }
			onSubmit={ handleSubmit }
			aria-describedby={ describedBy }
		>
			<div className={ cn('form', formClassName) }>
				<label>
					<span>Admin Portal URL</span>
					<input
						name="server"
						type="url"
						value={ config.server }
						onChange={ handleInputChange }
						placeholder="https://portal.example.com:444"
						required
					/>
				</label>

				<label>
					<span>Username</span>
					<input
						name="username"
						type="text"
						value={ config.username }
						onChange={ handleInputChange }
						required
					/>
				</label>

				<label>
					<span>Password</span>
					<PasswordInput
						name="password"
						value={ config.password }
						onChange={ handleInputChange }
						required={ initialSetup }
						placeholder={ initialSetup ? undefined : '[as configured]' }
						autoComplete="off"
					/>
				</label>
			</div>

			<button
				className="btn mrg-l-auto mrg-t-sm"
				type="submit"
				disabled={ !canSubmit }
			>
				{ initialSetup ? 'Connect to Powercode' : 'Update connection configuration' }
			</button>
		</form>
	);
};