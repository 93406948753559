import type { JSX } from 'preact/jsx-runtime';
import type { CustomFieldDef } from '../custom-fields';
import type { PowercodeCustomFieldsModel } from '../powercode-custom-fields-controller';
import type { RepositoryProviderMetadata_Powercode } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { useServices } from '../../../../services';
import { assertMetadataProvider } from '../../../../util/repository';


export type PowercodeCustomFieldSelectProps = CustomFieldDef & {
	id: PowercodeCustomFieldsModel.Key;
	model: PowercodeCustomFieldsModel;
};
export const PowercodeCustomFieldSelect = ({ id, label, description, model, validateField, extraContent: ExtraContent }: PowercodeCustomFieldSelectProps): JSX.Element | null => {
	useObservable(model, id);

	const { repositoryService } = useServices();
	useObservable(repositoryService, 'metadata');
	const { metadata } = repositoryService;
	if(metadata) {
		assertMetadataProvider<RepositoryProviderMetadata_Powercode>(metadata, 'Powercode');
	}

	const options = useMemo<JSX.Element[] | undefined>(() => (
		metadata?.customFields?.map((field) => {
			let invalid: string | null = null;
			if(validateField) {
				invalid = validateField(field);
			}

			return (
				<option
					value={ field.id }
					disabled={ !!invalid }
					key={ field.id }
				>
					{field.name}&nbsp;{invalid}
				</option>
			);
		})
	), [metadata?.customFields, validateField]);

	if(!metadata) {
		return null;
	}

	const descriptionId = id + 'Description';

	return (
		<>
			<label for={ id }>{ label }</label>
			<div id={ descriptionId }>
				{ description }
			</div>

			<select
				id={ id }
				name={ id }
				value={ model[id] ?? undefined }
				onChange={ ev => model[id] = ev.currentTarget.value as any } // eslint-disable-line @typescript-eslint/no-explicit-any
				aria-describedby={ descriptionId }
			>
				<option value={ undefined }>
					{ options?.length ? '(None)' : 'No custom fields configured.' }
				</option>

				{ options }
			</select>

			{ ExtraContent && <ExtraContent model={ model }/>}
		</>
	);
};