import type { CustomerSite, Device, NetworkItem } from '@brng/domain';
import type { FrequencyBand } from '@brng/common';
import type { UrlSearchParamController } from '../../util/url-search-param-controller';
import { bound, notify, observable } from 'ecce-preact';
import { NetworkFilter } from '../network-filter';


export type CustomerAlternativeDeviceOption = {
	band: FrequencyBand;
	devices: Device[];
};

export type CustomerAlternativeDevicesFilterConfig = {
	urlParams?: UrlSearchParamController;
};
export class CustomerAlternativeDevicesFilter extends NetworkFilter {
	#options: readonly CustomerAlternativeDeviceOption[] = [];
	get options(): readonly CustomerAlternativeDeviceOption[] { return this.#options; }
	@observable() private set options(value: readonly CustomerAlternativeDeviceOption[]) { this.#options = value; }
	
	#disabledDevices = new Set<string>();
	@observable() get disabledDevices(): ReadonlySet<string> { return this.#disabledDevices; }
	
	#disabledBands = new Set<string>();
	@observable() get disabledBands(): ReadonlySet<string> { return this.#disabledBands; }
	
	#anyEnabled = true;
	get anyEnabled(): boolean { return this.#anyEnabled; }
	@observable() private set anyEnabled(value: boolean) { this.#anyEnabled = value; }
	
	#initialSetCustomer = true;

	constructor(config?: CustomerAlternativeDevicesFilterConfig) {
		super({
			urlParams: config?.urlParams,
			urlParamKey: 'altDevices',
			urlParamDefault: '',
		});
		
		for(const param of this.getUrlParamValue().split(',')) {
			if(param === 'off') {
				this.#anyEnabled = false;
			} else if(param.startsWith('d')) {
				this.#disabledDevices.add(param.slice(1));
			} else if(param.startsWith('b')) {
				this.#disabledBands.add(param.slice(1));
			}
		}
	}
	
	@bound()
	setAnyEnabled(enabled: boolean) {
		if(enabled !== this.anyEnabled) {
			this.anyEnabled = enabled;
			this.changed();
		}
	}
	
	@bound()
	setBandEnabled(enabled: boolean, band: FrequencyBand): void {
		const previousSize = this.#disabledBands.size;
		if(enabled) {
			this.#disabledBands.delete(band.name);
		} else {
			this.#disabledBands.add(band.name);
		}
		
		if(previousSize !== this.#disabledBands.size) {
			notify(this, 'disabledBands');
			this.changed();
		}
	}
	
	@bound()
	setOptionEnabled(enabled: boolean, option: Device) {
		const previousSize = this.#disabledDevices.size;
		if(enabled) {
			this.#disabledDevices.delete(option.id);
		} else {
			this.#disabledDevices.add(option.id);
		}

		if(previousSize !== this.#disabledDevices.size) {
			notify(this, 'disabledDevices');
			this.changed();
		}
	}
	
	@bound()
	setCustomer(customer: CustomerSite | null | undefined): void {
		if(!customer?.alternativeDevices?.length) {
			this.options = [];
			return;
		}

		
		const options = new Map<FrequencyBand, Device[]>();
		for(const device of customer.alternativeDevices) {
			const band = device.radios[0]?.band;
			if(!band) {
				continue;
			}

			let devices = options.get(band);
			if(!devices) {
				devices = [];
				options.set(band, devices);
			}

			devices.push(device);
		}
		
		this.options = [ ...options.entries() ].map(([ band, devices ]) => ({ band, devices }));

		if(this.#initialSetCustomer) {
			this.#initialSetCustomer = false;
		} else {
			this.#disabledDevices.clear();
			this.#disabledBands.clear();
		}
	}
	
	protected filter(item: NetworkItem): boolean {
		if(item.kind !== 'device') {
			return true;
		}
		
		if(!this.anyEnabled) {
			return false;
		}
		
		const band = item.radios[0]?.band;
		if(band && this.disabledBands.has(band.name)) {
			return false;
		}

		return !this.disabledDevices.has(item.id);
	}

	protected toUrlParam(): string {
		const params = [];
		if(!this.#anyEnabled) {
			params.push('off');
		}
		for(const device of this.disabledDevices) {
			params.push('d' + device);
		}
		for(const band of this.disabledBands) {
			params.push('b' + band);
		}

		return params.join(',');
	}
}