import type { JSX } from 'preact/jsx-runtime';
import type { IconName } from './icon/icons';
import classNames from 'classnames';
import { forwardRef } from 'preact/compat';
import { Icon } from './icon';
import './text-input.scss';


const handleClickCapture = (ev: JSX.TargetedEvent<HTMLDivElement>) => {
	ev.currentTarget.querySelector('input')?.focus();
};

export type TextInputProps = Omit<JSX.IntrinsicElements['input'], 'type' | 'onInput'> & {
	icon?: IconName;
	value?: string;
	onInput?: (ev: JSX.TargetedEvent<HTMLInputElement>) => void;
	onValueChange?: (nextValue: string) => void;
	type?: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url';
};
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ className, icon, onInput, onValueChange, ...inputProps }, ref): JSX.Element => {
	const handleInput: JSX.GenericEventHandler<HTMLInputElement> = ev => {
		onInput?.(ev);
		onValueChange?.(ev.currentTarget.value);
	};

	return (
		<div
			className={ classNames('text-input', className) }
			onClick={ handleClickCapture }
		>
			{ icon && <Icon icon={ icon }/> }
			<input
				onInput={ handleInput }
				{ ...inputProps }
				ref={ ref }
			/>
		</div>
	);
});