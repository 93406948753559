import type { Overlay } from '../../services/overlay-rendering-service/overlay';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { GroundOverlay } from './google-maps';


export type MapOverlayViewProps = {
	overlay: Overlay;
};
export const MapOverlayView = ({ overlay }: MapOverlayViewProps): JSX.Element | null => {
	useObservable(overlay, 'url');

	if(!overlay.url) {
		return null;
	}
	
	return (
		<GroundOverlay
			bounds={ overlay.bounds as unknown as google.maps.LatLngBounds }
			url={ overlay.url }
		/>
	);
};