import type { FunctionComponent, JSX, VNode } from 'preact';
import type { ClassProp } from '../../util';
import classNames from 'classnames';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'preact/compat';
import { Expander } from '../expander';
import { MenuItem, MenuItemComponent } from './menu-item';
import './menu.scss';



export type MenuProps = ClassProp & {
	items: [ MenuItem, ...MenuItem[] ];
	open: boolean;
	onTransitionComplete?: (open: boolean) => void;
	onClose: () => void;
};
export const Menu = ({ className, items, open, onClose, onTransitionComplete }: MenuProps): VNode => {
	const handleClick = useCallback((item: MenuItem) => {
		if(MenuItem.isButton(item)) {
			item.onClick();
		}

		onClose();
	}, [onClose]);

	const [ hidden, setHidden ] = useState(true);
	useEffect(() => {
		if(open) {
			setHidden(false);
		}
	}, [open]);
	
	const handleTransitionComplete = useCallback((open: boolean) => {
		setHidden(!open);
		onTransitionComplete?.(open);
	}, [onTransitionComplete]);


	return (
		<div
			className={ classNames('menu', className) }
		>
			<Expander
				open={ open }
				duration="sm"
				onTransitionComplete={ handleTransitionComplete }
			>
				<div
					className="menu__items"
					hidden={ !open || hidden }
				>
					{ items.map((item, index) => (
						<MenuItemComponent item={ item } onClick={ handleClick } key={ index }/>
					))}
				</div>
			</Expander>
		</div>
	);
};

export const MenuHandler: FunctionComponent<MenuProps> = ({ children, ...menuProps }) => {
	const root = useRef<HTMLDivElement>(null);
	
	useLayoutEffect(() => {
		if(!root.current) {
			return;
		}

		const width = Array.from(root.current.getElementsByClassName('menu__item'))
			.reduce((width, el) => (
				width = Math.max(el.getBoundingClientRect().width, width)
			), 0);
		root.current.style.width = `${width}px`;
	}, [ menuProps.items ]);

	const handleKeyDown = useCallback<JSX.KeyboardEventHandler<HTMLDivElement>>(ev => {
		if(!menuProps.open || !root.current) {
			return;
		}

		let navDirection = undefined;
		switch(ev.key) {
			case 'Escape':
				menuProps.onClose();
				return;
			case ' ': {
				/*
					Let `<a>` components be triggered by space, as they are visually
					indistinct from `<button>`s here.
				*/
				if(ev.target instanceof HTMLElement && ev.target.tagName === 'A') {
					ev.target.click();
					ev.preventDefault();
				}
				return;
			}
			case 'Tab':
				// Trap focus in the menu.
				navDirection = ev.shiftKey ? -1 : 1;
				ev.preventDefault();
				break;
			case 'ArrowDown':
				navDirection = 1;
				ev.preventDefault();
				break;
			case 'ArrowUp':
				navDirection = -1;
				ev.preventDefault();
				break;
		}

		if(navDirection) {
			const items = Array.from(root.current.querySelectorAll('.menu__item')) as HTMLElement[];
			const focusedIndex = items.findIndex(item => item === document.activeElement);
			const nextFocusedIndex: number = (() => {
				let next = focusedIndex + navDirection;
				if(next >= items.length) {
					next = 0;
				}
				if(next < 0) {
					next = items.length - 1;
				}

				return next;
			})();

			items[nextFocusedIndex].focus();
		}

	}, [menuProps]);
	
	// Close when focus leaves the root container.
	useEffect(() => {
		const rootEl = root.current;

		if(!menuProps.open || !rootEl) {
			return;
		}

		const handleFocusOut = () => {
			if(!rootEl.contains(document.activeElement)) {
				menuProps.onClose();
			}
		};
		
		window.addEventListener('focusin', handleFocusOut);
		return () => { window.removeEventListener('focusin', handleFocusOut); };
	}, [menuProps]);
	
	// Close on click.
	useEffect(() => {
		if(!menuProps.open) {
			return;
		}

		const handleClick = () => {
			menuProps.onClose();
		};
		
		window.addEventListener('click', handleClick);
		return () => { window.removeEventListener('click', handleClick); };
	}, [menuProps]);
	
	return (
		<div
			className="menu-handler"
			onKeyDownCapture={ handleKeyDown }
			ref={ root }
		>
			{ children }
			<Menu { ...menuProps }/>
		</div>
	);
};