import type { PostFirstUserResult } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { UserCreationFormResult } from '../../components';
import { bound, observable, useObservable } from 'ecce-preact';
import { useEffect } from 'preact/hooks';
import { Alert, UserCreationForm, UserCreationFormController, useLoadingIndicator } from '../../components';
import { Controller, useController } from '../../util';


class SetupAdminController extends Controller {
	#loading: boolean = false;
	get loading(): boolean { return this.#loading; }
	@observable() private set loading(value: boolean) { this.#loading = value; }

	onComplete: VoidFunction | null = null;

	readonly formController: UserCreationFormController;
	constructor() {
		super();
		this.formController = new UserCreationFormController({
			self: true,
			mode: 'full',
			submitLabel: 'Create Admin',
			onSubmit: this.submit,
		});
	}

	@bound()
	async submit(result: UserCreationFormResult): Promise<void> {
		const response = await this.services.apiService.post<PostFirstUserResult>('/api/setup/first-user', result);

		if(!response.ok) {
			throw new Error('Failed to create admin user:' + response);
		}

		this.services.notificationService.notify({
			severity: 'success',
			message: <>Admin user created.<br/><strong>Welcome to Bearing!</strong></>,
		});

		this.onComplete?.();
	}
}

export type SetupAdminProps = {
	onComplete: VoidFunction;
};
export const SetupAdmin = ({ onComplete }: SetupAdminProps): JSX.Element => {
	const controller = useController(SetupAdminController);
	controller.onComplete = onComplete;
	useObservable(controller);

	const beginLoading = useLoadingIndicator();
	useEffect(() => {
		if(controller.loading) {
			return beginLoading();
		}
	}, [beginLoading, controller.loading]);

	return (
		<div className="stack-lg content content--narrow">
			<Alert
				severity="info"
				title="Create the first Bearing admin"
				icon="settings"
			/>

			<UserCreationForm controller={ controller.formController }/>
		</div>
	);
};