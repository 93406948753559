import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp, IdProp } from '../../util';
import classNames from 'classnames';
import './file-upload-input.scss';


export type FileUploadInputProps = IdProp & ClassProp & {
	accept?: string | string[];
	value?: File | null;
	onChange?: (file: File | null) => void;
	required?: boolean;
};
export const FileUploadInput = ({ id, className, accept, value, onChange, required }: FileUploadInputProps): JSX.Element => {
	const handleInput: JSX.GenericEventHandler<HTMLInputElement> = ev => {
		onChange?.(ev.currentTarget.files?.[0] ?? null);
	};

	return (
		<div className={ classNames(className, 'file-upload-input') }>
			<input
				id={ id }
				type="file"
				accept={ Array.isArray(accept) ? accept.join(',') : accept }
				value={ value ? `C:\\fakepath\\${value.name}` : '' }
				onInput={ handleInput }
				required={ required }
			/>
		</div>
	);
};