import type { DataService } from './data-service';
import type { ApiService } from './api-service';
import type { Settings, StringKeyOf } from '@brng/common';
import type { NotificationService } from './notification-service';
import type { EventCallback, EventType } from '../util';
import { makeObservable } from 'ecce-preact';
import { EventManager } from '../util';


export type SettingsServiceEvents = {
	changed: {
		key: StringKeyOf<Settings>;
		settings: Readonly<Settings>
	}
};

export type SettingsServiceDependencies = {
	dataService: DataService;
	apiService: ApiService;
	notificationService: NotificationService;
};
export class SettingsService {
	#updateId = 0;
	
	readonly #apiService: ApiService;
	readonly #notificationService: NotificationService;

	#settings: Settings | null = null;

	readonly #events = new EventManager<SettingsServiceEvents>();

	constructor(deps: SettingsServiceDependencies) {
		this.#apiService = deps.apiService;
		deps.dataService.on('data', ev => {
			this.#settings = makeObservable(ev.data.settings);
		});
		this.#notificationService = deps.notificationService;
	}
	
	#setSettings(settings: Readonly<Settings>) {
		if(!this.#settings) {
			this.#settings = makeObservable(settings);
		} else {
			for(const [ key, value ] of Object.entries(this.#settings)) {
				this.#setLocal(key as keyof Settings, value);
			}
		}
	}

	#setLocal<K extends keyof Settings>(key: K, value: Settings[K]): boolean {
		if(!this.#settings) {
			throw new Error('Settings are not yet loaded.');
		}
		
		if(this.#settings[key] !== value) {
			this.#settings[key] = value;
			return true;
		}

		return false;
	}
	
	get settings(): Readonly<Settings> {
		if(!this.#settings) {
			throw new Error('Settings are not yet loaded.');
		}

		return this.#settings;
	}

	get automaticErrorReports(): boolean {
		return this.#settings?.automaticErrorReports ?? false;
	}
	
	async update<K extends StringKeyOf<Settings>>(key: K, value: Settings[K]): Promise<void> {
		if(this.#setLocal(key, value)) {
			const updateId = ++this.#updateId;
			const response = await this.#apiService.patch<Settings>('/api/admin/settings', { [key]: value });
			if(response.ok && updateId === this.#updateId) {
				this.#setSettings(response.body);
				this.#notificationService.notify({
					severity: 'success',
					message: 'Settings updated',
					type: 'settings-updated',
				});
				this.#events.notify('changed', { key, settings: this.settings });
			}
		}
	}

	on<E extends EventType<SettingsServiceEvents>>(event: E, handler: EventCallback<SettingsServiceEvents, E>): this {
		this.#events.on(event, handler);
		return this;
	}

	off<E extends EventType<SettingsServiceEvents>>(event: E, handler: EventCallback<SettingsServiceEvents, E>): this {
		this.#events.off(event, handler);
		return this;
	}
}