import type { PostRepositoryResponse, RepositoryConfig, RepositoryProviderMetadata } from '@brng/common';
import type { DataService } from '../data-service';
import type { NotificationService } from '../notification-service';
import { shallowDiffObject } from '@brng/common';
import { bound, observable } from 'ecce-preact';
import { ApiResponse, type ApiService } from '../api-service';


export type RepositoryServiceDependencies = {
	apiService: ApiService;
	dataService: DataService;
	notificationService: NotificationService;
};

export class RepositoryService {
	readonly #apiService: ApiService;
	readonly #dataService: DataService;
	readonly #notificationService: NotificationService;

	#metadata: RepositoryProviderMetadata | null = null;
	get metadata(): RepositoryProviderMetadata | null { return this.#metadata; }
	@observable() private set metadata(value: RepositoryProviderMetadata) { this.#metadata = value; }

	#configuration: RepositoryConfig | null = null;
	get configuration(): RepositoryConfig | null { return this.#configuration; }
	@observable() private set configuration(value: RepositoryConfig | null) { this.#configuration = value; }

	constructor(deps: RepositoryServiceDependencies) {
		this.#dataService = deps.dataService;
		this.#apiService = deps.apiService;
		this.#notificationService = deps.notificationService;

		this.#dataService.on('data', ev => {
			this.metadata = ev.data.providerMetadata;
		});
	}

	async loadConfiguration() {
		this.configuration = null;
		const response = await this.#apiService.get<RepositoryConfig>('/api/admin/repository.json');
		if(!response.ok) {
			return;
		}

		this.configuration = response.body;
	}

	async setupConfiguration(configuration: RepositoryConfig): Promise<PostRepositoryResponse.Ok | PostRepositoryResponse.Error> {
		const response = await this.#apiService.post('/api/setup/repository', configuration);
		if(!response.ok) {
			throw new Error('Failed to setup repository: ' + ApiResponse.toStatusString(response));
		}
		const body = response.body as PostRepositoryResponse;
		if(!body.ok && body.reason === 'not-needed') {
			throw new Error('Attempted to setupConfiguration while already configured.');
		}

		return body;
	}

	@bound()
	async updateConfiguration(nextConfiguration: Partial<RepositoryConfig>): Promise<PostRepositoryResponse | null> {
		if(!this.configuration) {
			return null;
		}

		const patch = shallowDiffObject(this.configuration, nextConfiguration);
		if(!patch) {
			return null;
		}

		const response = await this.#apiService.patch<PostRepositoryResponse>('/api/admin/repository', patch);
		if(response.ok && response.body.ok) {
			this.#notificationService.notify({
				severity: 'success',
				type: 'updateConfiguration',
				message: `${this.metadata?.provider ?? 'Provider'} configuration updated.`,
			});
			await this.loadConfiguration();
			return response.body;
		}

		if(!response.ok && response.errorType === 'network') {
			return null;
		}

		return response.body as PostRepositoryResponse;
	}
}