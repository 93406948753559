import type { JSX } from 'preact/jsx-runtime';
import './map-slider.scss';
import type { ClassProp } from '../../../util';
import classNames from 'classnames';


export type MapSliderProps = ClassProp & {
	id: string;
	label: string;
	min: number;
	max: number;
	value: number;
	onChange: (value: number) => void;
	formatValue?: (value: number) => string;
};
export const MapSlider = ({ id, className, label, min, max, value, onChange, formatValue }: MapSliderProps): JSX.Element => {
	const handleChange: JSX.GenericEventHandler<HTMLInputElement> = ev => {
		const value = parseFloat(ev.currentTarget.value);
		if(!Number.isFinite(value)) {
			return;
		}
		onChange(value);
	};
	
	return (
		<div className={ classNames(className, 'map-slider') }>
			<label htmlFor={ id }>
				{ label }
			</label>
			<input
				id={ id }
				type="range"
				min={ min }
				max={ max }
				value={ value }
				onChange={ handleChange }
			/>
			<output for={ id }>
				{ formatValue?.(value) ?? value }
			</output>
		</div>
	);
};