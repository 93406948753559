import type { BandName } from '@brng/common';
import type { Device, FrequencyOverlapIssue, Site } from '@brng/domain';
import type { VNode } from 'preact';
import { FrequencyBand } from '@brng/common';
import { useEffect, useMemo, useRef } from 'preact/hooks';
import { SpectrumGraph, SpectrumGraphPopup, SpectrumGraphView } from '../../../../components/spectrum/spectrum-graph';
import { useServices } from '../../../../services';
import { useSitesInfo } from '../../use-sites-info';


type SpectrumProps = {
	frequencyBand: BandName,
	equipment: Device[],
	site: Site;
	overlaps: FrequencyOverlapIssue[];
};
export const SiteSpectrum = ({ frequencyBand, site, overlaps }: SpectrumProps): VNode => {
	const { focusedEquipmentSet, equipmentFilter } = useSitesInfo();
	const { squelchService } = useServices();
	
	const graph = useMemo<SpectrumGraph>(() => {
		return new SpectrumGraph({
			equipment: site.devices,
			frequencyBand: FrequencyBand.fromBand(frequencyBand),
			overlaps,
		}, {
			equipmentFilter,
			focusedEquipment: focusedEquipmentSet,
			squelchService,
		});
	}, [equipmentFilter, focusedEquipmentSet, frequencyBand, overlaps, site.devices, squelchService]);
	useEffect(() => () => graph.dispose(), [graph]);

	const graphRoot = useRef<HTMLDivElement>(null);

	return (
		<>
			<SpectrumGraphView graph={ graph } ref={ graphRoot } hasPopup/>
			<SpectrumGraphPopup graph={ graph } anchor={ graphRoot }/>
		</>
	);
};