import type { MapController } from './map-controller';
import type { ChildrenProp } from '../util';
import type { JSX } from 'preact';
import { createContext } from 'preact';
import { useContext } from 'preact/hooks';


const mapControllerContext = createContext<MapController | null>(null);

export type MapControllerProviderProps = ChildrenProp & {
	controller: MapController;
};
export const MapControllerProvider = ({ controller, children }: MapControllerProviderProps): JSX.Element => (
	<mapControllerContext.Provider value={ controller }>
		{ children }
	</mapControllerContext.Provider>
);

/**
 * Get the MapController provided by a `<MapView>`.
 *
 * @throws if the component is not a descendant of `<MapView>`.
 */
export const useMapController = (): MapController => {
	const controller = useContext(mapControllerContext);
	if(!controller) {
		throw new Error('MapController was null; ensure this component must be a descendent of <MapView>.');
	}

	return controller;
};