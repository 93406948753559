import type { Device } from '../device';
import { DeviceGeometry } from '../../util';
import { OutOfSectorIssue } from '.';


export function findOutOfSectorIssues(device: Device): void {
	if(device.type === 'Access Point' &&
			device.antennaType === 'Sector' &&
			Number.isFinite(device.azimuth) &&
			Number.isFinite(device.sectorSize)
	) {
		for(const customer of device.customers) {
			const equipmentGeometry = new DeviceGeometry(device);
			const percent = equipmentGeometry.percentageOutsideSector(customer.location);

			if(percent === 0) {
				return;
			}

			new OutOfSectorIssue({ customer, device, percent });
		}
	}
}
