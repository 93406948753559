import type { User } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { UsersAdminController } from './users-admin-controller';
import { useObservable } from 'ecce-preact';
import { Copyable, Dialog, DialogHeader } from '../../../components';
import { useActiveUser } from '../../../services';
import { makeMailtoHref, useLatch } from '../../../util';


const getMailtoHref = (args: { activeUser: User, url: string, invitedUser: User}): string => (
	makeMailtoHref({
		to: args.invitedUser.email,
		subject: 'Invitation to Bearing',
		body: `
Hi, ${args.invitedUser.name},

Here is your link to get started with Bearing:

${args.url}

${args.activeUser.name}
		`,
	})
);

export const ShowInvitationDialog = (p: { controller: UsersAdminController }): JSX.Element => {
	const activeUser = useActiveUser();
	useObservable(p.controller);

	const showInvitation = useLatch(
		p.controller.dialogMode?.kind === 'showInvitation'
			? p.controller.dialogMode
			: null
	);

	return (
		<Dialog
			open={ p.controller.dialogMode?.kind === 'showInvitation' }
			onClose={ p.controller.closeDialog }
			narrow
		>
			{ showInvitation && (
				<>
					<DialogHeader
						title={ `Invite ${showInvitation.user.name} to Bearing` }
						onClose={ p.controller.closeDialog }
					/>
					<main className="stack-md">
						<p className="mrg-0">
							An invite link has been created for <strong>{ showInvitation.user.name }</strong> to allow them
							to complete the sign up process.
						</p>
						<p className="mrg-b-0">
							Share this link with them, or&nbsp;
							<a
								href={ getMailtoHref({
									activeUser,
									url: showInvitation.url,
									invitedUser: showInvitation.user,
								}) }
								onClick={ p.controller.closeDialog }
							>
								send it via email
							</a>.
						</p>

						<Copyable className="hl pad-sm mrg-h-auto text-sm" value={ showInvitation.url }>
							<div className="pad-sm">
								{ showInvitation.url }
							</div>
						</Copyable>
					</main>
					<footer>
						<button
							className="btn btn--invisible mrg-l-auto"
							onClick={ p.controller.closeDialog }
						>
							OK
						</button>
					</footer>
				</>
			)}
		</Dialog>
	);
};