import type { BandName } from '@brng/common';
import type { Device, Site } from '@brng/domain';
import type { ObservableSet } from 'ecce-preact';
import type { VNode } from 'preact';
import type { JSXInternal } from 'preact/src/jsx';
import type { EquipmentFilter } from '../../../../filtering';
import { notNullish } from '@brng/common';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useCallback, useMemo } from 'preact/hooks';
import { useData } from '../../../../data';
import { useServices } from '../../../../services';
import { useSitesInfo } from '../../use-sites-info';
import './site-image.scss';
import { useSiteDetails } from './use-site-details';


const useDynamicCss = ({ devices }: Site): string => (
	useMemo(() => [
		// Show / hide text by selection.
		...devices.map(eq => `
			section.eq-${eq.id} .eq-text.eq-${eq.id} {
				opacity: 1;
				transition: opacity var(--fade-fast);
			}
		`),
	
		// De-emphasise non-hovered equipment arcs.
		...devices.map(eq => `
			section.eq-${eq.id} circle.eq-arc:not(.eq-${eq.id}) {
				opacity: 0.6;
				transition: opacity var(--fade-fast);
			}
			section.eq-${eq.id} circle.eq-base:not(.eq-${eq.id}) {
				opacity: 0.2;
				transition: opacity var(--fade-fast);
			}
		`),
	
		// Don't de-emphasise hovered equipment
		...devices.map(eq => `
			section.eq-${eq.id} circle.eq-arc.eq-${eq.id}, section.eq-${eq.id} circle.eq-base.eq-${eq.id} {
				opacity: 1 !important;
			}
		`),
	
		// Highlight hovered equipment arc masks.
		...devices.map(eq => `
			section.eq-${eq.id} .eq-base.eq-${eq.id} {
				fill: var(--color-hl-transparent);
			}
			section.eq-${eq.id} .data-row.eq-${eq.id} {
				background-color: var(--color-hl);
			}
			section.eq-${eq.id} .data-danger.eq-${eq.id} {
				background-color: var(--color-hl-danger);
			}
			section.eq-${eq.id} .spectrum .eq-${eq.id} {
				box-shadow: var(--spectrum-hover-bs);
				z-index: var(--spectrum-hover-z);
			}
		`),
	].join('\n'), [devices])
);


const getSectionClassName = (focusedEquipmentSet: ObservableSet<Device>, frequencyBand: string, equipmentFilter: EquipmentFilter): string => {
	const equipmentFocusClasses = focusedEquipmentSet
		.filter(eq => eq.radios.some(r => r.band?.band === frequencyBand))
		.map(({ id }) => id)
		.map(eqId => `eq-${eqId}`);

	const filterClassNames = {
		'eq-filter-bh': !equipmentFilter.showBackhauls,
		'eq-filter-ap': !equipmentFilter.showAccessPoints,
		'eq-filter-sm': !equipmentFilter.showSubscriberModules,
	};

	return classNames(equipmentFocusClasses, filterClassNames);
};

type SiteImageProps = { frequencyBand: BandName };
export const SiteImage = ({ frequencyBand }: SiteImageProps): VNode => {
	const { getEquipment } = useData();
	const { focusedEquipmentSet, equipmentFilter } = useSitesInfo();
	useObservable(equipmentFilter);
	useObservable(focusedEquipmentSet);
	const { site } = useSiteDetails();

	const { overlayRenderingService } = useServices();
	const focusedEquipmentRef = focusedEquipmentSet.ref;
	const svg = useMemo(() => {
		focusedEquipmentRef;
		const [ svg ] = overlayRenderingService.devicesSVGs({
			devices: site.devices,
			bandsToRender: [ frequencyBand ],
			overlayRadius: 1,
		});
		return {
			__html: svg,
		};
	}, [focusedEquipmentRef, overlayRenderingService, site.devices, frequencyBand]);

	const dynamicCss = useDynamicCss(site);
	
	const handleMouseMove = useCallback<JSXInternal.MouseEventHandler<HTMLElement>>(ev => {
		// Set focused equipment from <use> elements underneath the mouse cursor.
		focusedEquipmentSet.replace(
			document
				.elementsFromPoint(ev.clientX, ev.clientY)
				.filter(el => el instanceof SVGUseElement)
				.map(el => {
					const eqClass = Array.from(el.classList)
						.find(cls => cls.match(/^eq-[0-9]/));

					if(!eqClass) {
						return null;
					}

					const eqId = eqClass.replace('eq-', '');
					return getEquipment(eqId);
				})
				.filter(notNullish)
		);
	}, [focusedEquipmentSet, getEquipment]);
	
	const handleMouseOut = () => focusedEquipmentSet.clear();
	
	
	return (
		<div className="site-image">
			<style>{ dynamicCss }</style>
			<section
				className={ getSectionClassName(focusedEquipmentSet,frequencyBand, equipmentFilter) }
				onMouseMove={ handleMouseMove }
				onMouseOut={ handleMouseOut }
				dangerouslySetInnerHTML={ svg }
			/>
		</div>
	);
};
