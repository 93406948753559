import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp, IdProp } from '../../util';
import './aside.scss';
import classNames from 'classnames';
import { Icon, type IconName } from './icon';


type AsideProps = IdProp & ClassProp & ChildrenProp & {
	innerClassName?: string;
	icon?: IconName;
	title: string | JSX.Element;
};
export const Aside = (p: AsideProps): JSX.Element => {
	return (
		<aside id={ p.id } className={ classNames(p.className, 'aside') }>
			<Icon className="aside__icon" icon={ p.icon ?? 'help' }/>
			<div className="aside__title text-bolder">
				{p.title}
			</div>
			<div className="aside__bar"/>
			<div className={ p.innerClassName }>
				{ p.children}
			</div>
		</aside>
	);
};
