/*
	Work-around for the dialog-polyfill module accessing `window` & other browser
	globals which will not be available during testing.
*/

export type DialogPolyfill = {
	registerDialog: (el: HTMLElement) => void;
};

let polyfill: DialogPolyfill;
if(process.env.NODE_ENV !== 'testing') {
	polyfill = require('dialog-polyfill').default; // eslint-disable-line @typescript-eslint/no-var-requires
} else {
	polyfill = {
		registerDialog: () => { /**/ },
	};
}

export const dialogPolyfill = polyfill;