import type { Bounds, Point, SiteRaw } from '@brng/common';
import type { Network } from './network';
import type { FrequencyOverlapIssue, NetworkIssue } from './issues/issues';
import type { ReadonlyIssueSet } from './issues/issue-set';
import { asBounds, asPoint, notNullish } from '@brng/common';
import { Device } from './device';
import { getNearbyDevices } from './site-util';
import { IssueSet } from './issues/issue-set';


export type SiteUsage = 'Infrastructure' | 'Customer';
export type SiteSource = Readonly<{
	id: string;
	type: string;
}>;
export class Site {
	readonly #network: Network;

	/**
	 * Unique ID for the Site in the Network.
	 */
	readonly networkId: `site-${string}`;
	readonly kind = 'site' as const;
	
	readonly id: string;
	readonly source: SiteSource | null;
	readonly usage: SiteUsage;
	readonly link: string | null;
	readonly name: string;
	readonly type: string;
	// TODO(#577): Deprecate this.
	readonly latitude: number;
	// TODO(#577): Deprecate this.
	readonly longitude: number;
	readonly location: Readonly<Point>;

	readonly devices: readonly Device[];
	#parentDevices: readonly Device[] | null = null;
	#customers: readonly CustomerSite[] | null = null;

	#nearbyDevices: readonly Device[] | null = null;

	#issues = new IssueSet();

	constructor(network: Network, site: Readonly<SiteRaw>) {
		this.#network = network;
		this.networkId = `site-${site.id}`;
		this.id = site.id;
		this.source = site.source ?? null;
		this.usage = site.usage;
		this.link = site.link;
		this.name = site.name;
		this.type = site.type;
		this.latitude = site.latitude;
		this.longitude = site.longitude;
		this.location = Object.freeze(asPoint(site.latitude, site.longitude));

		this.devices = Object.freeze(
			site.devices.map(eq => new Device(this, eq))
		);
	}

	/**
	 * Internal method called by {@link Network}; do not use.
	 */
	initialise(_network: Network): void {
		if(this.usage === 'Customer') {
			this.#parentDevices = this.devices.map(d => d.parent).filter(notNullish);
		} else {
			const customers = new Set<CustomerSite>() ;
			for(const device of this.devices) {
				if(device.children) {
					for(const child of device.children) {
						customers.add(child.site as CustomerSite);
					}
				}
			}
			this.#customers = [ ...customers ];
		}
	}

	get bounds(): Bounds {
		return asBounds([ this.location ]);
	}

	get parentDevices(): readonly Device[] | null {
		return this.#parentDevices;
	}

	get customers(): readonly CustomerSite[] | null {
		return this.#customers;
	}

	findLinkingDevice(otherSite: Site): Device | null {
		if(otherSite === this) {
			return null;
		}

		if(this.parentDevices) {
			for(const device of this.parentDevices) {
				if(device.site === otherSite) {
					return device;
				}
			}
		}
		
		for(const device of this.devices) {
			if(device.parent?.site === otherSite) {
				return device.parent;
			}
		}

		return null;
	}

	/**
	 * The ID of the site represented in the user's Repository.
	 */
	get sourceId(): string {
		return this.source?.id ?? this.id;
	}
	
	/**
	 * The ID of the site represented in the user's Repository, formatted for
	 * display in the UI.
	 */
	get displayId(): string {
		if(this.source) {
			return this.source.type + ': ' + this.source.id;
		}

		return this.id;
	}

	get alternativeDevices(): readonly Device[] {
		if(this.#nearbyDevices) {
			return this.#nearbyDevices;
		}

		if(this.usage === 'Infrastructure') {
			return this.#nearbyDevices = [];
		}

		return this.#nearbyDevices = getNearbyDevices(this as CustomerSite, this.#network.infrastructureSites)
			.filter(d => !this.parentDevices?.includes(d));
	}

	get frequencyOverlaps(): readonly FrequencyOverlapIssue[] {
		return this.frequencyOverlaps;
	}

	private addIssue(issue: NetworkIssue): void {
		this.#issues.add(issue);
		this.#network['addIssue'](issue);
	}

	get issues(): ReadonlyIssueSet {
		return this.#issues;
	}
}

export type InfrastructureSite = Site & { usage: 'Infrastructure' };
export const isInfrastructureSite = (site: Site): site is InfrastructureSite => (
	site.usage === 'Infrastructure'
);

export type CustomerSite = Site & { usage: 'Customer' };
export const isCustomerSite = (site: Site): site is CustomerSite => (
	site.usage === 'Customer'
);