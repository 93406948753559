import type { Bounds, Point } from '@brng/common';
import type { EventCallback, EventType } from '../util';
import type { UrlSearchParamController } from '../util/url-search-param-controller';
import { asPoint, earth, notNullish } from '@brng/common';
import { type NetworkItem } from '@brng/domain';
import { bound, observable } from 'ecce-preact';
import { EventManager, MapUrl, prefersReducedMotion } from '../util';



export type MapClickEvent = Readonly<{
	item: NetworkItem;
}>;

export type MapControllerEvents = {
	click: MapClickEvent;
	loaded: { map: google.maps.Map };
};

export type MapPalette = Readonly<{
	theme: 'light' | 'dark';
	text: string;
	line: string;
}>;
export type MapTheme = MapPalette['theme'];
const LIGHT_PALETTE: MapPalette = {
	theme: 'light',
	text: '#fff',
	line: 'rgba(255,255,255,0.6)',
};
const DARK_PALETTE: MapPalette = {
	theme: 'dark',
	text: '#000',
	line: 'rgba(127,127,127,0.8)',
};

export type OverlayRadius = 'actual' | number;

export type MapControllerConfig = {
	/**
	 * Initial center-point of the map.
	 *
	 * @default [0,0]
	 */
	center?: Point;

	/**
	 * Initial zoom factor of the map.
	 *
	 * @default 8
	 */
	zoom?: number;

	/**
	 * Initial overlay radius.
	 *
	 * @default 'actual'
	 */
	overlayRadius?: OverlayRadius;

	/**
	 * If passed, the map will use & update the URL search parameters.
	 */
	urlParams?: UrlSearchParamController;
};

export type MapControllerSetCenterOptions = {
	/**
	 * Scroll smoothly to the target location.
	 *
	 * @default true
	 */
	smooth?: boolean;
};

export class MapController {
	static readonly #DEFAULT_ZOOM = 10;
	static readonly #DEFAULT_RADIUS = 3000;
	static readonly #DISTANCE_THRESHOLD = 5;
	static readonly #SMOOTH_SCROLL_ALPHA = .25;

	#smoothScrollTarget: Point | null = null;
	#smoothScrollFrameId: ReturnType<typeof requestAnimationFrame> | null = null;

	#bounds: Bounds | null = null;

	#center: Point;
	get center(): Point { return this.#center; }
	@observable() private set center(value: Point) { this.#center = value; }

	#zoom: number;
	get zoom(): number { return this.#zoom; }
	@observable() private set zoom(value: number) { this.#zoom = value; }
	
	#palette: MapPalette = DARK_PALETTE;
	get palette(): MapPalette { return this.#palette; }
	@observable() private set palette(value: MapPalette) { this.#palette = value; }
	
	#overlayRadius: OverlayRadius;
	get overlayRadius(): OverlayRadius { return this.#overlayRadius; }
	@observable() private set overlayRadius(value: OverlayRadius) { this.#overlayRadius = value; }
	
	#map: google.maps.Map | null = null;
	get map(): google.maps.Map | null { return this.#map; }
	@observable() private set map(value: google.maps.Map | null) { this.#map = value; }

	readonly #urlParams: UrlSearchParamController | null;
	readonly #mapListeners = new Set<google.maps.MapsEventListener | undefined>();
	readonly #events = new EventManager<MapControllerEvents>();
	
	constructor(config: MapControllerConfig = {}) {
		this.#urlParams = config.urlParams ?? null;
		this.#center = MapUrl.parseCenter(this.#urlParams?.getString(MapUrl.PARAM_CENTER)) ?? config.center ?? asPoint(0, 0);
		this.#zoom = this.#urlParams?.getNumber(MapUrl.PARAM_ZOOM) ?? config.zoom ?? MapController.#DEFAULT_ZOOM;
		this.#overlayRadius = MapUrl.parseRadius(this.#urlParams?.getString(MapUrl.PARAM_RADIUS)) ?? config.overlayRadius ?? MapController.#DEFAULT_RADIUS;
	}

	/**
	 * Set the Map's center position.
	 */
	setCenter(point: Readonly<Point>, options: MapControllerSetCenterOptions = {}): this {
		// Reset any existing smooth scrolling.
		if(this.#smoothScrollFrameId) {
			cancelAnimationFrame(this.#smoothScrollFrameId);
			this.#smoothScrollFrameId = null;
		}
		this.#smoothScrollTarget = null;
		
		if(!this.#map) {
			this.center = point;
			return this;
		}

		const smooth = !prefersReducedMotion() && (options.smooth ?? true);
		if(!smooth) {
			this.#map.setCenter(point);
			return this;
		}

		this.#smoothScrollTarget = point;
		this.#smoothScrollFrameId = requestAnimationFrame(this.#smoothScrollFrame);
		
		return this;
	}

	readonly #smoothScrollFrame = () => {
		if(!this.#map || !this.#smoothScrollTarget) {
			return;
		}

		const previous = this.#map.getCenter()?.toJSON();
		if(!previous) {
			return;
		}
		
		const stepDistance = earth.distance(previous, this.#smoothScrollTarget) * MapController.#SMOOTH_SCROLL_ALPHA;
		const azimuth = earth.azimuth(previous, this.#smoothScrollTarget);

		if(stepDistance < MapController.#DISTANCE_THRESHOLD) {
			// You have reached your destination.
			this.#map.setCenter(this.#smoothScrollTarget);
			this.#smoothScrollTarget = null;
			return;
		}

		const next: Point = earth.point(previous, stepDistance, azimuth);

		this.#map.setCenter(next);
		this.#smoothScrollFrameId = requestAnimationFrame(this.#smoothScrollFrame);
	};

	/**
	 * Set the Map's zoom level.
	 */
	setZoom(zoom: number): this {
		if(!this.#map) {
			this.#zoom = zoom;
		} else {
			this.#map.setZoom(zoom);
		}

		return this;
	}

	setBounds(bounds: Bounds): this {
		if(!this.#map) {
			this.#bounds = bounds;
			return this;
		}

		this.#map.fitBounds(bounds);
		return this;
	}

	setOverlayRadius(overlayRadius: OverlayRadius): this {
		if(overlayRadius !== this.overlayRadius) {
			this.overlayRadius = overlayRadius;
			this.#urlParams?.setString(MapUrl.PARAM_RADIUS, MapUrl.stringifyRadius(this.overlayRadius), MapUrl.stringifyRadius(MapController.#DEFAULT_RADIUS));
		}

		return this;
	}

	/**
	 * Called from the <MapView> component when the Map is loaded.
	 */
	@bound()
	private handleLoad(map: google.maps.Map) {
		if(map === this.map) {
			return;
		}

		this.dispose();
		
		this.map = map;
		this.map.setCenter(this.#center);
		this.map.setZoom(this.#zoom);

		// https://developers.google.com/maps/documentation/javascript/reference/map#Map-Events
		this.#mapListeners
			.add(this.map.addListener('bounds_changed', () => {
				if(!this.#map) {
					return;
				}

				if(this.#bounds) {
					this.#map.fitBounds(this.#bounds);
					this.#bounds = null;
				} else {
					const mapCenter = this.#map.getCenter()?.toJSON();
					if(mapCenter && earth.distance(this.center, mapCenter) > MapController.#DISTANCE_THRESHOLD) {
						this.center = mapCenter;
						this.#urlParams?.setString(MapUrl.PARAM_CENTER, MapUrl.stringifyCenter(mapCenter));
					}

					const mapZoom = this.#map.getZoom();
					if(notNullish(mapZoom) && this.#zoom !== mapZoom) {
						this.zoom = mapZoom;
						this.#urlParams?.setNumber(MapUrl.PARAM_ZOOM, mapZoom, MapController.#DEFAULT_ZOOM);
					}
				}
			}))
			.add(this.map.addListener('maptypeid_changed', () => {
				if(!this.#map) {
					return;
				}

				const nextPalette = MapController.#palletForMapTypeId(this.#map.getMapTypeId());
				if(nextPalette !== this.palette) {
					this.palette = nextPalette;
				}
			}));

		this.#events.notify('loaded', { map: this.map });
	}

	/**
	 * Called from network item components when they are clicked.
	 */
	@bound()
	private handleItemClicked(item: NetworkItem): void {
		this.#events.notify('click', { item });
	}

	/**
	 * Called from the <MapView> component when the Map is unmounted.
	 */
	@bound()
	private handleUnmount(): void {
		this.#mapListeners.forEach(listener => listener?.remove());
		this.#mapListeners.clear();
		this.#map = null;
	}

	on<E extends EventType<MapControllerEvents>>(event: E, listener: EventCallback<MapControllerEvents, E>): this {
		this.#events.on(event, listener);
		return this;
	}

	off<E extends EventType<MapControllerEvents>>(event: E, listener: EventCallback<MapControllerEvents, E>): this {
		this.#events.off(event, listener);
		return this;
	}

	dispose() {
		this.handleUnmount();
	}

	static #palletForMapTypeId(id: string | null | undefined): MapPalette {
		switch(id?.toLowerCase()) {
			case 'hybrid':
			case 'satellite':
				return LIGHT_PALETTE;
			default:
				return DARK_PALETTE;
		}
	}
}
