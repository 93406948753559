import type { VNode } from 'preact';
import type { SliderProps } from '../../../components';
import { useCallback } from 'preact/hooks';
import { Slider } from '../../../components';
import { useAnalysisIssues } from '../analysis-provider';


const steps: SliderProps['steps'] = [ 4, 6, 8, 10, 12, 14, 16 ];
const formatValue: SliderProps['formatValue'] = value => `${value}km`;

export const OutOfRangeToleranceSlider = (): VNode => {
	const { analysisIssuesFilter: { outOfRangeTolerance }, updateAnalysisIssuesFilter } = useAnalysisIssues();

	const handleChange = useCallback<SliderProps['onChange']>(value => {
		updateAnalysisIssuesFilter({ outOfRangeTolerance: value });
	}, [ updateAnalysisIssuesFilter ]);
	
	return (
		<Slider
			id="outOfRangeToleranceSlider"
			label='Leniency'
			steps={ steps }
			formatValue={ formatValue }
			slim
			value={ outOfRangeTolerance }
			onChange={ handleChange }
		/>
	);
};