import type { PostRepositoryResponse, RepositoryConfig_Powercode } from '@brng/common';
import type { FunctionComponent, JSX } from 'preact';
import type { PowercodeConnectionConfig } from '../../../config';
import type { AdminTabId } from '../admin-tabs';
import { useEffect, useState } from 'preact/hooks';
import { Spinner, useLoadingIndicator } from '../../../components';
import { PowercodeConnectionConfigForm } from '../../../config';
import { PowercodeCustomFieldsView } from '../../../config/powercode/custom-fields';
import { RepositoryErrorAlert } from '../../../config/repository-config-error-alert';
import { useServices } from '../../../services';
import { useAdminTabs } from '../admin-tabs-context';
import { AdminTabContent } from '../components/admin-tab-content';


type PowercodeTabProps = {
	tabId: AdminTabId;
	currentConfig: RepositoryConfig_Powercode | null;
};
const PowercodeTab: FunctionComponent<PowercodeTabProps> = ({ tabId, currentConfig, children }) => {
	return (
		<AdminTabContent tabId={ tabId }>
			{ !currentConfig && <Spinner/> }
			{ currentConfig && children }
		</AdminTabContent>
	);
};

export type PowercodeAdminProps = {
	configuration: RepositoryConfig_Powercode;
};
export const PowercodeAdmin = ({ configuration }: PowercodeAdminProps): JSX.Element => {
	const { activeAdminTabId } = useAdminTabs();
	const { repositoryService } = useServices();
	
	const [ errorResponse, setErrorResponse ] = useState<PostRepositoryResponse | null>(null);
	// Reset error response on admin tab change.
	useEffect(() => {
		setErrorResponse(null);
	}, [ activeAdminTabId ]);

	const beginLoading = useLoadingIndicator();
	const handleSubmit = async (nextConfiguration: Partial<RepositoryConfig_Powercode>) => {
		const endLoading = beginLoading();
		try {
			setErrorResponse(
				await repositoryService.updateConfiguration(nextConfiguration)
			);
		} finally {
			endLoading();
		}
	};
	return (
		<>
			{ errorResponse && (
				<div className="mrg-b-lg">
					<RepositoryErrorAlert response={ errorResponse } providerName="Powercode"/>
				</div>
			)}

			<PowercodeTab tabId="repository-connection" currentConfig={ configuration }>
				<PowercodeConnectionConfigForm
					formClassName="panel"
					onSubmit={ handleSubmit }
					initialConfig={ repositoryService.configuration as PowercodeConnectionConfig }
				/>
			</PowercodeTab>

			<PowercodeTab tabId="powercode-custom-fields" currentConfig={ configuration }>
				<PowercodeCustomFieldsView
					onSubmit={ handleSubmit }
					initialConfig={ configuration }
				/>
			</PowercodeTab>
		</>
	);
};