import type { Services } from './services';
import type { JSX } from 'preact';
import type { ChildrenProp } from '../util';
import type { DataService } from './data-service';
import type { NetworkService } from './network-service';
import type { Network } from '@brng/domain';
import type { ErrorService } from './error-service';
import type { SettingsService } from './settings-service';
import type { NotificationService } from './notification-service';
import type { RepositoryProvider, Squelch, User } from '@brng/common';
import { createContext } from 'preact';
import { useCallback, useContext } from 'preact/hooks';
import { observableRef, useObservable } from 'ecce-preact';
import { NullContextError } from '../util';


const servicesContext = createContext<Services | null>(null);

export type ServiceProviderProps = ChildrenProp & {
	services: Services;
};
/**
 * Provides the {@link Services} to the Preact application.
 *
 * @see {@link useServices()}
 * @see {@link useDataService()}
 * @see {@link useNetworkService()}
 */
export const ServiceProvider = ({ services, children }: ServiceProviderProps): JSX.Element => (
	<servicesContext.Provider value={ services }>
		{ children }
	</servicesContext.Provider>
);

/**
 * Get the {@link Services}.
 *
 * @throws If there is no {@link ServiceProvider}.
 */
export const useServices = (): Services => {
	const ctx = useContext(servicesContext);
	if(!ctx) {
		throw new NullContextError('servicesContext', 'ServiceProvider');
	}

	return ctx;
};

/**
 * Get the {@link ErrorService}.
 *
 * @throws If there is no {@link ServiceProvider}.
 */
export const useErrorService = (): ErrorService => useServices().errorService;

/**
 * Get the {@link DataService}.
 *
 * @throws If there is no {@link ServiceProvider}.
 */
export const useDataService = (): DataService => useServices().dataService;

/**
 * Get the {@link NetworkService}.
 *
 * @throws If there is no {@link ServiceProvider}.
 */
export const useNetworkService = (): NetworkService => useServices().networkService;

export const useNetwork = (): Network | null => {
	const networkService = useServices().networkService;
	useObservable(networkService, 'network');

	return networkService.network;
};

export const useSettingsService = (): SettingsService => useServices().settingsService;

export const useNotificationService = (): NotificationService => useServices().notificationService;


export const useActiveUser = (): User => {
	const { authService } = useServices();
	useObservable(authService, 'activeUser');

	if(!authService.activeUser) {
		throw new Error('activeUser was null.');
	}

	return authService.activeUser;
};

export const useAdmin = (): boolean => {
	const { authService } = useServices();
	useObservable(authService, 'admin');

	return authService.admin;
};

export const useRepositoryProviderName = (): RepositoryProvider | '' => {
	const { repositoryService } = useServices();
	useObservable(repositoryService, 'metadata');

	return repositoryService.metadata?.provider ?? '';
};

export const useSquelch = (kind: string, issueId: string): Readonly<Squelch | null> => {
	const { squelchService } = useServices();
	useObservable(squelchService, 'squelches');

	return squelchService.getSquelch(kind, issueId);
};

export const useIsSquelched = (): ((kind: string, issueId: string) => boolean) => {
	const { squelchService } = useServices();
	useObservable(squelchService, 'squelches');

	return useCallback((kind: string, issueId: string) => {
		return squelchService.isSquelched(kind, issueId);
	}, [observableRef(squelchService, 'squelches')]); // eslint-disable-line react-hooks/exhaustive-deps
};