import type { Site } from '@brng/domain';
import { useMemo } from 'preact/hooks';
import { useServices } from '../../services';
import { useMapController } from '../map-controller-context';
import { MapDeviceOverlay } from './map-device-overlay';
import { WirelessRangeWarning } from './wireless-range-warning';


const WIRELESS_RANGE_WARNING_THRESHOLD = 12;

export type MapCoverageOverlayProps = {
	sites: readonly Site[]
};
export const MapCoverageOverlay = ({ sites }: MapCoverageOverlayProps) => {
	const { overlayRenderingService } = useServices();
	const { palette } = useMapController();

	const overlay = useMemo(() => (
		overlayRenderingService.coverageOverlay(sites as Site[], palette.theme === 'light')
	), [overlayRenderingService, palette.theme, sites]);

	const showWarning = useMemo<boolean>(() => {
		const devices = sites
			.flatMap(s => s.devices)
			.filter(d => d.type === 'Access Point');

		if(!devices.length) {
			return false;
		}
		let numMissingWirelessRange = 0;
		for(const device of devices) {
			if(!device.range && !device.suppressWarnings) {
				if(++numMissingWirelessRange > WIRELESS_RANGE_WARNING_THRESHOLD) {
					return true;
				}
			}
		}

		return false;
	}, [sites]);
	
	return (
		<>
			<MapDeviceOverlay overlay={ overlay }/>
			<WirelessRangeWarning show={ showWarning }/>
		</>
	);
};