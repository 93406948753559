import type { HttpClient, HttpRequest, HttpResponse } from './http-client';
import type { EventCallback, EventType } from '../../util';
import { asError } from '@brng/common';
import { BearingError } from '../error-service/bearing-error';
import { HttpStatusCode } from '../../util/http';
import { AuthError } from '../auth-service';
import { EventManager } from '../../util';


export type ApiPath = (
	| '/api/admin/settings'
	| '/api/admin/repository'
	| '/api/admin/repository.json'
	| `/api/admin/users/invite/${string}`
	| `/api/admin/users/${string}/admin`
	| `/api/admin/users/${string}`
	| '/api/admin/users'
	| '/api/data'
	| '/api/invite'
	| `/api/invite/${string}`
	| '/api/setup/first-user'
	| '/api/setup/repository'
	| `/api/squelches/clean/${string}`
	| `/api/squelches/${string}/${string}`
	| '/api/login'
	| `/api/snmp/device/${string}`
	| `/api/snmp/device/refresh/${string}`
);

type ApiRequest = Omit<HttpRequest, 'path'> & {
	path: ApiPath;
};

export type ApiRequestOptions = {
	headers: Record<string, string>;
};

export namespace ApiResponse {
	export type Ok<T> = {
		ok: true;
		status: HttpStatusCode.Ok;
		body: T;
		headers: Headers;
	};
	export type NotModified = {
		ok: false;
		errorType: 'http';
		status: HttpStatusCode.NotModified;
		body: null;
		headers: Headers;
	};
	export type HttpError = {
		ok: false;
		errorType: 'http';
		status: HttpStatusCode.ClientError;
		body: unknown;
		headers: Headers;
	};
	export type NetworkError = {
		ok: false;
		errorType: 'network';
		error: Error;
	};

	export const toStatusString = (response: ApiResponse<unknown>): string => (
		'status' in response ? response.status.toString() : 'Network error'
	);
}
export type ApiResponse<T> = (
	| ApiResponse.Ok<T>
	| ApiResponse.NotModified
	| ApiResponse.HttpError
	| ApiResponse.NetworkError
);

export type NetworkErrorListener = (error: ApiResponse.NetworkError) => void;

export type ApiServiceEvents = {
	networkError: ApiResponse.NetworkError;
};

export type ApiServiceDependencies = {
	httpClient: HttpClient;
};

export class ApiService {
	static readonly #BODY_HEADERS = { 'content-type': 'application/json' } as const;
	static readonly #NO_BODY_HEADERS = {} as const;
	
	readonly #httpClient: HttpClient;
	readonly #events = new EventManager<ApiServiceEvents>();

	constructor(deps: Readonly<ApiServiceDependencies>) {
		this.#httpClient = deps.httpClient;
	}

	async #request<T>(request: Readonly<ApiRequest>, options: Partial<Readonly<ApiRequestOptions>> | undefined, retrying: boolean = false): Promise<ApiResponse<T>> {
		let response: HttpResponse;
		
		const headers = {
			...(request.body ? ApiService.#BODY_HEADERS : ApiService.#NO_BODY_HEADERS),
			...options?.headers,
		};
		try {
			response = await this.#httpClient.request(request, headers);
		} catch(err) {
			if(retrying) {
				console.error('API request failed twice, abandoning:', err); // eslint-disable-line no-console
				const response: ApiResponse.NetworkError = {
					ok: false,
					errorType: 'network',
					error: asError(err),
				};
				this.#events.notify('networkError', response);
				return response;
			}

			console.warn('API request failed, retrying:', err); // eslint-disable-line no-console
			return await(this.#request(request, options, true));
		}

		if(HttpStatusCode.isOk(response.status)) {
			return {
				ok: true,
				status: response.status,
				body: response.body,
				headers: response.headers,
			};
		}

		if(HttpStatusCode.isNotModified(response.status)) {
			return {
				ok: false,
				errorType: 'http',
				status: response.status,
				body: null,
				headers: response.headers,
			};
		}

		if(HttpStatusCode.isClientError(response.status)) {
			return {
				ok: false,
				errorType: 'http',
				status: response.status,
				body: response.body,
				headers: response.headers,
			};
		}

		if(HttpStatusCode.isAuthError(response.status)) {
			throw new AuthError(response.status);
		}

		throw new BearingError({
			kind: 'api',
			name: response.statusText,
			status: response.status,
			method: request.method,
			path: request.path,
			message: 'Received an unexpected response from Bearing.',
			details: (response.body as Record<string, string> | null)?.details ?? 'No details received.',
			stack: (response.body as Record<string, string> | null)?.stack ?? 'No stack received.',
		});
	}

	/**
	 * Make a GET request to the Bearing API.
	 *
	 * The caller is expected to handle 200, 304, 400 and 404 status codes.
	 *
	 * If we receive a 401 or 403 status code, this an {@link AuthError} will be
	 * thrown.
	 *
	 * For any other status code, a {@link BearingError} will be thrown.
	 *
	 * If we are unable to make the request due to a networking error, we invoke
	 * the {@link ApiServiceDependencies.retryHandler} to determine if we should
	 * retry the request, otherwise a {@link BearingError} will be thrown.
	 */
	get<T>(path: ApiPath, options?: ApiRequestOptions): Promise<ApiResponse<T>> {
		return this.#request({ method: 'GET', path, body: null }, options);
	}

	/**
	 * Make a POST request to the Bearing API.
	 *
	 * The caller is expected to handle 200, 304, 400 and 404 status codes.
	 *
	 * If we receive a 401 or 403 status code, this an {@link AuthError} will be
	 * thrown.
	 *
	 * For any other status code, a {@link BearingError} will be thrown.
	 *
	 * If we are unable to make the request due to a networking error, we invoke
	 * the {@link ApiServiceDependencies.retryHandler} to determine if we should
	 * retry the request, otherwise a {@link BearingError} will be thrown.
	 */
	post<T>(path: ApiPath, body: ApiRequest['body'] = null, options?: ApiRequestOptions): Promise<ApiResponse<T>> {
		return this.#request({ method: 'POST', path, body }, options);
	}

	/**
	 * Make a PUT request to the Bearing API.
	 *
	 * The caller is expected to handle 200, 304, 400 and 404 status codes.
	 *
	 * If we receive a 401 or 403 status code, this an {@link AuthError} will be
	 * thrown.
	 *
	 * For any other status code, a {@link BearingError} will be thrown.
	 *
	 * If we are unable to make the request due to a networking error, we invoke
	 * the {@link ApiServiceDependencies.retryHandler} to determine if we should
	 * retry the request, otherwise a {@link BearingError} will be thrown.
	 */
	put<T>(path: ApiPath, body: ApiRequest['body'] = null, options?: ApiRequestOptions): Promise<ApiResponse<T>> {
		return this.#request({ method: 'PUT', path, body }, options);
	}

	/**
	 * Make a PATCH request to the Bearing API.
	 *
	 * The caller is expected to handle 200, 304, 400 and 404 status codes.
	 *
	 * If we receive a 401 or 403 status code, this an {@link AuthError} will be
	 * thrown.
	 *
	 * For any other status code, a {@link BearingError} will be thrown.
	 *
	 * If we are unable to make the request due to a networking error, we invoke
	 * the {@link ApiServiceDependencies.retryHandler} to determine if we should
	 * retry the request, otherwise a {@link BearingError} will be thrown.
	 */
	patch<T>(path: ApiPath, body: ApiRequest['body'] = null, options?: ApiRequestOptions): Promise<ApiResponse<T>> {
		return this.#request({ method: 'PATCH', path, body }, options);
	}

	/**
	 * Make a DELETE request to the Bearing API.
	 *
	 * The caller is expected to handle 200, 304, 400 and 404 status codes.
	 *
	 * If we receive a 401 or 403 status code, this an {@link AuthError} will be
	 * thrown.
	 *
	 * For any other status code, a {@link BearingError} will be thrown.
	 *
	 * If we are unable to make the request due to a networking error, we invoke
	 * the {@link ApiServiceDependencies.retryHandler} to determine if we should
	 * retry the request, otherwise a {@link BearingError} will be thrown.
	 */
	delete<T>(path: ApiPath, options?: ApiRequestOptions): Promise<ApiResponse<T>> {
		return this.#request({ method: 'DELETE', path, body: null }, options);
	}

	on<E extends EventType<ApiServiceEvents>>(event: E, handler: EventCallback<ApiServiceEvents, E>): this {
		this.#events.on(event, handler);
		return this;
	}

	off<E extends EventType<ApiServiceEvents>>(event: E, handler: EventCallback<ApiServiceEvents, E>): this {
		this.#events.off(event, handler);
		return this;
	}
}