import type { CompanyMetadata } from './bearing';


export type RepositoryConfig_Powercode = {
	provider: 'Powercode';
	username: string;
	password: string;
	server: string;
	allowUnauthorized?: boolean;
	wirelessRangeId?: string;
	wirelessRangeUnits?: RepositoryRangeUnits;
	fallbackCenterFrequencyId?: string;
	fallbackChannelWidthId?: string;
};
export type RepositoryConfig_Local = {
	provider: 'Local';
	dataFile: string;
	errorFile?: string;
	wirelessRangeId?: string;
	wirelessRangeUnits?: RepositoryRangeUnits;
};
export type RepositoryConfig_Manual = {
	provider: 'Manual';
	companyMetadata: CompanyMetadata;
};
export type RepositoryConfig = (
	| RepositoryConfig_Powercode
	| RepositoryConfig_Local
	| RepositoryConfig_Manual
);
export type RepositoryProvider = RepositoryConfig['provider'];

export type RepositoryCustomField = {
	id: string;
	name: string;
	default: string;
};
export type RepositoryProviderMetadata_Powercode = {
	provider: 'Powercode';
	customFields?: RepositoryCustomField[];
	portalUrl: string;
	wirelessRange: {
		configured: boolean;
		defaultValue: number | undefined;
	},
};
export type RepositoryProviderMetadata_Local = {
	provider: 'Local';
	customFields: RepositoryCustomField[];
	portalUrl: string;
	wirelessRange: {
		configured: boolean;
		defaultValue: number | undefined;
	},
};
export type RepositoryProviderMetadata_Manual = {
	provider: 'Manual';
};
export type RepositoryProviderMetadata = (
	| RepositoryProviderMetadata_Powercode
	| RepositoryProviderMetadata_Local
	| RepositoryProviderMetadata_Manual
);

export type RepositoryErrorStatus = (
	| 'no-config-error'
	| 'invalid-config-error'
	| 'network-error'
	| 'authentication-error'
	| 'permission-error'
	| 'upstream-error'
	| 'user-actionable'
	| 'incomplete-certificate-chain'
	| 'unknown-error'
);

export type RepositoryRangeUnits = ('km' | 'miles');
export const REPOSITORY_RANGE_UNITS: Readonly<RepositoryRangeUnits[]> = [ 'km', 'miles' ];
export function asRepositoryRangeUnits(x: string): RepositoryRangeUnits {
	return x === 'miles' ? 'miles' : 'km';
}

export type ErrorDetails = (
	| string
	| string[]
	| Record<string, unknown>
);

export type RepositoryStatus = (
	| 'verifying'
	| 'authenticating'
	| 'loading'
	| 'ready'
	| 'closed'
);
export type MaybeRepositoryStatus = 'no-repository' | RepositoryStatus;

export namespace PostRepositoryResponse {
	export type Ok = {
		ok: true;
	};
	export type NotNeeded = {
		ok: false;
		reason: 'not-needed';
	};
	export type Error = {
		ok: false;
		reason: RepositoryErrorStatus;
		details: string;
	};
}
export type PostRepositoryResponse = (
	| PostRepositoryResponse.Ok
	| PostRepositoryResponse.NotNeeded
	| PostRepositoryResponse.Error
);