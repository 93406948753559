import type { ClassProp, IdProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import './select.scss';


export type SelectOption<T extends string | null = string | null> = {
	value: T;
	label: string;
};
export type SelectProps<T extends string> = IdProp & ClassProp & {
	name?: string;
	options: readonly SelectOption<T>[];
	value?: T;
	onChange?: (value: T) => void;
	required?: boolean;
	disabled?: boolean;
};
export const Select = <T extends string> (p: SelectProps<T>): JSX.Element => {
	return (
		<select
			id={ p.id }
			className={ classNames(p.className, 'select') }
			value={ p.value }
			onChange={ ev => p.onChange?.((ev.currentTarget.value || null) as T) }
			name={ p.name }
			required={ p.required }
			disabled={ p.disabled }
		>
			{ p.options.map(o => (
				<option value={ o.value } key={ o.value }>
					{ o.label }
				</option>
			))}
		</select>
	);
};