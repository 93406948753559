import type { JSX } from 'preact/jsx-runtime';
import { AdminTabContent } from '../components/admin-tab-content';
import { ManualRepositoryCsvUploadForm } from '../../../config';


export const ManualRepositoryAdmin = (): JSX.Element => {
	return (
		<AdminTabContent tabId="csv-upload">
			<ManualRepositoryCsvUploadForm/>
		</AdminTabContent>
	);
};