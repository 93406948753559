import type { RadioRaw } from '@brng/common';
import type { Device } from './device';
import { FrequencyBand } from '@brng/common';


export type RadioWithBand = Radio & {
	band: Exclude<Radio['band'], undefined>
};

export class Radio {
	readonly id: string;
	readonly band: FrequencyBand | undefined;
	readonly frequency: number;
	readonly channelWidth: number;

	readonly device: Device;

	
	constructor(device: Device, radio: RadioRaw) {
		this.device = device;
		
		this.id = radio.id;
		this.frequency = radio.frequency;
		this.channelWidth = radio.channelWidth;
		this.band = FrequencyBand.fromFrequency(radio.frequencyBand ?? radio.frequency) ?? undefined;
	}
}