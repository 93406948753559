[
  {
    "oid": "1.3.6.1.4.1.41112.1.4.1.1.4.1",
    "name": "Ubiquiti - Center Frequency",
    "maximum": 100000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS AP - !xc",
      "AirOS AP - xc",
      "AirOS BH - !xc",
      "AirOS BH - xc",
      "AirOS CPE - !xc",
      "AirOS CPE - xc"
    ],
    "radioModels": [
      "AM2G16-90",
      "LAP-GPS",
      "Loco5AC-US",
      "Loco5AC",
      "LocoM2",
      "M900",
      "NS-5AC-US",
      "PBE-2AC-400",
      "PBE-5AC-Gen2",
      "PBE-5AC-ISO-US",
      "PBE-M2-400",
      "R2AC-PRISM",
      "RM2-Ti",
      "Rocket M2",
      "RP-5AC-GEN2",
      "RP-5AC-US"
    ],
    "resultCount": 7776,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": "1.3.6.1.4.1.41112.1.4.5.1.8.1",
    "name": "Ubiquiti - Noise Floor",
    "maximum": 0,
    "minimum": -130,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS AP - !xc",
      "AirOS AP - xc",
      "AirOS CPE - !xc",
      "AirOS CPE - xc"
    ],
    "radioModels": [
      "AM2G16-90",
      "LAP-GPS",
      "Loco5AC-US",
      "Loco5AC",
      "LocoM2",
      "M900",
      "NS-5AC-US",
      "PBE-2AC-400",
      "PBE-5AC-Gen2",
      "PBE-5AC-ISO-US",
      "PBE-M2-400",
      "R2AC-PRISM",
      "RM2-Ti",
      "Rocket M2",
      "RP-5AC-GEN2",
      "RP-5AC-US"
    ],
    "resultCount": 5020,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "dBm"
  },
  {
    "oid": "1.3.6.1.4.1.41112.1.4.5.1.14.1",
    "name": "Ubiquiti - Channel Width",
    "maximum": 100,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS AP - !xc",
      "AirOS AP - xc",
      "AirOS BH - !xc",
      "AirOS BH - xc",
      "AirOS CPE - !xc",
      "AirOS CPE - xc"
    ],
    "radioModels": [
      "AM2G16-90",
      "LAP-GPS",
      "Loco5AC-US",
      "Loco5AC",
      "LocoM2",
      "M900",
      "NS-5AC-US",
      "PBE-2AC-400",
      "PBE-5AC-Gen2",
      "PBE-5AC-ISO-US",
      "PBE-M2-400",
      "R2AC-PRISM",
      "RM2-Ti",
      "Rocket M2",
      "RP-5AC-GEN2",
      "RP-5AC-US"
    ],
    "resultCount": 7776,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.3.1.1.5.1",
    "name": "Ubiquiti - AirFiber X - Center Frequency",
    "maximum": 1000000000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber X"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Khz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.3.1.1.28.1",
    "name": "Ubiquiti - AirFiber X - Channel Width",
    "maximum": 10000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber X"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.10.1.2.4.0",
    "name": "Ubiquiti - AirFiber X HD - Channel Width",
    "maximum": 10000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber X HD"
    ],
    "radioModels": null,
    "resultCount": 57,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.10.1.2.2.0",
    "name": "Ubiquiti - AirFiber X HD - Center Frequency",
    "maximum": 10000000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber X HD"
    ],
    "radioModels": null,
    "resultCount": 57,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "MHz"
  },
  {
    "oid": "1.3.6.1.4.1.161.19.3.3.2.83.0",
    "name": "Cambium PMP - Channel Width",
    "maximum": 5,
    "minimum": 1,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Cambium Access Point PMP450"
    ],
    "radioModels": [
      "PMP450",
      "PMP450i"
    ],
    "resultCount": 8,
    "manufacturerId": 1,
    "manufacturerName": "Cambium",
    "unitOfMeasurement": "Channel Width mapping"
  },
  {
    "oid": "1.3.6.1.4.1.161.19.3.1.7.37.0",
    "name": "Cambium PMP - Center Frequency",
    "maximum": 500000000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Cambium Access Point PMP450"
    ],
    "radioModels": [
      "PMP450",
      "PMP450i"
    ],
    "resultCount": 8,
    "manufacturerId": 1,
    "manufacturerName": "Cambium",
    "unitOfMeasurement": "MHz"
  },
  {
    "oid": "1.3.6.1.4.1.43356.2.1.2.6.1.1.6.1",
    "name": "Mimosa B5c - Center Frequency",
    "maximum": 6000,
    "minimum": 5000,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Mimosa B5c 5Ghz"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 23,
    "manufacturerName": "Mimosa",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": "1.3.6.1.4.1.43356.2.1.2.6.3.1.3.1",
    "name": "Mimosa B5c - Channel Width 1",
    "maximum": 100,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Mimosa B5c 5Ghz"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 23,
    "manufacturerName": "Mimosa",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": "1.3.6.1.4.1.43356.2.1.2.6.3.1.3.2",
    "name": "Mimosa B5c - Channel Width 2",
    "maximum": 120,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Mimosa B5c 5Ghz"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 23,
    "manufacturerName": "Mimosa",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.11.1.1.2.1",
    "name": "Ubiquiti - AirFiber GP - Center Frequency",
    "maximum": 1000000000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber - GP"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Khz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.11.1.1.3.1",
    "name": "Ubiquiti - AirFiber GP - Channel Width",
    "maximum": 10000,
    "minimum": 0,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS Fiber - GP"
    ],
    "radioModels": null,
    "resultCount": 2,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "Mhz"
  },
  {
    "oid": ".1.3.6.1.4.1.41112.1.4.1.1.6.1",
    "name": "Ubiquiti - AirOS - Transmit Power",
    "maximum": 60,
    "minimum": -10,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "AirOS AP - !xc",
      "AirOS AP - xc",
      "AirOS BH - !xc",
      "AirOS BH - xc",
      "AirOS CPE - !xc",
      "AirOS CPE - xc"
    ],
    "radioModels": [
      "AM2G16-90",
      "LAP-GPS",
      "Loco5AC-US",
      "Loco5AC",
      "LocoM2",
      "M900",
      "NS-5AC-US",
      "PBE-2AC-400",
      "PBE-5AC-Gen2",
      "PBE-5AC-ISO-US",
      "PBE-M2-400",
      "R2AC-PRISM",
      "RM2-Ti",
      "Rocket M2",
      "RP-5AC-GEN2",
      "RP-5AC-US"
    ],
    "resultCount": 7752,
    "manufacturerId": 14,
    "manufacturerName": "Ubiquiti",
    "unitOfMeasurement": "dbM"
  },
  {
    "oid": ".1.3.6.1.4.1.161.19.3.3.1.232.0",
    "name": "Cambium - 450 - Transmit Power",
    "maximum": 60,
    "minimum": -10,
    "dataType": "Gauge",
    "probeType": "SNMP",
    "deviceTypes": [
      "Cambium Access Point PMP450"
    ],
    "radioModels": [
      "PMP450",
      "PMP450i"
    ],
    "resultCount": 8,
    "manufacturerId": 1,
    "manufacturerName": "Cambium",
    "unitOfMeasurement": "dbM"
  }
]
