import { useCallback, useMemo } from 'preact/hooks';


export const useCachedGetter = <V>(get: (key: string) => V, inputs: unknown[] = []): (key: string) => V => {
	const cache = useMemo<Map<string, V>>(() => new Map(), inputs); // eslint-disable-line react-hooks/exhaustive-deps
	return useCallback(key => {
		if(cache.has(key)) {
			return cache.get(key) as V;
		}

		const value = get(key);
		cache.set(key, value);
		return value;
	}, [cache, get]);
};

type Cacheable = Record<string, string>;
type CacheKeyProp<T extends Cacheable> = keyof T;
type GetterCallback<T extends Cacheable, P extends CacheKeyProp<T>, E extends boolean> = (propValue: T[P]) => E extends true ? T : T | null;

export const useCachedByPropGetter = <
	T extends Cacheable, P extends CacheKeyProp<T>, E extends boolean,
>(prop: P, array: T[], errorOnMissing?: E): GetterCallback<T, P, E> => {
	return useCachedGetter(
		useCallback((propValue: unknown) => {
			const value = array.find(value => value[prop] === propValue) || null;
			if(!value && errorOnMissing) {
				throw new Error(`No item found for ${String(prop)}: ${propValue}`);
			}
			return value;
		}, [array, errorOnMissing, prop]),
		array
	) as GetterCallback<T, P, E>;
};

export const useCachedByIdGetter = <
	T extends { id: string },
	E extends boolean,
>(array: T[], safe?: E): GetterCallback<T, 'id', E> => useCachedByPropGetter('id', array, safe);
