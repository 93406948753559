import type { FunctionComponent, JSX } from 'preact';
import type { ClassProp } from '../util';
import classNames from 'classnames';
import { useMemo } from 'preact/hooks';
import { isInteractiveDescendant } from '../util';
import './data-table.scss';


export type DataTableProps = { className?: string };
export const DataTable: FunctionComponent<DataTableProps> = ({ className, children }) => {
	const rootClassName = useMemo(() => classNames('data-table', className), [ className ]);

	return (
		<div className={ rootClassName }>
			{ children }
		</div>
	);
};

export type DataRowProps = ClassProp & {
	status?: 'info' | 'warning' | 'danger';
	selected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	noFocus?: boolean;
};


type DataRowComponent = FunctionComponent<DataRowProps>;
export const DataRow: DataRowComponent = ({
	className,
	status = 'info',
	selected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	children,
}) => {

	const computedClassName = useMemo(() => classNames(
		'data-table__row', `data-table__row--${status}`,
		{
			'data-table__row--selected': selected,
			'data-table__row--interactive': !!onClick,
		},
		className
	), [className, onClick, selected, status]);

	const handleClick = useMemo<JSX.MouseEventHandler<HTMLDivElement> | undefined>(() => {
		if(!onClick) {
			return undefined;
		}

		return ev => {
			/*
				TODO: Handle these nested interactive elements better.

				For now, check if the target of the event is contained within an
				interactive element, and if so, skip our onClick event.
			*/
			if(isInteractiveDescendant(ev.currentTarget, ev.target)) {
				return;
			}

			onClick();
		};
	}, [ onClick ]);
	
	return (
		<div
			role={ onClick ? 'button' : undefined }
			tabIndex={ onClick ? 0 : undefined }
			className={ computedClassName }
			onMouseEnter={ onMouseEnter }
			onMouseLeave={ onMouseLeave }
			onClick={ handleClick }
		>
			{ children }
		</div>
	);
};
