import type { VNode } from 'preact';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { onRouteChange } from '../../../routes';
import './nav-item.scss';


export type NavItemComponent = (props: {
	className?: string;
	label: string;
	href?: string;
	action?: () => void;
}) => VNode;

export const NavItem: NavItemComponent = ({ className, label, href, action }) => {
	const [ isActive, setIsActive ] = useState(false);

	useEffect(() => (
		onRouteChange(() => {
			setIsActive(!!href && window.location.pathname === href);
		})
	), [href]);

	const handleClick = useCallback((ev: Event) => {
		if(!action) {
			return;
		}

		ev.preventDefault();
		action();
	}, [action]);

	const computedClassName = useMemo(() => cn(
		'nav-item', className, {
			'nav-item--active': isActive,
		}
	), [ className, isActive ]);

	return (
		<a
			className={ computedClassName }
			href={ isActive ? undefined : href || 'javascript:void(0)' }
			onClick={ handleClick }
			role="menuitem"
		>
			<span>{label}</span>
		</a>
	);
};
