import type { ComponentChildren, VNode } from 'preact';
import type { AdminTabId } from '../admin-tabs';
import { useAdminTabs } from '../admin-tabs-context';


export type AdminTabContentProps = { tabId: AdminTabId, children: ComponentChildren };
export const AdminTabContent = ({ tabId, children }: AdminTabContentProps): VNode | null => {
	const { activeAdminTabId } = useAdminTabs();
	
	if(activeAdminTabId !== tabId) {
		return null;
	}

	return <>{ children }</>; // eslint-disable-line react/jsx-no-useless-fragment
};