import type { BandName, Point } from '@brng/common';
import type { Network } from '..';
import type { Device } from '../device';
import type { RadioWithBand } from '../radio';
import { FrequencyBand, earth } from '@brng/common';
import { InterSiteInterferenceIssue } from '.';


function degreesBeyondSector(eqAzimuth: number, sectorSize: number, customerAzimuth: number): number {
	let normalAzimuth = customerAzimuth - eqAzimuth;
	if(normalAzimuth > 180) {
		normalAzimuth = normalAzimuth - 360;
	} else if (normalAzimuth < -180) {
		normalAzimuth = normalAzimuth + 360;
	}
	if(normalAzimuth < 0) {
		normalAzimuth = normalAzimuth * -1;
	}
	
	return Math.max(0, Math.round(normalAzimuth - sectorSize/2));
}

type FrequencyDetails = {
	band: BandName;
	frequencyLow: number;
	frequencyHigh: number;
	device: Device;
};

function createInterSiteIssue(detailsA: FrequencyDetails, detailsB: FrequencyDetails): void {
	// Same site
	if(detailsA.device.site.id === detailsB.device.site.id) {
		return;
	}
	// No overlap
	if(detailsA.frequencyLow >= detailsB.frequencyHigh || detailsA.frequencyHigh <= detailsB.frequencyLow) {
		return;
	}
	// Siblings
	if(detailsA.device.sibling && detailsA.device.sibling.id === detailsB.device.id) {
		return;
	}
	const details = (detailsA.device.id < detailsB.device.id) ? [detailsA, detailsB] as const : [detailsB, detailsA] as const;
	const points = details.map(value => value.device.site.location) as [ Point, Point ];
	const range = earth.distance(points[0], points[1]);
	if(range > 16000) {
		return;
	}
	const azimuth = earth.azimuth(points[0], points[1]);
	// Check facing
	if(details[0].device.antennaType === 'Omni' ||
		details[1].device.antennaType === 'Omni' ||
		(details[0].device.antennaType === 'Sector' &&
			!degreesBeyondSector(details[0].device.azimuth,details[0].device.sectorSize,azimuth)) ||
		(details[1].device.antennaType === 'Sector' &&
			!degreesBeyondSector(details[1].device.azimuth,details[1].device.sectorSize,azimuth < 180 ? (azimuth + 180) : (azimuth - 180)))) {

		new InterSiteInterferenceIssue({
			device1: detailsA.device,
			device2: detailsB.device,
			frequencyBand: FrequencyBand.fromBand(details[0].band),
			frequencyLow: Math.max(...details.map(value => value.frequencyLow)),
			frequencyHigh: Math.min(...details.map(value => value.frequencyHigh)),
		});
	}
}

function interSiteIssues(frequencyGroup: FrequencyDetails[]): void {
	while(frequencyGroup.length > 1) {
		const current = frequencyGroup.pop() as FrequencyDetails;
		frequencyGroup.map(details => createInterSiteIssue(current, details));
	}
}

export function findInterSiteInterferenceIssues(network: Network): void {
	const equipmentByFrequency: Record<BandName, FrequencyDetails[]> = (
		Object.fromEntries(FrequencyBand.bandNames.map(band => [ band, [] as FrequencyDetails[] ]))
	) as Record<BandName, FrequencyDetails[]>;

	// Limit to radios with full frequency and coverage info
	// TODO: should we include APs & BHs as customer sites?
	network.infrastructureSites
		.flatMap(site => site.devices)
		.filter(device => (device.antennaType === 'Omni' ||
					device.antennaType === 'Sector' &&
					Number.isFinite(device.azimuth) &&
					Number.isFinite(device.sectorSize)) &&
				device.radios.length &&
				device.radios[0]?.band &&
				Number.isFinite(device.radios[0].frequency) &&
				Number.isFinite(device.radios[0].channelWidth)
		)
		.map(device => ({ device, radio: device.radios[0] as RadioWithBand }))
		.forEach(({ device, radio }) => equipmentByFrequency[radio.band.band].push({
			band: radio.band.band,
			frequencyLow: radio.frequency - radio.channelWidth/2,
			frequencyHigh: radio.frequency + radio.channelWidth/2,
			device,
		}));

	Object.values(equipmentByFrequency).forEach(interSiteIssues);
}
