import type { MidasDeviceData } from '@brng/common';
import type { Device } from '@brng/domain';
import type { DataService } from '../../../../../services';
import type { ApiService } from '../../../../../services/api-service';
import { bound, observable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { useServices } from '../../../../../services';


export namespace DeviceSnmpState {
	export type Loading = {
		kind: 'loading';
		refresh: boolean;
	};
	export type Ready = {
		kind: 'ready';
		data: Readonly<MidasDeviceData>;
	};
	export type Disabled = {
		kind: 'disabled';
	};
	export type Error = {
		kind: 'error';
	};
}
export type DeviceSnmpState = (
	| DeviceSnmpState.Loading
	| DeviceSnmpState.Ready
	| DeviceSnmpState.Disabled
	| DeviceSnmpState.Error
);


export type DeviceSnmpDetailsControllerConfig = {
	device: Device;
	api: ApiService;
	data: DataService;
	snmpEnabled: boolean;
};
export class DeviceSnmpDetailsController {
	readonly #device: Device;
	readonly #api: ApiService;
	readonly #data: DataService;

	
	#snmpState: DeviceSnmpState = { kind: 'loading', refresh: false };
	get snmpState(): DeviceSnmpState { return this.#snmpState; }
	@observable() private set snmpState(value: DeviceSnmpState) { this.#snmpState = value; }

	constructor(config: DeviceSnmpDetailsControllerConfig) {
		this.#device = config.device;
		this.#api = config.api;
		this.#data = config.data;
		
		if(!config.snmpEnabled) {
			this.snmpState = { kind: 'disabled' };
			return;
		}
		
		this.loadData();
	}

	async loadData(): Promise<void> {
		if(this.snmpState.kind === 'disabled') {
			return;
		}

		this.snmpState = { kind: 'loading', refresh: false };

		const response = await this.#api.get<{ data: MidasDeviceData }>(`/api/snmp/device/${this.#device.id}`);
		if(!response.ok) {
			this.snmpState = { kind: 'error' };
			return;
		}

		this.snmpState = {
			kind: 'ready',
			data: response.body.data,
		};
	}

	@bound()
	async refreshData(): Promise<void> {
		this.snmpState = { kind: 'loading', refresh: true };

		const response = await this.#api.get<{ data: MidasDeviceData }>(`/api/snmp/device/refresh/${this.#device.id}`);
		if(!response.ok) {
			this.snmpState = { kind: 'error' };
			return;
		}

		this.#data.backgroundRefresh();

		this.snmpState = {
			kind: 'ready',
			data: response.body.data,
		};
	}
}

export const useDeviceSnmpDetailsController = (device: Device) => {
	const { apiService, dataService, settingsService } = useServices();
	
	return useMemo(() => (
		new DeviceSnmpDetailsController({
			device,
			api: apiService,
			data: dataService,
			snmpEnabled: settingsService.settings.snmpEnabled,
		})
	), [apiService, dataService, device, settingsService.settings.snmpEnabled]);
};