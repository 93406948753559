import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp } from '../../util';
import type { ModalStateProps } from '../molecules';
import classNames from 'classnames';
import { Icon, type IconName } from '../atoms';
import { Modal } from '../molecules';
import './dialog.scss';


export type DialogProps = ClassProp & ChildrenProp & ModalStateProps & {
	narrow?: boolean;
};

export const Dialog = ({ className, narrow, children, ...modalStateProps }: DialogProps) => {
	return (
		<Modal { ...modalStateProps }>
			<div className={ classNames('dialog', className, { 'dialog--narrow': narrow }) }>
				{ children }
			</div>
		</Modal>
	);
};

export type DialogHeaderProps = ClassProp & {
	icon?: IconName;
	title: string;
	onClose?: VoidFunction;
};
export const DialogHeader = (p: DialogHeaderProps): JSX.Element => (
	<header className={ classNames(p.className, 'flex-align-center') }>
		{ p.icon && (
			<Icon
				className="mrg-r-sm"
				icon={ p.icon }
			/>
		)}
		<h2>{p.title}</h2>
		{ p.onClose && (
			<button
				className="dialog-close-button btn btn--invisible mrg-l-auto"
				onClick={ p.onClose }
			>
				<Icon icon="x"/>
				<span className="visually-hidden">Close</span>
			</button>
		)}
	</header>
);