import type { ClassProp } from '../../../util';
import type { SpectrumGraph } from './spectrum-graph';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { forwardRef } from 'preact/compat';
import { SpectrumGraphSegmentView } from './spectrum-graph-segment-view';
import './spectrum-graph.scss';


export type SpectrumGraphViewProps = ClassProp & {
	graph: SpectrumGraph;
	hasPopup?: boolean;
	ariaHidden?: boolean;
};
export const SpectrumGraphView = forwardRef<HTMLDivElement, SpectrumGraphViewProps>(({ className, graph, hasPopup, ariaHidden }, ref) => {
	useObservable(graph, 'focusedSegment');
	useObservable(graph, 'segments');

	const segmentsClassName = classNames('spectrum-graph__segments', {
		'spectrum-graph__segments--with-popup': hasPopup && !!graph.focusedSegment,
	});

	return (
		<div
			className={ classNames('spectrum-graph', className) }
			ref={ ref }
			aria-hidden={ ariaHidden ?? false }
		>
			<div className={ segmentsClassName }>
				{ graph.segments.map(segment => (
					<SpectrumGraphSegmentView
						segment={ segment }
						key={ segment.id }
					/>
				)) }
			</div>
		</div>
	);
});