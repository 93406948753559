import type { JSX } from 'preact';
import type { SelectOption } from '../../../components';
import type { ClassProp, IdProp } from '../../../util';
import classNames from 'classnames';
import './map-select.scss';


export type MapSelectProps<T extends string> = IdProp & ClassProp & {
	options: readonly SelectOption<string>[];
	value: T;
	onChange: (value: T) => void;
	disabled?: boolean;
};
export const MapSelect = <T extends string>({ id, className, options, value, onChange, disabled }: MapSelectProps<T>): JSX.Element => {
	return (
		<div className={ classNames('map-select', className, { disabled }) }>
			<select
				id={ id }
				value={ value }
				onChange={ ev => onChange(ev.currentTarget.value as T) }
				disabled={ disabled }
			>
				{ options.map(opt => (
					<option value={ opt.value } key={ opt.value }>
						{ opt.label }
					</option>
				))}
			</select>
			<div className="map-select__arrow">
				<i class="fa fa-chevron-down"/>
			</div>
		</div>
	);
};