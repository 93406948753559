import type { FunctionComponent, VNode } from 'preact';
import { useNetwork } from '../../services';
import { AnalysisIssueDescription } from './analysis-issue-description';
import { AnalysisIssuesProvider } from './analysis-provider';
import { AnalysisIssueToolbar, AnalysisIssuesTable } from './components';
import { AnalysisIssueMapView } from './components/analysis-issues-map-view';
import { NoFiltersMatchNotice } from './components/no-filters-match-notice';
import './issues-layout.scss';


export const IssueRoute: FunctionComponent = (): VNode | null => {
	const network = useNetwork();
	if(!network) {
		return null;
	}

	return (
		<AnalysisIssuesProvider network={ network }>
			<AnalysisIssueToolbar/>
			<div className="issues-layout">
				<AnalysisIssueDescription className="issues-layout__description"/>
				<NoFiltersMatchNotice/>
				<div className="issues-layout__issues">
					<AnalysisIssuesTable/>
				</div>
				<div className="issues-layout__map">
					<AnalysisIssueMapView/>
				</div>
			</div>
		</AnalysisIssuesProvider>
	);
};