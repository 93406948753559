import type { JSX } from 'preact/jsx-runtime';
import type { RouteProps } from '../route-props';
import { useObservable } from 'ecce-preact';
import { useEffect } from 'preact/hooks';
import { MapControls, MapShareButton, MapView } from '../../map';
import { MapRadiusSlider } from '../../map/components/controls/map-radius-slider';
import { useServices } from '../../services';
import { useBreakpoint, useConstant } from '../../util';
import { MapControlSurface } from '../../map/components/controls/map-control-surface';
import { CustomerAlternativeDeviceFilterView } from '../../filtering/customer-alternative-devices-filter/customer-alternative-devices-filter-view';
import { MapRouteFrequencySelect, MapRouteSearch, MapRouteToolbar } from './components';
import { MapRouteInfoPopupWindow } from './components/map-route-info-popup';
import { MapRouteController } from './map-route-controller';
import { MapRouteItemsView } from './map-route-items-view';
import './map-route.scss';


export type MapRouteControlsProps = {
	controller: MapRouteController;
};
const MapRouteControls = ({ controller }: MapRouteControlsProps) => {
	useObservable(controller.display, 'overlayMode');

	return (
		<>
			{ controller.display.overlayMode === 'frequency' && (
				<MapControls position="LEFT_TOP" className="stack-md">
					<MapRouteFrequencySelect controller={ controller } overMap/>
					{ controller.map && (
						<MapRadiusSlider controller={ controller.map } overMap/>
					)}
					{ controller.display.focus?.kind === 'customerSite' && (
						<MapControlSurface className="customer-alternative-devices-filter-view-container">
							<CustomerAlternativeDeviceFilterView filter={ controller.display.alternativeDevices }/>
						</MapControlSurface>
					)}
				</MapControls>
			)}
			<MapControls position="TOP_CENTER">
				<MapRouteSearch controller={ controller }/>
			</MapControls>
			<MapControls position="LEFT_BOTTOM">
				<MapShareButton getUrlParams={ controller.getUrlParams }/>
			</MapControls>
		</>
	);
};
export const MapRoute = (_: RouteProps): JSX.Element | null => {
	const services = useServices();
	const large = useBreakpoint('md');

	const controller = useConstant(() => new MapRouteController(services));
	useEffect(() => () => controller.dispose(), [controller]);
	useObservable(controller, 'map');

	if(!controller.map) {
		return null;
	}

	return (
		<div className="map-route">
			<MapRouteToolbar controller={ controller } large={ large }/>
			<MapView
				className="map-route__map"
				controller={ controller.map }
			>
				<MapRouteItemsView display={ controller.display }/>
				<MapRouteInfoPopupWindow controller={ controller }/>
				{ large && (
					<MapRouteControls controller={ controller }/>
				) }
			</MapView>
		</div>
	);
};