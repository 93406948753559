import type { Device } from '@brng/domain';
import type { FunctionComponent } from 'preact';
import { createContext } from 'preact';
import { useContext, useMemo, useState } from 'preact/hooks';


export const EQUIPMENT_ORDERS = [
	'azimuth',
	'frequency',
	'name',
] as const;
export type EquipmentOrder = typeof EQUIPMENT_ORDERS[number];
export type EquipmentComparator = (a: Device, b: Device) => number;

const comparators: Record<EquipmentOrder, EquipmentComparator> = {
	'azimuth': (a, b) => a.azimuth - b.azimuth,
	'frequency': (a, b) => (a.radios[0]?.frequency ?? 0) - (b.radios[0]?.frequency ?? 0),
	'name': (a, b) => a.name.localeCompare(b.name),
};

export type SortedEquipmentContext = {
	order: EquipmentOrder;
	setOrder: (newOrder: EquipmentOrder) => void;
	equipmentComparator: EquipmentComparator;
};
const sortedEquipmentContext = createContext<SortedEquipmentContext | null>(null);

export const SortedEquipmentProvider: FunctionComponent = ({ children }) => {
	const [ order, setOrder ] = useState<EquipmentOrder>('azimuth');

	const value = useMemo<SortedEquipmentContext>(() => ({
		order,
		setOrder,
		equipmentComparator: (a, b) => comparators[order](a, b) || a.id.localeCompare(b.id),
	}), [order]);
	
	return (
		<sortedEquipmentContext.Provider value={ value }>
			{ children }
		</sortedEquipmentContext.Provider>
	);
};

export const useSortedEquipment = (): SortedEquipmentContext => {
	const ctx = useContext(sortedEquipmentContext);
	if(!ctx) {
		throw new Error('sortedEquipmentContext was null; missing <SortedEquipmentProvider>?');
	}

	return ctx;
};