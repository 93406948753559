import type { SnmpHelperController } from './snmp-helper-controller';
import type { SnmpProbeDeviceTypeData, SnmpProbeManufacturerData, SnmpProbeRadioModelData } from './snmp-probe-data';
import { bound, observable, subject } from 'ecce-preact';


export type SnmpProbeFilterItemData = (
	| SnmpProbeManufacturerData
	| SnmpProbeDeviceTypeData
	| SnmpProbeRadioModelData
);
export type SnmpProbeFilterItemKind = SnmpProbeFilterItemData['kind'];

const KIND_ORDER: Record<SnmpProbeFilterItemKind, number> = {
	'manufacturer': 0,
	'radioModel': 1,
	'deviceType': 2,
};

@subject()
export class SnmpProbeFilterItem<K extends SnmpProbeFilterItemKind = SnmpProbeFilterItemKind> {
	readonly #controller: SnmpHelperController;
	
	readonly kind: K;
	readonly name: string;
	readonly manufacturerName: string | undefined;

	#selected = false;
	get selected(): boolean { return this.#selected; }
	@observable() private set selected(value: boolean) { this.#selected = value; }
	
	constructor(controller: SnmpHelperController, data: SnmpProbeFilterItemData & { kind : K }) {
		this.#controller = controller;
		this.kind = data.kind;
		this.name = data.name;
		this.manufacturerName = (
			'manufacturerName' in data
				? data.manufacturerName
				: undefined
		);
	}

	get id() {
		return this.kind + this.name;
	}

	@bound()
	select() {
		this.#controller.filter.selectItem(this);
	}

	static compareName = (a: SnmpProbeFilterItem, b: SnmpProbeFilterItem): number => (
		a.name.localeCompare(b.name)
	);

	static compareKind = (a: SnmpProbeFilterItem, b: SnmpProbeFilterItem): number => {
		return KIND_ORDER[a.kind] - KIND_ORDER[b.kind];
	};
}

export class SnmpProbeManufacturer extends SnmpProbeFilterItem<'manufacturer'> {}
export class SnmpProbeDeviceType extends SnmpProbeFilterItem<'deviceType'> {}
export class SnmpProbeRadioModel extends SnmpProbeFilterItem<'radioModel'> {}