import type { VNode } from 'preact';
import type { MenuProps } from '.';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'preact/hooks';
import { Chevron } from '../chevron';
import { MenuHandler } from '.';
import './menu-button.scss';


export type MenuButtonProps = {
	items: MenuProps['items'];
	label: string | VNode;
};
export const MenuButton = ({ items, label }: MenuButtonProps): VNode => {
	const [ open, setOpen ] = useState(false);

	const button = useRef<HTMLButtonElement>(null);
	
	const handleClick = useCallback(() => {
		setOpen(prevOpen => !prevOpen);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
		button.current?.focus();
	}, []);

	const [ menuVisible, setMenuVisible ] = useState(false);
	const handleTransitionComponent = useCallback((visible: boolean) => {
		setMenuVisible(visible);
	}, []);
	
	return (
		<MenuHandler
			items={ items }
			open={ open }
			onClose={ handleClose }
			onTransitionComplete={ handleTransitionComponent }
		>
			<button
				className={ classNames('btn menu-btn', { 'menu-btn--open': open || menuVisible }) }
				onClick={ handleClick }
				ref={ button }
			>
				{ label }
				<Chevron down={ !open } size="xs"/>
			</button>
		</MenuHandler>
	);
};