import type { JSX } from 'preact/jsx-runtime';
import type { IconName } from '../../../components';
import type { ChildrenProp, ClassProp } from '../../../util';
import classNames from 'classnames';
import { Icon } from '../../../components';
import './sites-page-section.scss';


type SitesPageSectionProps = ClassProp & ChildrenProp & {
	title: string;
	icon?: IconName;
	link?: { href: string, onClick?: (ev: Event) => void };
};
export const SitesPageSection = (p: SitesPageSectionProps): JSX.Element => {
	const title = <h2>{p.icon && <Icon icon={ p.icon }/>}{p.title}</h2>;
	return (
		<section className={ classNames(p.className, 'sitesPageSection panel panel--dark') }>
			{ p.link ? (
				<a href={ p.link.href } onClick={ p.link.onClick }>
					{ title }
				</a>
			) : title }
			{ p.children }
		</section>
	);
};
