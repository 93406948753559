import type { RepositoryConfig_Powercode } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../../util';
import type { PowercodeCustomFieldsModel } from '../powercode-custom-fields-controller';
import { omitNullish } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { CUSTOM_FIELD_DEFS } from '../custom-fields';
import { PowercodeCustomFieldsController } from '../powercode-custom-fields-controller';
import { PowercodeCustomFieldSelect } from './powercode-custom-field-select';
import './powercode-custom-fields-view.scss';


type PowercodeCustomFieldsSubmitProps = ClassProp & {
	controller: PowercodeCustomFieldsController;
};
const PowercodeCustomFieldsSubmit = ({ className, controller }: PowercodeCustomFieldsSubmitProps) => {
	useObservable(controller, 'canSubmit');

	return (
		<div className={ className }>
			<button
				className="btn mrg-l-auto"
				type="submit"
				disabled={ !controller.canSubmit }
			>
				Update custom fields
			</button>
		</div>
	);
};

export type PowercodeCustomFieldsViewProps = ClassProp & {
	initialConfig: RepositoryConfig_Powercode;
	onSubmit: (config: Pick<RepositoryConfig_Powercode, PowercodeCustomFieldsModel.Key>) => void;
};
export const PowercodeCustomFieldsView = ({ initialConfig, onSubmit }: PowercodeCustomFieldsViewProps): JSX.Element => {
	const controller = useMemo<PowercodeCustomFieldsController>(() => (
		new PowercodeCustomFieldsController(initialConfig)
	), [initialConfig]);

	const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = ev => {
		ev.preventDefault();
		if(!controller.canSubmit) {
			return;
		}
		
		onSubmit(omitNullish(controller.model) as Pick<RepositoryConfig_Powercode, PowercodeCustomFieldsModel.Key>);
	};

	return (
		<form
			className="form powercode-custom-fields-view"
			onSubmit={ handleSubmit }
		>
			<div className="panel-group">
				{
					Object.entries(CUSTOM_FIELD_DEFS)
						.map(([ id, def ]) => (
							<section className="panel stack-sm" key={ id }>
								<PowercodeCustomFieldSelect
									id={ id as PowercodeCustomFieldsModel.Key }
									model={ controller.model }
									{ ...def }
								/>
							</section>
						))
				}
			</div>
			<PowercodeCustomFieldsSubmit
				className="powercode-custom-fields-view__submit"
				controller={ controller }
			/>
		</form>
	);
};