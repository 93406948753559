import type { NetworkItem } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import type { NetworkQueryResult } from '../../../../network-query';
import classNames from 'classnames';
import { Icon, NetworkItemIcon } from '../../../../components/atoms/icon';
import { getSnmpStatusClassName, getSnmpStatusDisplayInfo } from '../../../../util/snmp';
import './inventory-result-view.scss';


const itemDescription = (item: NetworkItem): string => {
	switch(item.kind) {
		case 'device':
			return `${item.type} (${item.deviceType})`;
		case 'site':
			if(item.usage === 'Customer') {
				return 'Customer Site';
			}
			return `Infrastructure Site (${item.type})`;
	}
};

const ResultStatus = ({ result }: InventoryResultViewProps): JSX.Element | null => {
	if(result.item.kind !== 'device') {
		return null;
	}

	const { icon, title } = getSnmpStatusDisplayInfo(result.item.snmpStatus);
	
	return (
		<span
			className={ classNames('flex-align-center gap-xs text-sm', getSnmpStatusClassName(result.item.snmpStatus)) }
		>
			<Icon
				icon={ icon }
				size="sm"
			/>
			SNMP: { title }
		</span>
	);
};

export type InventoryResultViewProps = {
	result: NetworkQueryResult;
};
export const InventoryResultView = ({ result }: InventoryResultViewProps): JSX.Element => {
	return (
		<a className="inventory-result-view panel" href={ '#' + result.item.networkId }>
			<header className="inventory-result-view__header">
				<NetworkItemIcon
					className="inventory-result-view__header-icon"
					item={ result.item }
					size="lg"
				/>
				<strong className="inventory-result-view__header-name">
					{result.item.name}
				</strong>
				<div className="inventory-result-view__header-description text-sm">
					{itemDescription(result.item)}
				</div>
			</header>

			{ !result.matchedFields.length && (
				<ResultStatus result={ result }/>
			)}

			{ !!result.matchedFields.length && (
				<ul className="inventory-result-view__matched-field-list panel-group">
					{ result.matchedFields.map(f => (
						<li className="panel panel--dark" key={ f }>
							<strong>{ f.name }</strong>:&nbsp;
							<span>{ f.value }</span>
						</li>
					)) }
				</ul>
			)}
		</a>
	);
};