import type { JSX } from 'preact/jsx-runtime';
import type { RepositoryErrorProps } from './repository-error';


export const PowercodeRepositoryError = ({ error }: RepositoryErrorProps): JSX.Element => {
	switch(error.status) {
		case 'authentication-error': return (
			<>
				<p>
					Bearing failed to authenticate with Powercode using the configured
					credentials.
				</p>
				<p>
					This might happen if the associated User was deleted or the password
					was changed on the Powercode Portal.
				</p>
			</>
		);
		
		case 'network-error': return (
			<>
				<p>
					Bearing failed to connect to Powercode on the configured Portal URL.
				</p>
				<p>
					This might happen if the URL has changed, there is no route between
					Bearing and the Powercode Portal or the Powercode Portal is down for
					maintenance. Bearing will recover as soon as the network route is
					restored.
				</p>
			</>
		);
		
		case 'upstream-error': return (
			<>
				<p>
					Bearing is having trouble retrieving data from Powercode.
				</p>
				<p>
					This can be caused by a variety of issues such as user interference or
					an upgrade to Powercode Portal. Please see the error details below for
					specific information.
				</p>
			</>
		);
		
		case 'permission-error': return (
			<>
				<p>
					Bearing does not have sufficient permissions on Powercode Portal.
				</p>
				<p>
					Please verify that the Bearing user has these permissions:
				</p>
				<ul>
					<li><code>Reports &rArr; Custom &rArr; Custom Reports = Edit</code></li>
					<li><code>Reports &rArr; Dashboard &rArr; Reports Dashboard = View</code></li>
				</ul>
			</>
		);
		default:
			return <p>TODO: write explanation for {error.status}</p>;
	}
};
