import type { JSX } from 'preact';
import { useObservable } from 'ecce-preact';
import { useEffect } from 'preact/hooks';
import { useNotificationService, type ActiveNotification } from '../../../services';
import { Notification } from './notification';
import './notification-area.scss';


export type NotificationAreaProps = {
	notifications: ActiveNotification[];
};
export const NotificationArea = (): JSX.Element => {
	const notificationService = useNotificationService();
	useObservable(notificationService, 'notifications');

	const { notifications } = notificationService;

	useEffect(() => {
		const els = document.querySelectorAll('[data-notification]');

		let nextY = 0;
		for(let i = els.length - 1; i>=0; --i) {
			const el = els.item(i);
			const notification = notifications[i];


			const x = notification.dismissed ? '100%' : 0;
			const y = notification.dismissed ? notification.y : nextY;
			const opacity = notification.dismissed ? 0 : 1;

			el.setAttribute('style', `transform: translate(${x}, ${y}px); opacity: ${opacity};`);

			if(!notification.dismissed) {
				notification.y = nextY;
				nextY -= el.getBoundingClientRect().height;
			}

			el.firstElementChild?.setAttribute('style', 'opacity: 1');
		}
	}, [notifications]);

	return (
		<div className="notification-area">
			{notifications.map(notification => (
				<Notification notification={ notification } key={ notification.id }/>
			))}
		</div>
	);
};