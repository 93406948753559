import type { VNode } from 'preact';
import { GpsSyncSettings } from './gps-sync-settings';
import { SnmpSettings } from './snmp-settings';
import { ErrorReportSettings } from './error-report-settings';


export const SettingsAdmin = (): VNode | null => {
	return (
		<div className="form panel-group">
			<SnmpSettings/>
			<GpsSyncSettings/>
			<ErrorReportSettings/>
		</div>
	);
};