import type { ClassProp } from '../../../util';
import type { JSX } from 'preact/jsx-runtime';


export type StackTraceProps = ClassProp & {
	error: Error;
};
export const StackTrace = ({ error }: StackTraceProps): JSX.Element => (
	<pre className="error-dialog__details">
		<strong>{error.name}: {error.message}</strong><br/>
		<div className="mrg-l-sm">
			{error.stack}
		</div>
	</pre>
);