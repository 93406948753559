import { useObservable } from 'ecce-preact';
import { Link } from '../../../../components';
import { NetworkItemIcon } from '../../../../components/atoms/icon';
import { useRepositoryProviderName } from '../../../../services';
import { MapUrl } from '../../../../util';
import { useInventoryController } from '../../inventory-controller-context';
import { InventoryDetailsDevice } from './inventory-details-device';
import { InventoryDetailsSite } from './inventory-details-site';
import './inventory-focus-view.scss';


export const InventoryFocusView = () => {
	const repositoryName = useRepositoryProviderName();
	const inventory = useInventoryController();
	useObservable(inventory, 'focusedItem');

	if(!inventory.focusedItem) {
		return null;
	}

	const item = inventory.focusedItem;
	
	return (
		<div className="inventory-focus-view">
			<div className="inventory-focus-view__header">
				<h2 className="inventory-focus-view__title">
					<NetworkItemIcon item={ item } size="lg"/>
					<span>{item.name}</span>
				</h2>
				<nav className="inventory-focus-view__nav">
					<Link
						label="Inventory"
						icon="arrowLeft"
						href="#"
					/>
					{ item.kind === 'site' && !!item.devices.length && (
						<Link
							label="Site Overview"
							icon="pieChart"
							href={ '/?siteId=' + item.id }
						/>
					) }
					<Link
						label="Map"
						icon="mapPin"
						href={ MapUrl.forItem(item).toRelativeUrl() }
					/>
					{ item.link && (
						<Link
							label={ repositoryName }
							href={ item.link }
							icon="powercode"
						/>
					)}
				</nav>
			</div>
			<div>
				{ item.kind === 'site' && <InventoryDetailsSite site={ item }/> }
				{ item.kind === 'device' && <InventoryDetailsDevice device={ item }/>}
			</div>
		</div>
	);
};