import type { ClassProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import { useEffect, useRef } from 'preact/hooks';


export type IntersectorProps = ClassProp & {
	onIntersectionBegin?: VoidFunction;
	onIntersectionEnd?: VoidFunction;
};

/**
 * Utility component which emits events when it scrolls in or out of view.
 */
export const Intersector = ({ className, onIntersectionBegin, onIntersectionEnd }: IntersectorProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const el = ref.current;
		if(!el) {
			return;
		}

		let intersecting = false;

		const observer = new IntersectionObserver(([ entry ]) => {
			if(entry.isIntersecting && !intersecting) {
				intersecting = true;
				onIntersectionBegin?.();
			} else if(intersecting) {
				intersecting = false;
				onIntersectionEnd?.();
			}
		});

		observer.observe(el);
		return () => observer.disconnect();
	}, [onIntersectionBegin, onIntersectionEnd]);
	
	return <div style="height: 1px" className={ className } ref={ ref }/>;
};