import type { CustomerSite, Device } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { Color } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { useMapController } from '../map-controller-context';
import { Marker, Polyline } from './google-maps';


const PATH = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';

const UNASSIGNED_COLOR: Color = Color.black;
const MISSING_DATA_COLOR: Color = new Color(181, 181, 181);



const getPinColor = (device: Device | null | undefined, highlight: boolean | undefined): Color => {
	const radio = device?.radios[0];
	if(!radio) {
		return UNASSIGNED_COLOR;
	}
	if(!radio.band) {
		return MISSING_DATA_COLOR;
	}

	const color = radio.band.color(radio.frequency, .15);
	
	return !highlight ? color.desaturate(0.75) : color;
};

const getPinLabel = (customer: CustomerSite, device: Device | null | undefined): string => {
	if(!device) {
		return `${customer.name} (not assigned)`;
	}
	if(!device.radios[0].band) {
		return `${customer.name} (missing frequency data)`;
	}
	return `${customer.name} (${device.name} ${device.radios[0].frequency}MHz)`;
};

export type MapCustomerSitePinProps = {
	customer: CustomerSite;
	device?: Device | null | undefined;
	highlight?: boolean;
	lineToDevice?: boolean;
	label?: boolean;
};

export const MapCustomerSitePin = ({ customer, device, highlight, lineToDevice, label }: MapCustomerSitePinProps): JSX.Element => {
	const controller = useMapController();
	useObservable(controller, 'palette');

	const labelText = getPinLabel(customer, device);

	
	const color = getPinColor(device, highlight);
	const borderColor = highlight ? color.darken(highlight ? .15 : .3) : color;
	const opacity = highlight ? .9 : .7;
	return (
		<>
			<Marker
				position={ customer.location }
				icon={ {
					path: PATH,
					fillColor: color.toRGBAHex(opacity),
					fillOpacity: 1,
					strokeColor: borderColor.toRGBAHex(highlight ? 1 : opacity),
					strokeWeight: 2,
				} }
				title={ getPinLabel(customer, device) }
				onClick={ () => controller['handleItemClicked'](customer) }
				label={ label ? {
					text: labelText,
					color: controller.palette.text,
				} : undefined }
			/>
			{ lineToDevice && device && (
				<Polyline
					path={ [
						customer.location as google.maps.LatLngLiteral & string,
						device.location as google.maps.LatLngLiteral & string,
					] as any } // eslint-disable-line @typescript-eslint/no-explicit-any
					options={ {
						strokeColor: color.toRGBHex(),
						strokeWeight: 2,
					} }
				/>
			)}
		</>
	);
};