import type { JSX } from 'preact';
import { useMemo } from 'preact/hooks';
import { useNotificationService, type ActiveNotification } from '../../../services';
import { Icon } from '../../atoms';
import './notification.scss';


const shouldDismissFromClick = (target: HTMLElement | null, root: HTMLElement): boolean => {
	if(!target || target === root) {
		return false;
	}

	switch(target.tagName) {
		case 'A':
		case 'BUTTON':
			return true;
		default:
			return shouldDismissFromClick(target.parentElement, root);
	}
};

export type NotificationProps = {
	notification: ActiveNotification;
};
export const Notification = ({ notification }: NotificationProps): JSX.Element => {
	const notificationService = useNotificationService();

	const dismiss = () => {
		notificationService.dismiss(notification.id);
	};

	const handleClickCapture: JSX.MouseEventHandler<HTMLDivElement> = ev => {
		if(ev.target instanceof HTMLElement && shouldDismissFromClick(ev.target, ev.currentTarget)) {
			dismiss();
		}
	};
	
	const icon = useMemo<string>(() => {
		switch(notification.info.severity) {
			case 'info':    return 'fa-info-circle';
			case 'success': return 'fa-check-circle';
			case 'warning': return 'fa-exclamation-triangle';
			case 'error':   return 'fa-exclamation-circle';
		}
	}, [notification.info.severity]);

	return (
		<div
			className="notification-wrapper"
			onClickCapture={ handleClickCapture }
			data-notification
		>
			<div className={ `notification notification--${notification.info.severity}` }>
				<i className={ `notification__icon fas ${icon}` }/>
				<div className="notification__message">
					{ notification.info.message }
				</div>
				<button className="btn btn--icon-only" onClick={ dismiss }>
					<Icon icon="x"/>
					<span className="visually-hidden">Dismiss notification</span>
				</button>
			</div>
		</div>
	);
};