import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../../../util';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { ItemKindFilterView, SiteUsageFilterView } from '../../../../../filtering';
import { DeviceTypeFilterView } from '../../../../../filtering/device-type-filter';
import { useInventoryController } from '../../../inventory-controller-context';
import { InventoryQueryInput } from './inventory-query-input';
import './inventory-filter-view.scss';
import { InventoryDeviceOrderView } from './inventory-device-order-view';


export const InventoryFilterView = ({ className }: ClassProp): JSX.Element => {
	const controller = useInventoryController();
	useObservable(controller.itemKindFilter);

	return (
		<div role="search" className={ classNames('inventory-filter-view', className) }>
			<ItemKindFilterView
				className="inventory-filter-view__kinds"
				filter={ controller.itemKindFilter }
			/>
			<InventoryQueryInput className="inventory-filter-view__query"/>
			<div className="inventory-filter-view__kindSpecificFilters">
				{ controller.itemKindFilter.devices && (
					<div className="flex">
						<DeviceTypeFilterView filter={ controller.deviceTypeFilter }/>
						<InventoryDeviceOrderView className="mrg-l-auto"/>
					</div>
				)}
				{ controller.itemKindFilter.sites && (
					<SiteUsageFilterView filter={ controller.siteUsageFilter }/>
				)}
			</div>
		</div>
	);
};