import type { JSX } from 'preact/jsx-runtime';
import type { SpectrumSliderController } from './spectrum-slider-controller';
import { bound, observable } from 'ecce-preact';
import { SpectrumGraphSegment } from '../spectrum-graph';


type SegmentPositionInfo = Readonly<{
	/** The graph segment at the specified position. */
	segment: SpectrumGraphSegment;

	/** The bounds of the Element which represents the segment. */
	elementBounds: Readonly<DOMRect>;
	
	/** The frequency at the the specified position. */
	frequency: number;
}>;

export class SpectrumSliderInputManager {
	readonly #slider: SpectrumSliderController;

	/** HTML Element representing the spectrum graph. */
	#graphEl: HTMLDivElement | null = null;
	
	#dragging = false;
	get dragging(): boolean { return this.#dragging; }
	@observable() private set dragging(value: boolean) { this.#dragging = value; }
	
	#dragOffsetX = 0;
	
	constructor(slider: SpectrumSliderController) {
		this.#slider = slider;
	}

	@bound()
	graphRef(el: HTMLDivElement | null): void {
		this.#graphEl = el;
	}
	
	@bound()
	handleKeyDown(ev: JSX.TargetedKeyboardEvent<HTMLDivElement>): void {
		switch(ev.key) {
			case 'ArrowRight':
				this.#slider.addFrequencyStep(1);
				break;
			case 'PageUp':
				this.#slider.addFrequencyStep(2);
				break;
			case 'ArrowLeft':
				this.#slider.addFrequencyStep(-1);
				break;
			case 'PageDown':
				this.#slider.addFrequencyStep(-2);
				break;
			case 'Home':
				this.#slider.setFrequency(this.#slider.minFrequency);
				break;
			case 'End':
				this.#slider.setFrequency(this.#slider.maxFrequency);
				break;
		}
		ev.currentTarget.focus();
	}

	@bound()
	handlePointerDownCapture(ev: JSX.TargetedPointerEvent<HTMLDivElement>): void {
		ev.currentTarget.focus();
		this.#beginDragging(ev);
	}

	#getSegmentPositionInfoAtX(screenX: number): SegmentPositionInfo | null {
		if(!this.#graphEl) {
			return null;
		}

		const segmentEls = this.#graphEl.querySelectorAll(`[${SpectrumGraphSegment.ID_ATTRIBUTE}]`);
		for(let i=0; i<segmentEls.length; ++i) {
			const element = segmentEls.item(i);
			const elementBounds = element.getBoundingClientRect();

			if(screenX >= elementBounds.x && screenX < elementBounds.x + elementBounds.width) {
				const segment = this.#slider.graph.getSegment(element.getAttribute(SpectrumGraphSegment.ID_ATTRIBUTE));
				if(!segment) {
					continue;
				}

				const offsetX = screenX - elementBounds.x;
				const ratio = offsetX / elementBounds.width;
				const frequency = segment.getFrequencyAtRatio(ratio);

				return { segment, elementBounds, frequency };
			}
		}

		return null;
	}

	#beginDragging(ev: JSX.TargetedPointerEvent<HTMLDivElement>) {
		if(!this.#graphEl || this.dragging) {
			return;
		}

		const info = this.#getSegmentPositionInfoAtX(ev.clientX);
		if(!info) {
			return;
		}

		/*
			If clicking directly on the slider's 'thumb' (the `used` segment), set
			`dragOffsetX` to maintain the spatial relationship between the mouse
			cursor and the segment.
		*/
		if(info.segment.kind === 'used') {
			const { x: thumbX, width: thumbWidth } = info.elementBounds;
			const thumbCenter = thumbX + (thumbWidth / 2);
			this.#dragOffsetX = ev.clientX - thumbCenter;
		} else {
			this.#dragOffsetX = 0;
			this.#slider.setFrequency(info.frequency);
		}

		this.dragging = true;
		this.#graphEl.setPointerCapture(ev.pointerId);
		this.#graphEl.addEventListener('pointermove', this.#handleDragPointerMoveEvent);
		this.#graphEl.addEventListener('pointercancel', this.#handleDragPointerUp);
		this.#graphEl.addEventListener('pointerup', this.#handleDragPointerUp);
	}

	#endDragging(ev: PointerEvent) {
		if(!this.#graphEl || !this.dragging) {
			return;
		}

		this.dragging = false;
		this.#graphEl.releasePointerCapture(ev.pointerId);
		this.#graphEl.removeEventListener('pointermove', this.#handleDragPointerMoveEvent);
		this.#graphEl.removeEventListener('pointerup', this.#handleDragPointerUp);
		this.#graphEl.removeEventListener('pointercancel', this.#handleDragPointerUp);
	}

	#handleDragPointerMoveEvent = (ev: PointerEvent): void => {
		ev.preventDefault();
		
		if(!this.dragging) {
			this.#endDragging(ev);
		}
		
		const info = this.#getSegmentPositionInfoAtX(ev.clientX - this.#dragOffsetX);
		if(!info) {
			return;
		}

		this.#slider.setFrequency(info.frequency);
	};

	#handleDragPointerUp = (ev: PointerEvent) => {
		this.#endDragging(ev);
	};
}