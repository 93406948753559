import type { JSX } from 'preact';
import type { ClassProp } from '../../util';
import classNames from 'classnames';
import './checkbox.scss';


export type CheckboxProps = ClassProp & {
	id: string;
	label: string;
	checked?: boolean;
	onChange?: (newChecked: boolean) => void;
	disabled?: boolean;
};
export const Checkbox = ({ id, className, label, checked, onChange, disabled }: CheckboxProps): JSX.Element => {
	return (
		<div className={ classNames(className, 'checkbox') }>
			<input
				id={ id }
				type="checkbox"
				checked={ checked }
				onChange={ ev => onChange?.(ev.currentTarget.checked) }
				disabled={ disabled }
			/>
			<label for={ id }>{ label }</label>
		</div>
	);
};
