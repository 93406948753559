import type { NetworkItem } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../util';
import type { MapRouteController } from '../map-route-controller';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { usePopper } from 'react-popper';
import { NetworkItemIcon } from '../../../components';
import { MapTextField } from '../../../map';
import './map-route-search.scss';


const INPUT_ID = 'mapSearchInput';
const RESULTS_ID = 'mapSearchResults';
const getResultItemId = (item: NetworkItem): string => 'mapSearchResult-' + item.networkId;

export type MapRouteSearchProps = ClassProp & {
	controller: MapRouteController;
	onSelectItem?: VoidFunction;
};
export const MapRouteSearch = ({ className, controller, onSelectItem }: MapRouteSearchProps): JSX.Element => {
	const [ root, setRoot ] = useState<HTMLDivElement| null>(null);
	const [ popup, setPopup ] = useState<HTMLDivElement| null>(null);
	const { styles, attributes } = usePopper(root, popup, {
		placement: 'bottom-start',
		strategy: 'fixed',
	});

	useObservable(controller.query, 'search');
	useObservable(controller.query, 'results');
	
	const [ open, setOpen ] = useState(false);
	const [ activeIndex, setActiveIndex ] = useState<number | null>(null);
	
	const close = useCallback(() => {
		setOpen(false);
		setActiveIndex(null);
	}, []);

	const selectItem = () => {
		if(activeIndex === null) {
			return;
		}

		const result = controller.query.results?.[activeIndex];
		if(result) {
			controller.display.setFocus(result.item);
			controller.map?.setCenter(result.item.location);
		}

		onSelectItem?.();
		close();
	};

	// Keyboard listbox behaviour.
	const handleKeyDown: JSX.KeyboardEventHandler<HTMLInputElement> = ev => {
		if(ev.key === 'Escape') {
			return close();
		}
		
		if(!controller.query.results?.length) {
			return;
		}
		setOpen(true);
		
		// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role#keyboard_interactions
		let dir = 0;
		switch(ev.key) {
			case 'Enter':
				return selectItem();
			case 'Home':
				return setActiveIndex(0);
			case 'End':
				return setActiveIndex(controller.query.results.length - 1);
			case 'ArrowDown':
				dir=1;
				break;
			case 'ArrowUp':
				dir=-1;
				break;
			default:
				return;
		}

		if(dir !== 0) {
			let nextIndex = (activeIndex ?? -1) + dir;
			if(nextIndex < 0) {
				nextIndex = controller.query.results.length - 1;
			} else if(nextIndex >= controller.query.results.length) {
				nextIndex = 0;
			}
			setActiveIndex(nextIndex);

			// Scroll the item in to view.
			const activeResult = controller.query.results?.[nextIndex];
			if(!activeResult) {
				return;
			}

			const el = document.getElementById(getResultItemId(activeResult.item));
			const parent = popup;
			if(el && parent) {
				const elBounds = el.getBoundingClientRect();
				const parentBounds = parent.getBoundingClientRect();
			
				if(elBounds.top < parentBounds.top) {
					el.scrollIntoView({ block: 'start' });
				}
				if(elBounds.bottom > parentBounds.bottom) {
					el.scrollIntoView({ block: 'end' });
				}
			}
		}
	};

	useEffect(() => {
		if(!root || !popup) {
			return;
		}
		
		const handleChange = () => {
			popup.style.width = root.clientWidth + 'px';
		};
		const observer = new ResizeObserver(handleChange);
		observer.observe(root);

		return () => {
			observer.disconnect();
		};
	}, [root, popup]);

	return (
		<div className={ className }>
			<div className={ classNames('map-route-search') } ref={ setRoot }>
				<label htmlFor={ INPUT_ID } className="visually-hidden">
					Search
				</label>
				<MapTextField
					id={ INPUT_ID }
					icon="search"
					value={ controller.query.search }
					onChange={ controller.query.setSearch }
					onFocus={ () => setOpen(true) }
					onBlur={ ev => {
						// Close the results popup, unless focus has move to it.
						if((ev.relatedTarget as HTMLElement)?.id !== RESULTS_ID) {
							close();
						}
					} }
					onClick={ () => setOpen(true) }
					onKeyDown={ handleKeyDown }
				/>
			</div>
			{ open && (
				<div
					ref={ setPopup }
					style={ styles.popper }
					{ ...attributes.popper }
				>
					<div
						id="mapSearchResults"
						className="map-route-search-results"
						role="listbox"
						aria-activedescendant={ controller.query.results?.[activeIndex ?? -1]?.item.networkId ?? undefined }
						aria-label="Search results"
						tabIndex={ -1 }
					>
						{!controller.query.results && (
							<div className="map-route-search-results__message">
								Search for sites, equipment, and customers.
							</div>
						)}

						{ !!controller.query.results && controller.query.results.length === 0 && (
							<div className="map-route-search-results__message text-center">
								No results for "{controller.query.search}"
							</div>
						)}

						{ controller.query.results?.map((r, index) => (
							<div
								role="option"
								id={ getResultItemId(r.item) }
								className="map-route-search-result"
								key={ r.item.networkId }
								data-selected={ index === activeIndex }
								onMouseMove={ () => setActiveIndex(index) }
								onClick={ () => { setActiveIndex(index); selectItem(); } }
							>
								<NetworkItemIcon
									className="map-route-search-result__icon"
									item={ r.item }
									size="sm"
								/>
								<span className="map-route-search-result__name">
									{r.item.name}
								</span>
								{ !!r.matchedFields.length && (
									<ol className="map-route-search-result__fields">
										{ r.matchedFields.map(field => (
											<li key={ field }>
												{ field.name }: { field.value }
											</li>
										))}
									</ol>
								)}
								<div/>
							</div>
						)) }
					</div>
				</div>
			)}
		</div>
	);
};