import type { Point } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp } from '../../../util';
import classNames from 'classnames';
import { InfoWindow } from '../google-maps';
import './map-info-popup.scss';


export type MapInfoPopupProps = ClassProp & ChildrenProp & {
	position: Point;
	title: string;
	offsetY?: number;
	onClose?: VoidFunction;
};
export const MapInfoPopup = ({ className, position, offsetY, onClose, title, children }: MapInfoPopupProps): JSX.Element => {
	return (
		<InfoWindow
			options={ {
				position,
				pixelOffset: new google.maps.Size(0, offsetY ?? 0),
			} }
			onCloseClick={ onClose }
		>
			<div className="map-info-popup">
				<h2 className="map-info-popup__title">{ title }</h2>
				<div className={ classNames('map-info-popup__content', className) }>
					{ children }
				</div>
			</div>
		</InfoWindow>
	);
};