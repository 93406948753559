import type { FunctionComponent, VNode } from 'preact';
import type { ClassProp } from '../../util';
import classNames from 'classnames';
import './more-menu.scss';
import { useEffect, useRef } from 'preact/hooks';
import { Expander } from '../expander';


export type MoreMenuButtonProps = ClassProp & {
	open: boolean;
	onClick: () => void;
};
export const MoreMenuButton = ({ open, onClick, className }: MoreMenuButtonProps): VNode => {
	return (
		<button
			className={ classNames('btn--icon-only more-menu-button', className, { 'more-menu-button--open': open }) }
			onClick={ onClick }
		>
			<div className="more-menu-button__icons">
				<i class="fas fa-ellipsis-h"/>
				<i class="fas fa-times"/>
			</div>
		</button>
	);
};

export type MoreMenuProps = {
	open: boolean;
	onClose: () => void;
};
export const MoreMenu: FunctionComponent<MoreMenuProps> = ({ open, onClose, children }): VNode => {
	const rootEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if(!open) {
			return;
		}
		const root = rootEl.current;
		const handleClick = (ev: MouseEvent) => {
			if(ev.target instanceof HTMLElement && !root?.contains(ev.target)) {
				onClose();
			}
		};

		window.addEventListener('click', handleClick);
		return () => { window.removeEventListener('click', handleClick); };
		
	}, [onClose, open]);
	
	return (
		<Expander open={ open } duration="sm" alwaysTransition>
			<div
				ref={ rootEl }
				className="more-menu"
			>
				{ children }
			</div>
		</Expander>
	);
};