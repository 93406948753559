import TEMP_PROBE_DATA from './temp-data.json';


export type SnmpProbeData = {
	name: string;
	manufacturerId: string;
	manufacturerName: string;
	oid: string;
	minimum: number;
	maximum: number;
	unitOfMeasurement: string;
	dataType: string;
	probeType: string;
	deviceTypes: string[];
	radioModels: string[] | null;
};

export type SnmpProbeManufacturerData = {
	kind: 'manufacturer';
	name: string;
};
export type SnmpProbeDeviceTypeData = {
	kind: 'deviceType';
	name: string;
	manufacturerName: string;
};
export type SnmpProbeRadioModelData = {
	kind: 'radioModel';
	name: string;
	manufacturerName: string;
};

export type SnmpProbeDataset = Readonly<{
	probes: readonly Readonly<SnmpProbeData>[];
	manufacturers: readonly Readonly<SnmpProbeManufacturerData>[];
	deviceTypes: readonly Readonly<SnmpProbeDeviceTypeData>[];
	radioModels: readonly Readonly<SnmpProbeRadioModelData>[];
}>;

// Simulate data structure received from /data or whatever endpoint.
export const SNMP_PROBE_DATA: SnmpProbeDataset = (() => {
	const probes: SnmpProbeData[] = [];

	const manufacturers = new Map<string, SnmpProbeManufacturerData>();
	const deviceTypes = new Map<string, SnmpProbeDeviceTypeData>();
	const radioModels = new Map<string, SnmpProbeRadioModelData>();
	
	for(const probeData of TEMP_PROBE_DATA as readonly SnmpProbeData[]) {
		probes.push(probeData);

		if(!manufacturers.has(probeData.manufacturerName)) {
			manufacturers.set(probeData.manufacturerName, {
				kind: 'manufacturer',
				name: probeData.manufacturerName,
			});
		}

		for(const deviceType of probeData.deviceTypes) {
			if(!deviceTypes.has(deviceType)) {
				deviceTypes.set(deviceType, {
					kind: 'deviceType',
					name: deviceType,
					manufacturerName: probeData.manufacturerName,
				});
			}
		}

		if(probeData.radioModels) {
			for(const radioModel of probeData.radioModels) {
				if(!radioModels.has(radioModel)) {
					radioModels.set(radioModel, {
						kind: 'radioModel',
						name: radioModel,
						manufacturerName: probeData.manufacturerName,
					});
				}
			}
		}
	}

	return {
		probes,
		manufacturers: [ ...manufacturers.values() ].sort(),
		deviceTypes: [ ...deviceTypes.values() ].sort(),
		radioModels: [ ...radioModels.values() ].sort(),
	};
})();