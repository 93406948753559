import type { ActiveNotification, NotificationId } from './notification';
import { bound, notify, observable } from 'ecce-preact';
import { NotificationInfo } from './notification';


type NotificationListener = (notifications: ActiveNotification[]) => void;


export class NotificationService {
	static readonly #MAX_ACTIVE_NOTIFICATIONS = 5;
	static readonly #DEFAULT_LIFETIME = 10000;
	static readonly #DISMISSED_PERIOD = 5000;
	static readonly #TICK_INTERVAL = 200;
	
	#nextId = 0;
	readonly #listeners = new Set<NotificationListener>();

	readonly #persistent = new Set<ActiveNotification.Persistent>();
	readonly #transient = new Set<ActiveNotification.Transient>();

	initialise() {
		setInterval(() => this.#tick(), NotificationService.#TICK_INTERVAL);
	}
	
	@observable()
	get notifications(): ActiveNotification[] {
		return [ ...this.#transient, ...this.#persistent ];
	}

	#setDismissed(notification: ActiveNotification): void {
		if(!notification.dismissed) {
			notification.dismissed = true;
			notification.dismissedTime = Date.now();
		}
	}
	
	#updateNotifications(): void {
		const all = this.notifications;
		for(let i=all.length - (NotificationService.#MAX_ACTIVE_NOTIFICATIONS + 1); i>=0; --i) {
			this.#setDismissed(all[i]);
		}

		notify(this, 'notifications');
		this.#listeners.forEach(listener => listener(all));
	}

	#remove(notification: ActiveNotification): void {
		if(notification.persistent) {
			this.#persistent.delete(notification);
		} else {
			this.#transient.delete(notification);
		}
	}
	
	@bound()
	notify(info: NotificationInfo): NotificationId {
		if(info.type) {
			this.notifications
				.filter(n => info.type && n.info.type === info.type)
				.forEach(n => this.#setDismissed(n));
		}
		
		const common: ActiveNotification.Common = {
			id: this.#nextId++,
			time: Date.now(),
			dismissed: false,
			dismissedTime: -1,
			y: 0,
		};

		if(NotificationInfo.isPersistent(info)) {
			this.#persistent.add({
				...common,
				persistent: true,
				info,
			});
		} else {
			this.#transient.add({
				...common,
				persistent: false,
				info,
			});
		}

		this.#updateNotifications();
		return common.id;
	}

	@bound()
	dismiss(id: NotificationId): void {
		const notification = this.notifications.find(n => n.id === id);
		if(notification) {
			this.#setDismissed(notification);
			this.#updateNotifications();
		}
	}

	#tick(): void {
		let changed = false;

		this.notifications.forEach(notification => {
			if(notification.dismissed) {
				const age = Date.now() - notification.dismissedTime;
				if(age > NotificationService.#DISMISSED_PERIOD) {
					this.#remove(notification);
					changed = true;
				}
			} else if(!notification.persistent) {
				const age = Date.now() - notification.time;
				const lifetime = notification.info.lifetime ?? NotificationService.#DEFAULT_LIFETIME;
				if(age > lifetime) {
					this.#setDismissed(notification);
					changed = true;
				}
			}
		});

		if(changed) {
			this.#updateNotifications();
		}
	}
}