import type { JSX } from 'preact';
import type { RouteProps } from './route-props';
import { MapController, MapView } from '../map';
import { getGoogleMapsConsoleError, useConstant, type ConsoleError } from '../util';

/*
	Special internal route, not meant for public consumption! Loaded in an
	`<iframe>` from the admin in order to verify a Google Maps API key.

	Attempts to load a map using the API key provided by the `?key=` search param.
	We use `postMessage()` to communicate the result back to the parent `Window`.
*/

export namespace GoogleMapsKeyVerificationMessage {
	type Common = {
		messageType: 'google-maps-verify';
	};
	export type Ok = Common & {
		kind: 'ok';
	};
	export type Error = Common & {
		kind: 'error';
		error: ConsoleError.GoogleMap;
	};
	export type Timeout = Common & {
		kind: 'timeout';
	};
}
export type GoogleMapsKeyVerificationMessage = (
	| GoogleMapsKeyVerificationMessage.Ok
	| GoogleMapsKeyVerificationMessage.Error
	| GoogleMapsKeyVerificationMessage.Timeout
);

export const isGoogleMapsKeyVerificationMessage = (x: unknown): x is GoogleMapsKeyVerificationMessage => {
	return (
		typeof(x) === 'object'
		&& !!x
		&& 'messageType' in x
		&& x.messageType === 'google-maps-verify'
	);
};

class MapApiKeyVerifier {
	/** Milliseconds to wait for the map to first load. */
	static readonly #LOAD_TIMEOUT = 5000;

	/** Milliseconds to wait after the map has loaded for an auth error. */
	static readonly #AUTH_ERROR_TIMEOUT = 3000;
	
	readonly map: MapController;
	readonly apiKey: string;

	#loadTimeout: ReturnType<typeof setTimeout> | null = null;

	constructor() {
		this.apiKey = new URLSearchParams(window.location.search).get('key') ?? 'no-key';

		// If we fail to load the map after a short time, fail with a timeout.
		this.#loadTimeout = setTimeout(() => {
			this.#postMessage({
				messageType: 'google-maps-verify',
				kind: 'timeout',
			});
		}, MapApiKeyVerifier.#LOAD_TIMEOUT);
		
		this.map = new MapController()
			.on('loaded', () => {
				// Map has loaded!
				if(this.#loadTimeout) {
					clearTimeout(this.#loadTimeout);
					this.#loadTimeout = null;
				}

				/*
					There is a short delay before Google reports auth errors. If we don't
					see any after a short time, consider the key verified.
				*/
				setTimeout(() => {
					const error = getGoogleMapsConsoleError();
					if(error) {
						return this.#postMessage({
							messageType: 'google-maps-verify',
							kind: 'error',
							error,
						});
					}
					
					this.#postMessage({
						messageType: 'google-maps-verify',
						kind: 'ok',
					});
				}, MapApiKeyVerifier.#AUTH_ERROR_TIMEOUT);
			});
	}

	#postMessage(message: GoogleMapsKeyVerificationMessage): void {
		window.parent.postMessage(message, window.location.origin);
	}
}

export const VerifyMapsKeyRoute = (_: RouteProps): JSX.Element => {
	const mapVerifier = useConstant(() => new MapApiKeyVerifier());
	
	return (
		<MapView
			controller={ mapVerifier.map }
			overrideGoogleMapsApiKey={ mapVerifier.apiKey }
		/>
	);
};