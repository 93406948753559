import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { useEffect, useRef } from 'preact/hooks';
import { useInventoryController } from '../../inventory-controller-context';
import { Viewport } from '../../../../components';
import { InventoryFilterView } from './inventory-filter-view';
import { InventoryNoResultsMessage } from './inventory-no-results';
import { InventoryResultListView } from './inventory-result-list-view';
import './inventory-search-view.scss';


export const InventorySearchView = (): JSX.Element | null => {
	const inventory = useInventoryController();
	useObservable(inventory, 'focusedItem');
	useObservable(inventory.query, 'results');

	const viewportRef = useRef<HTMLDivElement>(null);
	
	useEffect(() => {
		viewportRef.current?.scrollTo({ top: 0 });
	}, [inventory.query.results]);
	
	if(inventory.focusedItem) {
		return null;
	}

	return (
		<div className="inventory-search-view">
			<InventoryFilterView className="inventory-search-view__filter"/>
			<Viewport ref={ viewportRef } className="inventory-search-view__results">
				<InventoryResultListView/>
				<InventoryNoResultsMessage/>
			</Viewport>
		</div>
	);
};