import type { JSX, VNode } from 'preact';
import type { ConsoleError } from '../../../util';
import { useObservable } from 'ecce-preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { Alert, ModalLoadingIndicator } from '../../../components';
import { ExternalLink } from '../../../components/external-link';
import { getGoogleMapsConsoleError } from '../../../util';
import { isGoogleMapsKeyVerificationMessage } from '../../verify-maps-key-route';
import { useNotificationService, useSettingsService } from '../../../services/services-context';


class MapKeyError extends Error {
	constructor(public readonly mapError: ConsoleError.GoogleMap) {
		super('Failed to authenticate with google maps: ' + mapError.errorName);
	}
}

const verifyGoogleMapsKey = (key: string): Promise<void> => {
	const iframe = document.createElement('iframe');
	iframe.src=`__/verify-maps-key?key=${key}`;
	iframe.style.position = 'fixed';
	iframe.style.left = '100vw';
	iframe.style.width = '200px';
	iframe.style.height = '200px';

	document.body.appendChild(iframe);

	const promise = new Promise<void>((resolve, reject) => {
		const handleMessage = (ev: MessageEvent) => {
			if(ev.origin !== window.location.origin) {
				return;
			}

			const { data } = ev;
			if(!isGoogleMapsKeyVerificationMessage(data)) {
				return;
			}

			iframe.remove();
			window.removeEventListener('message', handleMessage);
			
			switch(data.kind) {
				case 'timeout':
					/*
						This would only happen if the map failed to load at all in the
						<iframe>, which is a situation we'd hope would never happen...
					*/
					// eslint-disable-next-line no-alert
					alert(
						'We were unable to determine whether the Google Maps API key you provided was valid.\n\n'
						+ 'Please visit the Map page to verify it is working.'
					);
					// Fallthrough
				case 'ok':
					resolve();
					return;
				case 'error':
					reject(new MapKeyError(data.error));
			}
		};
		
		window.addEventListener('message', handleMessage);
	});
	return promise;
};


type KeyErrorMessageProps = {
	error: ConsoleError.GoogleMap | null
};
const KeyErrorMessage= ({ error }: KeyErrorMessageProps): VNode | null => {

	type KeyErrorMessage = {
		title: string;
		message: VNode;
	};
	const message = useMemo<KeyErrorMessage | null>(() => {
		if(!error) {
			return null;
		}

		switch(error.errorName) {
			case 'InvalidKeyMapError':
				return {
					title: 'Invalid Key',
					message: (
						<>
							<p>
								That does not appear to be a valid Google Maps API key. Double
								check the key and try again.
							</p>
							<p>
								You can find a guide to creating Google Maps API keys on&nbsp;
								<ExternalLink href="https://developers.google.com/maps/documentation/javascript/get-api-key">
									Google's website
								</ExternalLink>.
							</p>
						</>
					),
				};
			case 'RefererNotAllowedMapError':
				return {
					title: 'Cannot use that key on this domain',
					message: (
						<>
							<p>
								You have "HTTP referrer" restrictions enabled for this key, and
								your Bearing domain is not allowed to use it.
							</p>
							<p>
								Make sure that <strong><code>{window.location.origin}</code></strong> is
								on the list of domains allowed to use this key.
							</p>
							<p>
								More information can be found on&nbsp;
								<ExternalLink href="https://developers.google.com/maps/documentation/javascript/get-api-key#restrict_key">
									Google's website
								</ExternalLink>.
							</p>
						</>
					),
				};
			case 'ApiTargetBlockedMapError':
				return {
					title: 'Cannot use Google Maps with that key',
					message: (
						<>
							<p>
								API restrictions are in place for this key which prevent us
								from using it to display dynamic maps.
							</p>
							<p>
								Make sure that the <strong>Maps Javascript API</strong> is on the
								list of enabled services for this key.
							</p>
							<p>
								A guide to setting up API restrictions can be found on&nbsp;
								<ExternalLink href="https://developers.google.com/maps/api-security-best-practices#api-restriction">
									Google's website
								</ExternalLink>.
							</p>
						</>
					),
				};
			case 'ApiNotActivatedMapError':
				return {
					title: 'Cannot use Google Maps with that key',
					message: (
						<>
							<p>
								The <strong>Maps Javascript API</strong> is not enabled for the
								Google Cloud Project that key belongs to.
							</p>
							<p>
								You can find a guide to setting up a Google Cloud project to use
								Google Maps on&nbsp;
								<ExternalLink href="https://developers.google.com/maps/documentation/javascript/cloud-setup#enabling-apis">
									Google's website
								</ExternalLink>.
							</p>
						</>
					),
				};
			default:
				return {
					title: 'Unable to use that key',
					message: (
						<>
							{ error.errorName ? (
								<p>
									Google Maps API responded with an error:&nbsp;
									<strong><code>{error.errorName}</code></strong>
								</p>
							) : (
								<p>
									Failed to authenticate with the Google Maps API using that key.
								</p>
							)}
							{ error.errorUrl ? (
								<p>
									More information is available on&nbsp;
									<ExternalLink href={ error.errorUrl }>Google's website</ExternalLink>.
								</p>
							) : (
								<p>
									More information may be available in your browser's console.
								</p>
							)}
						</>
					),
				};
		}
	}, [error]);

	if(!message) {
		return null;
	}
	
	return (
		<div className="mrg-b-md">
			<Alert severity="error" title={ message.title }>
				<div className="copy">
					{message.message}
				</div>
			</Alert>
		</div>
	);
};

export const GoogleMapsAdmin = (): VNode => {
	const { notify } = useNotificationService();
	const settingsService = useSettingsService();
	useObservable(settingsService.settings, 'googleMapsKey');

	const [ googleMapsKey, setGoogleMapsKey ] = useState<string | null>(settingsService.settings.googleMapsKey);
	useEffect(() => {
		setGoogleMapsKey(settingsService.settings.googleMapsKey);
	}, [settingsService.settings.googleMapsKey]);

	const hasChangedKey = googleMapsKey !== settingsService.settings.googleMapsKey;
	
	const handleInput = useCallback<JSX.GenericEventHandler<HTMLInputElement>>(ev => {
		setGoogleMapsKey(ev.currentTarget.value);
	}, []);

	const [ status, setStatus ] = useState<string | undefined>(undefined);
	const [ error, setError ] = useState<ConsoleError.GoogleMap | null>(
		settingsService.settings.googleMapsKey ? getGoogleMapsConsoleError() : null
	);
	
	
	const handleSubmit = useCallback<JSX.GenericEventHandler<HTMLFormElement>>((ev) => {
		ev.preventDefault();
		setError(null);

		if(!googleMapsKey) {
			return;
		}

		setStatus('Verifying API Key...');
		verifyGoogleMapsKey(googleMapsKey)
			.then(() => {
				if(!hasChangedKey) {
					setStatus(undefined);
					notify({ severity: 'success', message: 'Map key is valid.' });
					return;
				}

				setStatus('Updating API Key...');
				settingsService.update('googleMapsKey', googleMapsKey)
					.then(() => {
						/*
							As Google Maps loads itself globally, and provides no mechanism for
							'unloading', we need to refresh the page completely, in case we have
							previously attempted to load Google Maps with a different key.

							Wait half a second to give some time for the 'Settings updated'
							notification to show.
						*/
						setStatus('Reloading Google Maps...');
						setTimeout(() => {
							window.location.reload();
						}, 500);
					});
			})
			.catch(err => {
				setStatus(undefined);
				if(err instanceof MapKeyError) {
					setError(err.mapError);
				} else {
					throw err;
				}
			});

	}, [googleMapsKey, hasChangedKey, notify, settingsService]);
	
	return (
		<>
			<KeyErrorMessage error={ error }/>
			<ModalLoadingIndicator show={ !!status } message={ status }/>
			<div id="googleMapKeyDescription" className="panel">
				<div className="copy">
					<h3 className="mrg-0">Google Maps API Key</h3>
					<p>
						Bearing requires a Google Maps API key in order to show you maps.
					</p>

					<section className="copy">
						<h4>Creating API Keys</h4>
						<p>
							You can find a guide to creating Google Maps API keys on&nbsp;
							<ExternalLink href="https://developers.google.com/maps/documentation/javascript/get-api-key">
								Google's website
							</ExternalLink>.
						</p>
						<p>
							Ensure that you enable the <strong>Maps JavaScript API</strong>.
						</p>
					</section>

					<section className="copy">
						<h4>HTTP Referrer Restrictions</h4>
						<p>
							It is <strong>strongly recommended</strong> that you enable HTTP
							referrer restrictions on your key, to prevent their misuse. Ensure
							that your Bearing domain (
							<strong><code>{window.location.origin}</code></strong>
							) is on the list of allowed HTTP referrers.
						</p>
						<p>
							Details on how to configure API key restrictions can be found on&nbsp;
							<ExternalLink href="https://developers.google.com/maps/documentation/javascript/get-api-key#restrict_key">
								Google's website
							</ExternalLink>.
						</p>
					</section>
				</div>

				<hr className="mrg-v-md"/>

				<form
					className="form"
					onSubmit={ handleSubmit }
					aria-describedby="googleMapKeyDescription"
				>
					<label>
						<span>Google Maps API key</span>
						<input
							type="text"
							value={ googleMapsKey ?? '' }
							onInput={ handleInput }
							autoComplete="off"
							required
						/>
					</label>

					<div className="flex">
						<button
							className="btn mrg-l-auto"
							type="submit"
						>
							{ hasChangedKey
								? 'Update Google Maps API key'
								: 'Verify Google Maps API key'
							}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};