import { route } from 'preact-router';


export type UrlSearchParamControllerConfig = {
	/**
	 * Determines whether a new entry is created in the browser history when
	 * the URL parameters are changed.
	 */
	historyMode: 'navigate' | 'replace';
};
/**
 * Controller for reading & updating URL search params.
 *
 * When changing values here, the browser's URL will be updated according to the
 * behaviour specified by {@link UrlSearchParamControllerConfig.historyMode}.
 *
 * Updates made via `set*` methods will be immediately reflected in the values
 * returned by the `get*` methods. In order to avoid making too many requests to
 * the browser's History API, changes are batched within a single tick, and
 * debounced if necessary.
 */
export class UrlSearchParamController {
	static readonly #DEBOUNCE_THRESHOLD = 300;
	
	readonly #params: URLSearchParams;
	#lastUpdated = 0;
	#timeout: ReturnType<typeof setTimeout> | null = null;

	#replace: boolean;
	
	constructor(config: UrlSearchParamControllerConfig) {
		this.#params = new URLSearchParams(window.location.search);
		this.#replace = config.historyMode === 'replace';
	}

	#set(key: string, value: string, defaultValue: string | undefined): void {
		if(value === defaultValue) {
			this.delete(key);
			return;
		}

		if(this.#params.get(key) === value) {
			return;
		}

		this.#params.set(key, value);
		this.#apply();
	}

	/**
	 * Get the raw value of a search parameter. Returns `null` if there is no such
	 * parameter.
	 */
	getString(key: string): string | null;
	/**
	 * Get the value of a search parameter. Returns `defaultValue` if there is no
	 * such parameter.
	 */
	getString(key: string, defaultValue: string): string;
	getString(key: string, defaultValue?: string) {
		return this.#params.get(key) ?? defaultValue ?? null;
	}

	/**
	 * Set the value of a search parameter. If `value === defaultValue`, the
	 * search parameter will instead be deleted.
	 */
	setString(key: string, value: string | null, defaultValue?: string): this {
		if(!value) {
			return this.delete(key);
		}
		this.#set(key, value, defaultValue);
		return this;
	}

	/**
	 * Get the value of a search parameter as a number. Returns `null` if there is no such
	 * parameter, of if the parameter exists, but cannot be parsed as a number.
	 */
	getNumber(key: string): number | null;
	/**
	 * Get the value of a search parameter as a number, or `null` if there is no
	 * such parameter, of if the parameter exists but cannot be parsed as a
	 * number.
	 */
	getNumber(key: string, defaultValue: number): number;
	getNumber(key: string, defaultValue?: number) {
		const value = this.#params.get(key);
		if(!value) {
			return defaultValue ?? null;
		}

		const num = Number.parseFloat(value);
		if(!Number.isFinite(num)) {
			return defaultValue ?? null;
		}

		return num;
	}

	/**
	 * Set the value of a search parameter as a number. If `value === defaultValue`,
	 * the search parameter will instead be deleted.
	 */
	setNumber(key: string, value: number | null, defaultValue?: number): this {
		if(value === null) {
			return this.delete(key);
		}

		this.#set(key, value.toString(), defaultValue?.toString());
		return this;
	}

	/**
	 * Set the value of a search parameter as a boolean.
	 */
	setBoolean(key: string, value: boolean): this {
		if(value) {
			if(!this.#params.has(key)) {
				this.#params.set(key, '');
				this.#apply();
			}
		} else {
			if(this.#params.has(key)) {
				this.#params.delete(key);
				this.#apply();
			}
		}
	
		return this;
	}

	/**
	 * Get the value of a search parameter as a boolean.
	 */
	getBoolean(key: string) {
		return this.#params.has(key);
	}

	/**
	 * Remove a search parameter.
	 */
	delete(key: string): this {
		if(!this.#params.has(key)) {
			return this;
		}

		this.#params.delete(key);
		this.#apply();
		return this;
	}

	#apply() {
		if(this.#timeout) {
			return;
		}

		const timeSinceLastUpdate = Date.now() - this.#lastUpdated;
		const shouldDebounce = timeSinceLastUpdate < UrlSearchParamController.#DEBOUNCE_THRESHOLD;
		const delay = shouldDebounce ? 250 : 0;
		this.#timeout = setTimeout(() => {
			route(`${window.location.pathname}?${this.#params.toString()}`, this.#replace);
			this.#lastUpdated = Date.now();
			this.#timeout = null;
		}, delay);
	}

	toString(): string {
		return this.#params.toString();
	}

	dispose() {
		if(this.#timeout) {
			clearTimeout(this.#timeout);
		}
	}
}