import type { FunctionComponent, VNode } from 'preact';
import type { ClassProp } from '../util';
import classNames from 'classnames';
import { Icon } from './atoms';
import './external-link.scss';


export type ExternalLinkProps = ClassProp & { href: string };

export const ExternalLink: FunctionComponent<ExternalLinkProps> = ({ className, href, children }): VNode => (
	<a
		className={ classNames(className, 'external-link') }
		href={ href }
		target="_blank"
		rel="noopener noreferrer"
	>
		<Icon icon="externalLink" size="sm"/>
		<span>{ children }</span>
	</a>
);