import type { DataUploadResult } from '@brng/common';
import { bound, observable } from 'ecce-preact';
import { Controller } from '../../util';


export class ManualRepositoryUploadController extends Controller {
	#submitting: boolean = false;
	get submitting(): boolean { return this.#submitting; }
	@observable() private set submitting(value: boolean) { this.#submitting = value; }

	#sitesFile: File | null = null;
	get sitesFile(): File | null { return this.#sitesFile; }
	@observable() private set sitesFile(value: File | null) { this.#sitesFile = value; }

	#devicesFile: File | null = null;
	get devicesFile(): File | null { return this.#devicesFile; }
	@observable() private set devicesFile(value: File | null) { this.#devicesFile = value; }

	#error: DataUploadResult.InvalidData | null = null;
	get error(): DataUploadResult.InvalidData | null { return this.#error; }
	@observable() private set error(value: DataUploadResult.InvalidData | null) { this.#error = value; }

	@bound()
	setSitesFile(file: File | null) {
		this.sitesFile = file;
	}

	@bound()
	setDevicesFile(file: File | null) {
		this.devicesFile = file;
	}

	@bound()
	async handleSubmit() {
		if(this.submitting || !this.sitesFile || !this.devicesFile) {
			return;
		}

		try {
			this.submitting = true;
			this.error = null;

			const [ sites, devices ] = await Promise.all([
				this.#readFileToString(this.sitesFile),
				this.#readFileToString(this.devicesFile),
			]);

			const response = await this.services.apiService.post('/api/data', {
				type: 'csv',
				sites,
				devices,
			});

			if(response.ok) {
				await this.services.dataService.refresh();
				this.services.notificationService.notify({
					severity: 'success',
					type: 'dataUpload',
					message: 'Data was successfully updated',
				});

				this.sitesFile = null;
				this.devicesFile = null;
				return;
			}

			if(response.errorType === 'network') {
				return;
			}

			if(response.status !== 400) {
				throw new Error(`Failed to upload data: ${response.status} ${JSON.stringify(response.body, null, 2)}`);
			}

			this.error = response.body as DataUploadResult.Error;
			if(this.error.sitesError) {
				this.sitesFile = null;
			}
			if(this.error.devicesError) {
				this.devicesFile = null;
			}

			this.submitting = false;
		} finally {
			this.submitting = false;
		}
	}

	async #readFileToString(file: File): Promise<string> {
		return new TextDecoder('utf-8').decode(await file.arrayBuffer());
	}
}