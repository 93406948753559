import type { JSX } from 'preact/jsx-runtime';
import type { UsersAdminController } from './users-admin-controller';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { Checkbox, Dialog, DialogHeader, Form, Icon, Label, Select, getRepositoryIconName } from '../../../components';
import { useRepositoryProviderName } from '../../../services';


export const InviteFromRepositoryDialog = (p: { controller: UsersAdminController }): JSX.Element | null => {
	useObservable(p.controller);

	const repositoryName = useRepositoryProviderName();

	const [ selectedUser, setSelectedUser ] = useState<string | null>(null);
	const [ admin, setAdmin ] = useState(false);

	if(!p.controller.data?.repositoryUsers?.length) {
		return null;
	}

	const handleSubmit = () => {
		if(!selectedUser) {
			return;
		}

		p.controller.inviteUserFromRepository(selectedUser, admin)
			.then(() => {
				setSelectedUser(null);
				setAdmin(false);
			});

	};

	return (
		<Dialog
			open={ p.controller.dialogMode?.kind === 'inviteFromRepository' }
			onClose={ p.controller.closeDialog }
			narrow
		>
			<DialogHeader
				title={ `Invite from ${repositoryName}` }
				icon={ getRepositoryIconName(repositoryName) }
				onClose={ p.controller.closeDialog }
			/>
			<main className="stack">
				<Form id="repoUserForm" onSubmit={ handleSubmit }>
					<p className="mrg-0">
						You may invite an existing user from your {repositoryName} instance.
						Users invited this way will be deactivated if their account in {repositoryName} is removed.
					</p>

					<Label label="User to invite">
						<Select
							id="repoUserSelect"
							value={ selectedUser ?? '' }
							onChange={ setSelectedUser }
							options={ p.controller.data.repositoryUsers.map(u => ({
								label: u.name,
								value: u.id,
							})) }
							required
							disabled={ p.controller.loading }
						/>
					</Label>

					<Checkbox
						id="repoUserAdminCheckbox"
						label="Admin Privileges"
						checked={ admin }
						onChange={ setAdmin }
						disabled={ p.controller.loading }
					/>
				</Form>
			</main>

			<footer className="flex-justify-between">
				<button
					className="btn btn--primary mrg-l-auto"
					form="repoUserForm"
				>
					<Icon icon="userAdd"/>
					Invite
				</button>
			</footer>
		</Dialog>
	);
};