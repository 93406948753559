import type { Device, Network } from '@brng/domain';
import type { SquelchService } from '../../services';
import type { IssuePageFilter, IssuePageIssue, ProcessedIssuePageIssues } from './process-analysis';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useData } from '../../data';
import { useIsSquelched, useNetwork } from '../../services';


export type AnalysisFocusHook = {
	focusedIssue: IssuePageIssue | null;
	setFocusedIssue: (newFocus: IssuePageIssue) => void;
	focusedEquipment: Device | null;
	setFocusedEquipment: (newFocus: Device) => void;
	openDetails: string | undefined;
	setOpenDetails: (newOpen: string | undefined) => void;
};

namespace AnalysisFocusState {
	export type None = {
		kind: 'none';
		issue: null;
		equipment: null;
	};
	export type IssueFocus = {
		kind: 'issue';
		issue: IssuePageIssue;
		equipment: null;
	};
	export type EquipmentFocus = {
		kind: 'equipment'
		issue: null;
		equipment: Device;
	};

	export const NONE: None = {
		kind: 'none',
		issue: null,
		equipment: null,
	};

	export const ofFirstUnsquelchedIssue = (issues: IssuePageIssue[], isSquelched: SquelchService['isSquelched']): AnalysisFocusState => {
		const firstUnsquelchedIssue = issues.find(issue => !isSquelched(issue.kind, issue.id));
		if(firstUnsquelchedIssue) {
			return {
				kind: 'issue',
				issue: firstUnsquelchedIssue,
				equipment: null,
			};
		}

		return NONE;
	};
}
type AnalysisFocusState = AnalysisFocusState.None |  AnalysisFocusState.IssueFocus | AnalysisFocusState.EquipmentFocus;

const openDetailsForIssue = (issue: IssuePageIssue | undefined, previousOpenDetails: string | undefined): string | undefined => {
	switch(issue?.kind) {
		case 'outOfRange':
		case 'outOfSector':
			return issue.device.site.name;
		case 'interSite':
			if(issue.devices.some(device => device.site.name === previousOpenDetails)) {
				return previousOpenDetails;
			}
			return issue.devices[0].site.name;
	}
};

const getHashForFocusState = (focus: AnalysisFocusState, filter: IssuePageFilter): string => {
	if(focus.issue) {
		return focus.issue.id;
	}

	if(focus.equipment) {
		return 'eq-' + filter.kind + '-' + focus.equipment.id;
	}

	return '';
};


const getInitialFocusState = (network: Network | null, issues: IssuePageIssue[]): AnalysisFocusState => {
	const hash = window.location.hash.slice(1);

	if(!hash) {
		return AnalysisFocusState.NONE;
	}
	
	if(hash.startsWith('eq-')) {
		const [_, deviceId] = hash.slice(3).split('-');
		const device = network?.getDevice(deviceId);
		if(device) {
			return {
				kind: 'equipment',
				equipment: device,
				issue: null,
			};
		}
	}
	
	const issue = issues.find(issue => issue.id === hash);
	if(issue) {
		return {
			kind: 'issue',
			issue,
			equipment: null,
		};
	}
	
	return AnalysisFocusState.NONE;
};

export const useAnalysisFocus = ({ issues, issuesBySite, equipmentIds }: ProcessedIssuePageIssues, filter: IssuePageFilter): AnalysisFocusHook => {
	const { getSite } = useData();
	const network = useNetwork();
	const [ focusState, setFocusState ] = useState<AnalysisFocusState>(getInitialFocusState(network, issues));
	const [ openDetails, setOpenDetails ] = useState<string | undefined>(undefined);
	const isSquelched = useIsSquelched();

	useEffect(() => {
		route(window.location.pathname + '#' + getHashForFocusState(focusState, filter), true);
	}, [filter, focusState]);

	// Open the appropriate details when the focused issue changes.
	useEffect(() => {
		switch(focusState.kind) {
			case 'equipment':
				setOpenDetails(getSite(focusState.equipment.siteId).name);
				break;
			case 'issue':
				setOpenDetails(openDetailsForIssue(focusState.issue, openDetails));
				break;
		}
	}, [focusState, getSite]); // eslint-disable-line react-hooks/exhaustive-deps


	// Handle the currently focused item being filtered out.
	useEffect(() => {
		// If all issues are filtered out, show nothing.
		if(!issues.length) {
			setFocusState(AnalysisFocusState.NONE);
			return;
		}
		
		function findIssueFromSiteByKind() {
			switch(focusState.issue?.kind) {
				case 'outOfRange':
				case 'outOfSector': {
					const issue = focusState.issue;
					return issuesBySite.find(({ site }) => issue.device.site.name === site.name);
				}
				case 'interSite': {
					const issue = focusState.issue;
					return issuesBySite.find(({ site }) =>  issue.devices.some(device => site === device.site));
				}
			}
		}
		
		switch(focusState.kind) {
			case 'issue': {
				// Do nothing if the issue is still visible.
				if(issues.includes(focusState.issue)) {
					return;
				}
				
				// Keep focus on same site, if possible.
				const nextSite = findIssueFromSiteByKind();
				if(nextSite) {
					setFocusState(AnalysisFocusState.ofFirstUnsquelchedIssue(nextSite.issuesByEquipment[0].issues, isSquelched));
					return;
				}

				// Otherwise, reset focus to first available issue.
				setFocusState(AnalysisFocusState.ofFirstUnsquelchedIssue(issues, isSquelched));
				return;
			}
			case 'equipment':
				if(!equipmentIds.has(focusState.equipment.id)) {
					setFocusState(AnalysisFocusState.ofFirstUnsquelchedIssue(issues, isSquelched));
				}
				return;
				
			case 'none':
				setFocusState(AnalysisFocusState.ofFirstUnsquelchedIssue(issues, isSquelched));
				return;
		}
	}, [equipmentIds, focusState, isSquelched, issues, issuesBySite]);

	return useMemo<AnalysisFocusHook>(() => ({
		focusedIssue: focusState.issue,
		setFocusedIssue: issue => setFocusState({ kind: 'issue', issue, equipment: null }),
		focusedEquipment: focusState.equipment,
		setFocusedEquipment: equipment => setFocusState({ kind: 'equipment', issue: null, equipment }),
		openDetails,
		setOpenDetails,
	}), [ focusState, openDetails, setOpenDetails ]);
};
