import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { Icon, ModalLoadingIndicator, RepositoryIcon } from '../../../components';
import { useRepositoryProviderName } from '../../../services';
import { useController } from '../../../util';
import { InviteFromRepositoryDialog } from './invite-from-repository-dialog';
import { ShowInvitationDialog } from './show-invitation-dialog';
import { UsersAdminController } from './users-admin-controller';
import { UsersList } from './users-list';
import { InviteLocalUserDialog } from './invite-local-user-dialog';
import './users-admin.scss';


export const UsersAdmin = (): JSX.Element => {
	const controller = useController(UsersAdminController);
	useObservable(controller);

	const repositoryName = useRepositoryProviderName();

	return (
		<>
			{ controller.data && (
				<div className="usersAdmin stack-sm">
					<div className="toolbar" role="toolbar">
						<button className="btn" onClick={ controller.showInviteLocalUserDialog }>
							<Icon icon="addUser"/>
							Invite User
						</button>
						{ !!controller.data.repositoryUsers?.length && (
							<button className="btn" onClick={ controller.showInviteFromRepositoryDialog }>
								<RepositoryIcon/>
								Invite from {repositoryName}
							</button>
						)}
					</div>
					<UsersList
						title="Inactive"
						icon="warning"
						users={ controller.data.inactive }
						controller={ controller }
						description={ (
							<>
								Inactive users are linked to a {repositoryName} user who has been removed.
								<br/>They will not be able to login unless you reactive them.
							</>
						) }
					/>
					<UsersList
						title="Invited"
						icon="clock"
						users={ controller.data.pending }
						controller={ controller }
						description="Users who have not yet accepted their invitations."
					/>
					<UsersList
						title="Admins"
						icon="admin"
						users={ controller.data.admins }
						controller={ controller }
						description="Admins can update Bearing's settings, and manage other users."
					/>
					<UsersList
						title="Users"
						icon="users"
						users={ controller.data.active }
						controller={ controller }
					/>
				</div>
			)}

			<InviteLocalUserDialog controller={ controller }/>
			<InviteFromRepositoryDialog controller={ controller }/>
			<ShowInvitationDialog controller={ controller }/>
			<ModalLoadingIndicator show={ controller.loading }/>
		</>
	);
};