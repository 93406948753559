import type { VNode } from 'preact';
import { Toolbar } from '../../../components';
import { useAnalysisIssues } from '../analysis-provider';
import { AnalysisIssueKindSelect } from './analysis-issues-kind-select';
import './analysis-issues-toolbar.scss';
import { InterSiteFreqSelect } from './inter-site-freq-select';
import { InterSiteRangeSlider } from './inter-site-range-slider';
import { OutOfRangeToleranceSlider } from './out-of-range-tolerance-slider';
import { OutOfSectorToleranceSlider } from './out-of-sector-tolerance-slider';


export const AnalysisIssueToolbar = (): VNode => {
	const { analysisIssuesFilter: { kind } } = useAnalysisIssues();

	return (
		<Toolbar className="analysis-issues-toolbar" bp="lg">
			<AnalysisIssueKindSelect/>
			<div className="flex gap-sm flex-align-center">
				{ kind === 'outOfSector' && <OutOfSectorToleranceSlider/>}
				{ kind === 'outOfRange' && <OutOfRangeToleranceSlider/>}
				{ kind === 'interSite' && (
					<>
						<InterSiteFreqSelect/>
						<InterSiteRangeSlider/>
					</>
				)}
			</div>
		</Toolbar>
	);
};