import type { VNode } from 'preact';
import { useCallback } from 'preact/hooks';


export namespace MenuItem {
	type Common = {
		label: string | VNode;
	};
	export type Anchor = Common & {
		href: HTMLAnchorElement['href']
		target?: HTMLAnchorElement['target']
	};
	export type Button = Common & {
		onClick: () => void;
	};

	export const isAnchor = (props: MenuItem): props is MenuItem.Anchor => (
		'href' in props
	);
	export const isButton = (props: MenuItem): props is MenuItem.Button => !isAnchor(props);
}
export type MenuItem = MenuItem.Anchor | MenuItem.Button;

export type MenuItemComponentProps = {
	item: MenuItem;
	onClick: (item: MenuItem) => void;
};
export const MenuItemComponent = ({ item, onClick }: MenuItemComponentProps): VNode => {
	const handleClick = useCallback(() => onClick(item), [item, onClick]);
	
	const className = 'menu__item';
	
	if(MenuItem.isAnchor(item)) {
		return (
			<a className={ className } href={ item.href } target={ item.target } onClick={ handleClick }>
				{item.label}
			</a>
		);
	}

	return (
		<button className={ className } onClick={ handleClick }>
			{ item.label }
		</button>
	);
};