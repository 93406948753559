import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../../util';
import type { MapRouteController } from '../../map-route-controller';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { FilterButton } from '../../../../filtering';


export type MapRouteFilterToolsProps = ClassProp & {
	controller: MapRouteController;
	disableMode?: 'hide' | 'disable';
	forceLongLabel?: boolean;
};
export const MapRouteFilterTools = ({ className, controller, forceLongLabel, disableMode = 'hide' }: MapRouteFilterToolsProps): JSX.Element | null => {
	useObservable(controller.display, 'overlayMode');
	useObservable(controller.display, 'deviceTypeFilter');
	useObservable(controller.display, 'showCustomers');

	const disabled = controller.display.overlayMode !== 'frequency';
	if(disabled && disableMode === 'hide') {
		return null;
	}


	return (
		<div className={ classNames(className, 'flex gap-xs') }>
			<FilterButton
				label="Customers"
				shortLabel={ forceLongLabel ? 'Customers' : 'Cus' }
				checked={ controller.display.showCustomers }
				onChange={ controller.display.setShowCustomers }
				disabled={ disabled }
			/>
			<div className="button-group">
				<FilterButton
					label="Access Point"
					shortLabel={ forceLongLabel ? 'Access Points' : 'AP' }
					checked={ controller.display.deviceTypeFilter['Access Point'] }
					onChange={ show => controller.display.setDeviceTypeFilter('Access Point', show) }
					disabled={ disabled }
				/>
				<FilterButton
					label="Backhauls"
					shortLabel={ forceLongLabel ? 'Backhauls' : 'BH' }
					checked={ controller.display.deviceTypeFilter['Backhaul'] }
					onChange={ show => controller.display.setDeviceTypeFilter('Backhaul', show) }
					disabled={ disabled }
				/>
			</div>
		</div>
	);
};