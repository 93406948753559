import type { PostRepositoryResponse } from '@brng/common';
import type { VNode } from 'preact';
import { useMemo } from 'preact/hooks';
import { Alert } from '../components';
import { ErrorDetailDisplay } from '../components/organisms/error-details';


export type RepositoryErrorAlertProps = {
	providerName: string;
	response: PostRepositoryResponse | undefined | null;
};
export const RepositoryErrorAlert = ({ providerName, response }: RepositoryErrorAlertProps): VNode | null => {
	
	const title = useMemo<string>(() => {
		if(!response || response.ok) {
			return '';
		}

		switch(response.reason) {
			case 'authentication-error':
				return `Could not authenticate with ${providerName}`;
			case 'invalid-config-error':
				return 'Invalid configuration';
			case 'network-error':
			case 'upstream-error':
				return `Could not connect to ${providerName}`;
			case 'permission-error':
				return 'Insufficient permissions';
			case 'user-actionable':
				return 'We encountered an issue...';
			default:
				return 'Unknown error';
		}
	}, [ providerName, response ]);

	const message = useMemo<VNode | null>(() => {
		if(!response || response.ok) {
			return null;
		}

		switch(response.reason) {
			case 'authentication-error':
				return (
					<p>Please check that the provided credentials are correct.</p>
				);
			case 'invalid-config-error':
				return (
					<p>Missing some required information:</p>
				);
			case 'network-error':
			case 'upstream-error':
				return (
					<p>Please check the provided URL, and that {providerName} is available.</p>
				);
			case 'permission-error':
				return (
					<div>
						<p>
							Please verify that the Bearing user has these permissions:
						</p>
						<ul>
							<li><code>Reports &rArr; Custom &rArr; Custom Reports = Edit</code></li>
							<li><code>Reports &rArr; Dashboard &rArr; Reports Dashboard = View</code></li>
						</ul>
					</div>
				);
			default:
				return null;
		}
	}, [ providerName, response ]);

	const details = useMemo<VNode | null>(() => {
		if(response && 'details' in response) {
			if(response.reason === 'user-actionable') {
				return <p>{ response.details }</p>;
			}
			return <><ErrorDetailDisplay details={ response.details }/><br/></>;
		}

		return null;
	}, [response]);
	
	if(!response || response.ok || response.reason === 'incomplete-certificate-chain') {
		return null;
	}

	return (
		<Alert severity="error" title={ title }>
			<div className="stack-sm">
				{message}
				{details}
			</div>
		</Alert>
	);
};