import type { Network, InfrastructureSite, CustomerSite, NetworkItem } from '@brng/domain';
import type { MapDeviceTypeFilter, MapOverlayMode } from '../routes/map';
import type { MapRouteFocus } from '../routes/map/map-route-focus';
import type { OverlayRadius } from '../map';
import { Device , isInfrastructureSite, isCustomerSite, Site } from '@brng/domain';
import { FrequencyBand, type Point, notNullish, isBandName } from '@brng/common';

/**
 * Helpers to work with Map urls.
 */
export class MapUrl {
	/** Path to the map route. */
	static readonly PATH = '/map';
	
	/** URL search parameter for the map's center. */
	static readonly PARAM_CENTER = 'center';
	/** URL search parameter for the map's zoom level. */
	static readonly PARAM_ZOOM = 'zoom';
	/** URL search parameter for the map's device overlay radius. */
	static readonly PARAM_RADIUS = 'radius';
	/** URL search parameter for the map's chosen frequency band. */
	static readonly PARAM_FREQUENCY = 'freq';
	/** URL search parameter for the map's device type filter. */
	static readonly PARAM_DEVICE_FILTER = 'deviceFilter';
	/** URL search parameter for the map's hide customer filter. */
	static readonly PARAM_HIDE_CUSTOMER = 'cus';
	/** URL search parameter for the map's overlay mode */
	static readonly PARAM_OVERLAY_MODE = 'overlayMode';
	/** URL search parameter for the map's focus */
	static readonly PARAM_FOCUS = 'focus';

	readonly #params: URLSearchParams = new URLSearchParams();

	center(value: Point): this {
		this.#params.set(MapUrl.PARAM_CENTER, MapUrl.stringifyCenter(value));
		return this;
	}

	zoom(value: number): this {
		this.#params.set(MapUrl.PARAM_ZOOM, value.toString());
		return this;
	}

	radius(value: OverlayRadius): this {
		this.#params.set(MapUrl.PARAM_RADIUS, MapUrl.stringifyRadius(value));
		return this;
	}

	frequency(value: 'all' | FrequencyBand): this {
		this.#params.set(MapUrl.PARAM_FREQUENCY, MapUrl.stringifyFrequency(value === 'all' ? null : value));
		return this;
	}

	deviceFilter(value: MapDeviceTypeFilter): this {
		const param = MapUrl.stringifyDeviceFilter(value);
		if(param) {
			this.#params.set(MapUrl.PARAM_DEVICE_FILTER, param);
		}
		return this;
	}

	hideCustomers(value: boolean): this {
		if(value) {
			this.#params.set(MapUrl.PARAM_HIDE_CUSTOMER, '');
		}
		return this;
	}

	overlayMode(value: MapOverlayMode): this {
		this.#params.set(MapUrl.PARAM_OVERLAY_MODE, value);
		return this;
	}

	focus(value: NetworkItem): this;
	focus(value: Partial<Pick<MapRouteFocus, 'sites' | 'device' | 'customer'>>): this;
	focus(value: NetworkItem | Partial<Pick<MapRouteFocus, 'sites' | 'device' | 'customer'>>) {
		if(value instanceof Site) {
			if(isInfrastructureSite(value)) {
				this.focus({ sites: [ value ] });
			} else if(isCustomerSite(value)) {
				this.focus({ customer: value });
			}
			return this;
		}
		if(value instanceof Device) {
			this.focus({ device: value });
			return this;
		}

		const param = MapUrl.stringifyFocus(value);
		if(param) {
			this.#params.set(MapUrl.PARAM_FOCUS, param);
		}
		return this;
	}

	/**
	 * Get the URL params which describe the map state.
	 *
	 * @example ?zoom=5
	 */
	toUrlParams(): string {
		return '?' + this.#params.toString();
	}

	/**
	 * Get the relative URL to the map.
	 *
	 * @example /map?zoom=5
	 */
	toRelativeUrl(): string {
		return MapUrl.PATH + this.toUrlParams();
	}

	/**
	 * Get the full URL to the map.
	 *
	 * @example https://example.com/map?zoom=5
	 */
	toAbsoluteUrl(): string {
		const url = new URL(window.location.href);
		url.pathname = MapUrl.PATH;
		url.search = this.#params.toString();

		return url.href;
	}

	static forItem(item: NetworkItem): MapUrl {
		return new MapUrl()
			.focus(item)
			.center(item.location)
			.frequency('all')
			.zoom(13);
	}

	static stringifyCenter(point: Point): string {
		return point.lat + ',' + point.lng;
	}

	static parseCenter(input: string | null | undefined): Point | null {
		if(!input) {
			return null;
		}

		const parsed = input.split(',').map(Number.parseFloat);
		if(parsed.length !== 2 || !parsed.every(p => Number.isFinite(p))) {
			return null;
		}

		return { lat: parsed[0], lng: parsed[1] };
	}

	static stringifyRadius(radius: OverlayRadius): string {
		return radius.toString();
	}

	static parseRadius(input: string | null | undefined): OverlayRadius | null {
		if(!input) {
			return null;
		}

		if(input === 'actual') {
			return input;
		}

		const parsed = Number.parseFloat(input);
		return Number.isFinite(parsed) ? parsed : null;
	}
	
	static stringifyFrequency(band: FrequencyBand | null): string {
		if(!band) {
			return 'all';
		}
		return band.band;
	}

	static parseFrequency(input: string): FrequencyBand | null {
		return isBandName(input) ? FrequencyBand.fromBand(input) : null;
	}

	static stringifyDeviceFilter(filter: MapDeviceTypeFilter): string | null {
		if(filter['Access Point'] && filter['Backhaul']) {
			return null;
		}

		return filter['Access Point'] ? 'bh' : 'ap';
	}

	static parseDeviceFilter(value: string | null): MapDeviceTypeFilter {
		switch(value) {
			case 'ap':
				return {
					'Access Point': false,
					'Backhaul': true,
				};
			case 'bh':
				return {
					'Access Point': true,
					'Backhaul': false,
				};
			default:
				return {
					'Access Point': true,
					'Backhaul': true,
				};
		}
	}

	static stringifyFocus(focus: Partial<Pick<MapRouteFocus, 'sites' | 'device' | 'customer'>> | null): string | null {
		if(!focus) {
			return null;
		}

		const ids: string[] = [];
		if(focus.sites) {
			ids.push(...focus.sites.map(s => s.networkId));
		}
		if(focus.device) {
			ids.push(focus.device.networkId);
		}
		if(focus.customer) {
			ids.push(focus.customer.networkId);
		}

		return ids.join(',');
	}

	static parseFocus(value: string | null, network: Network): Pick<MapRouteFocus, 'sites' | 'device' | 'customer'> | null {
		if(!value) {
			return null;
		}

		const items = value.split(',')
			.map(id => network.getItem(id))
			.filter(notNullish);

		const sites: InfrastructureSite[] = [];
		let device: Device | null = null;
		let customer: CustomerSite | null = null;

		for(const item of items) {
			switch(item.kind) {
				case 'site': {
					if(isInfrastructureSite(item)) {
						sites.push(item);
					} else if(isCustomerSite(item)) {
						customer = item;
					}
					break;
				}
				case 'device':
					device = item;
			}
		}
		
		return { sites, device, customer };
	}
}