import type { Bounds, BandName } from '@brng/common';
import type { Device, RadioWithBand, Site } from '@brng/domain';
import { DeviceGeometry } from '@brng/domain';
import { asBoundsFromPointAndRange, asPoint, earth, FrequencyBand, notNullish, wordWrap } from '@brng/common';
import { hash as md5 } from 'spark-md5';


export type SVGOverlay = {
	key: string,
	svg: string,
	width: number,
	height: number,
	bounds: Bounds,
};

type SectorAttributes = {
	id?: string,
	x?: string,
	y?: string,
	class?: string,
	'clip-path'?: string,
	mask?: string,
	fill?: string,
	stroke?: string,
	'stroke-width'?: string,
	transform?: string,
};

const stdWidth = 400;
const stdRadius = stdWidth/2;
const siteSvgPalettes = {
	light: {
		text: '#ffffff',
		base: 'rgba(200,200,200)',
		conflict: 'rgba(255,0,0,0.7)',
		opacity: 0.4,
		arcBase: 'rgba(200,200,200,0.4)',
		boundingCircle: 'rgba(255,255,255,0.5)',
	},
	dark: {
		text: '#333333',
		base: 'rgba(127,127,127)',
		conflict: 'rgba(255,0,0,0.7)',
		opacity: 0.4,
		arcBase: 'rgba(127,127,127,0.4)',
		boundingCircle: 'rgba(127,127,127,0.4)',
	},
};

const getEquipmentTypeClassName = (equipment:Device): string => {
	switch(equipment.type) {
		case 'Access Point': return 'eqType--ap';
		case 'Backhaul'	: return 'eqType--bh';
		case 'Subscriber Module'	: return 'eqType--sm';
		default:
			return '';
	}
};

const frequencyRange = (radio: RadioWithBand): [ number, number ] => {
	if(!radio.frequency || !radio.channelWidth) {
		return [ Number(radio.band.band), Number(radio.band.band) ];
	}
	return [ (radio.frequency + (radio.channelWidth/2)),(radio.frequency - (radio.channelWidth/2)) ];
};

const modulatedFrequencyRange = (radio: RadioWithBand, offset: number): number[] => {
	const range = frequencyRange(radio);
	return range.map(value => radio.band.normalizeFrequency(value, offset));
};

const _round = (value: number): number => {
	const result = Number(value.toPrecision(4));
	return (result > -0.000001 && result < 0.000001)? 0 : result;
};

// Value of `maxRange === null` implies fixed range in use so no scaling
// If `maxRange` or `device.range` are invalid than we scale to 0
const _scaling = (maxRange: number | null, device: Device) => maxRange===null ? 1: (device.range && maxRange ? device.range / maxRange : 0);

const _sector = (device: Device, radius: number, attributes: SectorAttributes): string => {
	const extraAttributes = (Object.keys(attributes) as (keyof SectorAttributes)[])
		.map(name => `${name}="${attributes[name]}"`)
		.join(' ');

	if (device.antennaType === 'Sector') {
		// The use of a mid-point here avoids any awkward rounding errors to do with flags.
		const xStart = _round(stdRadius + radius * Math.cos(Math.toRadians(device.azimuth - device.sectorSize / 2 - 90)));
		const yStart = _round(stdRadius + radius * Math.sin(Math.toRadians(device.azimuth - device.sectorSize / 2 - 90)));
		const xMid = _round(stdRadius + radius * Math.cos(Math.toRadians(device.azimuth - 90)));
		const yMid = _round(stdRadius + radius * Math.sin(Math.toRadians(device.azimuth - 90)));
		const xEnd = _round(stdRadius + radius * Math.cos(Math.toRadians(device.azimuth + device.sectorSize / 2 - 90)));
		const yEnd = _round(stdRadius + radius * Math.sin(Math.toRadians(device.azimuth + device.sectorSize / 2 - 90)));

		return `<path id="sector-${device.id}" d="M ${stdRadius}  ${stdRadius} L ${xStart} ${yStart} A ${_round(radius)} ${_round(radius)} 0 0 1 ${xMid} ${yMid} A ${_round(radius)} ${_round(radius)} 0 0 1 ${xEnd} ${yEnd} z" ${extraAttributes}/>`;
	}
	return `<circle id="sector-${device.id}" cx="${stdRadius}" cy="${stdRadius}" r="${_round(radius)}" ${extraAttributes}/>`;
};

const _insetSector = (device: Device, radius: number, inset: number, attributes: SectorAttributes): string => {
	const extraAttributes = (Object.keys(attributes) as (keyof SectorAttributes)[])
		.map(name => `${name}="${attributes[name]}"`)
		.join(' ');

	if (device.antennaType === 'Sector') {
		const insetRadius = (radius - inset);
		const insetRadians = inset / insetRadius;
		// The use of a mid-point here avoids any awkward rounding errors to do with flags.
		const xStart = _round(stdRadius + insetRadius * Math.cos(Math.toRadians(device.azimuth - device.sectorSize / 2 - 90) + insetRadians));
		const yStart = _round(stdRadius + insetRadius * Math.sin(Math.toRadians(device.azimuth - device.sectorSize / 2 - 90) + insetRadians));
		const xMid = _round(stdRadius + insetRadius * Math.cos(Math.toRadians(device.azimuth - 90)));
		const yMid = _round(stdRadius + insetRadius * Math.sin(Math.toRadians(device.azimuth - 90)));
		const xEnd = _round(stdRadius + insetRadius * Math.cos(Math.toRadians(device.azimuth + device.sectorSize / 2 - 90) - insetRadians));
		const yEnd = _round(stdRadius + insetRadius * Math.sin(Math.toRadians(device.azimuth + device.sectorSize / 2 - 90) - insetRadians));
		
		const hypotenuse = (inset / Math.sin(Math.toRadians(device.sectorSize/2)));
		const xCentre = stdRadius + hypotenuse * Math.sin(Math.toRadians(device.azimuth));
		const yCentre = stdRadius - hypotenuse * Math.cos(Math.toRadians(device.azimuth));

		return `<path d="M ${xCentre}  ${yCentre} L ${xStart} ${yStart} A ${_round(radius)} ${_round(radius)} 0 0 1 ${xMid} ${yMid} A ${_round(radius)} ${_round(radius)} 0 0 1 ${xEnd} ${yEnd} z" ${extraAttributes}/>`;
	}
	return `<circle cx="${stdRadius}" cy="${stdRadius}" r="${_round(radius-inset)}" ${extraAttributes}/>`;
};

const _useSector = (device: Device, attributes: SectorAttributes): string => {
	const extraAttributes = (Object.keys(attributes) as (keyof SectorAttributes)[])
		.map(name => `${name}="${attributes[name]}"`)
		.join(' ');

	return `<use href="#sector-${device.id}" ${extraAttributes}/>`;
};

export const siteSVG = (equipment: readonly Device[], bandsToRender: readonly BandName[], overlayRadius: 'actual' | number, light=false, highlight:readonly string[]=[], conflict:readonly string[]=[], debug=false): SVGOverlay => {
	if(!equipment.length) {
		throw new Error('Received empty equipment array.');
	}

	if(!equipment.every((eq) => eq.site === equipment[0].site)) {
		throw new Error('All equipment must belong to the same site');
	}
	const site = equipment[0].site;

	const palette = siteSvgPalettes[ light ? 'light' : 'dark' ];

	const devices = equipment.filter(device => device.radios.find(radio => radio.band && bandsToRender.includes(radio.band.band) && (device.antennaType!=='Sector' || Number.isFinite(device.azimuth)) && (overlayRadius !== 'actual' || device.range)));

	const maxRange = overlayRadius === 'actual' ? Math.max(...devices.map(device => device.range).filter(notNullish), 0) : null;
	
	const boundsRadius = (maxRange || overlayRadius) as number;
	
	const highlightStrokeWidth = 1;

	const offset = 0.15;

	let svg = '<?xml version="1.0" standalone="no"?>';
	svg += `<svg height="${ 2 * stdWidth }" width="${ 2 * stdWidth }" viewBox="0 0 ${ stdWidth } ${ stdWidth }" xmlns="http://www.w3.org/2000/svg">`;
	svg += '<style>text {font-family: Arial, sans-serif;}</style>';
	svg += '<defs>';

	const generateRadialGradientId = (scaling:number, frequencyBand:FrequencyBand):string => {
		const rounded = _round(scaling);
		if(rounded <= 0 || rounded >= 1) {
			return `grad-${frequencyBand.band}`;
		}
		return `grad-${frequencyBand.band}-${rounded}`;
	};
	const radialGradientIds:string[] = [];

	// Generate band colour gradients
	bandsToRender.map(FrequencyBand.fromBand).forEach(frequencyBand => {
		const radialGradientId = generateRadialGradientId(1,frequencyBand);
		svg += `<radialGradient id="${radialGradientId}" gradientUnits="userSpaceOnUse">${frequencyBand.radialGradientStops}</radialGradient>`;
		radialGradientIds.push(radialGradientId);
	});

	devices.forEach(device => {
		const ranges: number[][] = [];
		const scaling = _scaling(maxRange, device);
		const sectorRadius = scaling * stdRadius;

		(device.radios.filter(radio => radio.band && bandsToRender.includes(radio.band.band)) as RadioWithBand[]).forEach(radio => {
			// Extend color gradients for actual range
			const radialGradientId = generateRadialGradientId(scaling, radio.band);
			if(!radialGradientIds.includes(radialGradientId)) {
				svg += `<radialGradient id="${radialGradientId}" href="#${generateRadialGradientId(1, radio.band)}" r="${_round(50 * scaling)}%"/>`;
				radialGradientIds.push(radialGradientId);
			}

			const range = modulatedFrequencyRange(radio, offset);
			if(range[0] === range[1]) {
				range[0] = offset;
				range[1] = 0;
			}
			if(ranges.find(pair => pair[0]===range[0] && pair[1]===range[1])) {
				return;
			}
			const existing = ranges.find(pair => pair[0]>=range[1] && pair[1]<=range[0]);
			if(existing) {
				existing[0] = Math.max(existing[0],range[0]);
				existing[1] = Math.max(existing[1],range[1]);
				return;
			}
			ranges.push(range);
		});
		ranges.sort((a,b) => b[0]-a[0]);

		// Omni text path
		if(device.antennaType!=='Sector') {
			const guideRadius = ranges[0][0] * stdRadius;
			const textRadius = _round(scaling * (guideRadius > (stdRadius - 40)?guideRadius - 20: guideRadius + 5));
			svg += `<path id="path${ device.id }" d="M ${ stdRadius } ${ stdRadius + textRadius } a ${ textRadius } ${ textRadius } 0 1,1 0,${ -2 * textRadius } a ${ textRadius } ${ textRadius } 0 1,1 0,${ 2 * textRadius }" />`;
		}
		
		for(let rangeIndex = 0;rangeIndex < ranges.length; rangeIndex++) {
			svg += `<circle id="range-${rangeIndex}-0-${device.id}" cx="${stdRadius}" cy="${stdRadius}" r="${ _round(sectorRadius * ranges[rangeIndex][0]) }"/>`;
			if(ranges[rangeIndex][1] !== 0) {
				svg += `<circle id="range-${rangeIndex}-1-${device.id}" cx="${stdRadius}" cy="${stdRadius}" r="${_round(sectorRadius * ranges[rangeIndex][1])}"/>`;
			}
		}

		// Frequency arc mask
		svg += `<mask class="${getEquipmentTypeClassName(device)}" id="mask${ device.id }">`;
		for(let rangeIndex = 0;rangeIndex < ranges.length; rangeIndex++) {
			svg += `<use href="#range-${rangeIndex}-0-${device.id}" fill="#FFFFFF" />`;
			if(ranges[rangeIndex][1] !== 0) {
				svg += `<use href="#range-${rangeIndex}-1-${device.id}" fill="#000000" />`;
			}
		}
		svg += '</mask>';
		
		// Device sectors
		svg += _sector(device, sectorRadius, {});

		if(highlight.includes(device.id) || conflict.includes(device.id)) {
			svg += `<mask  id="insetMask${device.id}">${_useSector(device, { fill: '#FFFFFF' })}`;
			for(let rangeIndex = 0;rangeIndex < ranges.length; rangeIndex++) {
				svg += `<use href="#range-${rangeIndex}-0-${device.id}" fill="#000000" />`;
				if(ranges[rangeIndex][1] !== 0) {
					svg += `<use href="#range-${rangeIndex}-1-${device.id}" fill="#FFFFFF" />`;
				}
			}
			svg += `${_insetSector(device, sectorRadius, highlightStrokeWidth, { fill: '#000000' })}</mask>`;
		}
	});

	svg += '</defs>';

	// Outer circle
	if(overlayRadius !== 'actual') {
		svg += `<circle cx="${stdRadius}" cy="${stdRadius}" r="${(stdRadius - 1)}" fill="transparent" stroke="${palette.boundingCircle}" stroke-width="2"/>`;
	}

	// Device base arcs
	devices.forEach(device => {
		svg += _useSector(device,{
			class: `eq-${ device.id } ${getEquipmentTypeClassName(device)} eq-base`,
			fill: palette.arcBase,
		});
	});

	// Frequency arcs
	devices.forEach(device => {
		const scaling = _scaling(maxRange, device);
		const frequencyBand = device.radios.find(radio => radio.band && bandsToRender.includes(radio.band.band))?.band;
		if(!frequencyBand) {
			return;
		}
		svg += _useSector(device,{
			class: `eq-${ device.id } ${getEquipmentTypeClassName(device)} eq-arc`,
			fill: `url(#${generateRadialGradientId(scaling, frequencyBand)})`,
			mask: `url(#mask${ device.id })`,
		});
	});

	// Highlight and Conflict
	devices.filter(device => highlight.includes(device.id) || conflict.includes(device.id)).forEach(device => {
		device.radios.filter(radio => radio.band && bandsToRender.includes(radio.band.band)).forEach(radio => {
			if(radio.band) {
				const fill = highlight.includes(device.id) ? radio.band.color(radio.frequency, offset).toRGBA(0.7) : palette.conflict;

				svg += _useSector(device, {
					class: `eq-${device.id} ${getEquipmentTypeClassName(device)} eq-highlight`,
					fill,
					'mask': `url(#insetMask${device.id})`,
				});
			}
		});
	});

	// Device names
	devices.forEach(device => {
		const scaling = _scaling(maxRange, device);
		let x;
		let y;
		const offsetAngle = device.sectorSize < 5?1:0;
		const fontSize = (15 * scaling)+'px';
		const lineHeight = (15 * scaling);
		if(device.antennaType==='Sector') {
			x = _round(stdRadius + ((scaling * stdWidth - 10) / 2) * Math.cos(Math.toRadians(device.azimuth - 90 - offsetAngle)));
			y = _round(stdRadius + ((scaling * stdWidth - 10) / 2) * Math.sin(Math.toRadians(device.azimuth - 90 - offsetAngle)));
			const lines = wordWrap(device.name, 21);
			if (device.azimuth >= 0 && device.azimuth < 180) {
				svg += `<text class="eq-${ device.id } ${getEquipmentTypeClassName(device)} eq-text" font-size="${fontSize}" ${ 'dominant-baseline="' + ((offsetAngle===1)?'ideographic':'middle') + '"' } x="${x}" y="${y}" transform="rotate(${device.azimuth - 90 - offsetAngle} ${x},${y})" text-anchor="end" fill="${palette.text}" aria-label="${device.name}">`;
				for (let i=0; i<lines.length; i++) {
					const hyp = (((lines.length-1)/2-i)*lineHeight);
					svg += `<tspan x="${x}" y="${y-hyp}">${lines[i]}</tspan>`;
				}
				svg += '</text>';
			} else {
				svg += `<text class="eq-${ device.id } ${getEquipmentTypeClassName(device)} eq-text" font-size="${fontSize}" ${ 'dominant-baseline="' + ((offsetAngle===1)?'hanging':'middle') + '"' } x="${x}" y="${y}" transform="rotate(${device.azimuth + 90 - offsetAngle} ${x},${y})" text-anchor="start" fill="${palette.text}" aria-label="${device.name}">`;
				for (let i=0; i<lines.length; i++) {
					const hyp = (((lines.length-1)/2-i)*lineHeight);
					svg += `<tspan x="${x}" y="${y-hyp}">${lines[i]}</tspan>`;
				}
				svg += '</text>';
			}
		} else {
			svg += `<text class="eq-${ device.id } ${getEquipmentTypeClassName(device)} eq-text" font-size="${fontSize}" text-anchor="middle" fill="${palette.text}"><textPath startOffset="50%" id="text${ device.id }" href="#path${ device.id }" aria-label="${ device.name }">${ device.name }</textPath></text>`;
		}
		
		if(debug) {
			const deviceGeometry = new DeviceGeometry(device);
			deviceGeometry.perimeterPoints().forEach(point => {
				const xOffset = (point.lng >= site.location.lng ? 1 : -1) * stdRadius * (earth.distance(site.location, { lat: site.location.lat, lng: point.lng }) / boundsRadius);
				const yOffset = (point.lat <= site.location.lat ? 1 : -1) * stdRadius * (earth.distance(site.location, { lat: point.lat, lng: site.location.lng }) / boundsRadius);
				
				svg += `<circle cx="${stdRadius + xOffset}" cy="${stdRadius + yOffset}" r="0.5" fill="${palette.boundingCircle}" stroke="transparent"/>`;
			});
		}
	});

	svg += '</svg>';

	return {
		key: md5(svg),
		svg,
		width: 2 * stdWidth,
		height: 2 * stdWidth,
		bounds: asBoundsFromPointAndRange(site.location, boundsRadius),
	};
};

export const coverageSVG = (allSites: Site[], light=false): SVGOverlay => {
	const palette = siteSvgPalettes[ light ? 'light' : 'dark' ];

	const key = 'coverage'+(light?'light':'dark')+allSites.map((site: Site) => site.id + '-' + site.devices.map(equipment => equipment.id).join('-')).join('--');

	// Only bother with sites that actually have RF equipment
	const sitesFilter = (site: Site) => site.devices.find(device => device.range);
	const devicesFilter = (device: Device) => device.type === 'Access Point' && device.range && (device.antennaType!=='Sector' || device.azimuth || device.azimuth===0);
	// We add 1km to max range to give us space around for the blur and sector polygon
	const spacing = 1000;
	const sites = allSites.filter(sitesFilter);
	if(!sites.length) {
		return {
			key,
			svg: '<?xml version="1.0" standalone="no"?><svg width="1" height="1" viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg"></svg>',
			width: 1,
			height: 1,
			bounds: { north: 0, south: 0, east: 0, west: 0 },
		};
	}
	const bounds = sites.map(site => asBoundsFromPointAndRange(site.location, Math.max(...site.devices.filter(devicesFilter).map(device => device.range) as number[], 0) + spacing)).reduce((result, value) => ({
		north: Math.max(result.north, value.north),
		south: Math.min(result.south, value.south),
		east: Math.max(result.east, value.east),
		west: Math.min(result.west, value.west),
	}));

	// For simplicity, we set the size of the SVG in meters
	const coverageCentre = asPoint((bounds.north+bounds.south)/2, (bounds.east+bounds.west)/2);
	const coverageWidth = earth.distance(asPoint(coverageCentre.lat, bounds.east),asPoint(coverageCentre.lat, bounds.west));
	const coverageHeight = earth.distance(asPoint(bounds.north, coverageCentre.lng),asPoint(bounds.south, coverageCentre.lng));
	const suggestedRenderScaling = Math.sqrt(coverageWidth*coverageHeight/4000000);
	const suggestedRenderWidth = _round(coverageWidth/suggestedRenderScaling);
	const suggestedRenderHeight = _round(coverageHeight/suggestedRenderScaling);
	const degreesLat = bounds.north-bounds.south;
	const degreesLng = bounds.east-bounds.west;

	let svg = '<?xml version="1.0" standalone="no"?>';
	svg += `<svg width="${ suggestedRenderWidth }" height="${ suggestedRenderHeight }" viewBox="0 0 ${ _round(coverageWidth) } ${ _round(coverageHeight) }" xmlns="http://www.w3.org/2000/svg">`;
	svg += '<defs>';
	svg += `<filter id="edgeBlur"><feGaussianBlur stdDeviation="${spacing/4}" /></filter>`;
	sites.forEach(site => {
		site.devices.filter(devicesFilter).forEach(device => {
			// Just to help typing
			if(!device.range) {
				return;
			}

			svg += _sector(device, device.range, {});
		});
	});
	svg += '</defs>';
	svg += `<g fill="${palette.base}" opacity="${palette.opacity}" filter="url(#edgeBlur)">`;

	sites.forEach(site => {
		const x = _round(coverageWidth * (site.longitude-bounds.west) / degreesLng);
		const y = _round(coverageHeight * (bounds.north-site.latitude) / degreesLat);

		site.devices.filter(devicesFilter).forEach(device => {
			// Balance offset used in generating stand-alone sectors
			svg += _useSector(device, {
				x: String(x-stdRadius),
				y: String(y-stdRadius),
			});
		});
	});

	svg += '</g>';
	svg += '</svg>';

	return {
		key,
		svg,
		width: suggestedRenderWidth,
		height: suggestedRenderHeight,
		bounds,
	};
};

/**
 * Creates overlay SVGs for use in maps
 *
 * @param devices devices to create SVGs for
 * @param bandsToRender frequency bands to be rendered
 * @param overlayRadius radius rendered in meters or `actual`
 * @param light colour scheme (light === true)
 * @param highlight Device ids which should show be highlighted
 * @param conflict Device ids which are conflicting
 * @param debug Use debug mode
 * @returns The least significant overlays first. Render using array index as z-index. Highlighted > Conflicted > Normal
 */
export const devicesSVGs = (devices: readonly Device[], bandsToRender: readonly BandName[], overlayRadius: 'actual' | number, light=false, highlight: readonly string[]=[], conflict: readonly string[]=[], debug=false): SVGOverlay[] => {
	const sites: Record<string, {devices: Device[], highlight: boolean, conflict: boolean}> = {};
	devices.forEach(device => {
		const entry = sites[device.site.id] ??= {
			devices: [],
			highlight: false,
			conflict: false,
		};
		entry.devices.push(device);
		entry.highlight = entry.highlight || highlight.includes(device.id);
		entry.conflict = entry.conflict || conflict.includes(device.id);
	});
	// Return overlays with the least significant first to improve rendered look with highlighted overlays having a higher z-index than conflict etc.
	return Object.values(sites).sort((a, b) => {
		if(a.highlight !== b.highlight) {
			return a.highlight ? 1 : -1;
		}
		if(a.conflict !== b.conflict) {
			return a.conflict ? 1 : -1;
		}
		return a.devices[0].site.id.localeCompare(b.devices[0].site.id);
	}).map(entry => siteSVG(entry.devices, bandsToRender, overlayRadius, light, highlight, conflict, debug));
};