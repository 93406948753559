import type { User } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { UserCreationFormResult } from '../components';
import type { RouteProps } from './route-props';
import { observable, useObservable } from 'ecce-preact';
import { route } from 'preact-router';
import { Alert, UserCreationForm, UserCreationFormController } from '../components';
import { Controller, useController } from '../util';


export type Status = 'loading' | 'notFound' | 'valid';

type InviteRouteStatus = (
	| { kind: 'notFound' }
	| { kind: 'valid', username: string, name: string, formController: UserCreationFormController }
);

class InviteRouteController extends Controller {
	readonly inviteCode: string | null = null;

	#status: InviteRouteStatus | null = null;
	get status(): InviteRouteStatus | null { return this.#status; }
	@observable() private set status(value: InviteRouteStatus | null) { this.#status = value; }

	#loading: boolean = false;
	get loading(): boolean { return this.#loading; }
	@observable() private set loading(value: boolean) { this.#loading = value; }

	constructor(inviteCode: string | undefined) {
		super();
		this.inviteCode = inviteCode ?? null;
	}

	protected async initialise(): Promise<void> {
		if(!this.inviteCode) {
			this.status = { kind: 'notFound' };
			return;
		}

		this.loading = true;
		const invite = await this.services.apiService.get<User>(`/api/invite/${this.inviteCode}`);
		this.loading = false;

		if(!invite.ok) {
			this.status = { kind: 'notFound' };
			return;
		}

		this.status = {
			kind: 'valid',
			name: invite.body.name,
			username: invite.body.username,
			formController: new UserCreationFormController({
				self: false,
				username: invite.body.username,
				name: invite.body.name,
				mode: 'passwordOnly',
				submitLabel: 'Set Password',
				onSubmit: result => this.#handleSubmit(result),
			}),
		};
	}

	async #handleSubmit(result: UserCreationFormResult): Promise<void> {
		if(this.loading) {
			return;
		}

		this.loading = true;
		const response = await this.services.apiService.post('/api/invite', {
			inviteCode: this.inviteCode,
			password: result.password,
		});

		if(!response.ok) {
			this.loading = false;
			console.error('Failed to accept invitation:', response); // eslint-disable-line no-console
			throw new Error('Failed to accept invitation');
		}

		this.services.notificationService.notify({
			severity: 'success',
			message: 'Welcome to Bearing!',
		});

		this.services.dataService.refresh();
		route('/');
	}
}

export const InviteRoute = (p: RouteProps): JSX.Element => {
	const controller = useController(() => new InviteRouteController(p.matches?.['inviteCode']));
	useObservable(controller);

	return (
		<div className="pad-v-xl content content--narrow stack-md">
			{ controller.status?.kind === 'notFound' && (
				<Alert
					severity="error"
					title="Invite Code Not Found"
				>
					<p>That invite code does not exist.</p>
					<p>Please contact with your BEARING administrator for an updated link.</p>
				</Alert>
			)}
			{ controller.status?.kind === 'valid' && (
				<>
					<Alert severity="info" title={ 'Welcome to Bearing, ' + controller.status.name }>
						<p>Set your password.</p>
					</Alert>

					<div className="stack-xs">
						<div className="text-lg text-bolder">Username</div>
						<div className="text-sm">You will use this to log in.</div>
						<div className="font-mono text-lg">{controller.status.username}</div>
					</div>

					<UserCreationForm
						controller={ controller.status.formController }
						disabled={ controller.loading }
					/>
				</>
			)}
		</div>
	);
};