import type { Squelch } from '@brng/common';
import type { NetworkIssue } from '.';
import { bound } from 'ecce';


export type SquelchListener = (squelch: Squelch | null) => void;
export abstract class Squelchable {
	abstract id: string;
	abstract kind: NetworkIssue.Kind;

	#handler: SquelchHandler | null = null;
	#squelch: Squelch | undefined | null = undefined;

	/**
	 * Called by Network to initialise handler.
	 */
	private initialise(handler: SquelchHandler): void {
		this.#handler = handler;
	}

	// TODO: investigate why this does not work in ecce...
	// @observable()
	get squelch(): Squelch | null {
		if(!this.#handler) {
			return null;
		}

		if(this.#squelch === undefined) {
			this.#squelch = this.#handler.getSquelch(this);
		}

		return this.#squelch;
	}

	setSquelched(value: boolean): void {
		if(!this.#handler) {
			return;
		}

		this.#squelch = this.#handler.setSquelched(this, value);

		// TODO: investigate why this does not work in ecce...
		// notify(this, 'squelch');
		// notify(this, 'squelched');
	}

	// TODO: investigate why this does not work in ecce...
	// @observable()
	get squelched(): boolean {
		return !!this.squelch;
	}

	@bound()
	toggleSquelched(): void {
		this.setSquelched(!this.squelched);
	}
}

export type SquelchHandler = {
	getSquelch(target: Squelchable): Squelch | null;
	setSquelched(target: Squelchable, squelched: boolean): Squelch | null;
};
