import type { VNode } from 'preact';
import { Alert } from '../components';


export const NotFoundRoute = (_props: { default: true, auth: 'open' }): VNode => {
	return (
		<Alert
			className="mrg-auto content content--narrow"
			severity="error"
			title="404"
		>
			The page <strong>{window.location.pathname}</strong> could not be found.
		</Alert>
	);
};
