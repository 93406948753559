const earthRadiusM = 6371000;

export type Point = Readonly<{
	lat: number;
	lng: number;
}>;

export type Bounds = {
	/**
	 * East longitude in degrees. Values outside the range [-180, 180] will be
	 * wrapped to the range [-180, 180). For example, a value of -190 will be
	 * converted to 170. A value of 190 will be converted to -170. This reflects
	 * the fact that longitudes wrap around the globe.
	 */
	east: number;
	/**
	 * North latitude in degrees. Values will be clamped to the range [-90, 90].
	 * This means that if the value specified is less than -90, it will be set
	 * to -90. And if the value is greater than 90, it will be set to 90.
	 */
	north: number;
	/**
	 * South latitude in degrees. Values will be clamped to the range [-90, 90].
	 * This means that if the value specified is less than -90, it will be set
	 * to -90. And if the value is greater than 90, it will be set to 90.
	 */
	south: number;
	/**
	 * West longitude in degrees. Values outside the range [-180, 180] will be
	 * wrapped to the range [-180, 180). For example, a value of -190 will be
	 * converted to 170. A value of 190 will be converted to -170. This reflects
	 * the fact that longitudes wrap around the globe.
	 */
	west: number;
};

export const asPoint = (lat: number, lng: number): Point => ({ lat: normaliseLatitude(lat), lng: normaliseLongitude(lng) });

export const asBounds = (points: Point[]): Bounds => {
	if(!points.length) {
		return { east: 0, north: 0, south: 0, west: 0 };
	}
	if(points.length === 1) {
		return { east: points[0].lng, north: points[0].lat, south: points[0].lat, west: points[0].lng };
	}
	return {
		east: normaliseLongitude(Math.max(...points.map(point => point.lng))),
		north: normaliseLatitude(Math.max(...points.map(point => point.lat))),
		south: normaliseLatitude(Math.min(...points.map(point => point.lat))),
		west: normaliseLongitude(Math.min(...points.map(point => point.lng))),
	};
};

export const asBoundsFromPointAndRange = (center: Point, range: number): Bounds => {
	const north = earth.point(center, range, 0);
	const south = earth.point(center, range, 180);
	const east = earth.point(center, range, 90);
	const west = earth.point(center, range, 270);
	
	return {
		north: north.lat,
		south: south.lat,
		east: east.lng,
		west: west.lng,
	};
};

const normaliseLatitude = (lat: number): number => Math.clamp(lat, -90, 90);

const normaliseLongitude = (lng: number): number => {
	if(lng > 180) {
		return lng - 360;
	}
	if(lng < -180) {
		return 360 + lng;
	}
	return lng;
};

export const pointMean = (points: Iterable<Point>): Point => {
	const result = { lat: 0, lng: 0 };
	let count = 0;
	for(const point of points) {
		result.lat += point.lat;
		result.lng += point.lng;
		++count;
	}

	if(count > 1) {
		result.lat /= count;
		result.lng /= count;
	}

	return result;
};

export class Sphere {
	// See: https://www.codeguru.com/cpp/cpp/algorithms/article.php/c5115/Geographic-Distance-and-Azimuth-Calculations.htm
	constructor(public readonly radius: number = earthRadiusM) {}

	point(point: Point, distance: number, azimuth: number): Point {
		const azi = Math.toRadians(azimuth);
		const lat1 = Math.toRadians(point.lat);
		const lon1 = Math.toRadians(point.lng);

		const b = distance/this.radius;
		const a = Math.acos(Math.cos(b)*Math.sin(lat1) + Math.cos(lat1)*Math.sin(b)*Math.cos(azi));

		const lat2 = Math.PI/2 - a;
		const lon2 = Math.asin(Math.sin(b)*Math.sin(azi)/Math.sin(a)) + lon1;

		return asPoint(Math.toDegrees(lat2), Math.toDegrees(lon2));
	}


	azimuth(point1: Point, point2: Point): number {
		const lat1 = Math.toRadians(point1.lat);
		const lon1 = Math.toRadians(point1.lng);
		const lat2 = Math.toRadians(point2.lat);
		const lon2 = Math.toRadians(point2.lng);
		const y = Math.sin(lon2-lon1) * Math.cos(lat2);
		const x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1);
		const azimuth = Math.atan2(y, x);
		return Math.toDegrees(azimuth);
	}

	distance(point1: Point, point2: Point): number {
		const lat1 = Math.toRadians(point1.lat);
		const lon1 = Math.toRadians(point1.lng);
		const lat2 = Math.toRadians(point2.lat);
		const lon2 = Math.toRadians(point2.lng);

		const b = Math.acos(Math.sin(lat2)*Math.sin(lat1) + Math.cos(lat2)*Math.cos(lat1)*Math.cos(lon2 - lon1));

		return this.radius * b;
	}
}

export const earth = new Sphere();
