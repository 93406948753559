export type StringKeyOf<T> = Exclude<keyof T, number | symbol>;

/**
 * Type of all the keys of `T` who's value satisfies `V`
 *
 * @example
 * type Foo = {
 *   a: number;
 *   b: string;
 *   c: number;
 *   d: string;
 * }
 *
 * type FooStrings = KeysOfType<Foo, string> // 'b' | 'd'
 */
export type KeysOfValue<T, V> = {
	[ K in StringKeyOf<T> ]: T[K] extends V ? K : never
}[StringKeyOf<T>];