import type { CustomerSite, InfrastructureSite } from './site';
import type { Device } from './device';
import type { Point } from '@brng/common';
import { earth } from '@brng/common';


// TODO: This should really probably fit in with SiteGeometry or some such but the expectation is to move it into Device/DeviceGeometry
export const getNearbyDevices = function (origin:CustomerSite, infrastructureSites: readonly InfrastructureSite[]):Device[] {
	const calculator = new RangeBearingCalculator({ lat: origin.latitude,lng: origin.longitude });
	
	const deviceScores = infrastructureSites.flatMap(site => site.devices
		.filter(device => device.type==='Access Point')
		.map(device => ({ device, score: device.geometry.outsideByRangeBearing(...calculator.rangeBearing({ lat: site.latitude,lng: site.longitude })) }))
		.filter(deviceScore => deviceScore.score < 10)
	).sort((a, b) => {
		if (a.score !== b.score) {
			return a.score - b.score;
		}
		return a.device.name.localeCompare(b.device.name);
	});
	
	return deviceScores.filter((deviceScore, index, array) => deviceScore.score <=0 || array.length<5)
		.map(deviceScore => deviceScore.device)
		.sort((a, b) => a.name.localeCompare(b.name));
};

class RangeBearingCalculator {
	private cache:Record<string, [ range: number, bearing: number ]> = {};
	
	constructor(public readonly origin: Point) {
	}
	
	rangeBearing(point:Point) {
		const key = point.lat+''+point.lng;
		if(!this.cache[key]) {
			this.cache[key] = [
				earth.distance(point, this.origin),
				earth.azimuth(point, this.origin),
			];
		}
		return this.cache[key] as [ range: number, bearing: number ];
	}
}