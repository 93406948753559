import type { DeviceRaw, RadioRaw, SiteRaw } from '@brng/common';
import type { NetworkConfig } from '../network';
import { Network } from '../network';


let nextId = 0;
const makeId = (prefix: string) => prefix + nextId++;

const DEFAULT_TEST_SITE: Omit<SiteRaw, 'id' | 'devices'> = {
	latitude: 0,
	longitude: 0,
	link: null,
	name: 'Test site ' + Math.random(),
	company: null,
	source: null,
	type: 'Tower',
	usage: 'Infrastructure',
};

const DEFAULT_TEST_DEVICE: Omit<DeviceRaw, 'id' | 'siteId' | 'radios'> = {
	antennaType: 'Sector',
	azimuth: 0,
	sectorSize: 2,
	siblingId: null,
	snmpStatus: 'SUCCESS',
	status: 'Good',
	suppressWarnings: false,
	type: 'Access Point',
	url: 'https://example.com',
	childIds: null,
	deviceType: 'Acme ABC123',
	ipAddress: '192.168.0.1',
	link: null,
	name: 'Test Device ' + Math.random(),
	parentId: null,
};

const DEFAULT_TEST_RADIO: Omit<RadioRaw, 'id'> = {
	frequencyBand: '5000',
	frequency: 5290,
	channelWidth: 20,
};

const makeTestRadio = (config: Partial<RadioRaw> = {}): RadioRaw => {
	return {
		id: config.id ?? makeId('r'),
		...DEFAULT_TEST_RADIO,
		...config,
	};
};

type TestDeviceConfig = Partial<Omit<DeviceRaw, 'siteId' | 'radios'>> & {
	radios?: Partial<RadioRaw>[]
};
const makeTestDevice = (siteId: string, config: TestDeviceConfig = {}): DeviceRaw => {
	return {
		id: makeId('d'),
		siteId,
		...DEFAULT_TEST_DEVICE,
		...config,
		radios: config.radios?.map(makeTestRadio) ?? [ makeTestRadio() ],
	};
};

type TestSiteConfig = Partial<Omit<SiteRaw, 'devices'>> & {
	devices?: TestDeviceConfig[];
};
const makeTestSite = (config: TestSiteConfig = {}): SiteRaw => {
	const id = config.id ?? makeId('s');
	return {
		id,
		...DEFAULT_TEST_SITE,
		...config,
		devices: config.devices?.map(device => makeTestDevice(id, device)) ?? [ makeTestDevice(id) ],
	};
};

export const makeTestNetwork = (sites?: TestSiteConfig[], config: Partial<Omit<NetworkConfig, 'sites'>> = {}): Network => {
	const network = new Network({
		sites: sites?.map(makeTestSite) ?? [ makeTestSite() ],
		settings: {
			automaticErrorReports: false,
			googleMapsKey: null,
			gpsSyncAzimuth: 0,
			gpsSyncEnabled: false,
			snmpEnabled: false,
			...config.settings,
		},
		squelchHandler: config.squelchHandler ?? null,
	});
	return network;
};
