import type { FrequencyBand, Point } from '@brng/common';
import type { Device } from '../device';
import type { CustomerSite } from '../site';
import { asPoint, earth } from '@brng/common';
import { Squelchable } from '..';


export class FrequencyOverlapIssue extends Squelchable {
	readonly kind = 'frequencyOverlap';
	readonly id: string;

	readonly devices: readonly [ Device, Device ];
	readonly overlap: number;
	readonly range: [ number, number ];

	constructor(init: { device1: Device, device2: Device, overlap: number, range: [ number, number ] }) {
		super();

		this.id = `${init.device1.id}-${init.device2.id}-${init.device1.radios[0]?.frequency}`;
		this.devices = [ init.device1, init.device2 ];
		this.overlap = init.overlap;
		this.range = init.range;

		this.devices[0]['addIssue'](this);
		this.devices[1]['addIssue'](this);
	}
}

export type MissableData = typeof MissingDataIssue.FIELDS[number];
export class MissingDataIssue {
	static readonly FIELDS = [
		'frequency-band', 'frequency', 'channel-width', 'azimuth', 'azimuth-backhaul', 'sector-size', 'not-omni',
	] as const;

	readonly kind = 'missingData';
	readonly id: string;

	readonly device: Device;
	readonly missing: readonly MissableData[];

	constructor(init: { device: Device, missing: readonly MissableData[] }) {
		this.id = `${this.kind}-${init.device.id}`;
		this.device = init.device;
		this.missing = init.missing;

		this.device['addIssue'](this);
	}

	get numMissingFields() {
		return this.missing.length;
	}

	includes(field: MissableData): boolean {
		return this.missing.includes(field);
	}
}

export class BadStatusIssue {
	readonly kind = 'badStatus';
	readonly id: string;

	readonly device: Device;
	readonly status: string;

	constructor(init: { device: Device, status: string }) {
		this.id = `${this.kind}-${init.device.id}`;
		this.device = init.device;
		this.status = init.status;

		this.device['addIssue'](this);
	}
}

export class InterSiteInterferenceIssue extends Squelchable {
	readonly kind = 'interSite';
	readonly id: string;

	readonly devices: readonly [ Device, Device ];
	readonly location: Point;
	readonly range: number;
	readonly frequencyBand: FrequencyBand;
	readonly frequencyLow: number;
	readonly frequencyHigh: number;

	constructor(init: { device1: Device, device2: Device, frequencyBand: FrequencyBand, frequencyLow: number, frequencyHigh: number }) {
		super();
		this.id = `interSite-${init.device1.id}-${init.device2.id}`;
		this.devices = [ init.device1, init.device2 ];
		const points = [ init.device1.location, init.device2.location ] as const;
		this.location = asPoint((points[0].lat + points[1].lat) / 2, (points[0].lng + points[1].lng) / 2);
		this.range = earth.distance(points[0],points[1]);
		this.frequencyBand = init.frequencyBand;
		this.frequencyLow = init.frequencyLow;
		this.frequencyHigh = init.frequencyHigh;

		this.devices[0]['addIssue'](this);
		this.devices[1]['addIssue'](this);
	}
}

export class OutOfRangeIssue extends Squelchable {
	readonly kind = 'outOfRange';
	readonly id: string;
	readonly device: Device;
	readonly customer: CustomerSite;
	readonly range: number;

	constructor(init: { device: Device, customer: CustomerSite, range: number }) {
		super();
		this.id = `outOfRange-${init.customer.id}-${init.device.id}-${init.device.site.id}`;
		this.device = init.device;
		this.customer = init.customer;
		this.range = init.range;

		this.device['addIssue'](this);
		this.customer['addIssue'](this);
	}

	get location(): Point {
		return this.customer.location;
	}
}

export class OutOfSectorIssue extends Squelchable {
	readonly kind = 'outOfSector';
	readonly id: string;
	readonly device: Device;
	readonly customer: CustomerSite;
	readonly percent: number;

	constructor(init: { device: Device, customer: CustomerSite, percent: number }) {
		super();
		this.id = `outOfSector-${init.customer.id}-${init.device.id}-${init.device.site.id}`;
		this.device = init.device;
		this.customer = init.customer;
		this.percent = init.percent;

		this.device['addIssue'](this);
		this.customer['addIssue'](this);
	}

	get location(): Point {
		return this.customer.location;
	}
}

export type NetworkIssue = (
	| FrequencyOverlapIssue
	| MissingDataIssue
	| BadStatusIssue
	| InterSiteInterferenceIssue
	| OutOfRangeIssue
	| OutOfSectorIssue
);
export namespace NetworkIssue {
	export type Kind = NetworkIssue['kind'];
}
