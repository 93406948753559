import type { MidasStatus } from '@brng/common';
import type { IconName } from '../components/atoms/icon/icons';
import './snmp.scss';
import type { Severity } from './severity';


export type SnmpStatusDisplayInfo = Readonly<{
	title: string;
	severity: Severity | 'none';
	icon: IconName;
}>;
const SNMP_STATUS_DISPLAY_INFO: Record<MidasStatus, SnmpStatusDisplayInfo> = {
	'SUCCESS': {
		title: 'Success',
		severity: 'success',
		icon: 'success',
	},
	'WAITING': {
		title: 'Waiting to update',
		severity: 'info',
		icon: 'clock',
	},
	'PARTIAL': {
		title: 'Partial success',
		severity: 'warning',
		icon: 'warning',
	},
	'FAILURE': {
		title: 'Failure',
		severity: 'error',
		icon: 'error',
	},
	'UNIDENTIFIED': {
		title: 'Unidentified',
		severity: 'none',
		icon: 'unknown',
	},
	'NOT_IDENTIFIED': {
		title: 'Not identified',
		severity: 'none',
		icon: 'unknown',
	},
	'NO_CONNECTION_DETAILS': {
		title: 'No SNMP connection details',
		severity: 'none',
		icon: 'error',
	},
};

export const getSnmpStatusDisplayInfo = (status: MidasStatus): SnmpStatusDisplayInfo => (
	SNMP_STATUS_DISPLAY_INFO[status]
);

export const getSnmpStatusClassName = (status: MidasStatus): string => (
	'snmpStatus--' + status
);

export const getSnmpVersionString = (x: number | null | undefined): string | undefined => {
	switch(x) {
		case 0: return '1';
		case 1: return '2C';
		case 3: return '3';
	}
};