import type { MidasStatus, SnmpConnectionDetails } from './snmp';


export type SiteRaw = {
	id: string;
	/** If the `id` is an amalgamation then `source` provides a way to display the original id to the user **/
	source: {
		id: string;
		type: string;
	} | null;
	link: string | null;
	devices: DeviceRaw[];
	name: string;
	company: string | null;
	type: 'Tower' | 'Silo' | 'Building' | 'House' | 'Repeater' | 'Pole' | 'Other';
	usage: 'Infrastructure' | 'Customer';
	latitude: number;
	longitude: number;
};

export type DeviceRaw = {
	id: string;
	link: string | null;
	siteId: string;
	/** Subscriber Modules should have a parentId **/
	parentId: string | null;
	/** Access Points should have childIds which may be empty **/
	childIds: string[] | null;
	/** Backhauls should have siblingID **/
	siblingId: string | null;
	name: string;
	deviceType: string;
	ipAddress: string;
	type: 'Access Point' | 'Backhaul' | 'Subscriber Module';
	url: string;
	radios: RadioRaw[];
	antennaType: 'Sector' | 'Omni';
	// TODO(#577): This is possibly undefined.
	azimuth: number;
	// TODO(#577): This is possibly undefined.
	sectorSize: number;
	height?: number;
	downtilt?: number;
	range?: number;
	status: 'Good' | 'Down' | 'Bad' | 'Warning' | 'Unknown';
	suppressWarnings: boolean;
	snmpConnectionDetails?: SnmpConnectionDetails;
	snmpStatus: MidasStatus;
};

export type RadioRaw = {
	id: string;
	frequencyBand: string | undefined;
	frequency: number;
	channelWidth: number;
};

export interface FrequencyOverlap {
	id: string;
	equipmentIds: string[];
	equipmentNames: string[];
	overlap: number;
	range: number[];
}

export type CompanyMetadata = {
	companyName: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
};
export type Metadata = CompanyMetadata & {
	portalUrl: string;
	rfDevices: number;
	sites: number;
	latitude: number;
	longitude: number;
	dataProvider: string;
};

export namespace LicenseState {
	export type Ok = (
		| 'BETA'
		| 'TRIAL'
		| 'VALID'
	);
	export type Failed = 'FAILED';
	export type Unreachable = 'UNREACHABLE';
	export type RepositoryError = 'REPOSITORY_ERROR';
}
export type LicenseState = (
	| LicenseState.Ok
	| LicenseState.Failed
	| LicenseState.Unreachable
	| LicenseState.RepositoryError
);

export type BillingStatus = (
	| undefined
	| 'cancelled'
	| 'failed'
);
