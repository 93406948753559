import type { NotificationInfo, NotificationService } from '../services';
import type { ChildrenProp } from './props';
import { createContext } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { useNotificationService } from '../services/services-context';


export class ClipboardController {
	static readonly NOTIFICATION_TYPE = 'clipboard';
	
	readonly enabled: boolean;
	readonly #notificationService: NotificationService;

	constructor(notificationService: NotificationService) {
		this.enabled = !!window.navigator?.clipboard;
		this.#notificationService = notificationService;
	}

	writeText(text: string, notification: boolean | string | NotificationInfo = true) {
		if(!this.enabled) {
			return ;
		}

		try {
			window.navigator.clipboard.writeText(text);
		} catch(err) {
			this.#notificationService.notify({
				type: ClipboardController.NOTIFICATION_TYPE,
				message: 'Failed to copy to clipboard',
				severity: 'error',
			});
		}
		
		if(!notification) {
			return;
		}

		let notificationInfo: NotificationInfo = {
			type: ClipboardController.NOTIFICATION_TYPE,
			message: 'Copied to clipboard',
			severity: 'success',
		};
		
		switch(typeof(notification)) {
			case 'string':
				notificationInfo.message = notification;
				break;
			case 'object':
				notificationInfo = notification;
				break;
		}

		this.#notificationService.notify(notificationInfo);
	}
}

const clipboardControllerContext = createContext<ClipboardController | null>(null);
export const ClipboardControllerProvider = ({ children }: ChildrenProp) => {
	const notificationService = useNotificationService();
	const [ clipboardController ] = useState(() => new ClipboardController(notificationService));

	return (
		<clipboardControllerContext.Provider value={ clipboardController }>
			{ children }
		</clipboardControllerContext.Provider>
	);
};
export const useClipboardController = (): ClipboardController => {
	const ctx = useContext(clipboardControllerContext);
	if(!ctx) {
		throw new Error('clipboardControllerContext was null; missing <ClipboardControllerProvider>?');
	}

	return ctx;
};