
export type CsvRow <K extends string> = Record<K, string | number>;

const cleanCsvValue = (value: number | string): string => {
	if(typeof(value) === 'number') {
		return value.toString();
	}

	if(!value) {
		return '';
	}
	
	if(value.includes(',')) {
		value = '"' + value.replaceAll('"', '""') + '"';
	}

	return value;
};

export const makeCsvDataUrl = <K extends string>(header: readonly K[], rows: Record<K, string | number>[]): string => {
	const csv = [
		header,
		...rows.map(row => header.map(col => cleanCsvValue(row[col]))),
	]
		.map(row => row.join(','))
		.join('\n');

	return `data:text/csv;charset=utf8;base64,${btoa(csv)}`;
};