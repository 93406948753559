import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { CustomerAlternativeDeviceOption, CustomerAlternativeDevicesFilter } from './customer-alternative-devices-filter';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { Checkbox, Icon } from '../../components';
import './customer-alternative-devices-filter-view.scss';


const AltDeviceBandView = (p: { option: CustomerAlternativeDeviceOption, filter: CustomerAlternativeDevicesFilter }): JSX.Element => {
	const [ open, setOpen ] = useState(true);
	const bandEnabled = !p.filter.disabledBands.has(p.option.band.name);

	const handleBandEnabledChange = (enabled: boolean) => {
		p.filter.setBandEnabled(enabled, p.option.band);
	};

	return (
		<details
			key={ p.option.band.name } class="section"
			open={ open }
			onToggle={ ev => setOpen(ev.currentTarget.open) }
		>
			<summary>
				<Checkbox
					id={ 'altDeviceBand' + p.option.band.name }
					className="band-checkbox"
					label={ p.option.band.name }
					checked={ bandEnabled }
					onChange={ handleBandEnabledChange }
					disabled={ !p.filter.anyEnabled }
				/>
				<Icon icon={ open ? 'chevronUp' : 'chevronDown' }/>
			</summary>
			<div className="section">
				{p.option.devices.map(device => (
					<Checkbox
						id={ 'altDevice' + device.id }
						label={ device.name }
						checked={ !p.filter.disabledDevices.has(device.id) }
						onChange={ enabled => p.filter.setOptionEnabled(enabled, device) }
						key={ device.id }
						disabled={ !p.filter.anyEnabled || !bandEnabled }
					/>
				))}
			</div>
		</details>
	);
};

export type CustomerAlternativeDeviceFilterViewProps = ClassProp & {
	filter: CustomerAlternativeDevicesFilter;
};
export const CustomerAlternativeDeviceFilterView = (p: CustomerAlternativeDeviceFilterViewProps): JSX.Element => {
	useObservable(p.filter);

	return (
		<div className={ classNames(p.className, 'customer-alternative-devices-filter-view') }>
			<Checkbox
				id="altDeviceEnabled"
				label="Alternative Equipment"
				checked={ p.filter.anyEnabled }
				onChange={ p.filter.setAnyEnabled }
			/>
			{ p.filter.options.map((option, index) => (
				<AltDeviceBandView option={ option } filter={ p.filter } key={ index }/>
			))}
		</div>
	);
};