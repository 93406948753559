import type { JSX } from 'preact/jsx-runtime';
import type { RouteProps } from '../route-props';
import { InventorySearchView } from './views';
import { InventoryFocusView } from './views/inventory-focus-view';
import { InventoryControllerProvider } from './inventory-controller-context';


export const InventoryRoute = (_: RouteProps): JSX.Element => (
	<InventoryControllerProvider>
		<InventorySearchView/>
		<InventoryFocusView/>
	</InventoryControllerProvider>
);