import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';
import { dialogPolyfill } from './dialog-polyfill';
import './modal.scss';


export type ModalStateProps = {
	open?: boolean;
	noDismiss?: boolean;
	transitionDuration?: number;
	onClose?: VoidFunction;
};

export type ModalProps = ModalStateProps & {
	children: JSX.Element;
};

export const Modal = ({ open, onClose, noDismiss, transitionDuration=300, children }: ModalProps) => {
	const [ dialogEl, setDialogEl ] = useState<HTMLDialogElement | null>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if(dialogEl) {
			dialogPolyfill.registerDialog(dialogEl);
		}
	}, [dialogEl]);

	const [ visible, setVisible ] = useState(open);

	const closeTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
	useLayoutEffect(() => {
		if(closeTimeout.current) {
			clearTimeout(closeTimeout.current);
		}

		if(!dialogEl) {
			return;
		}

		if(open) {
			if(!dialogEl.open) {
				setVisible(true);
				dialogEl.showModal();
			}
		} else {
			setVisible(false);
			closeTimeout.current = setTimeout(() => dialogEl.close(), transitionDuration);
		}
	}, [dialogEl, onClose, open, transitionDuration]);
	
	const handleKeyDown = useCallback((ev: KeyboardEvent) => {
		if(ev.key === 'Escape') {
			ev.preventDefault();

			if(noDismiss) {
				return;
			}

			onClose?.();
		}
	}, [noDismiss, onClose]);

	const handleBackdropClick = useCallback((ev: MouseEvent) => {
		if(noDismiss) {
			return;
		}

		if(ev.target === contentRef.current) {
			onClose?.();
		}
	}, [noDismiss, onClose]);
	
	const dialogClassName = classNames('modal', {
		'modal--visible': visible,
	});

	useEffect(() => {
		if(open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}

		return () => document.body.style.removeProperty('overflow');
	}, [open]);
	
	return (
		<dialog
			className={ dialogClassName }
			style={ `--modal-transition-duration: ${transitionDuration}ms` }
			ref={ setDialogEl }
			tabIndex={ visible ? 0 : -1 }
			onKeyDown={ handleKeyDown }
			onKeyDownCapture={ handleKeyDown }
		>
			<div
				className="modal__backdrop"
			/>
			<div
				className="modal__content"
				onMouseDownCapture={ handleBackdropClick }
				ref={ contentRef }
			>
				{ children }
			</div>
		</dialog>
	);
};