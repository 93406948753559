import type { Device, FrequencyOverlapIssue, Radio } from '@brng/domain';
import type { SpectrumGraph } from './spectrum-graph';
import { bound, observable } from 'ecce-preact';


export type EquipmentRadio = {
	equipment: Device;
	radio: Radio;
	low: number;
	high: number;
};

export type SpectrumGraphSegmentKind = (
	| 'used'
	| 'available'
	| 'excluded'
);
export type SpectrumGraphSegmentDef = {
	kind: SpectrumGraphSegmentKind;
	start: number;
	end: number;
	equipmentRadios?: EquipmentRadio[];
	overlaps?: FrequencyOverlapIssue[];
};

export class SpectrumGraphSegment {
	static readonly ID_ATTRIBUTE = 'data-spectrum-graph-segment-id';
	
	readonly kind: SpectrumGraphSegmentKind;
	readonly startFrequency: number;
	readonly endFrequency: number;
	readonly width: number;
	readonly equipmentRadios: Readonly<EquipmentRadio[]>;
	readonly equipment: ReadonlySet<Device>;
	readonly overlaps: Readonly<FrequencyOverlapIssue[]>;
	
	readonly #graph: SpectrumGraph;

	#highlight = false;
	get highlight(): boolean { return this.#highlight; }
	@observable() private set highlight(value: boolean) { this.#highlight = value; }
	
	#filtered = false;
	get filtered(): boolean { return this.#filtered; }
	@observable() private set filtered(value: boolean) { this.#filtered = value; }

	#overlap = false;
	get overlap(): boolean { return this.#overlap; }
	@observable() private set overlap(value: boolean) { this.#overlap = value; }


	constructor(config: Readonly<SpectrumGraphSegmentDef>, graph: SpectrumGraph) {
		this.kind = config.kind;
		this.startFrequency = config.start;
		this.endFrequency = config.end;
		this.width = this.endFrequency - this.startFrequency;
		this.equipmentRadios = Object.freeze([ ...config.equipmentRadios ?? [] ]);
		this.equipment = Object.freeze(new Set(config.equipmentRadios?.map(eqR => eqR.equipment)));
		this.overlaps = Object.freeze([ ...config.overlaps ?? [] ]);
		this.#graph = graph;
	}

	@bound()
	focus() {
		if(this.filtered) {
			return;
		}
		
		this.#graph.focusSegment(this);
	}

	@bound()
	blur() {
		this.#graph.blurSegment(this);
	}

	hasEquipment(equipment: Device | Iterable<Device>): boolean {
		if(Symbol.iterator in equipment) {
			for(const eq of equipment) {
				if(this.hasEquipment(eq)) {
					return true;
				}
			}
			return false;
		}

		return this.equipment.has(equipment);
	}

	hasAnyEquipment(): boolean {
		return !!this.equipment.size;
	}

	hasAnyOverlaps(): boolean {
		return !!this.overlaps.length;
	}

	/**
	 * Get the frequency value at a ratio of this segment's bandwidth.
	 * @param ratio Number in range 0..1
	 */
	getFrequencyAtRatio(ratio: number): number {
		return this.startFrequency + (this.endFrequency - this.startFrequency) * ratio;
	}

	get id() {
		return this.kind + this.startFrequency + this.endFrequency;
	}

	get frequencyBand() {
		return this.#graph.frequencyBand;
	}

	static readonly compare = (a: SpectrumGraphSegment, b: SpectrumGraphSegment): number => {
		return (a.startFrequency + a.endFrequency) - (b.startFrequency + b.endFrequency);
	};
}