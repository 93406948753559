import type { GetDataResponse, User } from '@brng/common';
import type { RouteAuthMode } from '../../routes/route-props';
import type { ApiService } from '../api-service';
import type { DataService } from '../data-service';
import { bound, notify, observable } from 'ecce-preact';
import { onRouteChange } from '../../routes';
import { AuthError } from './auth-error';


export type LoginArgs = {
	username: string;
	password: string;
};

export type AuthServiceDependencies = {
	apiService: ApiService;
	dataService: DataService;
};
export class AuthService {
	readonly #apiService: ApiService;
	readonly #dataService: DataService;

	#loginRequired: boolean = false;
	get loginRequired(): boolean { return this.#loginRequired; }
	@observable() private set loginRequired(value: boolean) { this.#loginRequired = value; }

	#activeUser: User | null = null;
	get activeUser(): User | null { return this.#activeUser; }
	@observable() private set activeUser(value: User | null) { this.#activeUser = value; }

	#routeAuthMode: RouteAuthMode = 'user';
	#data: GetDataResponse.Ok | null = null;
	#dataError: GetDataResponse.Error | null = null;

	constructor(deps: AuthServiceDependencies) {
		this.#apiService = deps.apiService;
		this.#dataService = deps.dataService;

		this.#dataService
			.on('error', ev => {
				this.#dataError = ev.error;
				this.#data = null;

				this.#updateLoginRequired();
			})
			.on('data', ev => {
				this.#dataError = null;
				this.#data = ev.data;
				this.activeUser = ev.data.me;
				notify(this, 'admin');

				this.#updateLoginRequired();
			});

		onRouteChange(ev => {
			this.#routeAuthMode = ev.auth;

			this.#updateLoginRequired();
		});
	}

	#updateLoginRequired(): void {
		if(this.#dataError?.reason === 'unauthenticated' && this.#routeAuthMode !== 'open') {
			this.loginRequired = true;
			return;
		}

		if(this.#dataError?.reason === 'repository-error' && !this.#dataError.me) {
			this.loginRequired = true;
			return;
		}

		if(this.#data) {
			if(this.#routeAuthMode === 'admin') {
				this.assertAdmin();
			}
		}

		if(this.loginRequired) {
			this.loginRequired = false;
		}
	}

	isAdmin(user: User | undefined | null): boolean {
		if(user?.status !== 'active') {
			return false;
		}

		return user.role === 'admin';
	}

	getActiveUser(): User {
		if(!this.activeUser) {
			throw new Error('activeUser was null.');
		}

		return this.activeUser;
	}

	@observable()
	get admin(): boolean {
		return this.isAdmin(this.activeUser);
	}

	/**
	 * Throws an {@link AuthError} if the active user does not have the admin role.
	 */
	assertAdmin(): void {
		if(!this.isAdmin(this.activeUser)) {
			throw new AuthError(403);
		}
	}

	async login(args: LoginArgs): Promise<boolean> {
		const response = await this.#apiService.post('/api/login', {
			username: args.username.trim(),
			password: args.password,
		});

		if(response.ok) {
			this.#dataService.refresh();
		}

		return response.ok;
	}

	@bound()
	async logout(): Promise<void> {
		this.#dataService.clearCache();
		await this.#apiService.delete('/api/login');
		window.location.reload();
	}
}