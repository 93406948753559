import type { BadStatusIssue, FrequencyOverlapIssue, InterSiteInterferenceIssue, MissableData, MissingDataIssue, Site } from '@brng/domain';
import type { JSX, VNode } from 'preact';
import type { SiteIssueTableSquelchMode } from './site-issues-table';
import { Fragment } from 'preact';
import { DataRow, IssueRow, Link, RepositoryLink, getNetworkItemIcon } from '../../../../components';
import { useSitesInfo } from '../../use-sites-info';



const DATA_LABELS: Record<MissableData, string> = {
	'frequency-band':   'Frequency Band',
	'frequency':        'Frequency',
	'channel-width':    'Channel width',
	'azimuth':          'Azimuth',
	'azimuth-backhaul': 'Azimuth (Backhaul)',
	'sector-size':      'Sector Size',
	'not-omni':         'Antenna Type should be \'Omni\'',
};

const SiteWarningContent = ({ warning }: { warning: BadStatusIssue | MissingDataIssue }): VNode => {
	switch(warning.kind) {
		case 'badStatus': {
			return <>Status: <code>{ warning.status }</code></>;
		}

		case 'missingData': {
			const equipmentHref = warning.device.link;
			return (
				<>
					{warning.missing.map(missing => {
						const docsHref = missing !== 'not-omni' && `faq/#${missing}`;
						let eqHref = equipmentHref;
						switch(missing) {
							case 'azimuth-backhaul':
								eqHref = equipmentHref + '#tabs-7';
								break;
							case 'frequency':
							case 'channel-width':
								eqHref = equipmentHref + '#tabs-2';
								break;
						}

						let label: string | JSX.Element = DATA_LABELS[missing];
						if(missing === 'frequency' || missing === 'channel-width') {
							label = <a href={ '/inventory#' + warning.device.networkId }>{label}</a>;
						}

						return (
							<Fragment key={ missing }>
								Missing data:&nbsp;
								<code key={ missing }>
									{ label }
								</code>
								{ eqHref && (
									<>
										&nbsp;
										<RepositoryLink href={ eqHref }/>
									</>
								)}
								{ docsHref && (
									<>
										&nbsp;
										<a href={ docsHref }>
											<i class="fa fa-question"/>
										</a>
									</>
								)}
								<br/>
							</Fragment>
						);
					})}
				</>
			);
		}
	}
};

type OverlapIssueRowProps = {
	overlap: FrequencyOverlapIssue;

	squelchMode: SiteIssueTableSquelchMode;
	onClick?: () => void;
	disableFocus?: boolean
};
export const OverlapIssueRow = ({ overlap, squelchMode, onClick, disableFocus }: OverlapIssueRowProps): VNode | null => {
	const { focusedEquipmentSet } = useSitesInfo();
	
	const handleMouseEnter = () => {
		if(disableFocus) {
			return;
		}

		focusedEquipmentSet.addAll(overlap.devices);
	};

	const handleMouseLeave = () => {
		if(disableFocus) {
			return;
		}
		focusedEquipmentSet.deleteAll(overlap.devices);
	};

	return (
		<IssueRow
			innerClassName="stack-sm"
			kind="frequencyOverlap"
			issue={ overlap }
			title="Frequency Overlap"
			squelchMode={ squelchMode }
			squelch={ overlap.squelch }
			onClick={ onClick }
			onMouseEnter={ handleMouseEnter }
			onMouseLeave={ handleMouseLeave }
		>
			<div>
				{overlap.devices.map(device => (
					<div key={ device.id }>{ device.name }</div>
				))}
			</div>
			<div>
				Overlaps by { overlap.overlap }MHz
				({overlap.range[0]}-{overlap.range[1]})
			</div>
		</IssueRow>
	);
};

type InterSiteIssueRowProps = {
	site: Site;
	issue: InterSiteInterferenceIssue;

	squelchMode: SiteIssueTableSquelchMode;
	onClick?: () => void;
	disableFocus?: boolean
};
export const InterSiteIssueRow = (p: InterSiteIssueRowProps): JSX.Element => {
	const thisDeviceIndex = p.issue.devices[0].site === p.site ? 0 : 1;
	const thisDevice = p.issue.devices[thisDeviceIndex];
	const otherDevice = p.issue.devices[1 - thisDeviceIndex];

	const content = (
		<>
			<Link
				label={ otherDevice.site.name }
				icon={ getNetworkItemIcon(otherDevice.site) }
				href={ '/?siteId=' + otherDevice.site.id }
			/>
			<div>
				<div>{thisDevice.name}</div>
				<div>{otherDevice.name}</div>
			</div>
		</>
	);

	return (
		<IssueRow
			innerClassName="stack-sm"
			kind="frequencyOverlap"
			issue={ p.issue }
			title="Inter-site Interference"
			squelchMode={ p.squelchMode }
			squelch={ p.issue.squelch }
			onClick={ p.onClick }
		>
			{ p.onClick && content }
			{ !p.onClick && (
				<a href={ '/issues#' + p.issue.id }>
					{content}
				</a>
			)}
		</IssueRow>
	);

};

type WarningIssueRowProps = {
	warning: BadStatusIssue | MissingDataIssue;
	onClick?: () => void;
	disableFocus?: boolean;
};
export const WarningIssueRow = ({ warning, onClick, disableFocus }: WarningIssueRowProps): VNode => {
	const { focusedEquipmentSet } = useSitesInfo();
	
	const handleMouseEnter = () => {
		if(disableFocus) {
			return;
		}

		focusedEquipmentSet.add(warning.device);
	};

	const handleMouseLeave = () => {
		if(disableFocus) {
			return;
		}

		focusedEquipmentSet.delete(warning.device);
	};

	return (
		<DataRow
			className="flex-column gap-sm"
			onClick={ onClick }
			onMouseEnter={ handleMouseEnter }
			onMouseLeave={ handleMouseLeave }
			noFocus
			key={ warning.kind + warning.device.id }
		>
			<div className="flex flex-wrap">
				<strong>
					{warning.device.name}
				</strong>
				<div className="mrg-l-auto">
					{ warning.device.deviceType }
				</div>
			</div>
			<div>
				<SiteWarningContent warning={ warning }/>
			</div>
		</DataRow>
	);
};