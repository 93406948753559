import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import classNames from 'classnames';
import './beta-flag.scss';


export type BetaFlagProps = ClassProp & {
	title?: string;
};
export const BetaFlag = ({ className, title }: BetaFlagProps): JSX.Element => (
	<div className={ classNames('betaFlag', className) } title={ title }>
		BETA
		{ title && <span className="visually-hidden">{title}</span>}
	</div>
);