import type { MapRouteController } from '../../map-route-controller';
import type { MapOverlayMode } from '../../map-route-display';
import { useObservable } from 'ecce-preact';


export type MapRouteOverlayModeSelectProps = {
	controller: MapRouteController;
	onChange?: VoidFunction;
};
export const MapRouteOverlayModeSelect = ({ controller, onChange }: MapRouteOverlayModeSelectProps) => {
	useObservable(controller.display, 'overlayMode');
	
	return (
		<select
			value={ controller.display.overlayMode }
			onChange={ ev => {
				controller.display.setOverlayMode(ev.currentTarget.value as MapOverlayMode);
				onChange?.();
			} }
		>
			<option value="frequency">Frequency</option>
			<option value="coverage">Coverage</option>
		</select>
	);
};