import type { User } from '@brng/common';
import type { IconName } from '../../../components';
import type { JSX } from 'preact/jsx-runtime';
import type { UsersAdminController } from './users-admin-controller';
import { useObservable } from 'ecce-preact';
import { Icon } from '../../../components';
import { useServices } from '../../../services';


export type UsersListProps = {
	title: string;
	icon: IconName;
	users: readonly User[] | undefined;
	controller: UsersAdminController;
	description?: string | JSX.Element;
};
export const UsersList = (p: UsersListProps): JSX.Element | null => {
	useObservable(p.controller, 'loading');
	const disabled = p.controller.loading;

	const { activeUser } = useServices().authService;
	if(!p.users?.length) {
		return null;
	}

	return (
		<section>
			<header className="flex-align-center">
				<Icon className="mrg-r-sm" size="lg" icon={ p.icon }/>
				<h2 className="mrg-0">{p.title}</h2>
			</header>
			{ p.description && <p className="text-sm mrg-v-xs">{p.description}</p> }
			<ul className="panel-group">
				{ p.users.map(user => (
					<li
						className="userItem panel"
						key={ user.id }
					>
						<div className="flex gap-sm flex-align-center">
							<div className="avatar">
								{ user.imageUrl && (
									<img src={ user.imageUrl }/>
								)}
								{ !user.imageUrl && (
									<Icon icon="user" size="lg"/>
								)}
							</div>
							<div className="stack-xs">
								<span className="text-lg">{user.username}</span>
								<span className="text-sm">{user.name}</span>
							</div>
						</div>
						{ user.id !== activeUser?.id && (
							<div className="actions stack-xs">
								{ user.status === 'pending' && (
									<>
										<button
											className="btn btn--invisible"
											onClick={ () => p.controller.showInvitationDialog(user) }
											disabled={ disabled }
										>
											<Icon icon="eye"/>
											Show Invitation
										</button>
										<button
											className="btn btn--invisible"
											onClick={ () => p.controller.deleteUser(user.id) }
											disabled={ disabled }
										>
											<Icon icon="delete"/>
											Revoke Invitation
										</button>
									</>
								)}
								{ user.status !== 'pending' && (
									<button
										className="btn btn--invisible"
										onClick={ () => p.controller.deleteUser(user.id) }
										disabled={ disabled }
									>
										<Icon icon="delete"/>
										Delete
									</button>
								)}
								{ user.status === 'inactive' && (
									<button
										className="btn btn--invisible"
										onClick={ () => p.controller.reactivateUser(user.id) }
									>
										<Icon icon="success"/>
										Reactivate
									</button>
								)}
								{ user.status === 'active' && (
									<>

										{ user.role === 'admin' && (
											<button
												className="btn btn--invisible"
												onClick={ () => p.controller.revokeAdminPermissions(user.id) }
												disabled={ disabled }
											>
												<Icon icon="adminOff"/>
												Revoke Admin Permissions
											</button>
										)}
										{ user.role === 'user' && (
											<button
												className="btn btn--invisible"
												onClick={ () => p.controller.grantAdminPermissions(user.id) }
												disabled={ disabled }
											>
												<Icon icon="admin"/>
												Grant Admin Permissions
											</button>
										)}
									</>
								)}
							</div>
						)}

					</li>
				))}
			</ul>
		</section>
	);
};