import type { Device, Site } from '@brng/domain';
import type { LegacyData } from '../data';
import { makeCsvDataUrl } from './csv';


const COLUMNS = [
	'Site ID',
	'Site Name',
	'Site Type',
	'Latitude',
	'Longitude',
	'Equipment Id',
	'Equipment Name',
	'Device Type',
	'IP Address',
	'Type',
	'Status',
	'Antenna Type',
	'Azimuth',
	'Sector Size',
	'Frequency Band',
	'Frequency',
	'Channel Width',
	'CRM URL',
	'Radio URL',
] as const;

const makeRow = (site: Site, eq: Device): Record<typeof COLUMNS[number], string | number> => ({
	'Site ID': eq.siteId,
	'Site Name': site.name,
	'Site Type': site.type,
	'Latitude': site.latitude,
	'Longitude': site.longitude,
	'Equipment Id': eq.id,
	'Equipment Name': eq.name,
	'Device Type': eq.deviceType,
	'IP Address': eq.ipAddress,
	'Type': eq.type,
	'Status': eq.status,
	'Antenna Type': eq.antennaType,
	'Azimuth': Math.round(eq.azimuth),
	'Sector Size': eq.sectorSize,
	'Frequency Band': eq.radios[0].band?.band ?? '',
	'Frequency': eq.radios[0]?.frequency ?? '',
	'Channel Width': eq.radios[0]?.channelWidth ?? '',
	'CRM URL': eq.link ?? 'No link provided',
	'Radio URL': eq.url,
});

export const makeAllDataReport = (data: LegacyData): string => (
	makeCsvDataUrl(COLUMNS, data.sites.flatMap(site => site.devices.map(eq => makeRow(site, eq))))
);