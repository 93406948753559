import type { JSX } from 'preact';
import type { ChildrenProp, ClassProp } from '../../util';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useServices } from '../../services';
import './repository-link.scss';


export type RepositoryLinkProps = ClassProp & ChildrenProp & {
	href: string | null;
};

export const RepositoryLink = ({ className, href, children }: RepositoryLinkProps): JSX.Element | null => {
	const { repositoryService } = useServices();
	useObservable(repositoryService, 'metadata');

	const { metadata } = repositoryService;
	if(!metadata || !('portalUrl' in metadata)) {
		return null;
	}

	if(!href) {
		return <span>(No link provided)</span>;
	}
	
	const hasChildren = Array.isArray(children) ? children.length : !!children;
	
	const absoluteHref = href.startsWith('http') ? href : metadata.portalUrl + href;
	
	return (
		<a
			className={ classNames('repository-link', className, { 'repository-link--with-children': hasChildren }) }
			href={ absoluteHref }
			target="_blank"
			rel="noreferrer"
		>
			{children}
			<i class="powercode-icon"/>
			<i class="fa fa-external-link-alt"/>
			{!hasChildren && (
				<span className="visually-hidden">
					View in {metadata.provider}
				</span>
			)}
		</a>
	);
};