import type { SnmpHelperController } from './snmp-helper-controller';
import type { SnmpProbeData } from './snmp-probe-data';
import type { SnmpProbeDeviceType, SnmpProbeFilterItem, SnmpProbeRadioModel } from './snmp-probe-filter-item';
import { bound } from 'ecce-preact';


export type SnmpProbeDef = Readonly<{
	data: Readonly<Omit<SnmpProbeData, 'deviceTypes' | 'radioModels'>>;
	deviceTypes: readonly SnmpProbeDeviceType[];
	radioModels: readonly SnmpProbeRadioModel[];
}>;
export class SnmpProbe {
	readonly #controller: SnmpHelperController;
	
	readonly #data: Readonly<Omit<SnmpProbeData, 'deviceTypes' | 'radioModels'>>;
	readonly deviceTypes: readonly SnmpProbeDeviceType[];
	readonly radioModels: readonly SnmpProbeRadioModel[];
	readonly filterItems: ReadonlySet<SnmpProbeFilterItem>;

	constructor(controller: SnmpHelperController, { data, deviceTypes, radioModels }: SnmpProbeDef) {
		this.#controller = controller;
		this.#data = data;
		this.deviceTypes = deviceTypes;
		this.radioModels = radioModels;
		this.filterItems = new Set([...deviceTypes, ...radioModels ]);
	}

	get name() { return this.#data.name; }
	get manufacturerId() { return this.#data.manufacturerId; }
	get manufacturerName() { return this.#data.manufacturerName; }
	get oid() { return this.#data.oid; }
	get minimum() { return this.#data.minimum; }
	get maximum() { return this.#data.maximum; }
	get unitOfMeasurement() { return this.#data.unitOfMeasurement; }
	get dataType() { return this.#data.dataType; }
	get probeType() { return this.#data.probeType; }

	filter(filterItem: SnmpProbeFilterItem): boolean {
		switch(filterItem.kind) {
			case 'manufacturer':
				return this.manufacturerName === filterItem.name;
			case 'deviceType':
			case 'radioModel':
				return this.filterItems.has(filterItem);
		}
	}

	@bound()
	showSqlDialog() {
		this.#controller.openSqlDialog(this);
	}

	get sql(): string {
		return `
# ${this.name}
INSERT INTO SNMPProbe 
(\`Name\`, \`OID\`, \`Minimum\`, \`Maximum\`, \`WarningModifier\`, \`WarningValue\`, \`BadModifier\`, \`BadValue\`, \`OutputModifier\`, \`Protected\`, \`CustomerViewable\`, \`DataType\`, \`Type\`, \`UnitOfMeasurement\`, \`SmartConvert\`, \`ManufacturerID\`, \`WarningPercentage\`, \`BadPercentage\`, \`WarningDeviationModifier\`, \`BadDeviationModifier\`, \`DeviationMinimum\`, \`PendingSubmission\`) 
VALUES 
('${this.name} - BRNG', '${this.oid}', ${this.minimum}, ${this.maximum}, 'LessThan', 0, 'LessThan', 0, 'None', 0, 0, '${this.dataType}', '${this.probeType}', '${this.unitOfMeasurement}', 0, ${this.manufacturerId}, 0, 0, '', '', 0, 1);
		`.trim();
	}
}
