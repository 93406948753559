import type { FunctionComponent, Ref, VNode } from 'preact';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { Chevron } from '../chevron';
import { Expander } from '../expander';
import { useDetailsController } from './details-controller';
import './details.scss';


type DetailsState = [ open: boolean, requestOpen: (newOpen: boolean) => void ];
const useDetailsState = (controllerId: string | undefined, initiallyOpen: boolean): DetailsState => {
	const uncontrolledState = useState(initiallyOpen);
	const controller = useDetailsController(controllerId);

	return useMemo<DetailsState>(() => {
		if(controller) {
			return [
				controller.isDetailsOpen(controllerId),
				open => controller.requestDetailsOpen(controllerId, open),
			];
		}
		return uncontrolledState;
	}, [controller, controllerId, uncontrolledState]);
};

export type DetailsEvent = {
	open: boolean;
	summary: HTMLElement;
};
export type DetailsListener = (event: DetailsEvent) => void;

export type DetailsProps = {
	className?: string;
	summary: string | VNode;
	initiallyClosed?: boolean;
	controllerId?: string;
	onChange?: DetailsListener;
	onTransitionFrame?: DetailsListener
	onTransitionEnd?: DetailsListener
};
export const Details: FunctionComponent<DetailsProps> = ({ className, summary, initiallyClosed = false, controllerId, onChange, onTransitionFrame, onTransitionEnd, children }) => {
	const summaryEl = useRef<HTMLElement>();
	
	const [ targetOpen, setTargetOpen ] = useDetailsState(controllerId, initiallyClosed);
	const [ detailsOpen, setDetailsOpen ] = useState(targetOpen);

	const handleToggle = useCallback((ev: Event) => {
		ev.preventDefault();
		setTargetOpen(!detailsOpen);
	}, [detailsOpen, setTargetOpen]);

	const handleTransitionComplete = useCallback((open: boolean) => {
		setDetailsOpen(open);
		if(onTransitionEnd) {
			onTransitionEnd({
				open,
				summary: summaryEl.current!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
			});
		}
	}, [onTransitionEnd]);
	
	useEffect(() => {
		if(!onTransitionFrame) {
			return;
		}

		if(targetOpen !== detailsOpen) {
			let frame: number;

			const handleFrame = () => {
				onTransitionFrame({
					open: targetOpen,
					summary: summaryEl.current!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
				});

				frame = requestAnimationFrame(handleFrame);
			};

			handleFrame();
			return () => cancelAnimationFrame(frame);
		}
	}, [ targetOpen, detailsOpen, onTransitionFrame ]);
	
	const previouslyDispatchedState = useRef(targetOpen);
	useEffect(() => {
		if(targetOpen !== previouslyDispatchedState.current) {
			previouslyDispatchedState.current = targetOpen;
			onChange?.({
				open: targetOpen,
				summary: summaryEl.current!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
			});
		}
	}, [ onChange, targetOpen ]);
	
	const rootClassName = useMemo(() => cn('details', className), [ className ]);
	
	return (
		<details
			className={ rootClassName }
			open={ targetOpen || detailsOpen }
		>
			<summary onClick={ handleToggle } ref={ summaryEl as Ref<HTMLElement> }>
				<div>
					{ summary }
				</div>
				<span className="details__control">
					<Chevron down={ !targetOpen }/>
				</span>
			</summary>
			<div>
				<Expander
					open={ targetOpen }
					onTransitionComplete={ handleTransitionComplete }
				>
					{ children }
				</Expander>
			</div>
		</details>
	);
};