import type { FunctionComponent } from 'preact';
import { Alert } from '../components';
import './license-failed.scss';


export const LicenseFailed: FunctionComponent = () => (
	<div class="license-failed flex-center">
		<Alert className="mrg-auto" severity="error" title="License Validation Failed">
			<p>
				We were unable to validate your license. Check that your license key is
				correct.
			</p>
			<p>
				If you have a BRNG account and a valid license key then please email
				us at <a href="mailto:support@brng.net?subject=License failure">support@brng.net</a>.
			</p>
		</Alert>
	</div>
);