import type { IconName } from '../../components';


const ADMIN_TAB_IDS = [
	'settings',
	'users',
	'repository-connection',
	'powercode-custom-fields',
	'csv-upload',
	'google-maps',
] as const;

export type AdminTabId = typeof ADMIN_TAB_IDS[number];

export const isAdminTabId = (x: unknown): x is AdminTabId => (
	ADMIN_TAB_IDS.includes(x as AdminTabId)
);

const TAB_LABELS: Record<AdminTabId, string> = {
	'settings': 'Settings',
	'users': 'Users',
	'repository-connection': 'Connection',
	'powercode-custom-fields': 'Custom Fields',
	'csv-upload': 'Upload CSV',
	'google-maps': 'Google Maps',
};
export const getAdminTabLabel = (id: AdminTabId): string => TAB_LABELS[id];

const TAB_ICONS: Record<AdminTabId, IconName> = {
	'settings': 'settings',
	'users': 'users',
	'repository-connection': 'database',
	'powercode-custom-fields': 'list',
	'google-maps': 'googleMaps',
	'csv-upload': 'uploadFile',
};
export const getAdminTabIcon = (id: AdminTabId): IconName => TAB_ICONS[id];

export type AdminTabSection = {
	key: string;
	title?: string;
	icon?: IconName;
	tabs: AdminTabId[];
};

export const getAdminTabSections = (providerName: string): AdminTabSection[] => {
	const tabs: AdminTabSection[] = [{
		key: 'general',
		tabs: [ 'settings', 'users' ],
	}];

	switch(providerName) {
		case 'Powercode':
			tabs.push({
				key: 'powercode',
				title: 'Powercode',
				icon: 'powercode',
				tabs: [ 'repository-connection', 'powercode-custom-fields' ],
			});
			break;
		case 'Manual':
			tabs.push({
				key: 'manual',
				title: 'Network Data',
				icon: 'database',
				tabs: [ 'csv-upload' ],
			});
	}

	tabs.push({
		key: 'integration',
		title: 'Integrations',
		icon: 'plug',
		tabs: [ 'google-maps' ],
	});
	
	return tabs;
};