import type { JSX } from 'preact';
import type { ChildrenProp, ClassProp, Severity } from '../../util';
import type { IconSize } from '../atoms';
import type { IconName } from '../atoms/icon/icons';
import classNames from 'classnames';
import { Icon } from '../atoms';
import './alert.scss';


export type AlertSize = 'md' | 'lg';
export type AlertSeverity = Severity | 'none';

const ALERT_ICON_SIZE: Record<AlertSize, IconSize> = {
	md: 'md',
	lg: 'xl',
};

export type AlertProps = ClassProp & ChildrenProp & {
	innerClassName?: string;
	severity: AlertSeverity;
	title: string;
	icon?: IconName;
	size?: AlertSize;
};
export const Alert = ({ className='', innerClassName, severity, title, children, icon, size = 'lg' }: AlertProps): JSX.Element => {
	return (
		<div className={ `alert alert--${severity} alert--${size} ${className}` }>
			<h2 className="alert__title">{title}</h2>
			<div className="alert__icon" aria-hidden>
				<Icon icon={ icon ?? (severity === 'none' ? 'info' : severity) } size={ ALERT_ICON_SIZE[size] }/>
			</div>
			{children && (
				<div className={ classNames(innerClassName, 'alert__body') }>
					{ children }
				</div>
			)}
		</div>
	);
};