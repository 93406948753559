import type { FunctionComponent } from 'preact';
import { createContext } from 'preact';
import { useCallback, useContext, useMemo } from 'preact/hooks';


export type DetailsControllerContext = {
	isDetailsOpen: (controllerId: string | undefined) => boolean;
	requestDetailsOpen: (controllerId: string | undefined, open: boolean) => void;
};
const detailsControllerContext = createContext<DetailsControllerContext | null>(null);


export type DetailsControllerProps = {
	/** `controllerId`(s) of `<Detail>`s which should be open. */
	open: string | undefined | string[];
	/** Callback invoked when a `<Details>` controlled by this Controller wishes to be opened or closed. */
	onOpenChange: (id: string, open: boolean) => void;
};
export const DetailsController: FunctionComponent<DetailsControllerProps> = ({ open, onOpenChange: onChange, children }) => {
	const isDetailsOpen = useCallback<DetailsControllerContext['isDetailsOpen']>(id => (
		!!id && !!open && open.includes(id)
	), [ open ]);
	
	const requestDetailsOpen = useCallback<DetailsControllerContext['requestDetailsOpen']>((id, open) => {
		if(!id) {
			return;
		}
		onChange(id, open);
	}, [onChange]);

	const contextValue = useMemo<DetailsControllerContext>(() => ({
		isDetailsOpen,
		requestDetailsOpen,
	}), [ isDetailsOpen, requestDetailsOpen ]);
	
	return (
		<detailsControllerContext.Provider value={ contextValue }>
			{ children }
		</detailsControllerContext.Provider>
	);
};

export const useDetailsController = (controllerId: string | undefined): DetailsControllerContext | null => {
	if(!controllerId) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useContext(detailsControllerContext);
};