import type { JSX } from 'preact/jsx-runtime';
import type { Overlay } from '../../services';
import type { RenderableDevice } from '../../services/overlay-rendering-service';
import type { BandName } from '@brng/common';
import { FrequencyBand } from '@brng/common';
import { useMemo } from 'preact/hooks';
import { useObservable } from 'ecce-preact';
import { useServices } from '../../services';
import { useMapController } from '../map-controller-context';
import { MapDeviceOverlay } from './map-device-overlay';
import { WirelessRangeWarning } from './wireless-range-warning';


export type MapDevicesOverlaysProps = {
	devices: readonly RenderableDevice[];
	bandsToRender?: readonly BandName[];
	highlight?: readonly string[];
	conflict?: readonly string[];
};

export const MapDevicesOverlays = ({ devices, bandsToRender, highlight, conflict }: MapDevicesOverlaysProps): JSX.Element => {
	const { overlayRenderingService } = useServices();

	const controller = useMapController();
	useObservable(controller, 'overlayRadius');
	useObservable(controller, 'palette');
	
	const overlays = useMemo<Overlay[]>(() => (
		overlayRenderingService.devicesOverlays({
			devices,
			bandsToRender: bandsToRender ?? FrequencyBand.bandNames,
			overlayRadius: controller.overlayRadius,
			light: controller.palette.theme === 'light',
			highlight,
			conflict,
		})
	), [overlayRenderingService, devices, bandsToRender, controller.overlayRadius, controller.palette.theme, highlight, conflict]);

	const showWarning = useMemo<boolean>(() => {
		return controller.overlayRadius === 'actual' && !devices.every(d => !!d.range);
	}, [controller.overlayRadius, devices]);
	
	return (
		<>
			{ overlays.map(o => (
				<MapDeviceOverlay overlay={ o } key={ o.key }/>
			))}
			<WirelessRangeWarning show={ showWarning }/>
		</>
	);
};