import { earth } from '@brng/common';
import { OutOfRangeIssue, type Device } from '..';


export function findOutOfRangeIssues(device: Device): void {
	if(device.type !== 'Access Point') {
		return;
	}

	for(const customer of device.customers) {
		const range = earth.distance(device.location, customer.location);

		const threshold = device.range ?? 4000;
		if(range>=threshold) {
			new OutOfRangeIssue({
				device,
				customer,
				range,
			});
		}
	}
}
