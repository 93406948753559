import type { JSX } from 'preact/jsx-runtime';
import type { Device } from '@brng/domain';
import type { MidasDeviceData } from '@brng/common';
import { useObservable } from 'ecce-preact';
import { Dialog, Icon, TextInput } from '../../../../../components';
import { useConstant } from '../../../../../util';
import { SnmpReportDialogController } from './snmp-report-dialog-controller';


export type SnmpReportDialogProps = {
	device: Device;
	data: MidasDeviceData;
	open: boolean;
	onClose: VoidFunction;
};
export const SnmpReportDialog = ({ device, data, open, onClose }: SnmpReportDialogProps): JSX.Element => {
	const controller = useConstant(() => new SnmpReportDialogController(device, data));
	useObservable(controller);
	
	return (
		<Dialog
			open={ open }
			onClose={ onClose }
			narrow
		>
			<header className="flex-justify-between pad-r-0">
				<h2>
					Add to BEARING
				</h2>
				<button
					className="btn btn--icon-only"
					onClick={ onClose }
				>
					<Icon icon="x"/>
				</button>
			</header>

			<main className="stack-sm">
				<p>
					Not all MIBs are publicly available.
				</p>
				<p>
					Attaching the device specific MIBs to this email will make it easier to add to the library.
				</p>
				<label>
					<span>
						Manufacturer
					</span>
					<TextInput
						value={ controller.make }
						onChange={ controller.handleMakeChange }
					/>
				</label>
				<label>
					<span>
						Model
					</span>
					<TextInput
						value={ controller.model }
						onChange={ controller.handleModelChange }
					/>
				</label>
			</main>
			<footer className="flex">
				<a
					href={ controller.href }
					className="btn btn--icon btn--primary pad-h-md mrg-l-auto"
					onClick={ onClose }
				>
					<Icon icon="email"/>
					Send
				</a>
			</footer>
		</Dialog>
	);
};