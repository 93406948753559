import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useMemo } from 'preact/hooks';
import { SpectrumGraphSegment } from './spectrum-graph-segment';


export type SpectrumGraphSegmentViewProps = {
	segment: SpectrumGraphSegment;
};
export const SpectrumGraphSegmentView = ({ segment }: SpectrumGraphSegmentViewProps): JSX.Element => {
	useObservable(segment, 'highlight');
	useObservable(segment, 'filtered');
	useObservable(segment, 'overlap');
	
	const style = useMemo<string>(() => {
		let style = `--seg-width: ${segment.width};`;
		if(segment.kind === 'used' && !segment.overlap) {
			style += ` background-image: ${segment.frequencyBand.linearGradient(segment.startFrequency, segment.endFrequency)}`;
		}
		return style;
	}, [segment.endFrequency, segment.frequencyBand, segment.kind, segment.overlap, segment.startFrequency, segment.width]);
	
	const className = classNames(
		'spectrum-graph-segment',
		'spectrum-graph-segment--' + segment.kind,
		{
			'spectrum-graph-segment--overlap': segment.overlap,
			'spectrum-graph-segment--highlight': segment.highlight,
			'spectrum-graph-segment--filtered': segment.filtered,
		}
	);
	
	return (
		<span
			className={ className }
			style={ style }
			onMouseEnter={ segment.focus }
			onMouseLeave={ segment.blur }
			{ ...{ [SpectrumGraphSegment.ID_ATTRIBUTE]: segment.id } }
		/>
	);
};