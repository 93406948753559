import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp } from '../../util';
import classNames from 'classnames';
import { Children, useRef, useState } from 'preact/compat';
import { useClipboardController } from '../../util';
import { Icon } from '../atoms';
import './copyable.scss';


export type CopyableProps = ClassProp & ChildrenProp & {
	value: string;
};

export const Copyable = ({ className, value, children }: CopyableProps): JSX.Element => {
	const controller = useClipboardController();
	const [ copied, setCopied ] = useState(false);
	
	const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();
	const handleClick = () => {
		if(timeout.current) {
			clearTimeout(timeout.current);
		}
		
		controller.writeText(value, {
			severity: 'success',
			message: `Copied "${value}" to clipboard`,
			type: 'copy',
		});
		setCopied(true);

		timeout.current = setTimeout(() => setCopied(false), 1500);
	};

	return (
		<button
			className={ classNames(className, 'copyable', { copied }) }
			onClick={ handleClick }
			disabled={ copied }
			title={ copied ? undefined : `Copy "${value}" to clipboard` }
		>
			{ Children.count(children) === 0 ? value :  children }
			<span className="visually-hidden">Copy to clipboard.</span>
			<Icon icon={ copied ? 'success' : 'copy' } size="sm"/>
		</button>
	);
};