import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { SiteUsageFilter } from './site-usage-filter';
import { useObservable } from 'ecce-preact';
import classNames from 'classnames';
import { FilterButton } from '../filter-button';


export type SiteUsageFilterViewProps = ClassProp & {
	filter: SiteUsageFilter;
};
export const SiteUsageFilterView = ({ className, filter }: SiteUsageFilterViewProps): JSX.Element => {
	useObservable(filter);

	return (
		<div className={ classNames(className, 'button-group') }>
			<FilterButton
				label="Infrastructure"
				shortLabel="Infra"
				checked={ filter.infrastructure }
				onChange={ filter.setInfrastructure }
			/>
			<FilterButton
				label="Customer"
				shortLabel="Cus"
				checked={ filter.customer }
				onChange={ filter.setCustomer }
			/>
		</div>
	);
};