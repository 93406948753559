import type { RepositoryProvider } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import { Alert, Icon, getRepositoryIconName } from '../../../../components';
import { BetaFlag } from '../../../../components/atoms/beta-flag';
import './choose-provider-view.scss';


type ProviderInfo = {
	label: string | JSX.Element;
	description: string;
};

const PROVIDER_INFOS: Record<RepositoryProvider, ProviderInfo> = {
	'Powercode': {
		label: 'Powercode',
		description: 'Connect Bearing to your Powercode installation',
	},
	'Manual': {
		label: <>CSV Upload <BetaFlag title="This feature is under active development."/></>,
		description: 'Upload CSV files describing your network',
	},
	'Local': {
		label: 'Local Data',
		description: 'Read data from a local file',
	},
};

export type ChooseProviderViewProps = {
	availableProviders: readonly RepositoryProvider[];
	onProviderChosen: (provider: RepositoryProvider) => void;
};
export const ChooseProviderView = ({ availableProviders: repositoryProviders, onProviderChosen }: ChooseProviderViewProps): JSX.Element => {
	
	return (
		<>
			<Alert
				title="Welcome to BEARING!"
				icon="rocket"
				severity="info"
			>
				To get started, choose how you want to get your Network's data into BEARING.
			</Alert>

			<ul className="choose-provider-view">
				{ repositoryProviders.map(provider => {
					const info = PROVIDER_INFOS[provider];

					return (
						<li className="content content--narrow" key={ provider }>
							<button
								className="btn provider-button"
								onClick={ () => onProviderChosen(provider) }
							>
								<Icon
									className="provider-button__icon"
									icon={ getRepositoryIconName(provider) }
									size="xl"
								/>
								<div className="provider-button__label text-xl flex-align-center">
									{ info.label }
								</div>
								<span className="provider-button__description">
									{ info.description }
								</span>
								<Icon className="provider-button__arrow" icon="arrowRight" size="lg"/>
							</button>
						</li>
					);
				}) }
			</ul>
		</>
	);
};