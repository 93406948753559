/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Device } from '@brng/domain';
import type { VNode } from 'preact';
import type { DetailsListener } from '../../../components/details';
import type { DetailsControllerProps } from '../../../components/details/details-controller';
import type { IssuePageIssue } from '../process-analysis';
import { useCallback, useMemo } from 'preact/hooks';
import { FrequencyBand } from '@brng/common';
import { Badge, DataRow, DataTable, IssueRow, NetworkItemIcon, RepositoryLink } from '../../../components';
import { Details, DetailsController } from '../../../components/details';
import { formatDistance } from '../../../util';
import { useAnalysisIssues } from '../analysis-provider';
import './analysis-issues-table.scss';


type AnalysisIssueRowProps = {
	issue: IssuePageIssue;
};
const AnalysisIssueRow = ({ issue }: AnalysisIssueRowProps): VNode => {
	const { focusedIssue, setFocusedIssue } = useAnalysisIssues();

	const handleClick = useCallback(() => {
		setFocusedIssue(issue);
	}, [ issue, setFocusedIssue ]);

	const title = useMemo<VNode>(() => {
		switch(issue.kind) {
			case 'outOfRange':
			case 'outOfSector':
				return (
					<strong className="flex-justify-between gap-xs width-100 mrg-b-xs">
						<span>{issue.customer.name}</span>
						<RepositoryLink href={ issue.customer.link }/>
					</strong>
				);
			case 'interSite':
				return (
					<strong>
						{issue.devices[0].site.name} <code>{'<->'}</code> {issue.devices[1].site.name}&nbsp;
						({formatDistance(issue.range)}, { issue.frequencyBand.name })
					</strong>
				);
		}
	}, [ issue ]);
	
	const content = useMemo<VNode>(() => {
		switch(issue.kind) {
			case 'outOfRange': return (
				<><strong>{formatDistance(issue.range)}</strong> from <strong>{issue.device.name}</strong></>
			);
			case 'outOfSector': return (
				<><strong>{issue.percent}%</strong> outside of the sector defined for <strong>{issue.device.name}</strong></>
			);
			case 'interSite': return (
				<>
					{issue.devices.map(device => (
						<div className="flex flex-justify-between" key={ device.id }>
							{device.name}
							<RepositoryLink href={ device.link }/>
						</div>
					))}
					<div> Overlaps by {issue.frequencyHigh - issue.frequencyLow}MHz ({issue.frequencyLow} - {issue.frequencyHigh})</div>
				</>
			);
		}
	}, [ issue ]);

	return (
		<IssueRow
			kind={ issue.kind }
			issue={ issue }
			title={ title }
			selected={ issue === focusedIssue }
			onClick={ handleClick }
			squelch={ issue.squelch }
			squelchMode="interactive"
		>
			{content}
		</IssueRow>
	);
};

type AnalysisEquipmentRowProps = {
	equipment: Device;
	issues: IssuePageIssue[];
};
const AnalysisEquipmentRow = ({ equipment, issues }: AnalysisEquipmentRowProps): VNode => {
	const { focusedEquipment, setFocusedEquipment } = useAnalysisIssues();
	
	const handleClick = useCallback(() => {
		setFocusedEquipment(equipment);
	}, [equipment, setFocusedEquipment]);
	
	return (
		<DataRow
			selected={ focusedEquipment === equipment }
		>
			<header className="flex-align-center mrg-b-sm">
				<button
					className="flex-1 flex-justify-start btn btn--invisible"
					onClick={ handleClick }
				>
					<h3 className="mrg-0">{ equipment.name }</h3>
				</button>
				<RepositoryLink href={ equipment.link }/>
			</header>
			<DataTable>
				{
					issues.map(issue => <AnalysisIssueRow issue={ issue } key={ issue.id }/>)
				}
			</DataTable>
		</DataRow>
	);
};
	
export const AnalysisIssuesTable = (): VNode => {
	const { issues } = useAnalysisIssues();

	const { openDetails, setOpenDetails } = useAnalysisIssues();
	
	const handleOpenChange = useCallback<DetailsControllerProps['onOpenChange']>(newOpenDetails => {
		setOpenDetails(newOpenDetails === openDetails ? undefined : newOpenDetails);
	}, [ openDetails, setOpenDetails ]);
	
	const handleDetailsTransitionFrame = useCallback<DetailsListener>(ev => {
		if(ev.open && ev.summary.getBoundingClientRect().y < 0) {
			ev.summary.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, []);
	
	return (
		<DetailsController
			open={ openDetails }
			onOpenChange={ handleOpenChange }
		>
			<div className="analysis-issues-table">
				{ issues.issuesBySite.map(({ site, issueCount, issuesByEquipment }) => (
					<section key={ site.id }>
						<Details
							controllerId={ site.name }
							summary={ (
								<header className="analysis-issues-table__site-header">
									<NetworkItemIcon item={ site }/>
									<h2>{ site.name }</h2>
									<Badge
										className="analysis-issues-table__issue-count"
										severity="error"
										value={ issueCount }
										label={ `${issueCount} issue${issueCount > 1 ? 's' : ''} at site` }
									/>
								</header>
							) }
							onTransitionFrame={ handleDetailsTransitionFrame }
						>
							<DataTable className="analysis-issues-table__equipment">
								{ issuesByEquipment.map(({ equipment, issues }) => (
									<AnalysisEquipmentRow equipment={ equipment } issues={ issues } key={ equipment.id }/>
								))}
							</DataTable>
						</Details>
					</section>
				))}
			</div>
		</DetailsController>
	);
};