import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { Checkbox } from '../../../components';
import { useSettingsService } from '../../../services';
import { SettingsSection } from './settings-section';


export const SnmpSettings = (): JSX.Element | null => {
	const settingsService = useSettingsService();
	useObservable(settingsService.settings, 'snmpEnabled');
	
	return (
		<SettingsSection title="Built in SNMP">
			<div id="enableSnmpDescription" className="form__description">
				Provided the Bearing server has appropriate network routes, it can use
				SNMP to identify devices and retrieve data. We are building our list of
				known devices but probes can be added to Powercode if required.
			</div>

			<Checkbox
				id="enableSnmpCheckbox"
				label="Use built in SNMP"
				checked={ settingsService.settings.snmpEnabled }
				onChange={ checked => settingsService.update('snmpEnabled', checked) }
				aria-describedby="enableSnmpDescription"
			/>
		</SettingsSection>
	);
};
