import type { TAnySchema, TUnion } from '@sinclair/typebox';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import { DeviceInput, SiteInput } from '@brng/common';
import { Kind } from '@sinclair/typebox';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { Alert, FileUploadInput, Form, Icon, Label, ModalLoadingIndicator } from '../../components';
import { Aside } from '../../components/atoms/aside';
import { useController } from '../../util';
import { DataValidationErrorView } from './data-validation-error-view';
import './manual-repository-csv-upload-form.scss';
import { ManualRepositoryUploadController } from './manual-repository-upload-controller';


type TemplateDownloadLinkProps = ClassProp & {
	type: 'site' | 'device';
};
const TemplateDownloadLink = ({ className, type }: TemplateDownloadLinkProps): JSX.Element => {
	return (
		<a
			className={ classNames(className, 'btn') }
			href={ `/api/data/csv-templates/${type}` }
			download
			{ ...{ native: true } }
		>
			<Icon icon="download"/>
			Download CSV Template
		</a>
	);
};


const getSchemaTypeDescription = (schema: TAnySchema): JSX.Element | null => {
	switch(schema[Kind]) {
		case 'Union': {
			if(!(schema as TUnion).anyOf.every(s => s[Kind] === 'Literal')) {
				return null;
			}

			return (
				<div>
					One of:
					<ul className="mrg-0 pad-0 mrg-l-lg">
						{ (schema as TUnion).anyOf.map((itemSchema, index) => (
							<li key={ index }>
								{ getSchemaTypeDescription(itemSchema)}
							</li>
						))}
					</ul>
				</div>
			);
		}
		case 'Literal': return (
			<div>
				<span className="font-mono">"{ schema.const }"</span>
				{ schema.description && (
					<><br/>{ schema.description }</>
				)}
			</div>
		);
		default: return null;
	}
};

type SchemaViewProps = {
	schema: typeof SiteInput | typeof DeviceInput;
};
const SchemaView = ({ schema }: SchemaViewProps): JSX.Element => {
	return (
		<div className="csvUploadSchema panel-group mrg-t-sm">
			{ Object.entries(schema.properties).map(([ key, propSchema ]) => (
				<div className="csvUploadItem panel panel--dark" key={ key }>
					<div>
						<h4 className="font-mono pad-0 mrg-0">{key}</h4>
						{ propSchema[Symbol.for('TypeBox.Optional')] && <span className="text-italic">(optional)</span> }
					</div>
					<div className="stack-sm">
						{ propSchema.description && <span>{ propSchema.description }</span> }
						{ getSchemaTypeDescription(propSchema) }
					</div>
				</div>
			)) }
		</div>
	);
};

const CSV_DESCRIPTIONS = [
	{
		title: 'Sites',
		type: 'site',
		schema: SiteInput,
		description: (
			<p className="mrg-0">
				Sites are the physical locations where your network's devices are
				located; either your own infrastructure, or your customer's homes
				and premises.
			</p>
		),
	},
	{
		title: 'Devices',
		type: 'device',
		schema: DeviceInput,
		description: (
			<p className="mrg-0">
				Devices are the access points, backhauls, and subscriber modules
				which make up your network.
			</p>
		),
	},
] as const;

const CsvDescriptionView = (p: { description: typeof CSV_DESCRIPTIONS[number] }) => {
	const [ showSchema, setShowSchema ] = useState(false);

	return (
		<section key={ p.description.title }>
			<h3 className="mrg-b-xs mrg-t-sm">{p.description.title}</h3>
			{ p.description.description }
			<div className="flex gap-md mrg-t-sm">
				<button
					className="btn"
					type="button"
					onClick={ () => setShowSchema(!showSchema) }
				>
					<Icon icon={ showSchema ? 'eyeOff' : 'eye' }/>
					{ showSchema ? 'Hide' : 'Show' } Schema
				</button>
				<TemplateDownloadLink type={ p.description.type }/>
			</div>
			{ showSchema && (
				<SchemaView schema={ p.description.schema }/>
			)}
		</section>
	);
};

export const ManualRepositoryCsvUploadForm = () => {
	const controller = useController(ManualRepositoryUploadController);
	useObservable(controller);

	return (
		<>
			<Form
				className="form"
				onSubmit={ controller.handleSubmit }
			>
				
				<Alert severity="info" icon="uploadFile" title="Upload CSV" innerClassName="stack-sm">
					<p>Describe your network using two CSV files.</p>
					
					<Aside title="Optional Fields" innerClassName="copy">
						<p>Bearing has built in SNMP with continually improving device support. If SNMP details are provided
							Bearing will attempt to calculate parent/child/sibling/mesh relationships and retrieve frequency
							and channel width data.
						</p>
					
						<p>If Bearing SNMP is disabled you should provide as much data as possible to make the most of the
							service.
						</p>
					</Aside>
					{ CSV_DESCRIPTIONS.map(description => (
						<CsvDescriptionView description={ description } key={ description.title }/>
					))}
				</Alert>

				<Label label="Sites CSV" required>
					<DataValidationErrorView
						error={ controller.error?.sitesError ?? null }
						errorCsvName="sites.csv"
					/>
					<FileUploadInput
						className="flex-1"
						value={ controller.sitesFile }
						onChange={ controller.setSitesFile }
						accept={ ['.csv', 'text/csv'] }
						required
					/>
				</Label>

				<Label label="Devices CSV" required>
					<DataValidationErrorView
						error={ controller.error?.devicesError ?? null }
						errorCsvName="equipment.csv"
					/>
					<FileUploadInput
						className="flex-1"
						value={ controller.devicesFile }
						onChange={ controller.setDevicesFile }
						accept={ ['.csv', 'text/csv'] }
						required
					/>
				</Label>

				<button className="btn mrg-l-auto" type="submit">
					Upload
				</button>
			</Form>

			<ModalLoadingIndicator
				show={ controller.submitting }
				message="Uploading Data"
			/>
		</>
	);
};