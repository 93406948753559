import type { Services } from '../services';
import { useEffect, useRef } from 'preact/hooks';
import { isConstructable } from '@brng/common';
import { useServices } from '../services/services-context';


export abstract class Controller {
	#services: Services | null = null;
	#initialised = false;

	protected initialise(): void {}
	protected dispose(): void {}

	protected get services(): Services {
		return this.assertInitialised(this.#services);
	}

	protected assertInitialised<T>(x: T | undefined | null): T {
		if(x === undefined || x === null) {
			throw new Error(`${this.#name} was not initialised.`);
		}

		return x;
	}

	get #name(): string {
		return Object.getPrototypeOf(this)?.constructor?.name ?? 'Controller';
	}

	private injectServices(services: Services): void {
		this.#services = services;
	}

	private runInitialise() {
		if(this.#initialised) {
			return;
		}

		this.initialise();
		this.#initialised = true;
	}

	private runDispose() {
		if(!this.#initialised) {
			return;
		}

		this.dispose();
		this.#initialised = false;
	}
}

export type ControllerConstructor<T extends Controller> = new () => T;
export type ControllerFactory<T extends Controller> = () => T;

export const useController = <T extends Controller>(init: ControllerFactory<T> | ControllerConstructor<T>): T => {
	const services = useServices();
	const controller = useRef<T | null>(null);

	if(controller.current === null) {
		controller.current = isConstructable(init)
			? new init()
			: init();
		controller.current['injectServices'](services);
		controller.current['runInitialise']();
	}

	useEffect(() => () => {
		controller.current?.['runDispose']();
	}, []);

	return controller.current;
};