import type { SpectrumSliderController } from '..';
import type { ClassProp } from '../../../util';
import { useObservable } from 'ecce-preact';
import { Slider } from '../../slider';


export type SpectrumChannelWidthSliderProps = ClassProp & {
	controller: SpectrumSliderController;
};
export const SpectrumChannelWidthSlider = ({ className, controller }: SpectrumChannelWidthSliderProps) => {
	useObservable(controller, 'channelWidth');

	return (
		<Slider
			id="channelWidthSlider"
			className={ className }
			label="Channel Width"
			formatValue={ v => <code>{v}MHz</code> }
			steps={ controller.channelWidthSnapPoints }
			value={ controller.channelWidth }
			onChange={ controller.setChannelWidth }
			slim
			immediate
		/>
	);
};