import type { DeviceTypeFilter } from './device-type-filter';
import type { ClassProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import classNames from 'classnames';
import { FilterButton } from '../filter-button';


export type DeviceTypeFilterViewProps = ClassProp & {
	filter: DeviceTypeFilter;
};
export const DeviceTypeFilterView = ({ className, filter }: DeviceTypeFilterViewProps): JSX.Element => {
	useObservable(filter);
	
	return (
		<div className={ classNames(className, 'button-group') }>
			<FilterButton
				label="Access Points"
				shortLabel="AP"
				checked={ filter.accessPoints }
				onChange={ filter.setAccessPoints }
			/>
			<FilterButton
				label="Backhauls"
				shortLabel="BH"
				checked={ filter.backhauls }
				onChange={ filter.setBackhauls }
			/>
			<FilterButton
				label="Subscriber Modules"
				shortLabel="SM"
				checked={ filter.subscriberModules }
				onChange={ filter.setSubscriberModules }
			/>
		</div>
	);
};