import type { ClassProp } from '../../../util';
import type { SnmpProbeFilter } from './snmp-probe-filter';
import { useObservable } from 'ecce-preact';
import { useSidebarTemplate } from '../../../components';


const FILTER_INPUT_LABEL = 'Manufacturer / Radio Model / Device Type';

export type SnmpProbeFilterInputProps = ClassProp & {
	filter: SnmpProbeFilter
};
export const SnmpProbeFilterInput = ({ className, filter }: SnmpProbeFilterInputProps) => {
	const sidebarTemplate = useSidebarTemplate();
	useObservable(filter, 'query');
	
	return (
		<div className={ className }>
			{sidebarTemplate?.collapsed && (
				<button
					className="btn btn--icon-only"
					onClick={ sidebarTemplate.closeDrawer }
					aria-label="Hide filter"
				>
					<i className="fa fa-arrow-left text-lg"/>
				</button>
			)}
			<input
				id="snmpFilterInput"
				value={ filter.query }
				onInput={ filter.updateQuery }
				aria-label={ 'Filter by ' + FILTER_INPUT_LABEL }
				placeholder={ FILTER_INPUT_LABEL }
			/>
		</div>
	);
};