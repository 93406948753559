import type { ChildrenProp, ClassProp, IdProp } from '../../util';
import type { JSX } from 'preact/jsx-runtime';
import './form.scss';
import classNames from 'classnames';


export type FormProps = IdProp & ClassProp & ChildrenProp & {
	onSubmit?: VoidFunction;
};
export const Form = ({ id, className, onSubmit, children }: FormProps): JSX.Element => {
	const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = ev => {
		ev.preventDefault();
		onSubmit?.();
	};

	return (
		<form
			id={ id }
			className={ classNames(className, 'form') }
			onSubmit={ handleSubmit }
		>
			{ children }
		</form>
	);
};