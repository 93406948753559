import type { VNode } from 'preact';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import { forwardRef } from 'preact/compat';


export type PasswordInputProps = Omit<JSX.HTMLAttributes<HTMLInputElement>, 'type' | 'autoComplete' | 'autoCapitalize'> & ClassProp & {
	inputClassName?: string;
	show?: boolean;
	autoComplete: 'off' | 'new-password' | 'current-password';
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({ show, ...inputProps }, ref): VNode => {
	return (
		<input
			{ ...inputProps }
			autoCapitalize="off"
			ref={ ref }
			type={ show ? 'text' : 'password' }
		/>
	);
});