import type { Bounds } from '@brng/common';
import { observable } from 'ecce-preact';


export class Overlay {
	readonly key: string;
	readonly bounds: Readonly<Bounds>;
	#url: string | undefined = undefined;

	/**
	 * @param key Unique identifier for this overlay.
	 * @param bounds Area which the overlay will be rendered over.
	 */
	constructor(key: string, bounds: Readonly<Bounds>) {
		this.key = key;
		this.bounds = { ...bounds };
	}
	
	setUrl(url: string): void {
		this.url = url;
	}

	get url(): string | undefined { return this.#url; }
	@observable() private set url(value: string | undefined) { this.#url = value; }
}