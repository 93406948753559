import type { Point } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassProp } from '../util';
import type { MapController } from './map-controller';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'preact/hooks';
import { useSettingsService } from '../services';
import { GoogleMap } from './components';
import { MapControllerProvider } from './map-controller-context';



const GRACE_PERIOD_API_KEY = 'AIzaSyB2lijjS3VpGvqB_uUMDuOMeo9EJsHdDKw';
const MAP_CONTAINER_STYLE = {
	height: '100%',
};
const INITIAL_CENTER: Point = {
	lat: 0, lng: 0,
};
const INITIAL_ZOOM = 0;

export type MapViewProps = ClassProp & ChildrenProp & {
	controller: MapController;
	/**
	 * Forces the map to use the passed google maps API key.
	 *
	 * Only exposed for use in the special API key verification route; should not
	 * generally be used.
	 */
	overrideGoogleMapsApiKey?: string;
};
export const MapView = ({ className, controller, overrideGoogleMapsApiKey, children }: MapViewProps): JSX.Element | null => {
	let googleMapsApiKey: string;
	if(!overrideGoogleMapsApiKey) {
		googleMapsApiKey = useSettingsService().settings.googleMapsKey ?? GRACE_PERIOD_API_KEY; // eslint-disable-line react-hooks/rules-of-hooks
	} else {
		googleMapsApiKey = overrideGoogleMapsApiKey;
	}
	const [ map, setMap ] = useState<google.maps.Map | null>(null);

	useEffect(() => {
		if(map) {
			controller['handleLoad'](map);
		}
	}, [controller, map]);
	
	const { isLoaded, loadError } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey,
	});

	if(loadError) {
		throw loadError;
	}

	if(!isLoaded) {
		return null;
	}

	return (
		<GoogleMap
			mapContainerClassName={ className }
			mapContainerStyle={ MAP_CONTAINER_STYLE }
			center={ INITIAL_CENTER }
			zoom={ INITIAL_ZOOM }
			mapTypeId="terrain"
			onLoad={ setMap }
			onUnmount={ controller['handleUnmount'] }
			id="googleMap"
		>
			<MapControllerProvider controller={ controller }>
				{ children }
			</MapControllerProvider>
		</GoogleMap>
	);
};