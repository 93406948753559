import type { JSX } from 'preact/jsx-runtime';
import type { SetupRepositoryController } from '../setup-repository-controller';
import { useObservable } from 'ecce-preact';
import { Alert } from '../../../../components';
import { PowercodeConnectionConfigForm } from '../../../powercode';
import { RepositoryErrorAlert } from '../../../repository-config-error-alert';


const FORM_DESCRIPTION_ID = 'setupRepositoryDescription';
export type SetupPowercodeRepositoryViewProps = {
	controller: SetupRepositoryController;
};
export const SetupPowercodeRepositoryView = ({ controller }: SetupPowercodeRepositoryViewProps): JSX.Element => {
	useObservable(controller, 'error');

	return (
		<div className="stack-lg">
			<Alert
				severity="info"
				title="Configure BEARING to use Powercode"
				icon="powercode"
			>
				<div id={ FORM_DESCRIPTION_ID } className="copy">
					<p>BEARING uses Powercode's report system to gather information.</p>
					<p>We recommend you create a new permission group and user in Powercode just for BEARING.</p>

					<p>
						The <strong>Bearing Reports</strong> permission group must provide:
					</p>
					<ul>
						<li><code>Reports &rArr; Custom &rArr; Custom Reports = Edit</code></li>
						<li><code>Reports &rArr; Dashboard &rArr; Reports Dashboard = View</code></li>
					</ul>
					<p>
						The <strong>bearing</strong> user:
					</p>
					<ul>
						<li>Username: <code>bearing</code></li>
						<li>Permission Groups: <code>Bearing Reports</code></li>
						<li>Status: <code>Active</code></li>
						<li>Name: <code>Bearing Frequency Management</code></li>
						<li>Viewable Customer: <code>All</code></li>
					</ul>
				</div>
			</Alert>

			<RepositoryErrorAlert
				providerName="Powercode"
				response={ controller.error }
			/>

			<PowercodeConnectionConfigForm
				className="content content--narrow"
				response={ controller.error }
				onSubmit={ controller.submitConfig }
				describedBy={ FORM_DESCRIPTION_ID }
			/>
		</div>
	);
};