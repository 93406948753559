import type { VNode } from 'preact';
import type { SliderProps } from '../../../components';
import { useCallback } from 'preact/hooks';
import { Slider } from '../../../components';
import { useAnalysisIssues } from '../analysis-provider';


const steps = [ 2, 4, 6, 8, 10, 12, 14, 16, 32 ];
const formatValue: SliderProps['formatValue'] = value => `0 - ${value}km`;

export const InterSiteRangeSlider = (): VNode => {
	const { analysisIssuesFilter: { interSiteRange }, updateAnalysisIssuesFilter } = useAnalysisIssues();

	const handleChange = useCallback<SliderProps['onChange']>(value => {
		updateAnalysisIssuesFilter({ interSiteRange: value });
	}, [ updateAnalysisIssuesFilter ]);

	return (
		<Slider
			id="interSideRangeSlider"
			label="Range"
			steps={ steps }
			formatValue={ formatValue }
			slim
			value={ interSiteRange }
			onChange={ handleChange }
		/>
	);
};