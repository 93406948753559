import type { Network, NetworkItem } from '@brng/domain';
import type { Services } from '../../services';
import { pointMean } from '@brng/common';
import { bound, observable } from 'ecce-preact';
import { MapController } from '../../map';
import { NetworkQuery } from '../../network-query';
import { UrlSearchParamController } from '../../util/url-search-param-controller';
import { MapRouteDisplay } from './map-route-display';


export class MapRouteController {
	readonly #services: Services;
	readonly #urlParams: UrlSearchParamController;
	readonly display: MapRouteDisplay;

	#map: MapController | null = null;
	get map(): MapController | null { return this.#map; }
	@observable() private set map(value: MapController | null) { this.#map = value; }

	readonly query: NetworkQuery;
	
	constructor(services: Services) {
		this.#services = services;
		this.#urlParams = new UrlSearchParamController({ historyMode: 'replace' });
		this.#rewriteLegacyUrlParams();
		this.display = new MapRouteDisplay(this.#urlParams);
		this.query = new NetworkQuery({
			limit: 20,
			customerLimit: 5,
			// Exclude Subscriber Modules for now, as many are not renderable...
			// TODO: instead filter by renderable devices.
			preFilter: item => item.kind !== 'device' || item.type !== 'Subscriber Module',
		});

		if(this.#services.networkService.network) {
			this.#handleNetwork({ network: this.#services.networkService.network });
		}
		this.#services.networkService.on('network', this.#handleNetwork);
	}

	#handleNetwork = ({ network }: { network: Network }) => {
		if(!this.#map) {
			this.map = new MapController({
				center: pointMean(network.infrastructureSites.map(s => s.location)),
				urlParams: this.#urlParams,
			});
			this.map.on('click', ev => {
				if(ev.item.kind === 'site') {
					this.display.openInfoPopup(ev.item);
				}
			});
		}

		this.display.setNetwork(network);
		this.query.setNetwork(network);
	};

	/**
	 * The previous version of the map used separate `?siteId=`, `&equipmentId=`,
	 * and `&customerId=` URL params to store the map's focus state. Now that each
	 * network item has a `networkId`, we use a single `?focus=` param which
	 * contains them all.
	 *
	 * This method re-writes these legacy parameters into the new format.
	 */
	#rewriteLegacyUrlParams() {
		const ids: NetworkItem['networkId'][] = [];

		const siteIds = this.#urlParams.getString('siteId')?.split(',');
		if(siteIds) {
			ids.push(...siteIds.map(siteId => `site-${siteId.startsWith('s') ? '' : 's'}${siteId}` as const));
		}

		const deviceId = this.#urlParams.getString('equipmentId');
		if(deviceId) {
			ids.push(`device-${deviceId}`);
		}
		const customerId = this.#urlParams.getString('customerId');
		if(customerId) {
			ids.push(`site-${customerId.startsWith('c') ? '' : 'c'}${customerId}`);
		}

		if(ids.length) {
			this.#urlParams.delete('siteId');
			this.#urlParams.delete('equipmentId');
			this.#urlParams.delete('customerId');
			this.#urlParams.setString('focus', ids.join(','));
		}
	}

	@bound()
	getUrlParams(): string {
		return this.#urlParams.toString();
	}
	
	dispose() {
		this.#services.networkService.off('network', this.#handleNetwork);
		this.#urlParams.dispose();
		this.#map?.dispose();
	}
}