import type { RepositoryCustomField } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { PowercodeCustomFieldsModel } from './powercode-custom-fields-controller';
import { CreateCustomFieldLink } from './components/create-custom-field-link';
import { WirelessRangeUnitsControl } from './components/wireless-range-units-radio';


export type ExtraCustomFieldContentProps = { model: PowercodeCustomFieldsModel };

export type CustomFieldDef = {
	label: string;
	description: JSX.Element;
	extraContent?: (props: { model: PowercodeCustomFieldsModel }) => JSX.Element;
	validateField?: (field: RepositoryCustomField) => string | null;
};

type CustomFieldKey = Exclude<PowercodeCustomFieldsModel.Key, 'wirelessRangeUnits'>;

export const CUSTOM_FIELD_DEFS: Record<CustomFieldKey, CustomFieldDef> = {
	wirelessRangeId: {
		label: 'Wireless Range',
		description: (
			<>
				<CreateCustomFieldLink/> for <code>equipment</code> in Powercode which
				will be used to determine potential Access Points for customers and
				Access Point's range on the coverage map.
				After it is created in powercode you may need to refresh this page.
				<ul>
					<li>
						This field <strong>must</strong> have a numeric default value
						greater than zero.
					</li>
					<li>
						We recommend a field name which includes the units used e.g.&nbsp;
						<strong>Wireless&nbsp;Range&nbsp;(km)</strong>
					</li>
				</ul>
			</>
		),
		extraContent: WirelessRangeUnitsControl,
		validateField: field  => {
			if(!field.default) {
				return '(no default value)';
			}
			const value = Number.parseFloat(field.default);
			if(Number.isNaN(value)) {
				return `(non-numeric default value: "${field.default}")`;
			}
	
			if(value <= 0) {
				return `(default value <= 0: "${field.default}")`;
			}
	
			return null;
		},
	},
	fallbackCenterFrequencyId: {
		label: 'Fallback Center Frequency',
		description: (
			<>
				<CreateCustomFieldLink/> for <code>equipment</code> in Powercode which
				can be used as a fallback method to specify the frequency for equipment
				which does not support querying via SNMP.
			</>
		),
	},
	fallbackChannelWidthId: {
		label: 'Fallback Channel Width',
		description: (
			<>
				<CreateCustomFieldLink/> for <code>equipment</code> in Powercode which
				can be used as a fallback method to specify the channel width for
				equipment which does not support querying via SNMP.
			</>
		),
	},
};