import type { ClassProp } from '../../../../../util';
import { useObservable } from 'ecce-preact';
import { TextInput } from '../../../../../components';
import { useInventoryController } from '../../../inventory-controller-context';


export const InventoryQueryInput = ({ className }: ClassProp) => {
	const inventory = useInventoryController();
	useObservable(inventory.query, 'search');

	return (
		<TextInput
			className={ className }
			icon="search"
			type="search"
			value={ inventory.query.search }
			onValueChange={ inventory.query.setSearch }
			aria-label="Search the inventory"
		/>
	);
};