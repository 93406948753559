import type { GetDataResponse } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { Icon, useLoadingIndicator } from '../../../components';
import { useServices } from '../../../services';
import { ChooseProviderView, SetupLocalRepositoryView, SetupPowercodeRepositoryView } from './components';
import { SetupRepositoryController } from './setup-repository-controller';
import { SetupManualRepositoryView } from './components/setup-manual-repository-view';


export type SetupRepositoryViewProps = {
	response: GetDataResponse.NoConfig;
};
export const SetupRepositoryView = ({ response }: SetupRepositoryViewProps): JSX.Element => {
	const { repositoryService, dataService } = useServices();
	const beginLoading = useLoadingIndicator();
	const [ controller ] = useState(() => new SetupRepositoryController({
		response,
		repositoryService,
		dataService,
		beginLoading,
	}));
	useObservable(controller, 'provider');

	return (
		<div className="stack-md">
			{ controller.provider === null && (
				<ChooseProviderView
					availableProviders={ controller.availableProviders }
					onProviderChosen={ controller.chooseProvider }
				/>
			)}

			{ controller.provider && controller.availableProviders.length > 1 && (
				<button
					onClick={ controller.resetProvider }
					className="btn btn--icon btn--invisible mrg-r-auto"
				>
					<Icon icon="arrowLeft"/>
					<span>Choose a different data provider</span>
				</button>
			)}

			{ controller.provider === 'Powercode' && (
				<SetupPowercodeRepositoryView controller={ controller }/>
			)}

			{ controller.provider === 'Local' && (
				<SetupLocalRepositoryView controller={ controller }/>
			)}

			{ controller.provider === 'Manual' && (
				<SetupManualRepositoryView controller={ controller }/>
			)}

		</div>
	);
};