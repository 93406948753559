import type { JSX } from 'preact/jsx-runtime';
import type { MapRouteController } from '../../map-route-controller';
import { Toolbar } from '../../../../components';
import { type ClassProp } from '../../../../util';
import { MapRouteFrequencySelect } from '../map-route-frequency-select';
import { MapRouteFilterTools } from './map-route-filter-tools';
import { MapRouteFocusTools } from './map-route-focus-tools';
import { MapRouteToolMenu } from './map-route-tool-menu';
import './map-route-toolbar.scss';
import { MapRouteOverlayModeSelect } from './overlay-mode-select';


export type MapRouteToolbarProps = ClassProp & {
	controller: MapRouteController;
	large: boolean;
};
export const MapRouteToolbar = ({ controller, large }: MapRouteToolbarProps): JSX.Element => {

	return (
		<Toolbar className="map-route-toolbar">
			<MapRouteFocusTools
				className="map-route-toolbar__focus"
				controller={ controller }
				large={ large }
			/>

			{ !large && (
				<>
					<MapRouteFrequencySelect controller={ controller } overMap={ false }/>
					<MapRouteToolMenu controller={ controller }/>
				</>
			)}
			
			{ large && (
				<>
					<MapRouteFilterTools className="map-route-toolbar__filter" controller={ controller }/>
					<MapRouteOverlayModeSelect controller={ controller }/>
				</>
			)}
		</Toolbar>
	);
};