import type { RepositoryConfig_Powercode } from '@brng/common';
import { deepEqual, omitNullish, pick } from '@brng/common';
import { makeObservable, observable, observe } from 'ecce-preact';


export namespace PowercodeCustomFieldsModel {
	export const KEYS = [
		'wirelessRangeId',
		'wirelessRangeUnits',
		'fallbackCenterFrequencyId',
		'fallbackChannelWidthId',
	] as const;
	export type Key = typeof KEYS[number];
}
export type PowercodeCustomFieldsModel = Record<PowercodeCustomFieldsModel.Key, string | null>;

const EMPTY_MODEL: PowercodeCustomFieldsModel = {
	wirelessRangeId: null,
	wirelessRangeUnits: null,
	fallbackCenterFrequencyId: null,
	fallbackChannelWidthId: null,
};

export class PowercodeCustomFieldsController {
	#initialValue: PowercodeCustomFieldsModel;
	#model: PowercodeCustomFieldsModel;

	#canSubmit = false;
	get canSubmit(): boolean { return this.#canSubmit; }
	@observable() private set canSubmit(value: boolean) { this.#canSubmit = value; }
	
	constructor(config: RepositoryConfig_Powercode) {
		this.#initialValue = {
			...EMPTY_MODEL,
			wirelessRangeUnits: 'km',
			...omitNullish(pick(config, PowercodeCustomFieldsModel.KEYS)),
		};
		this.#model = makeObservable(this.#initialValue);

		observe(this.#model, () => {
			this.canSubmit = !deepEqual(this.#initialValue, this.model);
		});
	}

	get model(): PowercodeCustomFieldsModel {
		return this.#model;
	}
}