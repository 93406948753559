import type { ErrorDetails, GetDataResponse } from '@brng/common';
import { asError } from '@brng/common';
import { useMemo } from 'preact/hooks';


export namespace ErrorInfo {
	export type Unknown = {
		kind: 'unknown';
		error: Error;
	};

	export type Repository = {
		kind: 'repository';
		response: GetDataResponse.RepositoryError;
	};

	export type Api = {
		kind: 'api';
		name: string;
		status?: number;
		message: string;
		method?: string;
		path?: string;
		details: ErrorDetails;
		stack?: string;
	};
	
	export const fromError = (error: unknown): ErrorInfo => (
		error instanceof BearingError ? error.info : {
			kind: 'unknown',
			error: asError(error),
		}
	);
}
export type ErrorInfo = (
	| ErrorInfo.Unknown
	| ErrorInfo.Repository
	| ErrorInfo.Api
);
export type BearingErrorInfo = Exclude<ErrorInfo, ErrorInfo.Unknown>;

export class BearingError extends Error {
	static #getMessage(info: BearingErrorInfo): string {
		switch(info.kind) {
			case 'api':
				return JSON.stringify(info.details);
			case 'repository':
				return info.response.status;
		}
	}
	
	constructor(public readonly info: BearingErrorInfo) {
		super(`[${info.kind}] ${BearingError.#getMessage(info)}`);
	}
}

export const useErrorDetails = (error: unknown): ErrorInfo | null => (
	useMemo(() => error ? ErrorInfo.fromError(error) : null, [ error ])
);