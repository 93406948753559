import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { RefObject } from 'preact';
import { bound, observable, useObservable } from 'ecce-preact';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@brng/common';
import { useState } from 'preact/hooks';
import { Controller } from '../../util';
import { Checkbox, Form, Icon } from '../atoms';
import { TextField } from '../molecules';


export type UserCreationFormMode = 'full' | 'passwordOnly' | 'noPassword';
export type UserCreationFormResult = {
	username: string;
	name: string;
	email: string;
	password: string;
	admin: boolean;
};
export type UserCreationFormControllerConfig = {
	username?: string;
	name?: string;
	/**
	 * Whether the form is for creating the current user, or for creating another
	 * user; affects the pronouns in form field descriptions, and whether the
	 * 'Admin Privileges' checkbox appears.
	 */
	self: boolean;
	mode: UserCreationFormMode;
	submitLabel: string;
	onSubmit: (result: UserCreationFormResult) => unknown;
};
export class UserCreationFormController extends Controller {
	#username: string;
	get username(): string { return this.#username; }
	@observable() private set username(value: string) { this.#username = value; }

	#hasChangedName: boolean;
	#name: string;
	get name(): string { return this.#name; }
	@observable() private set name(value: string) { this.#name = value; }

	#email: string = '';
	get email(): string { return this.#email; }
	@observable() private set email(value: string) { this.#email = value; }

	#password: string = '';
	get password(): string { return this.#password; }
	@observable() private set password(value: string) { this.#password = value; }

	#confirmPassword: string = '';
	get confirmPassword(): string { return this.#confirmPassword; }
	@observable() private set confirmPassword(value: string) { this.#confirmPassword = value; }

	#admin: boolean = false;
	get admin(): boolean { return this.#admin; }
	@observable() private set admin(value: boolean) { this.#admin = value; }

	readonly confirmPasswordRef: RefObject<HTMLInputElement> = { current: null };
	readonly submitLabel: string;
	readonly #onSubmit: (result: UserCreationFormResult) => unknown;
	readonly mode: UserCreationFormMode;
	readonly self: boolean;

	constructor(config: UserCreationFormControllerConfig) {
		super();
		this.#username = config.username ?? '';
		this.#hasChangedName = !config.username;
		this.#name = config.name ?? '';
		this.submitLabel = config.submitLabel;
		this.#onSubmit = config.onSubmit;
		this.mode = config.mode;
		this.self = config.self;
	}

	@bound()
	setUsername(value: string): void {
		this.username = value.toLowerCase();

		if(!this.#hasChangedName) {
			this.name = this.username;
		}
	}

	@bound()
	handleUsernameInputKeydown(ev: KeyboardEvent): void {
		if(ev.key === ' ') {
			ev.preventDefault();
		}
	}

	@bound()
	setName(value: string): void {
		this.name = value;
		this.#hasChangedName = true;
	}

	@bound()
	setEmail(value: string): void {
		this.email = value;
	}

	@bound()
	setPassword(value: string): void {
		this.password = value;
		this.#updateConfirmPasswordValidity();
	}

	@bound()
	setConfirmPassword(value: string): void {
		this.confirmPassword = value;
		this.#updateConfirmPasswordValidity();
	}

	@bound()
	setAdmin(value: boolean): void {
		this.admin = value;
	}

	#updateConfirmPasswordValidity() {
		this.confirmPasswordRef.current?.setCustomValidity(
			this.password !== this.confirmPassword
				? 'Passwords must match.'
				: ''
		);
	}

	@bound()
	handleSubmit(): void {
		this.#onSubmit({
			username: this.username,
			name: this.name,
			email: this.email,
			password: this.password,
			admin: this.admin,
		});
	}
}
type UserCreationFormProps = ClassProp & {
	controller: UserCreationFormController;
	disabled?: boolean;
};
export const UserCreationForm = (p: UserCreationFormProps): JSX.Element => {
	useObservable(p.controller);
	const [ showPasswords, setShowPasswords ] = useState(false);
	const passwordInputType = showPasswords ? 'text' : 'password';

	return (
		<Form onSubmit={ p.controller.handleSubmit }>
			{ p.controller.mode !== 'passwordOnly' && (
				<>
					<TextField
						id="usernameInput"
						label="Username"
						autocomplete="off"
						description={ `${p.controller.self ? 'You' : 'They' } will use this to login to Bearing.` }
						required
						value={ p.controller.username }
						onValueChange={ p.controller.setUsername }
						onKeyDown={ p.controller.handleUsernameInputKeydown }
						disabled={ p.disabled }
					/>
					<TextField
						id="nameInput"
						label="Display Name"
						autocomplete="off"
						description={ `${p.controller.self ? 'Your' : 'Their' } full name.` }
						required
						value={ p.controller.name }
						onValueChange={ p.controller.setName }
						disabled={ p.disabled }
					/>
					<TextField
						id="emailInput"
						label="Email Address"
						type="email"
						autocomplete="off"
						required
						value={ p.controller.email }
						onValueChange={ p.controller.setEmail }
						disabled={ p.disabled }
					/>
				</>
			)}

			{ p.controller.mode !== 'noPassword' && (
				<>
					<TextField
						id="passwordInput"
						label="Password"
						description={ `At least ${MIN_PASSWORD_LENGTH} characters long.` }
						type={ passwordInputType }
						required
						value={ p.controller.password }
						onValueChange={ p.controller.setPassword }
						minLength={ MIN_PASSWORD_LENGTH }
						maxLength={ MAX_PASSWORD_LENGTH }
						autoComplete="new-password"
						disabled={ p.disabled }
					/>

					<TextField
						id="confirmPasswordInput"
						label="Confirm Password"
						type={ passwordInputType }
						required
						value={ p.controller.confirmPassword }
						onValueChange={ p.controller.setConfirmPassword }
						ref={ p.controller.confirmPasswordRef }
						autoComplete="new-password"
						disabled={ p.disabled }
					/>
				</>
			)}
			{ p.controller.mode !== 'passwordOnly' && !p.controller.self && (
				<Checkbox
					id="adminPrivilegesCheckbox"
					label="Admin Privileges"
					checked={ p.controller.admin }
					onChange={ p.controller.setAdmin }
				/>
			)}

			<div className="flex flex-space-between flex-align-center flex-row-reverse mrg-t-md">
				<button class="btn mrg-l-auto" type="submit">
					{ p.controller.submitLabel }
				</button>

				{ p.controller.mode !== 'noPassword' && (
					<button
						className="btn btn--invisible btn--icon"
						type="button"
						onClick={ () => setShowPasswords(!showPasswords) }
					>
						<Icon icon={ showPasswords ? 'eyeOff' : 'eye' }/>
						{ showPasswords ? 'Hide Passwords' : 'Show Passwords' }
					</button>
				)}
			</div>
		</Form>
	);
};