import type { NetworkIssue } from '@brng/domain';
import type { JSX } from 'preact';
import type { ClassProp } from '../util';
import { Squelchable } from '@brng/domain';
import classNames from 'classnames';
import { Icon } from './atoms';


export const SquelchButton = (p: ClassProp & { issue: NetworkIssue }): JSX.Element | null => {
	if(!(p.issue instanceof Squelchable)) {
		return null;
	}

	return <SquelchButtonContent className={ p.className } issue={ p.issue }/>;
};

const SquelchButtonContent = (p: ClassProp & { issue: Squelchable }): JSX.Element => {
	return (
		<button
			className={ classNames('btn btn--icon', p.className) }
			title={ p.issue.squelch ? `Squelched by ${p.issue.squelch.blame}` : 'Squelch' }
			onClick={ p.issue.toggleSquelched }
		>
			<Icon icon={ p.issue.squelched ? 'eyeOff' : 'eye' }/>
			<span className="visually-hidden">
				{ p.issue.squelched ? 'Un-squelch issue' : 'Squelch issue' }
			</span>
		</button>
	);
};