import type { NetworkItem } from '@brng/domain';
import type { UrlSearchParamController } from '../../util/url-search-param-controller';
import { bound, observable } from 'ecce-preact';
import { NetworkFilter } from '../network-filter';


export type ItemKindFilterConfig = {
	urlParams?: UrlSearchParamController | null | undefined;
};
export class ItemKindFilter extends NetworkFilter {
	static readonly URL_SEARCH_PARAM = 'kind';
	static readonly URL_SEARCH_DEFAULT = 'site';
	
	#sites = true;
	get sites(): boolean { return this.#sites; }
	@observable() private set sites(value: boolean) { this.#sites = value; }
	
	#devices = false;
	get devices(): boolean { return this.#devices; }
	@observable() private set devices(value: boolean) { this.#devices = value; }
	
	constructor(config?: ItemKindFilterConfig) {
		super({
			urlParams: config?.urlParams,
			urlParamKey: ItemKindFilter.URL_SEARCH_PARAM,
			urlParamDefault: ItemKindFilter.URL_SEARCH_DEFAULT,
		});
		
		switch(this.getUrlParamValue()) {
			case 'site':
				this.#sites = true;
				this.#devices = false;
				break;
			case 'device':
				this.#devices = true;
				this.#sites = false;
				break;
		}
	}
	
	@bound()
	setSites(show: boolean): this {
		if(show === this.sites) {
			return this;
		}

		this.sites = show;
		this.devices = !show;
		
		this.changed();
		return this;
	}
	
	@bound()
	setDevices(show: boolean): this {
		if(show === this.devices) {
			return this;
		}
		
		this.devices = show;
		this.sites = !show;
		
		this.changed();
		return this;
	}

	protected override toUrlParam(): string {
		return this.sites ? 'site' : 'device';
	}
	
	protected filter(item: NetworkItem): boolean {
		switch(item.kind) {
			case 'site': return this.sites;
			case 'device': return this.devices;
		}
	}
}