import type { FunctionComponent } from 'preact';
import { Footer } from './footer';
import { PageHeader } from './header';
import './page.scss';


export const Page: FunctionComponent = ({ children }) => (
	<div className="page-layout">
		<PageHeader/>
		<main>{ children }</main>
		<Footer/>
	</div>
);
