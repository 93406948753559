import type { ErrorDetails } from '@brng/common';
import type { FunctionComponent, JSX } from 'preact';


export const renderDetails = (details: ErrorDetails): string | JSX.Element => {
	if(typeof(details) === 'string')  {
		return details;
	}

	if(Array.isArray(details)) {
		<ul>
			{
				details.map(detail => (
					<li key={ detail }>{ JSON.stringify(detail, null, 2) }</li>
				))
			}
		</ul>;
	}

	return (
		<table>
			{
				Object.entries(details)
					.map(([ key, value ]) => (
						<div key={ key }>
							<strong>{key}</strong>: {JSON.stringify(value, null, 2)}
						</div>
					))
			}
		</table>
	);
};

export type ErrorDetailsDisplayComponent = FunctionComponent<{
	details: ErrorDetails | undefined;
}>;

export const ErrorDetailDisplay: ErrorDetailsDisplayComponent = ({ details }) => {
	const detailMessage = details ? renderDetails(details) : null;
	
	if(!details) {
		return null;
	}
	
	return (
		<div className="panel flex-column rounded">
			<h3 className="mrg-0">Details:</h3>
			<code className="mrg-t-sm">
				{ detailMessage }
			</code>
		</div>
	);
};