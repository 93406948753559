import type { BillingStatus, LicenseState, Metadata, SiteRaw } from './bearing';
import type { ErrorDetails, RepositoryErrorStatus, RepositoryProvider, RepositoryProviderMetadata } from './repository';
import type { User } from './user';
import type { Settings } from './settings';
import type { SquelchData } from './squelch';


export namespace GetDataResponse {
	export type Common = {
		license: {
			state: LicenseState;
			expires?: string;
			billing: BillingStatus;
		},
		version: string;
	};
	
	export type NoConfig = Common & {
		ok: false;
		reason: 'no-config';
		availableProviders: RepositoryProvider[];
	};
	export type NoAdmin = Common & {
		ok: false;
		reason: 'no-admin';
		usernames: string[];
	};
	export type Unauthenticated = Common & {
		ok: false;
		reason: 'unauthenticated',
	};
	export type RepositoryError = Common & {
		ok: false;
		me: User | null;
		reason: 'repository-error';
		provider: RepositoryProvider;
		status: RepositoryErrorStatus;
		details: ErrorDetails | undefined;
	};
	
	export type Error = (
		| NoConfig
		| NoAdmin
		| Unauthenticated
		| RepositoryError
	);
	
	export type Ok = Common & {
		ok: true;
		me: User;
		sites: SiteRaw[];
		hiddenSites: string[];
		metadata: Metadata;
		providerMetadata: RepositoryProviderMetadata;
		squelches: SquelchData;
		settings: Settings;
		updateVersion: string | undefined;
	};
}

export type GetDataResponse = GetDataResponse.Error | GetDataResponse.Ok;