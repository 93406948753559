import type { JSX } from 'preact/jsx-runtime';
import type { Severity } from '../../util';


export type NotificationMessageComponentProps = {
	dismissNotification: () => void;
};

export namespace NotificationInfo {
	export type Common = {
		/**
		 * If `type` is provided, and another notification with the same `type` is
		 * currently shown, it will be auto dismissed.
		 */
		type?: string;
		message: string | JSX.Element;
		severity: Severity;
	};

	/** A notification which will auto-dismiss after some time. */
	export type Transient = Common & {
		lifetime?: number;
	};

	/** A notification which must be actively dismissed by the user. */
	export type Persistent = Common & {
		persistent: true;
	};

	export const isPersistent = (info: NotificationInfo): info is NotificationInfo.Persistent => {
		return 'persistent' in info && info.persistent;
	};
}
export type NotificationInfo = NotificationInfo.Transient | NotificationInfo.Persistent;
export type NotificationId = number;

export namespace ActiveNotification {
	export type Common = {
		id: NotificationId;
		time: number;
		dismissed: boolean;
		dismissedTime: number;
		y: number;
	};
	
	export type Transient = Common & {
		persistent: false;
		info: NotificationInfo.Transient
	};

	export type Persistent = Common & {
		persistent: true;
		info: NotificationInfo.Persistent;
	};
}
export type ActiveNotification = ActiveNotification.Persistent | ActiveNotification.Transient;