import { useRef } from 'preact/hooks';

/**
 * Returns the most recently non-nullish value passed to it.
 */
export const useLatch = <T>(value: T | undefined | null): T | null => {
	const ref = useRef<T | null>(null);
	if(value !== undefined && value !== null) {
		ref.current = value;
	}

	return ref.current;
};