import type { FunctionComponent } from 'preact';
import type { RouteProps } from '../route-props';
import { useObservable } from 'ecce-preact';
import { useController } from '../../util';
import { AdminRouteController } from './admin-route-controller';
import { AdminTabsProvider } from './admin-tabs-context';
import { AdminTabControls } from './components';
import { AdminTabContent } from './components/admin-tab-content';
import { GoogleMapsAdmin } from './integrations';
import { RepositoryAdmin } from './repository';
import { SettingsAdmin } from './settings';
import './admin-route.scss';
import { UsersAdmin } from './users';


export const AdminRoute: FunctionComponent<RouteProps> = () => {
	const controller = useController(() => new AdminRouteController());
	useObservable(controller, 'loading');

	if(controller.loading) {
		return null;
	}

	return (
		<AdminTabsProvider>
			<div className="admin-route">
				<AdminTabControls className="admin-route__controls"/>
				<div className="admin-route__content-wrapper">
					<div className="admin-route__content">
						<AdminTabContent tabId="settings">
							<SettingsAdmin/>
						</AdminTabContent>

						<AdminTabContent tabId="users">
							<UsersAdmin/>
						</AdminTabContent>

						<RepositoryAdmin/>

						<AdminTabContent tabId="google-maps">
							<GoogleMapsAdmin/>
						</AdminTabContent>
					</div>
				</div>
			</div>
		</AdminTabsProvider>
	);
};