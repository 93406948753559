import type { SnmpHelperController } from '../snmp-helper-controller';
import { useObservable } from 'ecce-preact';
import { Dialog } from '../../../components';
import { useClipboardController, usePersistent } from '../../../util';
import './snmp-probe-sql-dialog.scss';


export type SnmpProbeSqlDialogProps = {
	controller: SnmpHelperController;
};
export const SnmpProbeSqlDialog = ({ controller }: SnmpProbeSqlDialogProps) => {
	const clipboard = useClipboardController();
	useObservable(controller, 'sqlDialogTarget');

	const probe = usePersistent(controller.sqlDialogTarget);
	
	const handleCopyClick = () => {
		if(!probe) {
			return;
		}
		
		clipboard.writeText(probe.sql, 'Copied SQL to clipboard.');
		controller.closeSqlDialog();
	};
	
	return (
		<Dialog
			className="snmp-probe-sql-dialog"
			open={ !!controller.sqlDialogTarget }
			onClose={ controller.closeSqlDialog }
		>
			<header>
				<h2>SQL query to create: { probe?.name }</h2>
				<button
					className="btn btn--icon-only"
					onClick={ controller.closeSqlDialog }
				>
					<i class="fa fa-times"/>
				</button>
			</header>
			<main>
				<code className="snmp-probe-sql-dialog__sql rounded">
					<pre className="font-mono text-sm">
						{ probe?.sql }
					</pre>
				</code>
				<div className="copy">
					<p>
						The SQL provided above is an example of a probe <code>INSERT</code>
						&nbsp;into the <code>SNMPProbe</code> table of the Powercode database.
					</p>
					<p>
						You should verify any queries which modify your SQL database and be
						confident they can be reversed safely.
					</p>
				</div>
				<div className="text-sm">
					<h3>Legal note:</h3>&nbsp;

					There is no warranty of any kind, express or implied, for the SQL
					example provided. In no event shall the authors be liable for any
					claim, damages or other liability associated with the provided SQL
					example.
				</div>
			</main>
			{ clipboard.enabled && (
				<footer>
					<button
						className="btn btn--primary"
						onClick={ handleCopyClick }
					>
						<i class="fa fa-copy"/>&nbsp;
						Copy SQL
					</button>
				</footer>
			)}
			
		</Dialog>
	);
};