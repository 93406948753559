import type { VNode } from 'preact';
import type { ClassProp } from '../../../util';
import type { ErrorInfo } from '../../../services/error-service/bearing-error';
import { ErrorDetailDisplay } from '../error-details';
import { PowercodeRepositoryErrorContent } from './powercode-repository-error-content';
import { StackTrace } from './stack-trace';


export type ErrorDialogContentProps = ClassProp & {
	errorInfo: ErrorInfo
};
export const ErrorDialogContent = ({ className, errorInfo }: ErrorDialogContentProps): VNode => {
	switch(errorInfo.kind) {
		case 'repository':
			switch(errorInfo.response.provider) {
				case 'Powercode':
				case 'Local':
					return <div className={ className }><PowercodeRepositoryErrorContent errorInfo={ errorInfo }/></div>;
				default:
					return (
						<pre className="error-dialog__details">
							{JSON.stringify(errorInfo, null, 2)}
						</pre>
					);
			}
		case 'api':
			return (
				<div className={ className }>
					<div>{errorInfo.message}</div><br/>
					<ErrorDetailDisplay details={ errorInfo.details }/>
				</div>
			);
		
		case 'unknown':
			return (
				<div className={ className }>
					<strong>An unexpected error occurred:</strong><br/>
					<StackTrace error={ errorInfo.error }/>
				</div>
			);
	}
};