import type { Bounds, DeviceRaw, MidasStatus, Point } from '@brng/common';
import type { ReadonlyIssueSet } from './issues/issue-set';
import type { NetworkIssue } from './issues';
import type { Network } from './network';
import type { CustomerSite, Site } from './site';
import { asBounds, asBoundsFromPointAndRange, earth, notNullish } from '@brng/common';
import { DeviceGeometry } from '../util';
import { Radio } from './radio';
import { IssueSet } from './issues/issue-set';


export type DeviceType = 'Access Point' | 'Backhaul' | 'Subscriber Module';
export type AntennaType = 'Sector' | 'Omni';
export class Device {
	/**
	 * Unique ID for the Device in the Network.
	 */
	readonly networkId: `device-${string}`;
	readonly kind = 'device' as const;
	
	readonly id: string;
	readonly link: string | null;
	readonly name: string;
	readonly deviceType: string;
	readonly ipAddress: string;
	readonly type: DeviceType;
	readonly url: string;
	readonly antennaType: AntennaType;
	// TODO(#577): This is possibly undefined.
	readonly azimuth: number;
	readonly minAzimuth: number;
	readonly maxAzimuth: number;
	// TODO(#577): This is possibly undefined.
	readonly sectorSize: number;
	readonly height: number | undefined;
	readonly downtilt: number | undefined;
	readonly range: number | undefined;
	readonly status: string;
	readonly suppressWarnings: boolean;
	readonly snmpStatus: MidasStatus;

	readonly radios: readonly Radio[];

	/**
	 * True if device has azimuth, antennaType, sectorSize and a first radio with frequency band
	 */
	readonly partial: boolean;
	/**
	 * True if device has azimuth, antennaType, sectorSize and a first radio with frequency and channel width
	 */
	readonly valid: boolean;
	readonly geometry: DeviceGeometry;
	
	readonly site: Site;
	// TODO(#577): Deprecate this.
	readonly siteId: string;
	// TODO(#577): Deprecate this; added to maintain interface of device.sibling.
	readonly siteName: string;
	
	#customers: readonly CustomerSite[] = [];
	
	#siblingId: string | null;
	#sibling: Device | null = null;

	readonly #parentId: string | null;
	#parent: Device | null = null;

	readonly #childIds: string[] | null;
	#children: readonly Device[] | null = null;

	#issues = new IssueSet();

	constructor(site: Site, raw: DeviceRaw) {
		this.networkId = `device-${raw.id}`;
		this.site = site;
		this.siteId = site.id;
		this.siteName = site.name;
		
		this.id = raw.id;
		this.link = raw.link;
		this.name = raw.name;
		this.deviceType = raw.deviceType;
		this.ipAddress = raw.ipAddress;
		this.type = raw.type;
		this.url = raw.url;
		this.antennaType = raw.antennaType;
		this.azimuth = raw.azimuth;
		this.sectorSize = raw.sectorSize;
		this.height = raw.height;
		this.downtilt = raw.downtilt;
		this.range = raw.range;
		this.status = raw.status;
		this.suppressWarnings = raw.suppressWarnings;
		this.snmpStatus = raw.snmpStatus;

		this.radios = Object.freeze(
			raw.radios.map(r => new Radio(this, r))
		);
		
		this.partial = Device.#isFrequencyBandDataValid(this);
		this.valid = Device.#isFrequencyDataValid(this);
		if(this.valid && this.antennaType === 'Sector') {
			this.minAzimuth = this.azimuth - this.sectorSize / 2;
			this.maxAzimuth = this.azimuth + this.sectorSize / 2;
		} else {
			this.minAzimuth = this.azimuth;
			this.maxAzimuth = this.azimuth;
		}

		this.#siblingId = raw.siblingId;
		this.#parentId = raw.parentId;
		this.#childIds = raw.childIds;
		this.geometry = new DeviceGeometry(this);
	}

	/**
	 * Internal method called by {@link Network}; do not use.
	 */
	private initialise(network: Network): void {
		if(this.#siblingId) {
			this.#sibling = network.getDevice(this.#siblingId);
		}
		if(this.#parentId) {
			this.#parent = network.getDevice(this.#parentId);
		}
		if(this.#childIds) {
			this.#children = this.#childIds.map(id => network.getDevice(id)).filter(notNullish);
			this.#customers = this.#children.map(child => child.site as CustomerSite);
		}
	}

	/**
	 * Internal method called by {@link NetworkIssue} constructors; do not use.
	 */
	private addIssue(issue: NetworkIssue): void {
		this.#issues.add(issue);
		this.site['addIssue'](issue);
	}

	get bounds(): Bounds {
		const points = [ this.location ];
		if(!this.range) {
			return asBounds(points);
		}
		if(this.antennaType === 'Omni') {
			return asBoundsFromPointAndRange(this.location, this.range);
		}
		if(!Number.isFinite(this.azimuth)) {
			return asBounds(points);
		}
		// Rare, but appropriate to treat 0 as missing
		if(!this.sectorSize) {
			points.push(earth.point(this.location, this.range, this.azimuth));
		}
		else {
			const minAzimuth = this.azimuth - this.sectorSize / 2;
			const maxAzimuth = this.azimuth + this.sectorSize / 2;
			const isInSector = (azimuth: number) => (minAzimuth <= azimuth && maxAzimuth >= azimuth) || (minAzimuth <= (360 + azimuth) && maxAzimuth >= (360 + azimuth));
			points.push(earth.point(this.location, this.range, minAzimuth));
			points.push(earth.point(this.location, this.range, maxAzimuth));
			if(isInSector(0)) {
				points.push(earth.point(this.location, this.range, 0));
			}
			if(isInSector(90)) {
				points.push(earth.point(this.location, this.range, 90));
			}
			if(isInSector(180)) {
				points.push(earth.point(this.location, this.range, 180));
			}
			if(isInSector(-90)) {
				points.push(earth.point(this.location, this.range, 270));
			}
		}
		return asBounds(points);
	}

	get customers(): readonly CustomerSite[] {
		return this.#customers;
	}

	get sibling(): Device | null {
		return this.#sibling;
	}

	get parent(): Device | null {
		return this.#parent;
	}

	get children(): readonly Device[] | null {
		return this.#children;
	}

	get location(): Readonly<Point> {
		return this.site.location;
	}

	/**
	 * The ID of the site represented in the user's Repository, formatted for
	 * display in the UI.
	 */
	get sourceId(): string {
		return this.id;
	}

	/**
	 * The ID of the site represented in the user's Repository.
	 */
	get displayId(): string {
		return this.id;
	}

	/**
	 * @param device to check if it is related
	 * @returns boolean true if the device is a sibling, parent, child or sibling by parent
	 */
	public isRelated(device: Device) {
		return !!(this.sibling === device ||
			this.parent === device ||
			device.parent === this ||
			(this.parent && this.parent === device.parent));
	}

	get issues(): ReadonlyIssueSet {
		return this.#issues;
	}
	
	static #isFrequencyBandDataValid(device: Device): boolean {
		return !!((device.antennaType === 'Omni' || (device.antennaType === 'Sector' && Number.isFinite(device.azimuth) && Number.isFinite(device.sectorSize))) &&
			device.radios.length &&
			device.radios[0]?.band);
	}
	
	static #isFrequencyDataValid(device: Device): boolean {
		return Device.#isFrequencyBandDataValid(device) &&
			Number.isFinite(device.radios[0]?.frequency) &&
			Number.isFinite(device.radios[0]?.channelWidth);
	}
}