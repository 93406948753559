import type { JSXInternal } from 'preact/src/jsx';
import type { EquipmentOrder } from '../use-sorted-equipment';
import type { ClassProp } from '../../../util';
import type { JSX, VNode } from 'preact';
import { useCallback, useEffect, useMemo, useRef } from 'preact/hooks';
import { Toolbar } from '../../../components';
import { useData } from '../../../data';
import { EquipmentFilterControl } from '../../../filtering';
import { useSitesInfo } from '../use-sites-info';
import { EQUIPMENT_ORDERS, useSortedEquipment } from '../use-sorted-equipment';
import { ReportDownloadButton } from './reports-download-button';
import './sites-toolbar.scss';


const SiteSelect = ({ className }: ClassProp) => {
	const { data } = useData();
	const { selectSite, selectedSite } = useSitesInfo();

	const selectEl = useRef<HTMLSelectElement>(null);
	useEffect(() => {
		selectEl.current?.focus();
	}, []);

	const value = useMemo(() => selectedSite?.id ?? '', [ selectedSite ]);
	
	const handleInput = useCallback<JSXInternal.GenericEventHandler<HTMLSelectElement>>(ev => {
		selectSite(ev.currentTarget.value || null);
	}, [ selectSite ]);

		
	return (
		<select
			className={ className }
			ref={ selectEl }
			value={ value }
			onInput={ handleInput }
		>
			<option value={ '' }>Summary</option>
			{
				data?.sites
					.map(({ id, name }) => (
						<option key={ id } value={ id }>{name}</option>
					))
			}
		</select>
	);
};

const DeviceTypeSelect = ({ className }: ClassProp) => {
	const { deviceTypes, selectedDeviceType, selectDeviceType } = useSitesInfo();

	const handleChange = useCallback<JSX.GenericEventHandler<HTMLSelectElement>>(ev => {
		selectDeviceType(ev.currentTarget.value);
	}, [selectDeviceType]);
	
	if(!deviceTypes.length) {
		return null;
	}

	return (
		<select
			className={ className }
			value={ selectedDeviceType ?? '' }
			onInput={ handleChange }
		>
			<option value=''>All Device Types</option>
			{ deviceTypes.map(deviceType => (
				<option value={ deviceType } key={ deviceType }>{deviceType}</option>
			))}
		</select>
	);
};

const orderLabels: Record<EquipmentOrder, string> = {
	'azimuth': '⇅ Azimuth',
	'frequency': '⇅ Frequency',
	'name': '⇅ Name',
};

const EquipmentOrderSelect = () => {
	const { order, setOrder } = useSortedEquipment();

	const handleChange = useCallback<JSXInternal.GenericEventHandler<HTMLSelectElement>>(ev => {
		setOrder(ev.currentTarget.value as EquipmentOrder);
	}, [setOrder]);
	
	return (
		<select
			title="Sort Equipment"
			value={ order }
			onChange={ handleChange }
		>
			{ EQUIPMENT_ORDERS.map(order => (
				<option value={ order } key={ order }>
					{ orderLabels[order] }
				</option>
			))}
		</select>
	);
};

export const SitesToolbar = (): VNode => {
	const { selectedSite, equipmentFilter } = useSitesInfo();
	return (
		<Toolbar
			className="sites-toolbar"
			bp={ 1500 }
		>
			<SiteSelect className="sites-toolbar__site-select"/>
			<div className="sites-toolbar__options">
				{ selectedSite ? (
					<>
						<EquipmentFilterControl equipmentFilter={ equipmentFilter }/>
						<EquipmentOrderSelect/>
					</>
				) : (
					<>
						<DeviceTypeSelect className="sites-toolbar__device-select"/>
						<ReportDownloadButton/>
					</>
				)}
			</div>
		</Toolbar>
	);
};
