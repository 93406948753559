import type { Site } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { useMapController } from '../map-controller-context';
import { Marker } from './google-maps';
import { getMapSiteIcon } from './site-icons/site-icons';


export type MapInfrastructureSiteIconsProps = {
	site: Site;
	highlight?: boolean;
};
/**
 * Display the icon for a single site.
 */
export const MapInfrastructureSiteIcon = ({ site, highlight = false }: MapInfrastructureSiteIconsProps): JSX.Element | null => {
	const controller = useMapController();
	useObservable(controller, 'palette');
	
	return (
		<Marker
			position={ site.location }
			icon={ getMapSiteIcon(controller.palette.theme, site, highlight) }
			onClick={ () => controller['handleItemClicked'](site) }
			title={ site.name }
			clickable
		/>
	);
};