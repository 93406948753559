import { useEffect } from 'preact/hooks';
import { useNotificationService } from '../../services/services-context';


export type WirelessRangeWarningProps = {
	show: boolean;
};
export const WirelessRangeWarning = ({ show }: WirelessRangeWarningProps): null => {
	const { notify } = useNotificationService();
	
	useEffect(() => {
		if(!show) {
			return;
		}

		notify({
			type: 'wireless-range-warning',
			severity: 'warning',
			message: (
				<>
					<strong>Missing Wireless Range data</strong><br/>
					<a href="/faq#range">Click for details.</a>
				</>
			),
			persistent: true,
		});

	}, [notify, show]);
	
	return null;
};