import type { RefObject } from 'preact';
import { useCallback, useEffect, useRef } from 'preact/hooks';


type Autofocusable = { focus: () => void; };

type AutofocusHook<T extends Autofocusable> = {
	autofocusRef: RefObject<T>;
	refocus: () => void;
};
export const useAutofocus = <T extends Autofocusable = HTMLInputElement>(): AutofocusHook<T> => {
	const ref = useRef<T>(null);

	const refocus = useCallback(() => {
		ref.current?.focus();
	}, []);

	useEffect(() => {
		refocus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return { autofocusRef: ref, refocus };
};

/**
 * Test if `el` is an anchor or a button element descending from `root`.
 */
export const isInteractiveDescendant = (root: unknown, el: unknown): boolean => {
	if(!el || !(el instanceof HTMLElement) || !root || !(root instanceof HTMLElement)) {
		return false;
	}

	switch(el.tagName) {
		case 'A':
		case 'BUTTON':
			return true;
		default:
			if(!el.parentElement || el.parentElement === root) {
				return false;
			}
			return isInteractiveDescendant(root, el.parentElement);
	}
};

export class NullContextError extends Error {
	constructor(contextName: string, providerName: string) {
		super(`${contextName} was null; missing <${providerName}>?`);
		this.name = 'NullContextError';
	}
}