import type { DescriptorKey, MidasDeviceValues } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import type { AlertSeverity } from '../../../../../components';
import type { IconName } from '../../../../../components/atoms/icon/icons';
import type { ClassProp } from '../../../../../util';
import classNames from 'classnames';
import { isNullish } from '@brng/common';
import { formatMacAddress , formatDateTime } from '../../../../../util';
import { Alert, DefinitionList } from '../../../../../components';
import './device-snmp-values.scss';


const VALUE_KEYS = [
	'systemName',
	'centerFrequency',
	'channelWidth',
	'latitude',
	'longitude',
	'altitude',
	'macAddresses',
	'remoteMacAddresses',
] as const satisfies readonly DescriptorKey[];

const VALUE_LABEL: Record<typeof VALUE_KEYS[number], string> = {
	'systemName': 'System Name',
	'centerFrequency': 'Center Frequency',
	'channelWidth': 'Channel Width',
	'latitude': 'Latitude',
	'longitude': 'Longitude',
	'altitude': 'Altitude',
	'macAddresses': 'Internal MAC addresses',
	'remoteMacAddresses': 'Remote MAC addresses',
};

type ValueStatus = {
	severity: AlertSeverity;
	icon: IconName;
	date: number | null;
};
const getStatus = (value: MidasDeviceValues[DescriptorKey] | undefined): ValueStatus => {
	if(!value || (!value.lastSuccess && !value.lastSuccess)) {
		return {
			severity: 'warning',
			icon: 'unknown',
			date: null,
		};
	}

	const success = value.lastSuccess ?? 0;
	const failure = value.lastFailure ?? 0;

	if(success > failure) {
		return {
			severity: 'success',
			icon: 'success',
			date: success,
		};
	}
	
	return {
		severity: 'error',
		icon: 'error',
		date: failure,
	};
};

const isBuffer = (x: object | null): x is { data: number[] } => (
	!!x && 'type' in x && x.type === 'Buffer'
);

const formatValue = (value: unknown, type: DescriptorKey): string | number | JSX.Element | null => {
	if(isNullish(value)) {
		return null;
	}
	switch(typeof(value)) {
		case 'string':
		case 'number':
			return value;
		case 'object':
			if(Array.isArray(value)) {
				if(!value.length) {
					return null;
				}

				return (
					<ul>
						{ value.map((item, index) => (
							<li key={ index }>
								{ type === 'macAddresses' || type === 'remoteMacAddresses'
									? formatMacAddress(item)
									: item
								}
							</li>
						)) }
					</ul>
				);
			}

			if(isBuffer(value)) {
				return <>[Buffer] {value.data.join(', ')}</>;
			}
			
			return <pre>{ JSON.stringify(value)}</pre>;
		default:
			return JSON.stringify(value);
	}
};

export type DeviceSnmpValueProps = ClassProp &{
	values: MidasDeviceValues | null | undefined;
};
export const DeviceSnmpValues = ({ className, values }: DeviceSnmpValueProps): JSX.Element | null => {
	return (
		<section className={ className }>
			<ul className="panel-group">
				{ VALUE_KEYS.map(key => {
					const update = values?.[key];

					const { severity, icon, date } = getStatus(update);
					return (
						<li
							className={ classNames('device-snmp-value panel') }
							key={ key }
						>
							<Alert
								className={ `device-snmp-value__name device-snmp-value__name--${severity}` }
								severity={ severity }
								title={ VALUE_LABEL[key] }
								icon={ icon }
								size="md"
							>
								{ update?.message }
							</Alert>
							{ update && (
								<DefinitionList
									itemClassName="panel--dark"
									monospace
									definitions={ [
										{
											label: 'OID',
											value: update.oid,
										}, {
											label: 'Value',
											value: formatValue(update.value, key),
										}, {
											label: 'Raw Value',
											value: formatValue(update.raw, key),
										},
									] }
								/>
							)}

							{ !update && <span>No data.</span>}

							{ date && (
								<small className="device-snmp-value__date">
									Updated { formatDateTime(date) }
								</small>
							)}
						</li>
					);
				})}
			</ul>
		</section>
	);
};