import type { Device , Site } from '@brng/domain';
import type { NetworkService } from '../network-service';
import type { SVGOverlay } from '../../util';
import type { BandName } from '@brng/common';
import { coverageSVG, devicesSVGs } from '../../util';
import { Overlay } from './overlay';


export type OverlayRenderingServiceDependencies = {
	networkService: NetworkService;
};

/**
 * Data required to render a map overlay for a device.
 */
export type RenderableDevice = Device; // TODO: get things working then figure this as there are options...

export type RenderOptions = {
	devices: readonly RenderableDevice[];
	bandsToRender: readonly BandName[];
	overlayRadius: 'actual' | number;
	light?: boolean;
	highlight?: readonly string[];
	conflict?: readonly string[];
};
export class OverlayRenderingService {
	#pngCache: Record<string, Overlay> = {};
	
	constructor(deps: OverlayRenderingServiceDependencies) {
		deps.networkService.on('network', () => {
			this.#pngCache = {};
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	devicesSVGs({ devices, bandsToRender, overlayRadius, light = false, highlight = [], conflict = [] }: RenderOptions): string[] {
		return devicesSVGs(devices, bandsToRender, overlayRadius, light, highlight, conflict).map(svgOverlay => svgOverlay.svg);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	devicesOverlays({ devices, bandsToRender, overlayRadius, light = false, highlight = [], conflict = [] }: RenderOptions): Overlay[] {
		return this.#toOverlays(devicesSVGs(devices, bandsToRender, overlayRadius, light, highlight, conflict));
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	coverageOverlay(sites: Site[], light = false): Overlay {
		return this.#toOverlayPNG(coverageSVG(sites, light));
	}
	
	#toOverlays(svgOverlays: SVGOverlay[]):Overlay[] {
		if(svgOverlays.length <=2) {
			return svgOverlays.map(svgOverlay => this.#toOverlaySVG(svgOverlay));
		}
		return svgOverlays.map(svgOverlay => this.#toOverlayPNG(svgOverlay));
	}
	
	#toOverlaySVG(svgOverlay: SVGOverlay):Overlay {
		const overlay = new Overlay(
			svgOverlay.key + JSON.stringify(svgOverlay.bounds),
			svgOverlay.bounds
		);
		overlay.setUrl(`data:image/svg+xml,${encodeURIComponent(svgOverlay.svg)}`);
		return overlay;
	}
	
	#toOverlayPNG(svgOverlay: SVGOverlay):Overlay {
		const key = svgOverlay.key + JSON.stringify(svgOverlay.bounds);
		if(!this.#pngCache[key]) {
			const overlay = new Overlay(
				key,
				svgOverlay.bounds
			);
			this.#svgToPNG(svgOverlay).then(dataUrl => overlay.setUrl(dataUrl));
			this.#pngCache[key] = overlay;
		}
		return this.#pngCache[key];
	}
	
	#svgToPNG({ svg ,width ,height }: SVGOverlay): Promise<string> {
		return new Promise(resolve => {
			const canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;
			const image = new Image(width, height);

			const urlFromCanvas = () => {
				const context = canvas.getContext('2d') as CanvasRenderingContext2D;
				context.drawImage(image, 0, 0);
				return canvas.toDataURL('image/png');
			};

			image.onload = () => resolve(urlFromCanvas());
			image.src = `data:image/svg+xml,${encodeURIComponent(svg)}`;
		});
	}
}