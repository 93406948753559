import type { RepositoryConfig_Local } from '@brng/common';
import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import { usePatchReducer, type ClassProp } from '../../util';


export type LocalRepositorySetupFormProps = ClassProp & {
	initialConfig?: RepositoryConfig_Local | undefined | null;
	onSubmit?: (config: RepositoryConfig_Local) => void;
};

const EMPTY_STATE: Readonly<RepositoryConfig_Local> = {
	provider: 'Local',
	dataFile: '',
};

export const LocalRepositorySettingsForm = ({ className, initialConfig, onSubmit }: LocalRepositorySetupFormProps): JSX.Element => {
	const [ config, patchConfig ] = usePatchReducer(initialConfig ?? EMPTY_STATE);

	const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = ev => {
		ev.preventDefault();
		onSubmit?.(config);
	};

	return (
		<form
			className={ classNames(className, 'form') }
			onSubmit={ handleSubmit }
		>
			<label>
				<span>Data file path</span>
				<input
					type="text"
					value={ config.dataFile }
					onChange={ ev => patchConfig({ dataFile: ev.currentTarget.value }) }
				/>
			</label>
			<button className="btn mrg-l-auto" type="submit">Configure Local Data</button>
		</form>
	);
};