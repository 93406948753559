import type { GetDataResponse, PostRepositoryResponse, RepositoryConfig, RepositoryProvider } from '@brng/common';
import type { DataService } from '../../../services';
import type { RepositoryService } from '../../../services/repository-service';
import { bound, observable } from 'ecce-preact';


export type SetupRepositoryControllerConfig = {
	response: GetDataResponse.NoConfig;
	repositoryService: RepositoryService;
	dataService: DataService;
	beginLoading: () => VoidFunction;
};
export class SetupRepositoryController {
	readonly #response: GetDataResponse.NoConfig;
	readonly #repositoryService: RepositoryService;
	readonly #dataService: DataService;
	readonly #beginLoading: () => VoidFunction;

	#provider: RepositoryProvider | null = null;
	get provider(): RepositoryProvider | null { return this.#provider; }
	@observable() private set provider(value: RepositoryProvider | null) { this.#provider = value; }

	#error: PostRepositoryResponse.Error | null = null;
	get error(): PostRepositoryResponse.Error | null { return this.#error; }
	@observable() private set error(value: PostRepositoryResponse.Error | null) { this.#error = value; }

	constructor(config: SetupRepositoryControllerConfig) {
		this.#response = config.response;
		this.#repositoryService = config.repositoryService;
		this.#dataService = config.dataService;
		this.#beginLoading = config.beginLoading;

		if(this.#response.availableProviders.length === 1) {
			this.#provider = this.#response.availableProviders[0];
		}
	}

	get availableProviders(): readonly RepositoryProvider[] {
		return this.#response.availableProviders;
	}

	@bound()
	resetProvider(): void {
		this.provider = null;
	}

	@bound()
	chooseProvider(provider: RepositoryProvider): void {
		this.provider = provider;
	}

	@bound()
	async submitConfig(config: RepositoryConfig): Promise<void> {
		const endLoading = this.#beginLoading();
		try {
			this.error = null;
			const result = await this.#repositoryService.setupConfiguration(config);
			if(result.ok) {
				await this.#dataService.refresh();

				if(config.provider === 'Manual') {
					window.history.replaceState('', '', '/admin#csv-upload');
				}

				return;
			}
			this.error = result;
		} finally {
			endLoading();
		}
	}
}