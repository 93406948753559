import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { Checkbox } from '../../../components';
import { useSettingsService } from '../../../services';
import { SettingsSection } from './settings-section';



export const ErrorReportSettings = (): JSX.Element => {
	const settingsService = useSettingsService();
	useObservable(settingsService.settings, 'automaticErrorReports');

	return (
		<SettingsSection title="Error reporting">
			<div id="automaticErrorReportsDescription" className="form__description">
				If enabled, Bearing will automatically send crash and error reports.
			</div>

			<Checkbox
				id="automaticErrorReportsCheckbox"
				label="Enable automatic error reports"
				checked={ settingsService.settings.automaticErrorReports }
				onChange={ checked => settingsService.update('automaticErrorReports', checked) }
				aria-describedby="automaticErrorReportsDescription"
			/>
		</SettingsSection>
	);
};