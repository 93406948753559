import type { InfrastructureSite, Site } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import type { MapRouteController } from '../map-route-controller';
import type { MapRouteFocus } from '../map-route-focus';
import { isCustomerSite, isInfrastructureSite } from '@brng/domain';
import { useObservable } from 'ecce-preact';
import { MapInfoPopupSite } from '../../../map/components/controls/map-info-popup-site';
import './map-route-info-popup.scss';


type FocusButtonMode = 'focus' | 'unfocus';
const getFocusButtonMode = (focus: MapRouteFocus | null, site: Site): FocusButtonMode => {
	if(!focus) {
		return 'focus';
	}
	
	if(isInfrastructureSite(site)) {
		return focus.sites.includes(site) ? 'unfocus' : 'focus';
	}

	if(isCustomerSite(site)) {
		return focus.customer === site ? 'unfocus' : 'focus';
	}

	return 'focus';
};

export type MapRouteInfoPopupProps = {
	controller: MapRouteController
};
export const MapRouteInfoPopupWindow = ({ controller }: MapRouteInfoPopupProps): JSX.Element | null => {
	useObservable(controller.display, 'infoPopupTarget');
	useObservable(controller.display, 'focus');

	const site = controller.display.infoPopupTarget;
	if(!site) {
		return null;
	}

	const focusMode = getFocusButtonMode(controller.display.focus, site);
	const handleFocusClick = () => {
		controller.display.closeInfoPopup();
		switch(focusMode) {
			case 'focus':
				return controller.display.setFocus(site);
			case 'unfocus':
				return controller.display.removeFromFocus(site);
		}
	};

	const canAddToFocus = (
		controller.display.focus?.kind === 'infrastructureSites'
		&& isInfrastructureSite(site)
		&& !controller.display.focus.sites.includes(site)
	);
	const handleAddToFocus = () => {
		// Button will only be displayed if `site` is infrastructure.
		controller.display.addToFocus(site as InfrastructureSite);
		controller.display.closeInfoPopup();
	};
	
	return (
		<MapInfoPopupSite
			site={ controller.display.infoPopupTarget }
			onClose={ controller.display.closeInfoPopup }
		>
			<div className="button-group map-route-info-popup-actions">
				<button
					className="btn"
					onClick={ handleFocusClick }
				>
					{ focusMode === 'focus' ? 'Focus' : 'Unfocus' }
				</button>
				{ canAddToFocus && (
					<button
						style="flex: 1"
						className="btn"
						onClick={ handleAddToFocus }
					>
						Add to Focus
					</button>
				)}

			</div>

		</MapInfoPopupSite>
	);
};