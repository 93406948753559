import type { CustomerSite, Site } from '@brng/domain';
import type { JSX } from 'preact/jsx-runtime';
import { earth } from '@brng/common';
import { isCustomerSite, isInfrastructureSite } from '@brng/domain';
import { Children } from 'preact/compat';
import { Link, ObjectTable } from '../../../components';
import { useRepositoryProviderName } from '../../../services';
import { formatDegrees, formatDistance, formatFrequency, type ChildrenProp } from '../../../util';
import { MapInfoPopup } from './map-info-popup';


const getCustomerSiteData = (customer: CustomerSite): Record<string, string | JSX.Element | null> => {
	const device = customer.parentDevices?.[0];
	if(!device) {
		return {
			'Equipment': 'Not assigned',
		};
	}

	return {
		'Equipment': device.name,
		'Frequency': formatFrequency(device.radios[0]?.frequency),
		'Range': formatDistance(earth.distance(device.site.location, customer.location)),
		'Bearing': formatDegrees(earth.azimuth(device.site.location, customer.location)),
	};
};

export type MapInfoPopupSiteProps = ChildrenProp & {
	site: Site | null;
	onClose?: VoidFunction;
};
export const MapInfoPopupSite = ({ site, onClose, children }: MapInfoPopupSiteProps): JSX.Element | null => {
	const repositoryName = useRepositoryProviderName();

	if(!site) {
		return null;
	}

	return (
		<MapInfoPopup
			position={ site.location }
			onClose={ onClose }
			title={ site.name }
			offsetY={ -24 }
		>
			<div className="stack-0">
				{isInfrastructureSite(site) && (
					<Link
						label="Site Overview"
						icon="pieChart"
						href={ '/?siteId=' + site.id }
					/>
				)}
				<Link
					label="Inventory"
					icon="clipboard"
					href={ '/inventory#' + site.networkId }
				/>
				{ site.link && (
					<Link
						label={ repositoryName }
						href={ site.link }
						icon="powercode"
					/>
				)}
			</div>

			{ isCustomerSite(site) && (
				<>
					<hr/>
					<ObjectTable
						data={ getCustomerSiteData(site) }
					/>
				</>
			)}


			{ !!Children.count(children) && (
				<>
					<hr/>
					{ children }
				</>
			)}

		</MapInfoPopup>
	);
};