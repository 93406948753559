/**
 * Get the shallow diff between properties of `oldObj` & `newObj`, or `null` if
 * oldObj is shallow equal to `newObj`.
 * @param oldObj
 * @param newObj
 * @returns
 */
export const shallowDiffObject = <T extends Record<string, unknown>>(oldObj: T | null | undefined, newObj: T): Partial<T> | null => {
	if(!oldObj) {
		return { ...newObj };
	}

	const diff = Object.fromEntries(
		Object.entries(newObj).filter(([ k, v ]) => oldObj[k] !== v)
	) as Partial<T>;

	if(Object.keys(diff).length === 0)  {
		return null;
	}
	
	return diff;
};

export const deepEqual = (a: unknown, b: unknown): boolean => {
	if(a === b) {
		return true;
	}

	if(typeof(a) !== typeof(b)) {
		return false;
	}

	if(Array.isArray(a) && a.length === (b as []).length) {
		for(let i=0; i<a.length; ++i) {
			const itemA = a[i];
			const itemB = (b as [])[i];
			if(!deepEqual(itemA, itemB)) {
				return false;
			}
		}

		return true;
	}

	if(typeof(a) === 'object') {
		if(!a || !b) {
			return false;
		}
		
		for(const key of Object.keys(a)) {
			const valueA = (a as Record<string, unknown>)[key];
			const valueB = (b as Record<string, unknown>)[key];
			if(!deepEqual(valueA, valueB)) {
				return false;
			}
		}

		return true;
	}

	return false;
};

export const notNullish = <T>(x: T | null | undefined): x is T => x !== null && x !== undefined;
export const isNullish = <T>(x: T | null | undefined): x is null | undefined => x === null || x === undefined;

export type ObjectEntryComparator = <K extends string, V>(a: [ K, V ], b: [ K, V ]) => number;
const defaultObjectComparator: ObjectEntryComparator = ([ kA ], [ kB ]) => {
	if(kA > kB) {
		return 1;
	} else if(kB > kA) {
		return -1;
	}
	return 0;
};
export const sortObjectEntries = <K extends string, V>(object: Record<K, V>, comparator = defaultObjectComparator): Record<K, V> => (
	Object.fromEntries(Object.entries(object).sort(comparator)) as Record<K, V>
);


export const mapEntries = <InK extends string, InV, OutK extends string, OutV>(object: Record<InK, InV>, map: (entry: [InK, InV]) => [OutK, OutV] | null): Record<OutK, OutV> => (
	Object.fromEntries(
		Object.entries(object)
			.map(entry => map(entry as [InK, InV]))
			.filter(Array.isArray) as [OutK, OutV][]
	) as Record<OutK, OutV>
);

export const filterEntries = <V>(object: Readonly<Record<string, V>>, filter: (entry: [ string, V ]) => boolean): Record<string, V> => (
	Object.fromEntries(
		Object.entries(object)
			.filter(filter)
	)
);

export const omitNullish = <T extends Record<string, unknown>>(object: T): Partial<T> => (
	filterEntries(object, ([, value ]) => notNullish(value)) as Partial<T>
);

export const pick = <T extends Record<string, unknown>, K extends keyof T>(obj: Readonly<T>, keys: Readonly<K[]>): Pick<T, K> => (
	filterEntries(obj, ([k]) => keys.includes(k as K)) as Pick<T, K>
);

export const isConstructable = (x: unknown): x is (new (...args: unknown[]) => unknown) => {
	return typeof(x) === 'function' && !!x.prototype?.constructor;
};