import type { FunctionComponent } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import './expander.scss';


const DURATIONS = {
	'sm': 200,
	'md': 400,
};
export type ExpanderProps = {
	open: boolean;
	duration?: 'sm' | 'md';
	onTransitionComplete?: (open: boolean) => void;
	alwaysTransition?: boolean;
};
export const Expander: FunctionComponent<ExpanderProps> = ({ open, duration = 'md', onTransitionComplete, alwaysTransition = false, children }) => {
	const root = useRef<HTMLDivElement>(null);
	const content = useRef<HTMLDivElement>(null);

	const transition = useRef<number>();
	const [ targetHeight, setTargetHeight ] = useState(0);

	const immediateTransition = useRef(!alwaysTransition);
	
	useEffect(() => {
		clearTimeout(transition.current);
		setTargetHeight(open ? content.current!.scrollHeight : 0); // eslint-disable-line @typescript-eslint/no-non-null-assertion
		transition.current = setTimeout(() => {
			onTransitionComplete?.(open);
			immediateTransition.current = false;
		}, DURATIONS[duration]) as unknown as number;
	}, [open, children, onTransitionComplete, duration]);
	
	const rootStyle = useMemo(() => {
		const immediate = immediateTransition.current;
		immediateTransition.current = false;
		return {
			height: targetHeight,
			transition: immediate ? undefined : `height ${DURATIONS[duration]}ms`,
		};
	}, [targetHeight, duration]);
	
	useEffect(() => {
		const contentEl = content.current;
		if(!contentEl || !open) {
			return;
		}

		const observer = new ResizeObserver(() => {
			immediateTransition.current = true;
			setTargetHeight(contentEl.scrollHeight);
		});

		observer.observe(contentEl);
		return () => observer.disconnect();
	}, [open]);

	return (
		<div
			className="expander"
			ref={ root }
			style={ rootStyle }
		>
			<div
				className="expander__content"
				ref={ content }
			>
				{ children }
			</div>
		</div>
	);
};