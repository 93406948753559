import type { JSX } from 'preact/jsx-runtime';
import type { Overlay } from '../../services';
import { useObservable } from 'ecce-preact';
import { GroundOverlay } from './google-maps';


export type MapDeviceOverlayProps = {
	overlay: Overlay;
};
export const MapDeviceOverlay = ({ overlay }: MapDeviceOverlayProps): JSX.Element | null => {
	useObservable(overlay, 'url');
	
	if(!overlay.url) {
		return null;
	}
	
	return (
		<GroundOverlay
			bounds={ overlay.bounds as unknown as google.maps.LatLngBounds }
			url={ overlay.url }
		/>
	);
};