import type { BandName } from '@brng/common';
import type { Device, InterSiteInterferenceIssue, Network, OutOfRangeIssue, OutOfSectorIssue, Site } from '@brng/domain';
import { useMemo } from 'preact/hooks';
import { useIsSquelched } from '../../services';


export type IssuePageIssue = InterSiteInterferenceIssue | OutOfRangeIssue | OutOfSectorIssue;
export const ISSUE_PAGE_ISSUE_KINDS = [ 'interSite', 'outOfRange', 'outOfSector' ] as const satisfies readonly IssuePageIssue['kind'][];


export type IssuePageFilter = {
	kind: IssuePageIssue['kind'];
	outOfSectorTolerance: number;
	outOfRangeTolerance: number;
	interSiteRange: number;
	interSiteBand: undefined | BandName;
};

export type IssuePageIssuesByEquipment = {
	equipment: Device;
	issues: IssuePageIssue[];
};

export type IssuePageIssuesBySite = {
	site: Site;
	issueCount: number;
	issuesByEquipment: IssuePageIssuesByEquipment[];
};

export type ProcessedIssuePageIssues = {
	numUnfilteredIssues: number;
	issues: IssuePageIssue[];
	issuesBySite: IssuePageIssuesBySite[];
	siteIds: Set<string>;
	equipmentIds: Set<string>;
};

const filterIssue = (issue: IssuePageIssue, { outOfRangeTolerance, outOfSectorTolerance, interSiteRange, interSiteBand }: IssuePageFilter): boolean => {
	switch(issue.kind) {
		case 'outOfRange':
			return Math.floor(issue.range / 1000) > outOfRangeTolerance;
		case 'outOfSector':
			return issue.percent > outOfSectorTolerance;
		case 'interSite':
			return (
				issue.range < interSiteRange * 1000
				&& (!interSiteBand || issue.frequencyBand.band === interSiteBand)
			);
		default:
			return true;
	}
};

export const useProcessedAnalysis = (network: Network, filter: IssuePageFilter): ProcessedIssuePageIssues => {
	const isSquelched = useIsSquelched();

	return useMemo<ProcessedIssuePageIssues>(() => {
		const filtered: ProcessedIssuePageIssues = {
			numUnfilteredIssues: 0,
			issues: [],
			issuesBySite: [],
			siteIds: new Set(),
			equipmentIds: new Set(),
		};

		const bySite: Record<string, IssuePageIssuesBySite> = {};
		const byEquipment: Record<string, IssuePageIssuesByEquipment> = {};

		const addIssue = (site: Site, equipment: Device, issue: IssuePageIssue) => {
			if(!filtered.siteIds.has(site.id)) {
				filtered.siteIds.add(site.id);

				const processedSite: IssuePageIssuesBySite = {
					site,
					issueCount: 0,
					issuesByEquipment: [],
				};
				filtered.issuesBySite.push(processedSite);
				bySite[site.id] = processedSite;
			}

			if(!filtered.equipmentIds.has(equipment.id)) {
				filtered.equipmentIds.add(equipment.id);

				const processedEquipment: IssuePageIssuesByEquipment = {
					equipment,
					issues: [],
				};

				bySite[site.id].issuesByEquipment.push(processedEquipment);
				byEquipment[equipment.id] = processedEquipment;
			}

			if(!isSquelched(issue.kind, issue.id)) {
				++bySite[site.id].issueCount;
			}
			byEquipment[equipment.id].issues.push(issue);
		};

		for(const issue of network.issues.getByKind(filter.kind, { includeSquelched: true })) {
			++filtered.numUnfilteredIssues;

			if(filterIssue(issue, filter)) {
				switch(issue.kind) {
					case 'outOfRange':
					case 'outOfSector': {
						addIssue(issue.device.site, issue.device, issue);
						break;
					}
					case 'interSite':
						for(let i=0; i<2; ++i) {
							addIssue(issue.devices[i].site, issue.devices[i], issue);
						}
						break;
				}
			}
		}

		filtered.issuesBySite.sort((a, b) => a.site.name.localeCompare(b.site.name));
		filtered.issuesBySite.forEach(site => {
			site.issuesByEquipment.sort((a, b) => a.equipment.name.localeCompare(b.equipment.name));
		});

		filtered.issuesBySite.forEach(bySite => {
			bySite.issuesByEquipment.forEach(eq => {
				filtered.issues.push(...eq.issues);
			});
		});
		
		return filtered;
	}, [filter, isSquelched, network.issues]);
};