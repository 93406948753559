import type { ApiServiceDependencies } from './api-service';
import type { DataServiceConfig } from './data-service';
import { h } from 'preact';
import { ApiService } from './api-service';
import { NetworkErrorMessage } from './api-service/network-error-message';
import { AuthService } from './auth-service';
import { DataService } from './data-service';
import { ErrorService } from './error-service';
import { NetworkService } from './network-service';
import { NotificationService } from './notification-service';
import { OverlayRenderingService } from './overlay-rendering-service/overlay-rendering-service';
import { RepositoryService } from './repository-service';
import { SettingsService } from './settings-service';
import { SquelchService } from './squelch-service';


export type ServicesConfig = {
	dataService: DataServiceConfig;
};
export type ServicesDependencies = {
	apiService: ApiServiceDependencies;
};

/**
 * The service layer.
 */
export class Services {
	readonly apiService: ApiService;
	readonly errorService: ErrorService;
	readonly dataService: DataService;
	readonly authService: AuthService;
	readonly networkService: NetworkService;
	readonly repositoryService: RepositoryService;
	readonly settingsService: SettingsService;
	readonly squelchService: SquelchService;
	readonly overlayRenderingService: OverlayRenderingService;
	readonly notificationService: NotificationService;

	constructor(config: ServicesConfig, deps: ServicesDependencies) {
		this.apiService = new ApiService(deps.apiService);
		this.dataService = new DataService(config.dataService, {
			apiService: this.apiService,
		});
		this.authService = new AuthService({
			apiService: this.apiService,
			dataService: this.dataService,
		});
		this.squelchService = new SquelchService({
			apiService: this.apiService,
			dataService: this.dataService,
			authService: this.authService,
		});
		this.networkService = new NetworkService({
			dataService: this.dataService,
			squelchHandler: this.squelchService,
		});
		this.notificationService = new NotificationService();
		this.settingsService = new SettingsService({
			apiService: this.apiService,
			dataService: this.dataService,
			notificationService: this.notificationService,
		});
		this.errorService = new ErrorService({
			dataService: this.dataService,
			settingsService: this.settingsService,
		});

		this.repositoryService = new RepositoryService({
			apiService: this.apiService,
			dataService: this.dataService,
			notificationService: this.notificationService,
		});
		this.overlayRenderingService = new OverlayRenderingService({
			networkService: this.networkService,
		});

		this.apiService.on('networkError', () => {
			this.notificationService.notify({
				severity: 'error',
				type: 'networkError',
				message: h(NetworkErrorMessage, {}),
			});
		});

		this.settingsService.on('changed', ev => {
			if(ev.key === 'gpsSyncEnabled' || ev.key === 'gpsSyncAzimuth') {
				this.networkService.updateSettings(ev.settings);
			}
		});
	}

	initialise() {
		this.dataService.initialise();
		this.notificationService.initialise();
	}
}