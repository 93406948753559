import type { SetupRepositoryController } from '../setup-repository-controller';
import type { JSX } from 'preact/jsx-runtime';
import { useObservable } from 'ecce-preact';
import { Alert } from '../../../../components';
import { LocalRepositorySettingsForm } from '../../../local';
import { RepositoryErrorAlert } from '../../../repository-config-error-alert';


export type SetupLocalRepositoryViewProps = {
	controller: SetupRepositoryController;
};
export const SetupLocalRepositoryView = ({ controller }: SetupLocalRepositoryViewProps): JSX.Element => {
	useObservable(controller, 'error');

	return (
		<div className="stack-lg">
			<Alert
				severity="info"
				icon="file"
				title="Configure local data"
			/>

			<RepositoryErrorAlert
				providerName="Local"
				response={ controller.error }
			/>

			<LocalRepositorySettingsForm
				className="content content--narrow"
				onSubmit={ controller.submitConfig }
			/>
		</div>
	);
};