import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../../util';
import type { IconName } from './icons';
import { useRepositoryProviderName } from '../../../services';
import { Icon } from './icon';


export const getRepositoryIconName = (providerName: string): IconName => {
	switch(providerName) {
		case 'Powercode':
			return 'powercode';
		case 'Local':
			return 'file';
		case 'Manual':
			return 'uploadFile';
		default:
			return 'database';
	}
};

export const RepositoryIcon = (p: ClassProp): JSX.Element | null => {
	const providerName = useRepositoryProviderName();
	if(!providerName) {
		return <span className={ p.className }/>;
	}

	return <Icon icon={ getRepositoryIconName(providerName) }/>;
};