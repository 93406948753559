import type { JSX } from 'preact/jsx-runtime';
import type { ClassProp } from '../../util';
import type { ItemKindFilter } from './item-kind-filter';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { FilterButton } from '../filter-button';


export type ItemKindFilterViewProps = ClassProp & {
	filter: ItemKindFilter;
};
export const ItemKindFilterView = ({ className, filter }: ItemKindFilterViewProps): JSX.Element => {
	useObservable(filter);
	
	return (
		<div className={ classNames(className, 'button-group') }>
			<FilterButton
				label="Sites"
				shortLabel="Sites"
				checked={ filter.sites }
				onChange={ filter.setSites }
			/>
			<FilterButton
				label="Equipment"
				shortLabel="Eq"
				checked={ filter.devices }
				onChange={ filter.setDevices }
			/>
		</div>
	);
};