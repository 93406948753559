import type { VNode } from 'preact';
import type { Severity } from '../util';
import classNames from 'classnames';
import { useMemo } from 'preact/hooks';
import './badge.scss';


export type BadgeProps = {
	className?: string;
	value: number;
	label: string;
	severity: Severity;
};
export const Badge = ({ className, label, value, severity }: BadgeProps): VNode => {
	const computedClassName = useMemo(() => classNames('badge', `badge--${severity}`, className), [className, severity]);
	
	return <strong className={ computedClassName } title={ label }><span>{value}</span></strong>;
};