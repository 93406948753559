import type { JSX } from 'preact/jsx-runtime';
import classNames from 'classnames';
import './map-control-surface.scss';

/**
 * Generic surface for controls rendered on top of a Google map.
 */
export const MapControlSurface = (props: JSX.HTMLAttributes<HTMLDivElement>): JSX.Element => {
	return (
		<div
			{ ...props }
			className={ classNames(props.className, 'map-control-surface') }
		/>
	);
};