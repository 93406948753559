import type { VNode } from 'preact';
import { useAnalysisIssues } from '../analysis-provider';


export const NoFiltersMatchNotice = (): VNode | null => {
	const { issues: { numUnfilteredIssues, issues } } = useAnalysisIssues();

	if(!numUnfilteredIssues || issues.length) {
		return null;
	}
		
	return (
		<div className="pad-md mrg-t-lg flex-center">
			<strong className="text-lg">No issues match your filter criteria</strong>
		</div>
	);
};