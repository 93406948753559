import type { FunctionComponent } from 'preact';
import { useData } from '../../data';
import { SitesSummary } from './components';
import { SiteDetails } from './components/site-details';
import { SitesToolbar } from './components/sites-toolbar';
import { SitesInfoProvider } from './use-sites-info';
import { SortedEquipmentProvider } from './use-sorted-equipment';
import './site-route.scss';


export const SiteRoute: FunctionComponent = () => {
	const { data } = useData();

	if(!data) {
		return null;
	}
	
	return (
		<SortedEquipmentProvider>
			<SitesInfoProvider>
				<SitesToolbar/>
				<div className="sites pad-md">
					<SitesSummary/>
					<SiteDetails/>
				</div>
			</SitesInfoProvider>
		</SortedEquipmentProvider>
	);
};
