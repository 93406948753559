import type { ComponentChildren } from 'preact';
import { Component } from 'preact';

/**
 * Utility component to wrap externally managed DOM.
 * Reference: https://preactjs.com/guide/v10/external-dom-mutations
 */
export class NoUpdate extends Component {
	shouldComponentUpdate(): boolean {
		return false;
	}

	render(): ComponentChildren {
		return this.props.children;
	}
}
