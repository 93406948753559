import type { JSX } from 'preact/jsx-runtime';
import type { SelectOption } from '../../../../../components';
import type { ClassProp } from '../../../../../util';
import type { InventoryDeviceOrder } from '../../../inventory-sorting';
import { Icon, Select } from '../../../../../components';
import { useInventoryController } from '../../../inventory-controller-context';


const ORDER_OPTIONS: SelectOption<InventoryDeviceOrder>[] = [
	{
		value: 'default',
		label: 'Default',
	}, {
		value: 'snmpStatus',
		label: 'SNMP Status',
	},
];

export const InventoryDeviceOrderView = ({ className }: ClassProp): JSX.Element | null => {
	const controller = useInventoryController();

	return (
		<div className={ className }>
			<div className="flex-align-center gap-sm mrg-l-auto" title="Sort Equipment">
				<Icon icon="sort"/>
				<label className="visually-hidden" htmlFor="sortEquipmentSelect">
					Sort Equipment
				</label>

				<Select
					id="sortEquipmentSelect"
					options={ ORDER_OPTIONS }
					value={ controller.deviceOrder }
					onChange={ controller.setDeviceOrder }
				/>
			</div>
		</div>
	);
};