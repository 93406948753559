import type { FunctionComponent } from 'preact';
import type { Device, Site } from '@brng/domain';
import type { BandName } from '@brng/common';
import { createContext } from 'preact';
import { useContext, useMemo } from 'preact/hooks';
import { useSitesInfo } from '../../use-sites-info';


type SiteDetailsContext = {
	equipmentByFrequencyBand: Record<BandName, Device[]>;
	site: Site;
};

const context = createContext<SiteDetailsContext | null>(null);

export const SiteDetailsProvider: FunctionComponent = ({ children }) => {
	const { selectedSite } = useSitesInfo();

	const equipmentByFrequencyBand = useMemo<SiteDetailsContext['equipmentByFrequencyBand']>(() => {
		if(!selectedSite) {
			return {} as Record<BandName, Device[]>;
		}

		const equipmentByFreq: Partial<Record<BandName, Device[]>> = {};

		selectedSite.devices.forEach(eq => {
			const freq = eq.radios[0]?.band?.band;
			if(!freq) {
				return;
			}

			(equipmentByFreq[freq] ??= []).push(eq);
		});
		
		return Object.fromEntries(
			Object.entries(equipmentByFreq)
				.sort(([ a ], [ b ]) => a.localeCompare(b))
		) as Record<BandName, Device[]>;
		
	}, [ selectedSite ]);


	const value = useMemo<SiteDetailsContext | null>(() => {
		if(!selectedSite) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return null!;
		}

		return { site: selectedSite, equipmentByFrequencyBand };
	}, [selectedSite, equipmentByFrequencyBand ]);

	return <context.Provider value={ value } children={ children }/>;
};

export const useSiteDetails = (): SiteDetailsContext => {
	const { selectedSite } = useSitesInfo();
	const ctx = useContext(context);

	if(!selectedSite) {
		throw new Error('selectedSite was null. This hook should not be called without a site selected!');
	}
	if(!ctx) {
		throw new Error('siteDetailsContext was null. Missing <SiteDetailsProvider>?');
	}

	return ctx;
};
