import type { ClassProp } from '../../../util';
import type { SpectrumSliderController } from './spectrum-slider-controller';
import classNames from 'classnames';
import { useObservable } from 'ecce-preact';
import { useConstant } from '../../../util';
import { SpectrumGraphView } from '../spectrum-graph';
import { SpectrumSliderInputManager } from './spectrum-slider-input-manager';
import './spectrum-slider-view.scss';


export type SpectrumSliderViewProps = ClassProp & {
	id?: string;
	controller: SpectrumSliderController;
};
export const SpectrumSliderView = ({ id, className, controller }: SpectrumSliderViewProps) => {
	useObservable(controller, 'frequency');
	useObservable(controller, 'channelWidth');

	const input = useConstant(() => new SpectrumSliderInputManager(controller));
	useObservable(input, 'dragging');
	
	const rootClassName = classNames('spectrum-slider', {
		'spectrum-slider--dragging': input.dragging,
	}, className);
	
	return (
		<div
			id={ id }
			className={ rootClassName }
			ref={ input.graphRef }
			tabIndex={ 0 }
			onKeyDown={ input.handleKeyDown }
			onPointerDownCapture={ input.handlePointerDownCapture }
			aria-role="slider"
			aria-valuemin={ controller.minFrequency }
			aria-valuemax={ controller.maxFrequency }
			aria-valuenow={ controller.frequency }
			aria-valuetext={ `${controller.frequency}MHz` }
		>
			<SpectrumGraphView
				className="spectrum-slider__graph"
				graph={ controller.graph }
				ariaHidden
			/>
		</div>
	);
};